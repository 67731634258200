import React from 'react';
import { createGlobalStyle, css, rem, tablet } from 'theme';

import * as Elements from './elements';
import { Normalized } from './NormalizedStyle';

// Spread imported elements into a new object to add typings.
const globalElements: { [key: string]: React.ComponentClass } = { ...Elements };

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    line-height: 1.6;
  }

  body {
    font-family: ${(p) => p.theme.fonts.stacks.primary};
    font-size: ${rem(14)};
    color: ${(p) => p.theme.colors.gray900};

    ${tablet(css`
      font-size: ${rem(16)};
    `)}
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  @font-face {
    font-family: 'Diodrum';
    src: url('https://origin.ziggo.nl/etc/designs/nlgeneric/dist/fonts/diodrum-medium.woff2') format('woff2');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Diodrum';
    src: url('https://origin.ziggo.nl/etc/designs/nlgeneric/dist/fonts/diodrum-semibold.woff2') format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('https://origin.ziggo.nl/etc/designs/nlgeneric/dist/fonts/open-sans-v15-latin-600.woff2') format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('https://origin.ziggo.nl/etc/designs/nlgeneric/dist/fonts/open-sans-v15-latin-700.woff2') format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'OpenSans';
    src: url('https://origin.ziggo.nl/etc/designs/nlgeneric/dist/fonts/open-sans-v15-latin-regular.woff2') format('woff2');
    font-weight: 400;
  }
`;

export default () => (
  <React.Fragment>
    <Normalized />
    <GlobalStyle />
    {/* Iterate over all elements and return its components */}
    {Object.keys(globalElements).map((elementName) => {
      const Component = globalElements[elementName];
      return <Component key={elementName} />;
    })}
  </React.Fragment>
);
