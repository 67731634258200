export enum PaymentArrangementStartDateEnum {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export enum PaymentArrangementTypeEnum {
  ELIGIBLE = 'availablePA',
  ACTIVE = 'activePA',
  PAST = 'pastPA',
  HISTORY = 'historyPA',
  NO_HISTORY = 'noHistoryPA',
}

export enum PaymentArrangementPaymentMethodEnum {
  CASH = 'CA',
  DIRECT_DEBIT = 'DD',
}

export enum ActivePaymentArrangementStatusEnum {
  BROKEN = 'BROKEN',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  COMPLETED_BEFORE_DUE_DATE = 'COMPLETED_BEFORE_DUE_DATE',
  OPEN = 'OPEN',
  RENEGOTIATED = 'RENEGOTIATED',
  STOPCOLLECTION = 'STOPCOLLECTION',
  GRACE_PERIOD = 'GRACE_PERIOD',
}

export enum ActivePaymentArrangementInstallmentStatusEnum {
  BACKED_OUT = 'BACKED_OUT',
  CANCELLED = 'CANCELLED',
  DD_REQ_SENT = 'DD_REQ_SENT',
  PAID = 'PAID',
  PAID_BEFORE_DUE_DATE = 'PAID_BEFORE_DUE_DATE',
  PENDING = 'PENDING',
  RENEGOTIATED = 'RENEGOTIATED',
  UNPAID = 'UNPAID',
}

export enum PaymentArrangementHistoryStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface ActivePaymentArrangementInstallment {
  /**
   * Active PA installment identifier.Active PA term number
   * @type {string}
   * @memberof ActivePaymentArrangementInstallment
   */
  installmentId: string;
  /**
   * Active PA installment status
   * @type {ActivePaymentArrangementInstallmentStatusEnum}
   * @memberof ActivePaymentArrangementInstallment
   */
  status: ActivePaymentArrangementInstallmentStatusEnum;
  /**
   * Active PA installment amount
   * @type {number}
   * @memberof ActivePaymentArrangementInstallment
   */
  amount: number;
  /**
   * Active PA installment due date
   * @type {string}
   * @memberof ActivePaymentArrangementInstallment
   */
  dueDate: string;
}

export interface ActivePaymentArrangement {
  /**
   * Active payment arrangement identifier
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  PAId: string;
  /**
   * Total active PA amount to be paid
   * @type {number}
   * @memberof ActivePaymentArrangement
   */
  pastDueAmount: number;
  /**
   * Agreed PA amount
   * @type {number}
   * @memberof ActivePaymentArrangement
   */
  agreedDueAmount: number;
  /**
   * Active PA status
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  status: ActivePaymentArrangementStatusEnum;
  /**
   * Total active PA amount already paid (the sum of paid terms(installments))
   * @type {number}
   * @memberof ActivePaymentArrangement
   */
  paidAmount: number;
  /**
   * Bankaccount linked to active PA
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  bankAccountNumber: string;
  /**
   * Active PA start date
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  startDate: string;
  /**
   * Active PA end date (due date of last installment)
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  endDate: string;
  /**
   * Active PA payment method
   * @type {string}
   * @memberof ActivePaymentArrangement
   */
  paymentMethod: PaymentArrangementPaymentMethodEnum;
  /**
   * Active PA payment method
   * @type {Array<ActivePaymentArrangementInstallment>}
   * @memberof ActivePaymentArrangement
   */
  installments: ActivePaymentArrangementInstallment[];
}

export interface RecommendedPaymentArrangementInstallment {
  /**
   * Recommended policy code. Policy for consumer customer for collection process fixed
   * @type {string}
   * @memberof RecommendedPaymentArrangementInstallment
   */
  policyCode: string;
  /**
   * Active PA installment identifier.Active PA term number
   * @type {string}
   * @memberof RecommendedPaymentArrangementInstallment
   */
  installmentId: string;
  /**
   * Number of terms
   * @type {number}
   * @memberof RecommendedPaymentArrangementInstallment
   */
  installmentCount: number;
  /**
   * Term amount
   * @type {number}
   * @memberof RecommendedPaymentArrangementInstallment
   */
  installmentAmount: number;
}

export interface RecommendedPaymentArrangement {
  /**
   * Installments of recommended PA
   * @type {RecommendedPaymentArrangementInstallment[]}
   * @memberof RecommendedPaymentArrangement
   */
  installments: RecommendedPaymentArrangementInstallment[];
}

export interface PaymentArrangementHistory {
  /**
   * Payment Arrangement Identifier
   * @type {string}
   * @memberof PaymentArrangementHistory
   */
  PAId: string;
  /**
   * PA start date
   * @type {string}
   * @memberof PaymentArrangementHistory
   */
  startDate: string;
  /**
   * PA end date
   * @type {string}
   * @memberof PaymentArrangementHistory
   */
  lastUpdateDate: string;
  /**
   * PA status
   * @type {string}
   * @memberof PaymentArrangementHistory
   */
  status: PaymentArrangementHistoryStatusEnum;
  /**
   * Past due amount
   * @type {number}
   * @memberof PaymentArrangementHistory
   */
  pastDueAmount: number;
}
export interface PaymentArrangement {
  /**
   * Customer is eligible (or not) to create a new Payment arrangement
   * @type {boolean}
   * @memberof PaymentArrangement
   */
  isEligible: boolean;
  /**
   * The reason why the customer is not eligible
   * @type {string | null}
   * @memberof PaymentArrangement
   */
  reason: string | null;
  /**
   * Total PA amount to be paid in a new PA
   * @type {number | null}
   * @memberof PaymentArrangement
   */
  pastDueAmount: number;
  /**
   * Customer is in collection suspend
   * @type {string}
   * @memberof PaymentArrangement
   */
  collectionSuspend: boolean;
  /**
   * Current collection step
   * @type {string | null}
   * @nullable
   * @memberof PaymentArrangement
   */
  currentCollectionStep: string | null;
  /**
   * Debt reason
   * @type {string | null}
   * @memberof PaymentArrangement
   */
  debtReason: string | null;
  /**
   * Active payment arrangement
   * @type {ActivePaymentArrangement | null}
   * @memberof PaymentArrangement
   */
  activePaymentArrangement: ActivePaymentArrangement | null;
  /**
   * Recommended payment arrangement
   * @type {RecommendedPaymentArrangement | null}
   * @memberof PaymentArrangement
   */
  recommendedPaymentArrangement: RecommendedPaymentArrangement | null;
  /**
   * Payment arrangement history
   * @type {PaymentArrangementHistory[]}
   * @memberof PaymentArrangement
   */
  paymentArrangementHistory: PaymentArrangementHistory[];
  /**
   * Customer's current bank code
   * @type {string}
   * @memberof PaymentArrangement
   */
  bankCode: string;
  /**
   * Customer's current bank account number
   * @type {string}
   * @memberof PaymentArrangement
   */
  bankAccountNumber: string;
  /**
   * Customer's current mandate identifier
   * @type {string}
   * @memberof PaymentArrangement
   */
  mandateId: string;
  /**
   * Customer's current mandate sign date
   * @type {string}
   * @memberof PaymentArrangement
   */
  mandateSignDate: string;
}

export interface CreatePaymentArrangementRequest {
  /**
   * ContactId of fixed Unify customer to create CRM interaction
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  contactId: string;
  /**
   * Recommended policy code
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  policyCode: string;
  /**
   * PA amount to be paid
   * @type {number}
   * @memberof CreatePaymentArrangementRequest
   */
  pastDueAmount: number;
  /**
   * Number of terms
   * @type {number}
   * @memberof CreatePaymentArrangementRequest
   */
  installmentCount: number;
  /**
   * Term amount / first due amount
   * @type {number}
   * @memberof CreatePaymentArrangementRequest
   */
  installmentAmount: number;
  /**
   * Start date first term
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  startDate: string;
  /**
   * DD or CA (Direct Debit or Cash)
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  paymentMethod: PaymentArrangementPaymentMethodEnum;
  /**
   * The employee identifier will be mapped to memo field in BSL request.
   * Used in all rbac controls and audits as a unique key
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  employeeId: string;
  /**
   * Customer current bank code. Mandatory if paymentMethod=DD
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  bankCode: string;
  /**
   * Customer current bank account number
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  bankAccountNumber: string;
  /**
   * Customer current mandate identifier
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  mandateId: string;
  /**
   * Customer current mandate sign date
   * @type {string}
   * @memberof CreatePaymentArrangementRequest
   */
  mandateSignDate: string;
  /**
   * Customer is in collection suspend
   * @type {boolean}
   * @memberof CreatePaymentArrangementRequest
   */
  collectionSuspend: boolean;
}

export interface CreatePaymentArrangementResponse {
  /**
   * Status code
   * @type {string}
   * @memberof CreatePaymentArrangementResponse
   */
  statusCode: string;
  /**
   * Status code description
   * @type {string}
   * @memberof CreatePaymentArrangementResponse
   */
  statusDescription: string;
}

export enum CreatePaymentArrangementErrorsEnum {
  WRONG_START_DATE = 'CL1-000320: start date of treatment is less then first due date of installment plan',
}
