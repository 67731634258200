import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import {
  CreatePaymentArrangementErrorsEnum,
  CreatePaymentArrangementRequest,
  CreatePaymentArrangementResponse,
  PayMeansPaymentMethodEnum,
  PaymentArrangement,
  PaymentArrangementPaymentMethodEnum,
  PaymentArrangementStartDateEnum,
  RecommendedPaymentArrangementInstallment,
} from 'api';
import { ErrorMessage, Radio } from 'components/Form';
import { validators } from 'components/Form/validate';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { PayMeansContext } from 'context/pay-means.context';
import { useContactDetailsContext } from 'pages/ContactDetails/contact-details.context';
import React, { useContext, useRef, useState } from 'react';
import { SESSION_STORAGE_KEYS, SessionStorage } from 'utils';
import { ContentUtil } from 'utils/content';
import { DateUnit, dateUtils } from 'utils/date';

import { PaymentInstallmentsRadioGroup } from './PaymentInstallmentsRadioGroup';
import { PaymentMethodsRadioGroup } from './PaymentMethodsRadioGroup';
import { content } from './payment-arrangement.content';
import * as S from './payment-arrangement.styles';

const contentUtil = new ContentUtil({ content, componentName: 'CreatePaymentArrangement' });

interface ICreatePaymentArrangementProps {
  recommendedInstallments: RecommendedPaymentArrangementInstallment[];
  paymentArrangement: PaymentArrangement | null;
  createPaymentArrangement: (
    paymentArrangement: CreatePaymentArrangementRequest
  ) => Promise<CreatePaymentArrangementResponse>;
  verifyPaymentArrangement: () => Promise<void>;
}

interface ICreatePAFormValues {
  selectedInstallmentId: string;
  selectedPaymentMethod: PaymentArrangementPaymentMethodEnum;
  selectedStartDateType: PaymentArrangementStartDateEnum;
  agreementCheck: boolean;
}

export const CreatePaymentArrangement = ({
  recommendedInstallments,
  paymentArrangement,
  createPaymentArrangement,
  verifyPaymentArrangement,
}: ICreatePaymentArrangementProps) => {
  if (!recommendedInstallments?.length || !paymentArrangement) return null;

  const { bankCode, bankAccountNumber, collectionSuspend, pastDueAmount, mandateId, mandateSignDate } =
    paymentArrangement;
  const { contactDetails } = useContactDetailsContext();
  if (!contactDetails) return null;

  const { id: contactId } = contactDetails.primaryContact;
  const startDateInput = useRef<HTMLInputElement>(null);

  const minPaymentStartDate = dateUtils.format(dateUtils.add(new Date(), 6, DateUnit.DAYS), 'yyyy-MM-dd');
  const maxPaymentStartDate = dateUtils.format(dateUtils.add(new Date(), 29, DateUnit.DAYS), 'yyyy-MM-dd');

  const [createPASuccess, setCreatePASuccess] = useState(false);
  const [createPAFailed, setCreatePAFailed] = useState(false);
  const [createPAFailedTitle, setCreatePAFailedTitle] = useState('errorTitle');
  const [checkboxIsChecked, setCheckboxIsChecked] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(PaymentArrangementPaymentMethodEnum.DIRECT_DEBIT);
  const [datePickerType, setDatePickerType] = useState('text');
  const [startDateType, setStartDateType] = useState(PaymentArrangementStartDateEnum.AUTOMATIC);
  const [paymentStartDate, setPaymentStartDate] = useState(minPaymentStartDate);
  const [paymentStartDateError, setPaymentStartDateError] = useState<string>();
  const { payMeans } = useContext(PayMeansContext);

  if (createPASuccess) {
    return (
      <Row>
        <Col l={9} data-testid="payment-arrangement-create-success">
          <S.PaymentArrangementCard>
            <S.Title>{contentUtil.translateToString('createPA.successTitle')}</S.Title>
            <InlineAlert type="success" testid="create-payment-arrangement-succeed">
              {contentUtil.translateToString('createPA.successDescription')}
            </InlineAlert>
          </S.PaymentArrangementCard>
        </Col>
      </Row>
    );
  }

  const handleSubmit = async (values: ICreatePAFormValues) => {
    if (createPASuccess || createPAFailed || !values) return;

    const selectedInstallment = recommendedInstallments.find(
      (item: RecommendedPaymentArrangementInstallment) => item.installmentId === values.selectedInstallmentId
    );

    if (!selectedInstallment || !values.selectedPaymentMethod) {
      setCreatePAFailed(true);
      return;
    }

    const { policyCode, installmentAmount, installmentCount } = selectedInstallment;
    const startDate =
      values.selectedStartDateType === PaymentArrangementStartDateEnum.AUTOMATIC
        ? minPaymentStartDate
        : paymentStartDate;

    const pa = {
      contactId,
      policyCode,
      pastDueAmount,
      installmentCount,
      installmentAmount,
      startDate: dateUtils.format(startDate, 'yyyy-MM-dd HH:mm:ss'),
      paymentMethod: values.selectedPaymentMethod,
      employeeId: SessionStorage.get(SESSION_STORAGE_KEYS.agentId) || 'MyZiggo',
      bankCode,
      bankAccountNumber,
      mandateId,
      mandateSignDate,
      collectionSuspend,
    };

    try {
      const result = await createPaymentArrangement(pa);
      if (result?.statusCode === '400') {
        if (result?.statusDescription === CreatePaymentArrangementErrorsEnum.WRONG_START_DATE) {
          setCreatePAFailedTitle('errorStartDateTitle');
        }
        setCreatePAFailed(true);
        return;
      }
      await verifyPaymentArrangement();
      setCreatePASuccess(true);
    } catch (e) {
      setCreatePAFailed(true);
      setCheckboxIsChecked(false);
    }
  };

  const onFormValuesChange = (values: ICreatePAFormValues) => {
    if (!values) return;

    if (values.selectedPaymentMethod !== paymentMethod) {
      setPaymentMethod(values.selectedPaymentMethod);
    }

    if (values.selectedStartDateType !== startDateType) {
      setStartDateType(values.selectedStartDateType);

      if (values.selectedStartDateType === PaymentArrangementStartDateEnum.MANUAL) {
        startDateInput?.current?.toggleAttribute('disabled', false);
        startDateInput?.current?.setAttribute('min', minPaymentStartDate);
        startDateInput?.current?.setAttribute('max', maxPaymentStartDate);
        startDateInput?.current?.setAttribute('value', paymentStartDate);
        setDatePickerType('date');
      }

      if (values.selectedStartDateType === PaymentArrangementStartDateEnum.AUTOMATIC) {
        startDateInput?.current?.removeAttribute('min');
        startDateInput?.current?.removeAttribute('max');
        startDateInput?.current?.removeAttribute('value');
        startDateInput?.current?.toggleAttribute('disabled', true);
        setPaymentStartDate('');
        setPaymentStartDateError(undefined);
        setDatePickerType('text');
      }
    }
  };

  const onPaymentStartDateChange = (): void => {
    const value = startDateInput?.current?.value;

    if (!value) return;

    if (
      dateUtils.isBefore(new Date(value), new Date(minPaymentStartDate)) ||
      dateUtils.isAfter(new Date(value), new Date(maxPaymentStartDate))
    ) {
      setPaymentStartDateError(contentUtil.translateToString('createPA.errorStartDateRange'));
      setPaymentStartDate('');
    } else {
      setPaymentStartDate(value);
      setPaymentStartDateError(undefined);
    }
  };

  const paymentInstallmentOptions = recommendedInstallments.map(
    ({ installmentId, installmentCount, installmentAmount }) => ({
      label: contentUtil.translateToString('createPA.paymentInstallment', {
        installmentCount: `${installmentCount.toString()} ${installmentCount === 1 ? 'termijn' : 'termijnen'}`,
        installmentAmount: installmentAmount.toFixed(2),
      }),
      value: installmentId,
    })
  );

  const paymentMethodOptions = [
    {
      label: contentUtil.translateToString('createPA.manualPayment'),
      value: PaymentArrangementPaymentMethodEnum.CASH,
    },
  ];

  if (payMeans?.paymentMethod !== PayMeansPaymentMethodEnum.MANUAL) {
    paymentMethodOptions.unshift({
      // @ts-expect-error TS(2322): Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
      label: bankAccountNumber
        ? contentUtil.translateHtml('createPA.automaticPayment', { bankAccountNumber })
        : contentUtil.translateToString('createPA.automaticPaymentNoAccountNumber'),
      value: PaymentArrangementPaymentMethodEnum.DIRECT_DEBIT,
      disabled: !bankAccountNumber,
      sublabel: (
        <S.ChangeAccountNumberLink data-testid="change-account-number" to="/gegevens/betaalgegevens-wijzigen">
          {bankAccountNumber
            ? contentUtil.translateToString('createPA.changeAccountNumber')
            : contentUtil.translateToString('createPA.fillInAccountNumber')}
        </S.ChangeAccountNumberLink>
      ),
    });
  }

  const paymentStartDateOptions = [
    {
      label: contentUtil.translateToString('createPA.automaticDate'),
      value: PaymentArrangementStartDateEnum.AUTOMATIC,
    },
    {
      label: contentUtil.translateToString('createPA.manualDate'),
      value: PaymentArrangementStartDateEnum.MANUAL,
    },
  ];

  let defaultPaymentMethod;
  if (!bankCode || !bankAccountNumber || payMeans?.paymentMethod === PayMeansPaymentMethodEnum.MANUAL) {
    defaultPaymentMethod = PaymentArrangementPaymentMethodEnum.CASH;
    paymentMethod !== PaymentArrangementPaymentMethodEnum.CASH &&
      setPaymentMethod(PaymentArrangementPaymentMethodEnum.CASH);
  } else {
    defaultPaymentMethod = PaymentArrangementPaymentMethodEnum.DIRECT_DEBIT;
  }

  return (
    <Row>
      <Col l={9} data-testid="payment-arrangement-create">
        <S.PaymentArrangementCard>
          <S.PaymentArrangementForm
            id="create-payment-arrangement-form"
            testid="create-payment-arrangement-form"
            hideCancelButton
            onSubmit={handleSubmit}
            buttonVariant={ButtonVariant.PRIMARY}
            buttonText={contentUtil.translateToString('createPA.button')}
            initialValues={{
              selectedStartDateType: PaymentArrangementStartDateEnum.AUTOMATIC,
              selectedInstallmentId: recommendedInstallments[0].installmentId,
              selectedPaymentMethod: defaultPaymentMethod,
              agreementCheck: false,
            }}
            isSuccess={createPASuccess || createPAFailed}
            onUpdate={onFormValuesChange}
            validate={(values) => {
              const validatorsList = [
                validators.required(values.agreementCheck),
                validators.directDebitHasBankAccount(bankAccountNumber)(values.selectedPaymentMethod),
              ];

              if (values.selectedStartDateType === PaymentArrangementStartDateEnum.MANUAL) {
                validatorsList.push(validators.required(paymentStartDate));
              }

              return validatorsList;
            }}
            render={() => {
              return (
                <>
                  {createPAFailed && (
                    <InlineAlert
                      type="error"
                      testid="create-payment-arrangement-failed"
                      onClose={() => {
                        setCreatePAFailed(false);
                        setCreatePAFailedTitle('');
                      }}>
                      {createPAFailedTitle
                        ? contentUtil.translateToString(`createPA.${createPAFailedTitle}`)
                        : contentUtil.translateToString('createPA.errorTitle')}
                    </InlineAlert>
                  )}
                  <S.Title>{contentUtil.translateToString('createPA.title')}</S.Title>
                  <div>{contentUtil.translateHtml('createPA.subTitle')}</div>
                  <div>
                    {contentUtil.translateHtml('createPA.totalAmount', {
                      pastDueAmount: pastDueAmount ? pastDueAmount.toFixed(2) : (0).toFixed(2),
                    })}
                  </div>
                  {contactDetails?.primaryContact?.email ? (
                    <React.Fragment>
                      <S.Text>{contentUtil.translateToString('createPA.confirmationEmail')}</S.Text>
                      <S.EmailContent>
                        <S.EmailTitle>{contactDetails?.primaryContact.email}</S.EmailTitle>
                        <S.ChangeEmail
                          data-testid="change-email-address-button"
                          to="/gegevens/contactgegevens-wijzigen">
                          {contentUtil.translateToString('createPA.changeEmail')}
                        </S.ChangeEmail>
                      </S.EmailContent>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <InlineAlert type="warning" testid="fill-in-email-address-warning">
                        {contentUtil.translateToString('createPA.noEmail')}
                        <S.FillEmailAddressLink
                          data-testid="fill-in-email-address-button"
                          to="/gegevens/contactgegevens-wijzigen">
                          {contentUtil.translateToString('createPA.fillInEmail')}
                        </S.FillEmailAddressLink>
                      </InlineAlert>
                    </React.Fragment>
                  )}
                  <S.OverviewTitle>{contentUtil.translateToString('createPA.chooseNumberOfPayments')}</S.OverviewTitle>
                  <PaymentInstallmentsRadioGroup options={paymentInstallmentOptions} />
                  <S.OverviewTitle>{contentUtil.translateToString('createPA.choosePaymentMethod')}</S.OverviewTitle>
                  <PaymentMethodsRadioGroup options={paymentMethodOptions} />
                  <S.OverviewTitle>{contentUtil.translateToString('createPA.chooseStartDate')}</S.OverviewTitle>
                  <S.RadioContent>
                    <Radio id="selectedStartDateType" name="selectedStartDateType" options={paymentStartDateOptions} />
                    <S.DatePicker error={!!paymentStartDateError}>
                      <input
                        data-testid="start-date-component"
                        ref={startDateInput}
                        type={datePickerType}
                        title={contentUtil.translateToString('createPA.mouseOverTooltip')}
                        onChange={onPaymentStartDateChange}
                      />
                      {paymentStartDateError && (
                        <ErrorMessage className="error-message" message={paymentStartDateError} />
                      )}
                    </S.DatePicker>
                  </S.RadioContent>
                  <S.AgreementCheckbox
                    id="agreementCheck"
                    name="agreementCheck"
                    data-testid="agreement-checkbox"
                    label={
                      paymentMethod === PaymentArrangementPaymentMethodEnum.DIRECT_DEBIT
                        ? contentUtil.translateToString('createPA.automaticCustomerAgreement')
                        : contentUtil.translateHtml('createPA.manualCustomerAgreement')
                    }
                    validate={[validators.acceptTerms]}
                    checked={checkboxIsChecked}
                    onChange={() => setCheckboxIsChecked(!checkboxIsChecked)}
                  />
                  <S.AddendumText>{contentUtil.translateHtml('createPA.addendumPayEveryMonth')}</S.AddendumText>
                  {paymentMethod === PaymentArrangementPaymentMethodEnum.CASH && (
                    <S.AddendumText>{contentUtil.translateHtml('createPA.addendumPayOnTime')}</S.AddendumText>
                  )}
                  <S.AddendumText>{contentUtil.translateHtml('createPA.addendumStopArrangement')}</S.AddendumText>
                </>
              );
            }}
          />
        </S.PaymentArrangementCard>
      </Col>
    </Row>
  );
};
