import { ExternalLinkIcon } from 'assets/icons';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled, { css, gapSize, hoverFocus, rem, spacingBase, tablet } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

import { ButtonProps } from './Button';

export const ButtonStyles = css<ButtonProps>`
  font-size: ${rem(16)};
  font-family: ${(p) => p.theme.fonts.stacks.secondary};
  border: 0;
  border-radius: ${borderRadius.button};
  font-weight: ${(p) => p.theme.fonts.weights.bold};
  padding: ${() => gapSize(1.5)} ${() => gapSize(1)};
  box-shadow: ${boxShadow.buttonLight};
  cursor: pointer;
  transition: ${(p) => p.theme.transitions.default};
  transition-property: opacity;
  height: ${rem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};

  span {
    align-self: center;
    line-height: ${rem(16)};
  }

  ${(p) =>
    hoverFocus(css`
      opacity: 0.7;
      color: ${p.theme.colors.white};

      svg {
        fill: ${p.theme.colors.white};
      }
    `)}

  &:disabled {
    cursor: not-allowed;
    background-color: ${(p) => p.theme.colors.gray300};
    color: ${(p) => p.theme.colors.white};
    ${(p) =>
      hoverFocus(css`
        background-color: ${p.theme.colors.gray300};
        color: ${p.theme.colors.white};
      `)};
  }

  &.outline {
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.black};
    border: ${rem(2)} solid ${(p) => p.theme.colors.black};
    box-shadow: none;
    ${(p) =>
      hoverFocus(css`
        opacity: 1;
        color: ${p.theme.colors.white};
        background: ${p.theme.colors.black};
      `)}

    &:disabled {
      background: ${(p) => p.theme.colors.white};
      border-color: ${(p) => p.theme.colors.gray300};
      color: ${(p) => p.theme.colors.gray300};
    }
  }

  &.primary {
    background: ${(p) => p.theme.colors.primary};
    &:disabled {
      background-color: ${(p) => p.theme.colors.gray300};
      color: ${(p) => p.theme.colors.white};
    }
    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }

  ${tablet(css`
    width: ${rem(220)};
  `)};
`;

export const ExternalLink = styled(ExternalLinkIcon)`
  fill: ${(p) => p.theme.colors.primary};
  margin-left: ${() => spacingBase};
  width: ${rem(16)};
`;

export const Button = styled.button<ButtonProps>`
  ${ButtonStyles}
`;

export const Link = styled.a<ButtonProps>`
  ${ButtonStyles}
`;

export const NavLink = styled(RouterNavLink)<ButtonProps>`
  ${ButtonStyles}
`;
