import React from 'react';
import styled from 'theme';

const Loader = styled.svg`
  color: ${(p) => p.theme.colors.primary};
`;

const LoaderStroke = styled.path`
  animation:
    dashZiggo 2s ease-in-out reverse infinite,
    pulsate 1s linear infinite alternate;
  fill: none;
  stroke-dasharray: 96;
  stroke-miterlimit: 10;
  stroke-width: 3.4845;
  stroke: currentColor;

  @keyframes dashZiggo {
    50% {
      stroke-dashoffset: 96;
    }

    100% {
      stroke-dashoffset: 192;
    }
  }
`;

const LoaderFill = styled.path`
  animation: pulsate 1s linear infinite alternate;
  clip-rule: evenodd;
  fill-rule: evenodd;
  fill: currentColor;

  @keyframes pulsate {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }
`;

export const LoaderIcon = (props: React.SVGAttributes<SVGElement>) => (
  <Loader xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="50px" height="50px" {...props}>
    <LoaderStroke d="M28.2 6.6v16.7c0 2.7-2.2 4.8-4.8 4.8H6.7c-2.7 0-4.8-2.2-4.8-4.8V6.7C1.9 4 4 1.8 6.7 1.8h16.7c2.2 0 4 1.4 4.6 3.4" />
    <LoaderFill d="M23.2 23.4c-.1-1.3-.9-3.3-3.2-3.6h-7.9l9.1-8.7c.4-.4 1.2-1.6.6-2.9-.5-1.1-1.3-1.5-2.8-1.5H7.8c0 1.5.8 3.3 2.8 3.6h6.5l-9.5 9c-.4.4-1 1.5-.5 2.7.5 1 1.2 1.5 2.6 1.5h13.5v-.1z" />
  </Loader>
);
