import styled, { css } from 'styled-components';
import { rem, tablet } from 'theme';

export const Heading = styled.h5`
  padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(32)};

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const Content = styled.div`
  flex: 1;

  padding: ${rem(32)};

  border-top: solid 2px ${(p) => p.theme.colors.gray300};
  border-bottom: solid 2px ${(p) => p.theme.colors.gray300};

  a:hover {
    text-decoration: underline;
  }

  h4 {
    padding-bottom: ${rem(8)};
  }
`;

export const Section = styled.div`
  display: flex;
  height: calc(100vh - ${rem(2)});

  &:last-child {
    padding: 0;
  }

  flex-direction: column;

  ${tablet(css`
    height: auto;
  `)}
`;

export const Actions = styled.div`
  padding: ${rem(16)};
`;
