import { gql } from 'graphql-request';

export const getOutagesQuery = gql`
  query getOutages($billingCustomerId: String!) {
    getOutages(billingCustomerId: $billingCustomerId) {
      id
      type
      impactedProducts
      creationDate
      expectedSolveDate
      telephoneNumbers
      subscribed
    }
  }
`;

export const subscribeOutageQuery = gql`
  mutation subscribeOutage($billingCustomerId: String!, $id: String!, $telephoneNumbers: [String!]!) {
    subscribeOutage(billingCustomerId: $billingCustomerId, id: $id, telephoneNumbers: $telephoneNumbers) {
      success
    }
  }
`;

export const unsubscribeOutageQuery = gql`
  mutation unsubscribeOutage($billingCustomerId: String!, $id: String!) {
    unsubscribeOutage(billingCustomerId: $billingCustomerId, id: $id) {
      success
    }
  }
`;
