export enum WifiSpotStatusEnum {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  ORDERED = 'ORDERED',
}

export interface WifiSpot {
  status: WifiSpotStatusEnum;
  username: string;
  password: string;
}

export interface WifiSpotStatus {
  enabled: boolean;
}

export interface IWifiRoamingStatus {
  statusCode: string;
  statusDescription: string;
  severity: string | undefined;
  status: string;
  displayAction: string;
  customerId: string;
}
