import { LogoIconZiggo } from 'assets/img/Logo';
import { NavLink } from 'react-router-dom';
import styled, { css, gapSize, hoverFocus, rem, screenMobilePlusAndSmaller } from 'theme';

export const Header = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: ${rem(2)} solid ${(p) => p.theme.colors.grayL30};
  height: ${rem(85)};
  padding: 0 ${() => gapSize(3)};
`;

export const Logo = styled(LogoIconZiggo)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const LinkPaymentOverview = styled(NavLink)`
  position: absolute;
  left: ${() => gapSize(4)};
  color: ${(p) => p.theme.colors.gray900};

  ${(p) =>
    hoverFocus(css`
      color: ${p.theme.colors.gray900};
    `)}

  ${screenMobilePlusAndSmaller(css`
    svg {
      width: ${rem(20)};
      height: ${rem(20)};
    }
  `)}
`;

export const SecureConnection = styled('div')`
  position: absolute;
  right: ${() => gapSize(4)};
  color: ${(p) => p.theme.colors.gray300};

  ${screenMobilePlusAndSmaller(css`
    svg {
      width: ${rem(22)};
      height: ${rem(22)};
    }
  `)}
`;
