import { IContent } from 'models/content.model';

const globalContent: IContent = {
  nl: {
    global: {
      salutation: {
        SIR: 'Dhr.',
        MADAM: 'Mevr.',
        UNKNOWN: '',
      },
      countries: {
        NLD: 'Nederland',
        DEU: 'Duitsland',
        BEL: 'België',
      },
      forms: {
        button: {
          error: 'Helaas...',
          loading: 'Aan het laden',
          submit: 'Nu wijzigen',
          success: 'Bedankt voor het doorgeven!',
          cancel: 'Toch niet',
        },
        messages: {
          confirmation: 'Weet je het zeker?',
          'confirmation-cancel': 'Nee, toch niet',
          'confirmation-error': 'Oei!',
          'confirmation-success': 'Gelukt!',
          error:
            'We hebben niet alle gegevens goed ontvangen. Controleer alsjeblieft de velden en probeer het opnieuw.',
          success: 'Het formulier is vertuurd',
        },
        notifications: {
          label: 'Houd mij op de hoogte',
          title: 'Berichtgeving',
          'type-email': 'Via e-mail (naar: {email})',
          'type-my-vodafone': 'Via Mijn Ziggo',
          'type-phone': 'Telefonisch',
          'type-sms': 'Via sms',
        },
        'select-default-placeholder': 'Maak een keuze',
      },
      quickLinksHeader: 'Snel naar',
    },
    error: {
      validation: {
        email: 'Wil je je e-mailadres controleren?',
        emailUserName: 'Deze karakters zijn niet toegestaan. @ziggo.nl hoef je niet in te vullen.',
        iban: 'Vul alsjeblieft een bestaand rekeningnummer in.',
        imei: 'Vul alsjeblieft een geldig IMEI-nummer in.',
        invalid: 'Wil je dit veld nog eens nakijken? Het lijkt niet goed ingevuld.',
        'min-items-selected': 'Selecteer minimaal ${value} items',
        'min-value': 'Vul een waarde in die groter is dan ${value}',
        mobile: 'Vul een geldig mobiel nummer in. Bv. 0612345678.',
        fixed: 'Vul een telefoon nummer in. Bv. 0201234567',
        number: 'Vul alsjeblieft een getal in.',
        phone: 'Wil je een geldig telefoonnummer invullen?',
        anyRequired: 'Vul een van de volgende waardes in',
        required: 'Wil je dit veld alsjeblieft invullen?',
        acceptTerms: 'Je moet akkoord gaan met de voorwaarden',
        zipcode: 'Vul een geldige postcode in',
        postcode: 'Vul een geldige postcode in',
        accountnumber: 'Geef een Nederlands, Belgisch of Duits rekeninnummer door voor de automatische incasso.',
        passwordsMatch: 'Vul nog eens hetzelfde wachtwoord in.',
        maxLength: 'De tekst is te lang. Probeer het wat korter te omschrijven.',
        emailFirstNameMaxLength: 'Het maximum aantal karakters is bereikt.',
        emailLastNameMaxLength: 'Het maximum aantal karakters is bereikt.',
        emailUserNameMaxLength: 'Het maximum aantal karakters is bereikt.',
        kvkLength: 'KvK nummer moet precies 8 cijfers bevatten',
        directDebitHasBankAccount: 'Vul een geldige rekeningnummer in',
        noSpecialCharacters: 'In dit veld zijn geen speciale tekens toegestaan.',
      },
      general: {
        no_data: 'We kunnen je gegevens nu niet laten zien. Probeer het later nog eens.',
        error_text: 'Er is iets misgegaan. Probeer het later nog eens.',
        error_title: 'Helaas...',
      },
    },
    ok: 'Ok',
    input: {
      password: {
        show: 'Toon wachtwoord',
        hide: 'Verberg wachtwoord',
      },
    },
  },
  en: {},
};

export default globalContent;
