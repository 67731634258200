import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import SupportCase, { SupportCaseStatus } from 'api/support-case.types';
import { ArrowIcon } from 'assets/icons';
import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { Button } from 'components/Button';
import { ChildPage } from 'components/ChildPage';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { ModalButtons } from 'components/Modal/modal.styles';
import { PageHeader } from 'components/PageHeader';
import { SectionHeader } from 'components/SectionHeader';
import { BillingCustomerContext } from 'context/billing-customer.context';
import SupportCaseContext from 'context/support-case/support-case.context';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentUtil, globalContentUtil } from 'utils';
import { Helmet } from 'react-helmet';

import SupportCaseClosedBlock from './components/SupportCaseClosedBlock/SupportCaseClosedBlock';
import SupportCaseComments from './components/SupportCaseComments/SupportCaseComments';
import SupportCaseDetailsBlock from './components/SupportCaseDetailsBlock';
import content from './support-case-detail.content';
import * as Styles from './support-case-details.styles';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseDetail' });

const SupportCaseDetail = () => {
  const { reference } = useParams();
  const [supportCase, setSupportCase] = useState<SupportCase>();
  const [errorWithData, setErrorWithData] = useState<boolean>();
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [errorWithClosing, setErrorWithClosing] = useState<boolean>(false);
  const [fetchingClosure, setFetchingClosure] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { findSupportCase, closeSupportCase } = useContext(SupportCaseContext);
  const { activeBcId } = useContext(BillingCustomerContext);

  const fetchSupportCaseData = () => {
    setFetchingData(true);
    findSupportCase(activeBcId, reference)
      .then((supportCase: SupportCase) => setSupportCase(supportCase))
      .catch(() => setErrorWithData(true))
      .finally(() => setFetchingData(false));
  };

  useEffect(() => {
    if (!supportCase && !fetchingData && !errorWithData) {
      fetchSupportCaseData();
    }
  }, [supportCase, fetchingData, errorWithData]);

  const openModal = () => {
    setShowModal(true);
    setErrorWithClosing(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmSupportCaseClosure = async () => {
    setFetchingClosure(true);

    const response = await closeSupportCase(activeBcId, reference);
    const { success } = response;

    setFetchingClosure(false);

    if (!success) {
      setErrorWithClosing(true);
      return;
    }

    setShowModal(false);
    fetchSupportCaseData();
  };

  return (
    <ChildPage>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader
        title={contentUtil.translateToString('pageTitle')}
        parents={[
          { to: '/service/case/overzicht', label: contentUtil.translateToString('parentPage.service') }, // @todo: Change to /service once the Service page is done
          { to: '/service/case/overzicht', label: contentUtil.translateToString('parentPage.overview') },
        ]}
      />
      <Wrapper>
        <Row>
          <Col>
            <Styles.BackLink to="/service/case/overzicht" data-testid="backlink">
              <ArrowIcon /> {contentUtil.translateToString('backToOverview')}
            </Styles.BackLink>
          </Col>
        </Row>
        <Row>
          <Col>
            {!fetchingData && supportCase && (
              <>
                <SupportCaseDetailsBlock
                  reference={supportCase?.reference}
                  title={supportCase?.title}
                  description={supportCase?.description}
                  contactName={supportCase?.contactName}
                  date={supportCase?.date}
                  status={supportCase?.status}
                  attachments={supportCase?.attachments}
                />

                {!!supportCase.comments?.length && <SupportCaseComments comments={supportCase.comments} />}

                {supportCase.status === SupportCaseStatus.CLOSED ? (
                  <SupportCaseClosedBlock />
                ) : (
                  <Styles.Buttons>
                    <Button
                      buttonVariant={ButtonVariant.DARK}
                      to={`/service/case/${reference}/nieuwe-opmerking`}
                      testid="support-case-go-to-new-comment-page">
                      {contentUtil.translateToString('addComment')}
                    </Button>
                    {supportCase.closeable && (
                      <Button
                        buttonVariant={ButtonVariant.DARK}
                        outline
                        onClick={openModal}
                        testid="support-case-confirm-close-modal-button">
                        {contentUtil.translateToString('closeSupportCase')}
                      </Button>
                    )}
                  </Styles.Buttons>
                )}
              </>
            )}
            {fetchingData && <Loader />}
            {errorWithData && (
              <InlineAlert type="error" testid="support-case-detail-error">
                {contentUtil.translateToString('somethingWrong')}
              </InlineAlert>
            )}
          </Col>
        </Row>
        {showModal && (
          <Modal onClose={closeModal} data-test-id="support-case-confirm-close-modal">
            <section>
              <SectionHeader>{contentUtil.translateToString('confirmClosureModal.header')}</SectionHeader>
              <p>{contentUtil.translateToString('confirmClosureModal.content')}</p>
              {errorWithClosing && (
                <InlineAlert type="error" testid="support-case-close-error">
                  {globalContentUtil.translate('error.general.error_text')}
                </InlineAlert>
              )}
              <ModalButtons>
                <Button
                  // @ts-expect-error TS(2322): Type '{ children: string; primary: true; disabled:... Remove this comment to see the full error message
                  primary
                  disabled={fetchingClosure}
                  onClick={confirmSupportCaseClosure}
                  testid="support-case-confirm-close-button">
                  {fetchingClosure
                    ? `${globalContentUtil.translateToString('global.forms.button.loading')}...`
                    : contentUtil.translateToString('confirmClosureModal.confirm')}
                </Button>
              </ModalButtons>
            </section>
          </Modal>
        )}
      </Wrapper>
    </ChildPage>
  );
};

export default SupportCaseDetail;
