import { appSettings } from 'config/app.settings';
import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    parents: {
      internet: 'Internet',
      products: 'Je producten',
    },
    pageTitle: 'E-mailadres instellen',
    emailSectionHeader: 'E-mailadres',
    aliasesSectionHeader: 'Of maak een alias / andere naam',
    changePasswordSectionHeader: 'Een nieuw wachtwoord',
    hasError: 'Er is iets niet goed gegaan. Probeer je het nog een keer?',
    sideCard: {
      header: 'E-mail van Ziggo',
      content: 'Bekijk alle mogelijkheden en de rondleiding.',
      linkText: 'Zo werkt Ziggo Mail',
      linkUrl: `${appSettings.ENV_ZIGGO_URL}/klantenservice/e-mail/ziggo-mail`,
    },
    emailDisabled: {
      default: 'Dit e-mailadres is tijdelijk geblokkeerd.',
      hasAliases: 'Het hoofd e-mailadres is geblokkeerd. Dit e-mailadres en alle aliassen werken niet meer.',
      subHeader: 'Je kunt de blokkade opheffen door je wachtwoord te resetten in de e-mail instellingen.',
    },
  },
  en: {},
};
