import styled, { rem } from 'theme';

export const Field = styled('div')`
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  height: ${rem(60)};
  border-radius: ${rem(4)};

  label {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 200ms all;
    padding: 0 ${rem(20)};
    margin: 0;
    pointer-events: none;
    color: ${(p) => p.theme.colors.gray300};
  }

  select:valid + label,
  input:focus + label,
  input:not([value='']) + label {
    font-size: 75%;
    transform: translate3d(0, -20%, 0);
  }
`;

export default Field;
