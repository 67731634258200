import { WorkaroundApi } from 'api/workaround.api';
import { Workaround, WorkaroundContactTimesEnum, WorkaroundTypeEnum } from 'api/workaround.types';
import { Button } from 'components/Button/button.styles';
import { Checkbox, Form, Input, Label, TextArea } from 'components/Form';
import { InlineAlert } from 'components/InlineAlert';
import { PiiMask } from 'components/PiiMask';
import { useContactDetailsContext } from 'pages/ContactDetails/contact-details.context';
import { WorkaroundContext } from 'pages/Workaround/models/WorkaroundContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentUtil, globalContentUtil } from 'utils';
import transformers from 'utils/transformers';

import { content } from './workaround-form.content';
import * as S from './workaround-form.styles';

interface WorkaroundFormPropsOuter {
  context: WorkaroundContext;
  billingCustomerId: string;
}

const contentUtil = new ContentUtil({ content, componentName: 'WorkaroundForm' });

interface IWorkaroundFormValues {
  email: string;
  phone: string;
  saveContactPreferences?: boolean;
  contactTimes: {
    MORNING?: boolean;
    AFTERNOON?: boolean;
    EVENING?: boolean;
  };
  description: string;
  context?: string;
}

const WorkaroundForm = ({ context, billingCustomerId, ...rest }: WorkaroundFormPropsOuter) => {
  const [isCompleted, setCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [communicationError, setCommunicationError] = useState(false);

  const { contactDetails } = useContactDetailsContext();

  const checkInputErrors = (values: IWorkaroundFormValues) => {
    const hasNoEmailOrPhone = !values.email && !values.phone;
    setCommunicationError(hasNoEmailOrPhone);

    return hasNoEmailOrPhone;
  };

  const onSubmit = async (values: IWorkaroundFormValues) => {
    const hasInputError = checkInputErrors(values);

    if (hasInputError) {
      return;
    }

    setIsLoading(true);
    setHasError(false);
    // eslint-disable-next-line no-param-reassign
    values.phone = (values.phone && transformers.transformMsisdn(values.phone)) || '';
    const workaroundRequest: Workaround = {
      email: values.email || '',
      phone: values.phone || '',
      contactTimes: values.contactTimes ? (Object.keys(values.contactTimes) as WorkaroundContactTimesEnum[]) : [],
      context: contentUtil.translateToString(`context.${context.feature}`),
      saveContactPreferences: !!values.saveContactPreferences,
      workaroundType: context.workaroundType,
      description: values.description || '',
    };

    try {
      await WorkaroundApi.submitWorkaround(billingCustomerId, workaroundRequest);
      setCompleted(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const primaryContact = contactDetails?.primaryContact;

  const initialValues = {
    phone:
      (primaryContact && primaryContact.phone && transformers.transformMsisdnToReadable(primaryContact.phone)) || '',
    email: (primaryContact && primaryContact.email) || '',
  };
  const navigate = useNavigate();

  return (
    <>
      {isCompleted && (
        <>
          <InlineAlert type="success" testid="workaround-form-success">
            {contentUtil.translateToString('form.submitted')}
          </InlineAlert>
          <Button onClick={() => navigate(-2)}>{contentUtil.translate('form.back')}</Button>
        </>
      )}
      {hasError && (
        <InlineAlert type="error">
          <strong>{globalContentUtil.translate('error.general.error_text')}</strong>
        </InlineAlert>
      )}
      {!isCompleted && (
        <Form
          onCancel={() => navigate(-2)}
          id="workaround-form"
          isLoading={isLoading}
          testid="workaround-form"
          initialValues={initialValues}
          buttonTextCancel={contentUtil.translateToString('form.cancel')}
          buttonText={contentUtil.translateToString('form.submit')}
          onSubmit={onSubmit}
          {...rest}
          render={(_, validators, validateItem) => (
            <React.Fragment>
              <div className="full-width">
                {context.workaroundType !== 'SALES' && <S.Hr />}
                <h3>{contentUtil.translate(`${context.workaroundType}.details`)}</h3>
                <p>{contentUtil.translate(`${context.workaroundType}.contactText`)}</p>
              </div>

              <Label>{contentUtil.translateToString('form.context')}</Label>
              <S.Context>{contentUtil.translateToString(`context.${context.feature}`)}</S.Context>

              <PiiMask>
                <Input
                  id="email"
                  name="email"
                  testid="workaround-email"
                  label={contentUtil.translate('form.email')}
                  validate={[validators.email]}
                />
              </PiiMask>

              <PiiMask>
                <Input
                  id="phone"
                  name="phone"
                  testid="workaround-phone"
                  label={contentUtil.translate('form.phone')}
                  validate={[validators.phone]}
                  error={validateItem('phone')}
                />
              </PiiMask>
              {communicationError && (
                <S.InlineError data-testid="contact-means-required">
                  {contentUtil.translate('form.error.contactDetails')}
                </S.InlineError>
              )}
              <Checkbox
                id="save-contact-preferences"
                name="saveContactPreferences"
                data-testid="workaround-save-contact-preferences"
                label={contentUtil.translateToString('form.saveContactPreferences')}
              />

              {context.workaroundType === WorkaroundTypeEnum.CARE && (
                <>
                  <div className="full-width">
                    <br />
                  </div>

                  <Label>{contentUtil.translateToString('form.whenToReach')}</Label>
                  <div>
                    <Checkbox
                      id="contact-time-morning"
                      name="contactTimes.MORNING"
                      data-testid="contact-time-morning"
                      label={contentUtil.translateToString('form.time.MORNING')}
                    />
                    <Checkbox
                      id="contact-time-afternoon"
                      name="contactTimes.AFTERNOON"
                      data-testid="contact-time-afternoon"
                      label={contentUtil.translateToString('form.time.AFTERNOON')}
                    />
                    <Checkbox
                      id="contact-time-evening"
                      name="contactTimes.EVENING"
                      data-testid="contact-time-evening"
                      label={contentUtil.translateToString('form.time.EVENING')}
                    />
                  </div>

                  <PiiMask>
                    <TextArea
                      id="description"
                      name="description"
                      rows={12}
                      data-testid="workaround-description"
                      label={contentUtil.translateToString('form.description')}
                      validate={[validators.maxLength]}
                    />
                  </PiiMask>
                </>
              )}
            </React.Fragment>
          )}
        />
      )}
    </>
  );
};

export default WorkaroundForm;
