import { PasswordEyeCrossedIcon, PasswordEyeIcon } from 'assets/icons';
import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import { composeValidators } from 'components/Form/validate';
import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { globalContentUtil } from 'utils';

import * as S from './input.styles';

export interface IInputProps {
  name: string;
  id?: string;
  type?: string;
  validate?: Function[];
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: string;
  touched?: boolean;
  meta?: any;
  testid?: string;
  className?: string;
  label?: string | React.ReactNode;
  autoComplete?: string;
  hideErrorLabel?: boolean;
  maxLength?: number;
  focused?: boolean;
}

export const Input = (props: IInputProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const ref = useRef() as React.RefObject<HTMLInputElement>;

  const {
    name,
    testid,
    id,
    className,
    label,
    validate,
    isDisabled,
    isRequired,
    error,
    autoComplete,
    hideErrorLabel,
    type = 'text',
    maxLength,
    focused = false,
  } = props;

  useEffect(() => {
    if (focused && ref && ref.current) {
      ref.current.focus();
    }
  }, [focused]);

  const toggleShowPassword = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
    ref && ref.current && ref.current.focus();
    setShowPassword(!showPassword);
  };

  return (
    <Field
      name={name}
      validate={validate && !isDisabled ? composeValidators(validate) : undefined}
      render={({ input, meta }) => (
        <>
          {label && (
            <S.Label className="input-label" htmlFor={id}>
              {label} {isRequired && <span> *</span>}
            </S.Label>
          )}
          <S.InputWrapper
            hideErrorLabel={hideErrorLabel}
            className={`input-wrapper ${className || ''} ${type === 'password' ? 'input-password' : ''}`}>
            <S.Input
              {...input}
              className="input"
              type={showPassword ? 'text' : type}
              autoComplete={autoComplete}
              disabled={isDisabled}
              maxLength={maxLength}
              ref={ref}
              id={id}
              error={!!((meta.touched && meta.error) || error)}
              data-testid={testid}
            />
            {type === 'password' && (
              <S.ShowPasswordButton onClick={toggleShowPassword} tabIndex={-1}>
                {showPassword ? (
                  <PasswordEyeCrossedIcon title={globalContentUtil.translateToString('input.password.hide')} />
                ) : (
                  <PasswordEyeIcon title={globalContentUtil.translateToString('input.password.show')} />
                )}
              </S.ShowPasswordButton>
            )}
          </S.InputWrapper>
          {meta.error && meta.touched && <ErrorMessage className="error-message" message={meta.error} />}
          {error && !(meta.error && meta.touched) && <ErrorMessage className="error-message" message={error} />}
        </>
      )}
    />
  );
};
