import { IOutage, OutageApi } from 'api';
import { BillingCustomerContext } from 'context/billing-customer.context';
import * as R from 'ramda';
import React, { createContext, useContext, useEffect, useState } from 'react';

import IOutageContext from './interface/IOutageContext';

// @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
const OutageContext = createContext<IOutageContext>({});
const useOutageContext = () => useContext<IOutageContext>(OutageContext);

interface OutageProviderProps {
  children: React.ReactNode;
}

const OutageProvider: React.FC = ({ children }: OutageProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const [outages, setOutages] = useState<Array<IOutage>>([]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [successModalType, setSuccessModalType] = useState<'subscribe' | 'unsubscribe'>('subscribe');

  const getOutages = async () => {
    const { activeBcId } = billingCustomerContext;

    if (!activeBcId) return;
    setLoading(true);
    try {
      const { data } = await OutageApi.getOutages(activeBcId);
      if (!data.length) {
        setError('error.noOutages');
      } else {
        setOutages(data);
      }
    } catch (e) {
      setError('error.noOutages');
    } finally {
      setLoading(false);
    }
  };

  const unsubscribeOutage = async (outage: IOutage) => {
    if (!outage.id) {
      setError('error.noOutageId');
      return;
    }

    const { activeBcId } = billingCustomerContext;
    setLoading(true);
    try {
      // @ts-expect-error TS(2339): Property 'success' does not exist on type '{ data:... Remove this comment to see the full error message
      const { success } = await OutageApi.unsubscribeOutage(activeBcId, outage.id);
      if (success) {
        setSuccessModalType('unsubscribe');
        setShowSuccessModal(true);
        return;
      }
      setError('error.unsubscribeOutage');
    } catch (e) {
      setError('error.unsubscribeOutage');
    } finally {
      setLoading(false);
    }
  };

  const subscribeOutage = async (outage: IOutage) => {
    if (!outage.id) {
      setError('error.noOutageId');
      return;
    }

    const { activeBcId } = billingCustomerContext;
    setLoading(true);
    try {
      // @ts-expect-error TS(2339): Property 'success' does not exist on type '{ data:... Remove this comment to see the full error message
      const { success } = await OutageApi.subscribeOutage(
        activeBcId,
        outage.id,
        // @ts-expect-error TS(2769): No overload matches this call.
        R.filter(Boolean, outage.telephoneNumbers)
      );
      if (success) {
        setSuccessModalType('subscribe');
        setShowSuccessModal(true);
        return;
      }
      setError('error.subscribeOutage');
    } catch (e) {
      setError('error.subscribeOutage');
    } finally {
      setLoading(false);
    }
  };

  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (billingCustomerContext.completed) getOutages();
  }, [billingCustomerContext.completed]);

  return (
    <OutageContext.Provider
      value={{
        isLoading,
        error,
        outages,
        successModalType,
        showSuccessModal,
        hideSuccessModal,
        getOutages,
        subscribeOutage,
        unsubscribeOutage,
      }}>
      {children}
    </OutageContext.Provider>
  );
};

export { OutageContext, OutageProvider, useOutageContext };
