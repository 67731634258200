import { Table as SandwichTable } from '@vodafoneziggo/sandwich';
import styled from 'styled-components';

export const Table = styled(SandwichTable)`
  svg {
    position: relative;
    top: 2px;
    scale: 0.8;
  }
`;
