import { ApiResponse, query, rest } from './api';
import {
  cancelAppointmentMutation,
  createAppointmentMutation,
  createAppointmentSlotListQuery,
  getAppointmentSlotsQuery,
  getAppointmentsQuery,
  rescheduleAppointmentMutation,
} from './appointment.query';
import {
  Appointment,
  AppointmentCancel,
  AppointmentContact,
  AppointmentCreate,
  AppointmentPermissions,
  AppointmentReschedule,
  AppointmentSlotsAvailable,
  AppointmentsUnauthenticated,
  CreateAppointment,
} from './appointment.types';

interface AppointmentApiInterface {
  cancelAppointment(
    billingCustomerId: string,
    requestId: string,
    caseId: string,
    appointmentCancel?: AppointmentCancel
  ): ApiResponse<{ success: boolean }>;

  getAppointments(billingCustomerId: string): ApiResponse<Appointment[]>;

  getAppointmentSlots(
    billingCustomerId: string,
    requestId: string,
    dateFrom: string
  ): ApiResponse<AppointmentSlotsAvailable>;

  createAppointmentSlotList(
    billingCustomerId: string,
    orderId: string,
    dateFrom: string
  ): ApiResponse<AppointmentSlotsAvailable>;

  createAppointment(billingCustomerId: string, appointmentCreate: CreateAppointment): ApiResponse<{ success: boolean }>;

  rescheduleAppointment(
    billingCustomerId: string,
    requestId: string,
    appointmentReschedule?: AppointmentReschedule
  ): ApiResponse<{ success: boolean }>;

  // descoped Unify functionality
  cancelAppointmentUnauthenticated(
    requestId: string,
    appointmentToken: string,
    appointmentCancel?: AppointmentCancel
  ): ApiResponse<{ success: boolean }>;

  createAppointmentUnauthenticated(appointmentCreate?: AppointmentCreate): ApiResponse<{ success: boolean }>;

  getAppointmentContact(appointmentToken: string): ApiResponse<AppointmentContact>;

  getAppointmentSlotsUnauthenticated(
    appointmentToken: string,
    dateFrom: string,
    requestId: string | null,
    installationGuarantee: boolean
  ): ApiResponse<AppointmentSlotsAvailable>;

  getAppointmentsUnauthenticated(appointmentToken: string): ApiResponse<AppointmentsUnauthenticated>;

  rescheduleAppointmentUnauthenticated(
    requestId: string,
    appointmentToken: string,
    appointmentReschedule?: AppointmentReschedule
  ): ApiResponse<{ success: boolean }>;
}

const AppointmentApi: AppointmentApiInterface = {
  cancelAppointment(
    billingCustomerId: string,
    requestId: string,
    caseId: string,
    appointmentCancel?: AppointmentCancel
  ): ApiResponse<{ success: boolean }> {
    return query({
      query: cancelAppointmentMutation,
      variables: { billingCustomerId, requestId, caseId, appointmentCancel },
      name: 'cancelAppointment',
    }).then(({ cancelAppointment }) => ({ data: cancelAppointment }));
  },

  getAppointments(billingCustomerId: string): ApiResponse<Appointment[]> {
    return query({
      query: getAppointmentsQuery,
      variables: { billingCustomerId },
      name: 'getAppointments',
    }).then(
      ({
        getAppointments,
        getAppointmentPermissions,
      }: {
        getAppointments: Appointment[];
        getAppointmentPermissions: AppointmentPermissions;
      }) => {
        const { needsCancellationReason } = getAppointmentPermissions;

        return {
          data: getAppointments.map((appointment) => ({
            ...appointment,
            needsCancellationReason,
          })),
        };
      }
    );
  },

  getAppointmentSlots(
    billingCustomerId: string,
    requestId: string,
    dateFrom: string
  ): ApiResponse<AppointmentSlotsAvailable> {
    return query({
      query: getAppointmentSlotsQuery,
      variables: { billingCustomerId, requestId, dateFrom },
      name: 'getAppointmentSlots',
    }).then(({ getAppointmentSlots }) => ({ data: getAppointmentSlots }));
  },

  createAppointmentSlotList(
    billingCustomerId: string,
    orderId: string,
    dateFrom: string
  ): ApiResponse<AppointmentSlotsAvailable> {
    return query({
      query: createAppointmentSlotListQuery,
      variables: { billingCustomerId, orderId, dateFrom },
      name: 'createAppointmentSlotList',
    }).then(({ createAppointmentSlotList }) => ({ data: createAppointmentSlotList }));
  },

  createAppointment(
    billingCustomerId: string,
    appointmentCreate: CreateAppointment
  ): ApiResponse<{ success: boolean }> {
    return query({
      query: createAppointmentMutation,
      variables: { billingCustomerId, appointmentCreate },
      name: 'createAppointment',
    }).then(({ createAppointment }) => ({ data: createAppointment }));
  },

  rescheduleAppointment(
    billingCustomerId: string,
    requestId: string,
    appointmentReschedule?: AppointmentReschedule
  ): ApiResponse<{ success: boolean }> {
    return query({
      query: rescheduleAppointmentMutation,
      variables: { billingCustomerId, requestId, appointmentReschedule },
      name: 'rescheduleAppointment',
    }).then(({ rescheduleAppointment }) => ({ data: rescheduleAppointment }));
  },

  // descoped Unify functionality
  cancelAppointmentUnauthenticated(
    requestId: string,
    appointmentToken: string,
    appointmentCancel?: AppointmentCancel
  ): ApiResponse<{ success: boolean }> {
    return rest({
      url: `/appointment/cancel`,
      data: {
        requestId,
        appointmentToken,
        cancellationReason: appointmentCancel,
      },
      method: 'POST',
      authenticated: false,
    });
  },
  createAppointmentUnauthenticated(appointmentCreate?: AppointmentCreate): ApiResponse<{ success: boolean }> {
    return rest({
      url: `/appointment/create`,
      data: { ...appointmentCreate },
      method: 'POST',
      authenticated: false,
    });
  },
  getAppointmentContact(appointmentToken: string): ApiResponse<AppointmentContact> {
    return rest({
      url: `/appointment/contact`,
      params: {
        appointmentToken,
      },
      method: 'GET',
      authenticated: false,
    });
  },
  getAppointmentSlotsUnauthenticated(
    appointmentToken: string,
    dateFrom: string,
    requestId: string | null,
    installationGuarantee: boolean
  ): ApiResponse<AppointmentSlotsAvailable> {
    return rest({
      url: `/appointment/slots`,
      params: {
        appointmentToken,
        dateFrom,
        requestId,
        installationGuarantee,
      },
      method: 'GET',
      authenticated: false,
    });
  },
  getAppointmentsUnauthenticated(appointmentToken: string): ApiResponse<AppointmentsUnauthenticated> {
    return rest({
      url: `/appointment`,
      params: {
        appointmentToken,
      },
      method: 'GET',
      authenticated: false,
    });
  },
  rescheduleAppointmentUnauthenticated(
    requestId: string,
    appointmentToken: string,
    appointmentReschedule?: AppointmentReschedule
  ): ApiResponse<{ success: boolean }> {
    return rest({
      url: `/appointment/reschedule`,
      data: {
        requestId,
        appointmentToken,
        ...appointmentReschedule,
      },
      method: 'POST',
      authenticated: false,
    });
  },
};

export { AppointmentApi };
