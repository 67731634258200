import { DetailSection } from 'components/Modal/modal.styles';
import { WorkaroundContext } from 'pages/Workaround/models/WorkaroundContext';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from './workaround-call-section.content';
import * as S from './workaround-call-section.styles';

interface WorkaroundCallSectionProps {
  context: WorkaroundContext;
}

const contentUtil = new ContentUtil({ content, componentName: 'WorkaroundCallSection' });

export const WorkaroundCallSection = ({ context }: WorkaroundCallSectionProps) => {
  if (context.workaroundType === 'SALES') {
    return <WorkaroundCallSectionSales />;
  }
  return <WorkaroundCallSectionCare />;
};

const WorkaroundCallSectionSales = () => (
  <>
    <DetailSection>
      <S.PhoneNumber>
        <a href={`tel:${contentUtil.translateToString(`SALES.phoneNumber`)}`}>
          {contentUtil.translate(`SALES.phoneNumber`)}
        </a>
      </S.PhoneNumber>
      <S.TarifText>
        <S.Link external href={contentUtil.translateToString('tariffLink')}>
          {contentUtil.translateHtml(`SALES.tariff`)}
        </S.Link>
      </S.TarifText>
    </DetailSection>
    <p>{contentUtil.translate(`callCenterAvailability`)}</p>
  </>
);

const WorkaroundCallSectionCare = () => (
  <>
    <DetailSection>
      <S.PhoneNumber>
        <a href={`tel:${contentUtil.translateToString(`CARE.phoneNumber.internal`)}`}>
          {contentUtil.translate(`CARE.phoneNumber.internal`)}
        </a>
      </S.PhoneNumber>
      <S.TarifText>{contentUtil.translateHtml(`CARE.tariff.internal`)}</S.TarifText>
    </DetailSection>
    <DetailSection>
      <S.PhoneNumber>
        <a href={`tel:${contentUtil.translateToString(`CARE.phoneNumber.external`)}`}>
          {contentUtil.translate(`CARE.phoneNumber.external`)}
        </a>
      </S.PhoneNumber>
      <S.TarifText>
        <S.Link external href={contentUtil.translateToString('tariffLink')}>
          {contentUtil.translateHtml(`CARE.tariff.external`)}
        </S.Link>
      </S.TarifText>
    </DetailSection>
    <p>{contentUtil.translate(`callCenterAvailability`)}</p>
  </>
);
