import { IContent } from 'models/content.model';

const content: IContent = {
  nl: {
    title: 'Vraag of verzoek gesloten',
    description: 'Je vraag of verzoek is afgesloten. Je kunt geen reactie meer plaatsen.',
    needFurtherHelp: 'Heb je nog vragen? Neem dan contact met ons op via 0900 1884',
  },
  en: {},
};

export default content;
