export default class RequiredError extends Error {
  public readonly name: 'RequiredError' = 'RequiredError' as const;

  public constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
  }
}
