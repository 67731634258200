// source based on: https://stackoverflow.com/questions/21928083/iban-validation-check (old My)
import { Message } from 'models';

const mod97 = (valueInput: string) => {
  let checksum = parseInt(valueInput.slice(0, 2), 10);
  let fragment;

  for (let offset = 2; offset < valueInput.length; offset += 7) {
    fragment = String(checksum) + valueInput.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

// all SEPA countries codes with their respective lengths according to https://www.iban.com/structure
const SEPA_COUNTRY_CODE_LENGTHS: any = {
  AD: 24,
  AT: 20,
  BE: 16,
  BG: 22,
  CH: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  EE: 20,
  ES: 24,
  FI: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GR: 27,
  HR: 21,
  HU: 28,
  IE: 22,
  IS: 26,
  IT: 27,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MT: 31,
  NL: 18,
  NO: 15,
  PL: 28,
  PT: 25,
  RO: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  VA: 22,
};

type validatorReturnType = undefined | Message;

const isValid = (value: any, countryCodeLengths: Record<any, unknown>): validatorReturnType => {
  if (!value || typeof value !== 'string') {
    return undefined;
  }

  const ibanString = String(value)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '');
  const code = ibanString.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

  if (!code || ibanString.length !== countryCodeLengths[code[1]]) {
    return { translationKey: 'error.validation.iban' };
  }

  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
    return (letter.charCodeAt(0) - 55).toString();
  });

  const temp = mod97(digits);
  return temp === 1 ? undefined : { translationKey: 'error.validation.iban' };
};

export const isValidAllCountriesIBAN = (value: any): validatorReturnType => {
  return isValid(value, SEPA_COUNTRY_CODE_LENGTHS);
};
