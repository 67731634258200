import { ServerError } from 'assets/img/ServerError';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { content } from 'pages/Products/pages/InternetProducts/pages/EmailSettings/components/SectionAlias/section-alias.content';
import React from 'react';
import { ContentUtil } from 'utils/content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionAlias' });

interface IRemovalFailedModalProps {
  closeModal(): void;
}

export const RemovalFailedModal = ({ closeModal }: IRemovalFailedModalProps) => (
  <Modal onClose={closeModal}>
    {(onClose) => (
      <section data-testid="removal-failed-modal">
        <ModalIcon>
          <ServerError />
        </ModalIcon>
        <h4>{contentUtil.translate('removalFailedModal.header')}</h4>
        {contentUtil.translateHtml('removalFailedModal.content')}
        <ModalButtons>
          <Button onClick={onClose} outline>
            {contentUtil.translate('removalFailedModal.button')}
          </Button>
        </ModalButtons>
      </section>
    )}
  </Modal>
);
