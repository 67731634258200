import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    start: 'Fijn dat je er bent.',
    next: 'Er is nog meer voor je.',
    and: 'en',
    conversation: {
      ceased: `We zien dat je geen klant meer bent. Download alle facturen en sla ze op. Wil je weer klant worden?`,
      softBar: `Betaal zo snel mogelijk je openstaande facturen en de incassokosten. Dan blijft je abonnement actief.`,
      invoices: `Je hebt nog een onbetaalde factuur. Zou je die willen betalen alsjeblieft?`,
      merge: `Voeg je Mijn Ziggo en My Vodafone account samen. Dan heb je 1 login voor alle diensten en apps van Ziggo en Vodafone.`,
      done: `Niets bijzonders vandaag. Nog een mooie dag!`,
      welcome: `In Mijn Ziggo staan je abonnement, producten, contact- en betaalgegevens, verbruikskosten en facturen. Bekijk en wijzig je tv-pakket en instellingen.`,
      welcomeMigrated: `Een frisse wind ging door Mijn Ziggo. Je vindt hier nog steeds alles wat je gewend bent, maar dan in een nieuw jasje.`,
      openOrder: 'We verwerken je laatste bestelling. Bekijk de status en wanneer je pakket bij je is.',
      hollandsNieuweTvBenefit:
        'Omdat je ook klant bent bij hollandsnieuwe krijg je een extra Ziggo tv-pakket van ons. Mooi toch?',
      vodafoneTvBenefit:
        'Ziggo Sport Totaal, Ziggo Kids of SkyShowtime helemaal gratis? Maak een keuze en activeer je gratis zenders.',
      vodafoneInternetBenefit:
        'Omdat je ook Vodafone hebt, krijg je van ons gratis internetbeveiliging met antivirus om veilig te mailen, online te shoppen en bankieren.',
      portalOutage: 'Binnenkort is Mijn Ziggo even uit de lucht.',
      outage: {
        noDate: `Op dit moment werkt #{productType} helaas niet zoals het hoort. De storing is bij ons bekend en hoef je niet te melden. We lossen dit zo snel mogelijk op. Sorry voor het eventuele ongemak.`,
        startDate: `Vanaf #{startDate} werkt #{productType} helaas niet zoals het hoort. De storing is bij ons bekend en hoef je niet te melden. We lossen dit zo snel mogelijk op. Sorry voor het eventuele ongemak.`,
        endDate: `Tot #{endDate} werkt #{productType} helaas niet zoals het hoort. De storing is bij ons bekend en hoef je niet te melden. Sorry voor het eventuele ongemak.`,
        range: `Van #{startDate} t/m #{endDate} werkt #{productType} helaas niet zoals het hoort. De storing is bij ons bekend en hoef je niet te melden. Sorry voor het eventuele ongemak.`,
      },
      maintenance: {
        range: `Van #{startDate} t/m #{endDate} werken we aan #{reason} #{productType}. Dit onderhoud is nodig om je de beste producten en diensten te bieden. Sorry voor het eventuele ongemak.`,
        endDate: `We werken tot #{endDate} aan #{reason} #{productType}. Dit onderhoud is nodig om je de beste producten en diensten te bieden. Sorry voor het eventuele ongemak.`,
        startDate: `We werken vanaf #{startDate} aan #{reason} #{productType}. Dit onderhoud is nodig om je de beste producten en diensten te bieden. Sorry voor het eventuele ongemak.`,
        noDate: `We werken op dit moment aan #{reason} #{productType}. Dit onderhoud is nodig om je de beste producten en diensten te bieden. Sorry voor het eventuele ongemak.`,
      },
      openAppointment:
        'Je hebt een afspraak met een monteur op #{date}. Tussen #{startTime} en #{endTime} uur is de monteur bij je.',
    },
    reason: {
      HARDWARE: 'de apparaten van',
      ADJUSTMENT: 'een aanpassing van',
      CHANGE_HARDWARE_CONFIGURATION: 'de apparaatinstellingen voor',
      OTHER: 'het verbeteren van',
    },
    productType: {
      INTERNET: 'Ziggo Internet',
      DIGITAL_PHONE: 'Ziggo Bellen',
      DIGITAL_TV: 'Ziggo TV',
      CATV: 'Ziggo TV',
      OTHER: 'onze diensten',
    },
    action: {
      invoices: 'Naar je facturen',
      openOrder: 'Naar je bestellingen',
      products: 'Bekijk onze pakketten',
      mergeAccounts: 'Accounts samenvoegen',
      tvBenefit: 'Kies je gratis tv-pakket',
      internetBenefit: 'Beveiliging instellen',
      appointments: 'Afspraak bekijken',
      next: 'Ga verder',
      done: 'Ok!',
    },
  },
  en: {},
};
