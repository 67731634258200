import { ChevronIcon } from 'assets/icons';
import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { Card } from 'components/Card';
import { ContentBackground } from 'components/ContentBackground';
import { Col, Row } from 'components/Grid';
import { Link } from 'components/Link';
import { PageHeader } from 'components/PageHeader';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { FeatureContext } from 'context/feature/feature.context';
import WorkaroundForm from 'pages/Workaround/components/WorkaroundForm';
import { WorkaroundPopup } from 'pages/Workaround/components/WorkaroundPopup';
import { workaroundContexts } from 'pages/Workaround/models/WorkaroundContext';
import React, { useContext, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ContentUtil } from 'utils';
import { Helmet } from 'react-helmet';

import { content } from './workaround-page.content';
import * as S from './workaround-page.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Workaround' });

export const WorkaroundPage = () => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const { isEnabled } = useContext(FeatureContext);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const params = useParams();
  const context = workaroundContexts.find((workaroundContext) => params.context === workaroundContext.url);

  if (!context || isEnabled(context.feature)) {
    return <Navigate to="/" />;
  }

  const title = contentUtil.translateToString(`${context.workaroundType}.title`);

  const bcId = billingCustomerContext.activeBcId;

  return (
    <ContentBackground>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader title={title} />
      <Wrapper>
        <Row>
          <Col>
            <Card>
              {showEmailModal && (
                <WorkaroundPopup phoneContent context={context} onClose={() => setShowEmailModal(false)} />
              )}
              {context.workaroundType !== 'SALES' && (
                <>
                  <h3>{contentUtil.translate(`${context.workaroundType}.askYourQuestion`)}</h3>
                  <p>{contentUtil.translateHtml(`${context.workaroundType}.askYourQuestionText`)}</p>

                  <Link primary onClick={() => setShowEmailModal(true)}>
                    <S.Rotate>
                      <ChevronIcon />
                    </S.Rotate>
                    {contentUtil.translate(`${context.workaroundType}.preferPhone`)}
                  </Link>
                </>
              )}
              <WorkaroundForm billingCustomerId={bcId} context={context} />
            </Card>
          </Col>
        </Row>
      </Wrapper>
    </ContentBackground>
  );
};

export default WorkaroundPage;
