class Transformers {
  public transformMsisdnToReadable(msisdn?: string) {
    if (!msisdn) return null;

    let readable = msisdn;
    if (msisdn.startsWith('31')) {
      readable = `0${msisdn.slice(2)}`;
    } else if (msisdn.startsWith('+31')) {
      readable = `0${msisdn.slice(3)}`;
    } else if (msisdn.startsWith('0031')) {
      readable = `0${msisdn.slice(4)}`;
    }

    // If msisdn starts with 06: add space every 2 characters
    // Else (097): add space after the first 3 characters and for the remaining string after every 2 characters
    readable = readable.startsWith('06')
      ? readable.replace(/\d{2}(?!$)/gm, '$& ')
      : (readable.replace(/\s/, '').match(/^(\+?\d{3})(\d{3})?(\d{2})?(\d{2})(.*)+/) || [])
          .filter((m) => m)
          .slice(1)
          .join(' ');

    return readable;
  }

  /**
   * transform a msisdn to a msisdn with country code prefix, making it easier for the BE to accept
   */
  public transformMsisdn(input: string) {
    const specialCharacters = /[.-\s]+/gi;
    const phone = input.replace(specialCharacters, '');

    return this.internationalisePhoneNumber(phone);
  }

  /**
   * returns a phonenumber with a + - country - number. E.g. +31612345678 / +49123456789
   */
  private internationalisePhoneNumber(phone: string) {
    switch (true) {
      case phone.startsWith('00'):
        return `+${phone.slice(2)}`;
      case phone.startsWith('0'):
        return `+31${phone.slice(1)}`;
      case phone.startsWith('31'):
        return `+${phone}`;
      case phone.startsWith('6'):
        return `+31${phone}`;
      case phone.startsWith('97'):
        return `+31${phone}`;
      default:
        return phone;
    }
  }

  public capitalizeFirstCharOfString(str: string) {
    return str[0].toUpperCase() + str.substring(1);
  }
}

export default new Transformers();
