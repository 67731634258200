import { InvoicePayment, InvoicePaymentMethodEnum, InvoiceSummary } from 'api';
import { ChevronIcon } from 'assets/icons';
import { Creditcard } from 'assets/img/Creditcard';
import { IDeal } from 'assets/img/IDeal';
import { ErrorCross } from 'components/ErrorCross';
import { Loader } from 'components/Loader';
import { InvoicesContext } from 'context/invoices.context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ContentUtil, globalContentUtil } from 'utils/content';

import { content } from './invoice-payment-card.content';
import * as S from './invoice-payment-card.styles';
import { filterAndMapEnabledPaymentMethods } from './utils';

const contentUtil = new ContentUtil({ content, componentName: 'InvoicePaymentCard' });

interface InvoicePaymentCardProps {
  invoice: InvoiceSummary;
}

export const InvoicePaymentCard = ({ invoice }: InvoicePaymentCardProps) => {
  const [paymentChoice, setPaymentChoice] = useState<null | InvoicePaymentMethodEnum>(null);
  const [payment, setPayment] = useState<null | InvoicePayment>(null);
  const [paymentMethods, setPaymentMethods] = useState<InvoicePaymentMethodEnum[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getPaymentMethods, getPaymentLink } = useContext(InvoicesContext);

  useEffect(() => {
    setLoading(true);
    getPaymentMethods(invoice.id)
      .then((options) => {
        const enabledPaymentMethods = filterAndMapEnabledPaymentMethods(options);
        setPaymentMethods(enabledPaymentMethods);
      })

      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (paymentChoice) {
      setLoading(true);
      getPaymentLink(invoice.id, paymentChoice)
        .then((response) => {
          setPayment(response);
        })
        .catch((_error) => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [paymentChoice]);

  const isIngenico = paymentChoice === InvoicePaymentMethodEnum.INGENICO;
  const isIdeal = paymentChoice === InvoicePaymentMethodEnum.IDEAL;
  const isCreditCard = paymentChoice === InvoicePaymentMethodEnum.CREDIT_CARD;

  const renderError = () => (
    <S.ErrorContainer>
      <ErrorCross>{globalContentUtil.translate('error.general.error_text')}</ErrorCross>
    </S.ErrorContainer>
  );

  return (
    <S.Card>
      {error && renderError()}
      {!isIngenico && !paymentChoice && paymentMethods.length > 0 && (
        <Choice options={paymentMethods} setPaymentChoice={setPaymentChoice} />
      )}

      {isIdeal && payment && <IDealForm iframeSrc={payment.link} />}
      {isCreditCard && payment && <CreditCardForm iframeSrc={payment.link} />}
      {isIngenico && payment && <IngenicoForm invoice={invoice} payment={payment} />}
      {loading && <Loader />}
    </S.Card>
  );
};

interface ChoiceProps {
  options: InvoicePaymentMethodEnum[];
  setPaymentChoice: (choice: InvoicePaymentMethodEnum) => void;
}

const Choice = ({ options = [], setPaymentChoice }: ChoiceProps) => (
  <>
    {options.includes(InvoicePaymentMethodEnum.INGENICO) && (
      <S.PaymentOption
        onClick={() => setPaymentChoice(InvoicePaymentMethodEnum.INGENICO)}
        data-testid="choice-ingenico">
        <S.IconWrapper>
          <IDeal />
        </S.IconWrapper>
        <S.PaymentOptionText>{contentUtil.translate('paymentOptionIngenico')}</S.PaymentOptionText>
        <S.ChevronWrapper>
          <ChevronIcon />
        </S.ChevronWrapper>
      </S.PaymentOption>
    )}
    {options.includes(InvoicePaymentMethodEnum.IDEAL) && (
      <S.PaymentOption onClick={() => setPaymentChoice(InvoicePaymentMethodEnum.IDEAL)} data-testid="choice-ideal">
        <S.IconWrapper>
          <IDeal />
        </S.IconWrapper>
        <S.PaymentOptionText>{contentUtil.translate('paymentOptionIdeal')}</S.PaymentOptionText>
        <S.ChevronWrapper>
          <ChevronIcon />
        </S.ChevronWrapper>
      </S.PaymentOption>
    )}
    {options.includes(InvoicePaymentMethodEnum.CREDIT_CARD) && (
      <S.PaymentOption
        onClick={() => setPaymentChoice(InvoicePaymentMethodEnum.CREDIT_CARD)}
        data-testid="choice-credit-card">
        <S.IconWrapper>
          <Creditcard />
        </S.IconWrapper>
        <S.PaymentOptionText>{contentUtil.translate('paymentOptionCreditcard')}</S.PaymentOptionText>
        <S.ChevronWrapper>
          <ChevronIcon />
        </S.ChevronWrapper>
      </S.PaymentOption>
    )}
  </>
);

interface IframePaymentFormProps {
  iframeSrc: string;
}

interface IframeIngenicoPaymentFormProps {
  invoice: InvoiceSummary;
  payment?: InvoicePayment;
}

const IDealForm = (props: IframePaymentFormProps) => (
  <S.IframeWrapper>
    <IDeal />
    <S.IdealIframe id={`${InvoicePaymentMethodEnum.IDEAL}-iframe`} src={props.iframeSrc} data-testid="iframe-ideal" />
  </S.IframeWrapper>
);

const CreditCardForm = (props: IframePaymentFormProps) => (
  <S.IframeWrapper>
    <S.CreditcardIframe
      id={`${InvoicePaymentMethodEnum.CREDIT_CARD}-iframe`}
      src={props.iframeSrc}
      data-testid="iframe-credit-card"
    />
  </S.IframeWrapper>
);

const IngenicoForm = ({ invoice, payment }: IframeIngenicoPaymentFormProps) => {
  const form = useRef<HTMLFormElement>(null);

  useEffect(() => {
    form?.current?.submit();
  }, []);

  return (
    <S.IframeWrapper>
      <form ref={form} method="post" action={payment?.link} id="form-ingenico" name="form-ingenico">
        <input type="hidden" name="PSPID" value={payment?.merchant} />
        <input type="hidden" name="ORDERID" value={invoice.paymentReference} />
        <input type="hidden" name="AMOUNT" value={invoice.dueAmount} />
        <input type="hidden" name="CURRENCY" value={payment?.currency} />
        <input type="hidden" name="LANGUAGE" value={payment?.locale} />
        <input type="hidden" name="ACCEPTURL" value={payment?.acceptUrl} />
        <input type="hidden" name="DECLINEURL" value={payment?.declineUrl} />
        <input type="hidden" name="EXCEPTIONURL" value={payment?.exceptionUrl} />
        <input type="hidden" name="HOMEURL" value={payment?.homeUrl} />
        <input type="hidden" name="CANCELURL" value={payment?.cancelUrl} />
        <input type="hidden" name="BACKURL" value={payment?.backUrl} />
        <input type="hidden" name="SHASIGN" value={payment?.hash} />
      </form>
    </S.IframeWrapper>
  );
};
