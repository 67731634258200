import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import { css } from 'theme';

/**
 * Breakpoint variables
 */
const breakpointMobile = 320;
const breakpointMobilePlus = 480;
const breakpointTabletPortrait = 768;
const breakpointTabletLandscape = 1024;
export const breakpointDesktop = 1200;

/**
 * Device named breakpoints, used for the mixins.
 */
export const mobileStart = breakpointMobile;
export const mobileEnd = breakpointMobilePlus - 1;

export const mobilePlusStart = breakpointMobilePlus;
export const mobilePlusEnd = breakpointTabletPortrait - 1;

export const tabletPortraitStart = breakpointTabletPortrait;
export const tabletPortraitEnd = breakpointTabletLandscape - 1;

export const tabletLandscapeStart = breakpointTabletLandscape;
export const tabletLandscapeEnd = breakpointDesktop - 1;

const desktopStart = breakpointDesktop;

/**
 * @deprecated `screenMobilePlusAndSmaller` is deprecated because it is not mobile-first,
 * use `desktop`, `tablet`, or one the `AndBigger` queries
 */
export const screenMobilePlusAndSmaller = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (max-width: ${mobilePlusEnd}px) {
    ${strings};
  }
`;

/**
 * @deprecated `screenTabletPortraitAndSmaller` is deprecated because it is not mobile-first,
 * use `desktop`, `tablet`, or one the `AndBigger` queries
 */
export const screenTabletPortraitAndSmaller = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (max-width: ${tabletPortraitEnd}px) {
    ${strings};
  }
`;

export const screenMobilePlusAndBigger = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (min-width: ${mobilePlusStart}px) {
    ${strings};
  }
`;

export const screenTabletPortraitAndBigger = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (min-width: ${tabletPortraitStart}px) {
    ${strings};
  }
`;

export const screenTabletLandscapeAndBigger = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (min-width: ${tabletLandscapeStart}px) {
    ${strings};
  }
`;

const screenDesktopAndBigger = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @media only screen and (min-width: ${desktopStart}px) {
    ${strings};
  }
`;

/* Sensible media Queries */
export const tablet = screenTabletPortraitAndBigger;
export const desktop = screenDesktopAndBigger;
