export interface UnbilledTelephonyEventPage {
  totalRecords: number;
  items: UnbilledTelephonyEvent[];
}
export interface UnbilledEvent {
  id: string;
  startTime: string;
  chargeCategory: TelevisionChargeCategory | StreamingService;
  chargeAmount: number;
  description: string;
}

export interface UnbilledUsage {
  type: string;
  price: VatPrice;
}

export interface UnbilledTelephonyUsage {
  id: string;
  phoneNumber: string;
  telephonyUsageCategories: UnbilledTelephonyUsageCategory[];
}

export interface UnbilledTelevisionEvent extends UnbilledEvent {
  movieTitle: string | null;
  smartcardNumber: string;
}

export interface UnbilledStreamingEvent extends UnbilledEvent {
  chargeCategory: StreamingService;
}

export interface UnbilledTelevisionUsage extends UnbilledUsage {
  type: TelevisionChargeCategory;
}

export interface UnbilledStreamingUsage extends UnbilledUsage {
  type: StreamingService;
}

export interface UnbilledTelephonyEvent {
  category: TelephonyChargeCategory;
  start: string;
  duration: string;
  destinationNumber: string;
  vatPrice: VatPrice;
}

export interface VatPrice {
  includingVat?: number;
  excludingVat: number;
}

export interface UnbilledTelephonyUsageCategory {
  type: TelephonyChargeCategory;
  price: VatPrice;
}

export enum TelevisionChargeCategory {
  ONDEMAND = 'ON_DEMAND',
  DAYPASS = 'DAYPASS',
}

export enum TelephonyChargeCategory {
  CUSTOMERSERVICE = 'CUSTOMER_SERVICE',
  INTERNATIONAL = 'INTERNATIONAL',
  INTERNATIONALMOBILE = 'INTERNATIONAL_MOBILE',
  INTERNATIONALSERVICE = 'INTERNATIONAL_SERVICE',
  INTERNETDIALIN = 'INTERNET_DIAL_IN',
  NATIONAL = 'NATIONAL',
  NATIONALMOBILE = 'NATIONAL_MOBILE',
  OTHER = 'OTHER',
  PAGER = 'PAGER',
  SATELLITE = 'SATELLITE',
  SERVICEFREE = 'SERVICE_FREE',
  SERVICEPAID = 'SERVICE_PAID',
  SERVICEPAIDTAXFREE = 'SERVICE_PAID_TAX_FREE',
  VOICEMAIL = 'VOICEMAIL',
}

export enum StreamingService {
  NETFLIX = 'NETFLIX',
  NETFLIX_BASIC = 'NETFLIX_BASIC',
  NETFLIX_STANDAARD = 'NETFLIX_STANDAARD',
  NETFLIX_PREMIUM = 'NETFLIX_PREMIUM',
  DISNEY_PLUS = 'DISNEY_PLUS',
  DISNEY_PLUS_STANDAARD = 'DISNEY_PLUS_STANDAARD',
  DISNEY_PLUS_STANDAARD_MET_RECLAME = 'DISNEY_PLUS_STANDAARD_MET_RECLAME',
  VIAPLAY = 'VIAPLAY',
  HBO_MAX = 'HBO_MAX',
  HBO_MAX_STANDAARD = 'HBO_MAX_STANDAARD',
  HBO_MAX_STANDAARD_LEGACY = 'HBO_MAX_STANDAARD_LEGACY',
  SKY_SHOWTIME = 'SKY_SHOWTIME',
  SKYSHOWTIME = 'SKYSHOWTIME',
  VIDEOLAND = 'VIDEOLAND',
  VIDEOLAND_BASIS = 'VIDEOLAND_BASIS',
  VIDEOLAND_PLUS = 'VIDEOLAND_PLUS',
  VIDEOLAND_PREMIUM = 'VIDEOLAND_PREMIUM',
}

export enum StreamingServicePartnerIds {
  NETFLIX = 'B2B_NETFLIX',
  VIAPLAY = 'B2B_VIAPLAY',
  DISNEY_PLUS = 'B2B_DISNEY',
  HBO_MAX = 'B2B_HBO',
  SKY_SHOWTIME = 'B2B_SKY',
  VIDEOLAND = 'B2B_RTL',
  ZIGGO_SPORT_TOTAAL = 'B2B_ZIGGO_SPORT_TOTAAL',
}
