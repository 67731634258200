import { gql } from 'graphql-request';

export const getPaymentArrangementQuery = gql`
  query getPaymentArrangement($billingCustomerId: String!) {
    getPaymentArrangement(billingCustomerId: $billingCustomerId) {
      isEligible
      reason
      pastDueAmount
      collectionSuspend
      currentCollectionStep
      debtReason
      activePaymentArrangement {
        PAId
        pastDueAmount
        agreedDueAmount
        status
        paidAmount
        bankAccountNumber
        startDate
        endDate
        paymentMethod
        installments {
          installmentId
          status
          amount
          dueDate
        }
      }
      recommendedPaymentArrangement {
        installments {
          policyCode
          installmentId
          installmentCount
          installmentAmount
        }
      }
      paymentArrangementHistory {
        PAId
        startDate
        lastUpdateDate
        status
        pastDueAmount
      }
      bankCode
      bankAccountNumber
      mandateId
      mandateSignDate
    }
  }
`;

export const setPaymentArrangementQuery = gql`
  mutation setPaymentArrangement(
    $billingCustomerId: String!
    $paymentArrangement: CreatePaymentArrangementRequestInput!
  ) {
    setPaymentArrangement(billingCustomerId: $billingCustomerId, paymentArrangement: $paymentArrangement) {
      statusCode
      statusDescription
    }
  }
`;
