import { gql } from 'graphql-request';

export const putContactDetailsQuery = gql`
  mutation putBillingCustomer(
    $billingCustomerId: String!
    $contactId: String!
    $email: String!
    $phone: String!
    $mobile: String
  ) {
    putBillingCustomer(
      billingCustomerId: $billingCustomerId
      id: $contactId
      email: $email
      phone: $phone
      mobile: $mobile
    ) {
      success
    }
  }
`;

export const putContactDetails = gql`
  mutation putContactDetails(
    $billingCustomerId: String!
    $contactId: String!
    $initials: String!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $title: Salutation
    $birthDate: String!
  ) {
    putContactDetails(
      billingCustomerId: $billingCustomerId
      id: $contactId
      initials: $initials
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      title: $title
      birthDate: $birthDate
    ) {
      success
    }
  }
`;
