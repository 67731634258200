const content = {
  nl: {
    pageTitle: 'Betaalgegevens wijzigen',
    error: 'Het is helaas niet gelukt om je factuur type te wijzigen, probeer het later nog eens.',
    success: 'Bedankt voor het doorgeven. Je huidige papieren factuur wordt gewijzigd naar een digitale factuur.',
    title: 'Type factuur wijzigen',
    content:
      'Je huidge papieren factuur wijzigen naar een digitale factuur. <br> <strong>Let op deze wijziging kan niet ongedaan worden gemaakt.<strong>',
    buttonConfirm: 'Nu wijzigen',
    buttonCancel: 'Toch niet',
    buttonBack: 'Terug',
  },
  en: {},
};

export default content;
