import { appSettings } from 'config/app.settings';
import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    title: 'Betaling niet gelukt',
    content: `Je factuur is nog niet betaald. Dit kan verschillende redenen hebben. Probeer het nog eens of neem contact op met onze <a href="${appSettings.AEM_ZIGGO_URL}/klantenservice/contact" target="_blank">klantenservice</a>.`,
    linkTextBetaalmogelijkheden: 'Alle betaalmogelijkheden',
    linkTextFacturen: 'Naar je facturen',
    error: 'We hebben je factuurgegevens niet op kunnen halen. Probeer het later nog eens.',
  },
  en: {},
};
