import React from 'react';

export const ArrowIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg role="none" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      transform="translate(-38 -38)"
      fillRule="evenodd"
      d="M52 46.006c0-1.006-.857-2.012-1.941-2.012h-8.343l5.208-5.398a1.88 1.88 0 0 0-2.746 0L38 45l6.178 6.404a1.88 1.88 0 0 0 2.746 0l-5.208-5.398H52z"
      id="a"
    />
  </svg>
);
