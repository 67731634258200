import styled, { contentMargins, contentPadding, css, gapSize, rem, screenMobilePlusAndSmaller } from 'theme';
import borderRadius from 'theme/global/border-radius';

export const EmailAddress = styled('div')`
  background-color: ${(p) => p.theme.colors.gray100};
  border-radius: ${borderRadius.button};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => p.theme.fonts.weights.bold};
  ${contentPadding()};
  ${contentMargins(0.5, 0, 0.5, 0)};

  :first-child {
    margin-top: ${() => gapSize(2)};
  }

  :last-child {
    margin-bottom: ${() => gapSize(2)};
  }

  ${screenMobilePlusAndSmaller(css`
    flex-direction: column;
    align-items: flex-start;

    button {
      font-size: ${rem(14)};
    }
  `)}
`;

export const EmailCardContent = styled('div')`
  width: 100%;
`;

export const EmailAddressAliasListItem = styled('div')`
  margin-left: 2rem;
`;

export const EmailAddressAliasesList = styled('div')`
  background-color: ${(p) => p.theme.colors.grayL40};
  border-radius: ${(p) => p.theme.buttons.borderRadius};
  ${contentMargins(0.5, 0, 0.5, 0)};

  div:last-child {
    padding-bottom: 1rem;
  }
`;

export const EmailAddressMainListItem = styled(EmailAddress)`
  ${contentPadding(1, 1, 0, 1)};
`;
