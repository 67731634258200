// @todo: Replace with crypto.randomUUID()

/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// Generates a random UUID.
// See: https://gist.github.com/jed/982883
// prettier-ignore
function randomId(a?: any): string {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    // @ts-expect-error TS(2365): Operator '+' cannot be applied to types 'number[]'... Remove this comment to see the full error message
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, randomId);
}

export default randomId;
