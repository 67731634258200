import styled, { contentMargins, contentPadding, css, rem, spacingBase } from 'theme';
import boxShadow from 'theme/global/box-shadow';

interface IAlertComponentProps {
  fitContent: boolean;
}

const AlertComponent = styled.section<IAlertComponentProps>`
  position: relative;
  display: flex;
  box-shadow: ${boxShadow.section};
  border-radius: ${(p) => p.theme.buttons.borderRadius};
  border-bottom: ${rem(2)} solid;
  ${(p) =>
    p.fitContent &&
    css`
      width: fit-content;
    `};

  ${contentMargins(1, 0, 1, 0)};
`;

export const Icon = styled('div')`
  ${contentPadding()};
  border-top-left-radius: ${(p) => p.theme.buttons.borderRadius};
  border-bottom-left-radius: ${(p) => p.theme.buttons.borderRadius};
  fill: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    vertical-align: middle;
  }
`;

export const Success = styled(AlertComponent)`
  background: ${(p) => p.theme.colors.white};
  ${Icon} {
    background-color: ${(p) => p.theme.colors.success};
  }
  border-color: ${(p) => p.theme.colors.success};
`;

export const Info = styled(AlertComponent)`
  background: ${(p) => p.theme.colors.white};
  ${Icon} {
    background-color: ${(p) => p.theme.colors.blue};
  }
  border-color: ${(p) => p.theme.colors.blue};
`;

export const Error = styled(AlertComponent)`
  background: ${(p) => p.theme.colors.white};
  ${Icon} {
    background-color: ${(p) => p.theme.colors.red};
  }
  border-color: ${(p) => p.theme.colors.red};
`;

export const Warning = styled(AlertComponent)`
  background: ${(p) => p.theme.colors.white};
  ${Icon} {
    background-color: ${(p) => p.theme.colors.primary};
  }
  border-color: ${(p) => p.theme.colors.primary};
`;

export const Content = styled('div')`
  ${contentPadding(1, 1, 1, 1)};
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  span {
    vertical-align: middle;
  }

  a {
    font-weight: ${(p) => p.theme.fonts.weights.bold};
    text-decoration: underline;
  }
`;

export const CloseIcon = styled('button')`
  position: absolute;
  top: ${() => spacingBase};
  right: ${() => spacingBase};
  color: ${(p) => p.theme.colors.gray300};
  padding: ${() => spacingBase};
  align-self: flex-start;
  appearance: none;
  border: none;
  cursor: pointer;
  background: transparent;

  svg {
    width: ${rem(15)};
    height: ${rem(15)};
  }
`;
