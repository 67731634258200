import React from 'react';

import * as S from './error-cross.styles';

interface IErrorCrossProps {
  children: React.ReactNode;
}

export const ErrorCross = (props: IErrorCrossProps) => (
  <React.Fragment>
    <S.Cross data-testid="error-cross" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <S.Circle cx="26" cy="26" r="25" fill="none" />
      <S.Path fill="none" d="M18,34 L34,18" />
      <S.Path fill="none" d="M18,18 L34,34" />
    </S.Cross>
    <S.Children>{props.children}</S.Children>
  </React.Fragment>
);
