export enum PayMeansPaymentMethodEnum {
  CREDITCARD = 'CREDIT_CARD',
  DIRECTDEBIT = 'DIRECT_DEBIT',
  MANUAL = 'MANUAL',
}

export enum PayMeansCurrencyEnum {
  EUR = 'EUR',
}

export interface DirectDebit {
  accountHolder: string | null;
  /** The bank acount without spaces. */
  accountNumber: string;
}

export enum CreditCardBrandEnum {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMERICANEXPRESS = 'AMERICAN_EXPRESS',
  OTHER = 'OTHER',
}

export interface CreditCard {
  brand?: CreditCardBrandEnum;
  validityEndMonth?: string;
  validityEndYear?: string;
  lastFourDigits?: string;
}

export enum PayMeansInvoiceDeliveryMethodEnum {
  PAPER = 'PAPER',
  EBILL = 'EBILL',
}

export interface PayMeans {
  paymentMethod: PayMeansPaymentMethodEnum;
  directDebit?: DirectDebit;
  creditcard?: CreditCard;
  invoiceDeliveryMethod: PayMeansInvoiceDeliveryMethodEnum;
  allowUpdateAccountHolder?: boolean;
}

/** Bank details in case of updating the pay means. */
export interface UpdatePayMeans {
  accountHolder: string;
  /** The bank acount in electronic IBAN format */
  accountNumber: string;
}
