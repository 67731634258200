import { Label } from 'components/Form/components/Label';
import { composeValidators } from 'components/Form/validate';
import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import randomId from 'utils/random-id';

import { ChoiceError } from '../ChoiceError';
import * as S from './checkbox.styles';

interface CheckboxProps {
  name: string;
  label: string | ReactNode;
  id?: string;
  checked?: boolean;
  validate?: Function[];
  disabled?: boolean;
  onChange?: Function;
  value?: string;
}

export class Checkbox extends React.Component<CheckboxProps> {
  onFieldChange = (e: any, FFonFieldChange: any) => {
    FFonFieldChange(e);
    if (typeof this.props.onChange === 'function') this.props.onChange(e);
  };

  render() {
    // Random ID is generated if id is not set (this way labels will stay accessible).
    const { name, label, validate, id = randomId(), checked, disabled, value, ...rest } = this.props;

    const isRequired = !!(validate && validate.length && validate.find((validation) => validation.name === 'required'));

    return (
      <React.Fragment>
        <Field
          name={name}
          type="checkbox"
          validate={validate ? composeValidators(validate) : undefined}
          render={({ input }) => (
            <S.Checkbox className="checkbox">
              <Label isRequired={isRequired} htmlFor={`${id}`}>
                <input
                  {...input}
                  {...rest}
                  id={`${id}`}
                  type="checkbox"
                  value={value}
                  checked={checked}
                  disabled={disabled}
                  onChange={(e) => this.onFieldChange(e, input.onChange)}
                />
                <span>{label}</span>
              </Label>
            </S.Checkbox>
          )}
        />
        <ChoiceError name={name} />
      </React.Fragment>
    );
  }
}
