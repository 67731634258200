import { BillingCustomer, Products, TelevisionPackage } from 'api';
import { IContractsAndContacts } from 'models';

export default class UserUtil {
  public static determineMergedStatus = (user: IContractsAndContacts[] | undefined) => {
    if (user && user.length >= 2) {
      const hasVodafone = !!user.find((a) => a.billing_customer?.brand.toUpperCase() === 'VODAFONE');
      const hasZiggo = !!user.find((a) => a.billing_customer?.brand.toUpperCase() === 'ZIGGO');
      return hasVodafone && hasZiggo;
    }

    return false;
  };

  public static getUserByBcHash = (
    bcIdHash: string,
    billingCustomers: BillingCustomer[] | null,
    ziggoUsers?: IContractsAndContacts[]
  ): IContractsAndContacts | undefined => {
    let user;
    if (bcIdHash) {
      const unhashedBc = billingCustomers && billingCustomers.find((bc) => bc.hashedId === bcIdHash);

      if (unhashedBc) {
        user = ziggoUsers && ziggoUsers.find((u) => u.billing_customer.billing_customer_id === unhashedBc.id);
      }
    }
    return user || (ziggoUsers && ziggoUsers[0]);
  };

  public static hasTVPackages = (products: Products) => {
    const tvPackages: TelevisionPackage[] = products.televisionProducts
      ? products.televisionProducts.reduce((packages, product) => [...packages, ...(product?.packages || [])], [])
      : [];
    return tvPackages && tvPackages.length > 0;
  };

  public static hasOnlyDtvOffering = (products: Products) => {
    for (const product of products.televisionProducts) {
      if (product.offering?.id !== '110365') return false;
    }

    return true;
  };
}
