export enum WorkaroundContactTimesEnum {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export enum WorkaroundTypeEnum {
  CARE = 'CARE',
  SALES = 'SALES',
}

export interface Workaround {
  workaroundType: WorkaroundTypeEnum;
  context: string;
  email?: string;
  phone?: string;
  saveContactPreferences?: boolean;
  description?: string;
  contactTimes?: Array<WorkaroundContactTimesEnum>;
}
