export default [
  'AABN',
  'ABNA',
  'FTSB',
  'ABNC',
  'ADYB',
  'AEGO',
  'ANDL',
  'ARBN',
  'ARSN',
  'ASNB',
  'ATBA',
  'BCDM',
  'BCIT',
  'BICK',
  'BINK',
  'BITS',
  'BKCH',
  'BKMG',
  'BLGW',
  'BMEU',
  'BNDA',
  'BNGH',
  'BNPA',
  'BOFA',
  'BOFS',
  'BOTK',
  'BUNQ',
  'CHAS',
  'CITC',
  'CITI',
  'COBA',
  'DEUT',
  'DHBN',
  'DLBK',
  'DNIB',
  'EBUR',
  'FBHL',
  'FLOR',
  'FRNX',
  'FVLB',
  'GILL',
  'HAND',
  'HHBA',
  'HSBC',
  'ICBC',
  'ICBK',
  'INGB',
  'ISAE',
  'ISBK',
  'KABA',
  'KASA',
  'KNAB',
  'KOEX',
  'KRED',
  'LOCY',
  'LOYD',
  'LPLN',
  'MHCB',
  'MOYO',
  'NNBA',
  'NWAB',
  'PCBC',
  'RABO',
  'RBRB',
  'SNSB',
  'SOGE',
  'TEBU',
  'TRIO',
  'UBSW',
  'UGBI',
  'VOWA',
  'ZWLB',
];
