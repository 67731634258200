import { AuthContext } from 'context/auth/auth.context';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { FeatureContext } from 'context/feature/feature.context';
import { FEATURES } from 'models/features.model';
import { PermissionSettings } from 'models/permissions.model';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { isUserAllowed } from 'utils/permissions';

export interface RestrictedSettings {
  /** Feature string to test for */
  feature?: FEATURES;
  /** Users allowed to view */
  permissions?: PermissionSettings;
}

const DEFAULT_AUTHORISATION = true;

interface RestrictedProps extends RestrictedSettings {
  /** Can be used as a render prop, which will then return the viewAllowed as a param, or
   * you can directly inject children */
  children: ((viewAllowed: boolean) => React.ReactNode) | React.ReactNode;
}

/**
 * Restrict the view of a component based on a feature name.
 * Features have a on/off state in feature-settings.json
 * and have a restriction in the file authorisation.postpaid.settings.json.
 *
 * If you only want to restrict based on roles, you can pass the 'withoutToggle'
 * prop to ignore anything in the feature-toggle files.
 *
 * If a user is NOT allowed to view the component, null is returned in render.
 *
 * Usage:
 * <Restricted feature="name.of.your.feature">
 *  <div>Add your own components here.</div>
 * </Restricted>
 */
export const Restricted: React.FunctionComponent<RestrictedProps> = ({
  feature,
  permissions,
  children,
}: RestrictedProps) => {
  const [viewAllowed, setViewAllowed] = useState<null | boolean>(null);
  const { isEnabled: isFeatureEnabled } = useContext(FeatureContext);

  const authContext = useContext(AuthContext);
  const billingCustomerContext = useContext(BillingCustomerContext);

  useEffect(() => {
    let hasAccess = DEFAULT_AUTHORISATION;
    let isEnabled = DEFAULT_AUTHORISATION;

    if (
      permissions &&
      billingCustomerContext.contractsAndContacts &&
      billingCustomerContext.contractsAndContacts.billing_customer
    ) {
      hasAccess = isUserAllowed(
        billingCustomerContext.contractsAndContacts.billing_customer.is_consumer,
        authContext.roles,
        permissions
      );
    }

    if (feature) {
      isEnabled = isFeatureEnabled(feature);
    }

    setViewAllowed(hasAccess && isEnabled);
  }, [billingCustomerContext.contractsAndContacts, authContext.roles.length]);

  // Don't do anything yet if viewAllowed isn't resolved yet.
  if (viewAllowed === null) return null;

  // If children is used as render prop, we always render the children with the viewAllowed boolean.
  // Component is then responsible for handling this.
  if (children instanceof Function) return children(viewAllowed) as ReactElement;

  // If view is not allowed and no render prop is passed, component will not render.
  if (!viewAllowed) return null;

  // If view IS allowed but no render prop is passed, it's children are simply rendered.
  return children as ReactElement;
};
