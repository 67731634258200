import { Input } from 'components/Form/components/Input/input.styles';
import styled, { css, rem, screenMobilePlusAndSmaller } from 'theme';

export const IbanInput = styled(Input)`
  padding: 0 ${rem(16)};

  &::placeholder {
    font-family: ${(p) => p.theme.fonts.stacks.primary};
    font-weight: ${(p) => p.theme.fonts.weights.regular};
  }
`;

export const BankCodeSuggestions = styled('div')`
  display: flex;
  flex-direction: column;
  grid-column: 2;

  ${screenMobilePlusAndSmaller(css`
    grid-column: 1;
  `)}
`;

export const BankCode = styled('span')`
  cursor: pointer;
  display: inline-block;
  width: ${rem(65)};
  height: ${rem(30)};
  line-height: ${rem(30)};
  border-radius: ${rem(43)};
  background-color: ${(p) => p.theme.colors.white};
  text-align: center;
  margin-left: ${rem(5)};
  font-size: ${rem(12)};
  border: ${rem(1)} solid ${(p) => p.theme.colors.grayL30};

  &:hover {
    color: ${(p) => p.theme.colors.primary};
    font-weight: ${(p) => p.theme.fonts.weights.bold};
  }
`;
