import { useEffect, useRef } from 'react';

export const useClickOutsideAndKeyNavigation = (
  callback: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
  cycleThroughInputs = false
) => {
  const ref = useRef<HTMLDivElement | HTMLFormElement>(null);

  useEffect(() => {
    const element = ref.current;
    const focusElements: NodeListOf<HTMLInputElement> | never[] =
      (element && element.querySelectorAll('button, input, select, textarea, [href]')) || [];
    const firstFocusElement = focusElements[0];
    const lastFocusElement = focusElements[focusElements.length - 1];

    const handleTabKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstFocusElement) {
          event.preventDefault();
          cycleThroughInputs ? lastFocusElement.focus() : callback(event);
        } else if (!event.shiftKey && document.activeElement === lastFocusElement) {
          event.preventDefault();
          cycleThroughInputs ? firstFocusElement.focus() : callback(event);
        }
      }
    };

    const handleEscapeKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        callback(event);
      }
    };

    const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
      const consentModal = document.getElementById('onetrust-consent-sdk');
      const isClickInsideConsentModal = consentModal?.contains(event.target as Node);

      if (!element?.contains(event.target as Node) && !isClickInsideConsentModal) {
        event.preventDefault();
        callback(event);
      }
    };

    document.addEventListener('keydown', handleTabKeyDown);
    document.addEventListener('keydown', handleEscapeKeyDown);
    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleTabKeyDown);
      document.removeEventListener('keydown', handleEscapeKeyDown);
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [callback, cycleThroughInputs]);

  return ref;
};
