import { UnbilledTelephonyEvent, UnbilledUsageApi } from 'api';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { Duration } from 'models/duration.model';
import React, { createContext, useContext, useEffect, useState } from 'react';

export interface PhoneUsageFilters {
  subscriptionId?: string;
  date: number;
  durationFrom?: number;
  durationTo?: number;
  priceFrom?: number;
  priceTo?: number;
  destinationNumber?: string;
}

export interface PhoneUsagePagination {
  pageSize: number;
  pageOffset: number;
}

export interface IUnbilledPhoneEventsContext {
  isLoading: boolean;
  hasError: boolean;
  phoneEvents: UnbilledTelephonyEvent[] | null;
  filters: PhoneUsageFilters;
  pagination: PhoneUsagePagination;
  itemCount: number;
  setFilters: (filters: PhoneUsageFilters | Function) => void;
  setPagination: (pagination: PhoneUsagePagination) => void;
}

interface UnbilledPhoneEventsProviderProps {
  children: JSX.Element;
}

const initialPagination = {
  pageSize: 10,
  pageOffset: 0,
};

const UnbilledPhoneEventsContext = createContext<IUnbilledPhoneEventsContext | undefined>(undefined);

const useUnbilledPhoneEventsContext = () => useContext(UnbilledPhoneEventsContext) as IUnbilledPhoneEventsContext;

const UnbilledPhoneEventsProvider = ({ children }: UnbilledPhoneEventsProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const [phoneEvents, setPhoneEvents] = useState<UnbilledTelephonyEvent[] | null>(null);
  const [filters, setFilters] = useState<PhoneUsageFilters>({ date: 1 });
  const [pagination, setPagination] = useState<PhoneUsagePagination>(initialPagination);
  const [itemCount, setItemCount] = useState(0);

  const getPhoneEvents = async () => {
    const { activeBcId } = billingCustomerContext;
    const { date, durationFrom, durationTo, priceFrom, priceTo, destinationNumber, subscriptionId } = filters;
    const { pageSize, pageOffset } = pagination;

    if (!activeBcId || !subscriptionId) return;

    setLoading(true);

    try {
      const dateTo = new Date();
      const dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth(), date, 0, 0, 0, 0);

      let durationFromString: string | undefined;
      let durationToString: string | undefined;

      if (durationFrom !== undefined) {
        durationFromString = Duration.fromSeconds(durationFrom).toString();
      }

      if (durationTo !== undefined) {
        durationToString = Duration.fromSeconds(durationTo).toString();
      }

      const { data } = await UnbilledUsageApi.getUnbilledTelephonyEvents(
        activeBcId,
        subscriptionId,
        pageSize,
        pageOffset,
        dateFrom && dateFrom.toISOString(),
        dateTo && dateTo.toISOString(),
        durationFromString,
        durationToString,
        priceFrom,
        priceTo,
        destinationNumber
      );

      setPhoneEvents(data.items);
      setItemCount(data.totalRecords || 0);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPhoneEvents();
  }, [filters, pagination]);

  useEffect(() => {
    if (billingCustomerContext.completed) getPhoneEvents();
  }, [billingCustomerContext.completed]);

  return (
    <UnbilledPhoneEventsContext.Provider
      value={{
        isLoading,
        hasError,
        phoneEvents,
        filters,
        pagination,
        itemCount,
        setFilters,
        setPagination,
      }}>
      {children}
    </UnbilledPhoneEventsContext.Provider>
  );
};

export { UnbilledPhoneEventsContext, UnbilledPhoneEventsProvider, useUnbilledPhoneEventsContext };
