import styled from '@vodafoneziggo/sandwich/theme';
import { ChevronIcon } from 'assets/icons';
import { css, gapSize, keyframes, rem, spacingBase, tablet } from 'theme';

export const Header = styled('header')`
  position: relative;
  z-index: 2;
`;

export const Wrapper = styled('div')`
  max-width: ${(p) => p.theme.grid.maxWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayL40};

  ${tablet(css`
    padding: 0 ${() => gapSize(3)};
    border-bottom: none;
  `)}
`;

export const LinksLeft = styled('div')`
  flex: 1;
  display: flex;
  font-weight: 500;
  font-size: ${rem(14)};
  font-family: ${(p) => p.theme.fonts.stacks.primary};
`;

export const LinksRight = styled(LinksLeft)`
  justify-content: flex-end;
  font-size: ${rem(9)};
`;

const linkStyles = css`
  height: ${rem(73)};
  min-width: ${rem(64)};
  text-transform: uppercase;

  border-bottom: 4px solid white;
  display: flex;
  align-items: center;

  ${tablet(css`
    height: ${rem(80)};
    min-width: ${rem(80)};
    border-left: 1px solid ${(p) => p.theme.colors.grayL30};

    &:hover {
      border-bottom: 4px solid ${(p) => p.theme.colors.primary};
    }
  `)}

  svg {
    margin-bottom: ${() => spacingBase};
  }
`;

export const Link = styled('a')`
  ${linkStyles}

  &:hover {
    color: black;
  }
`;

export const HomeLink = styled(Link)`
  display: none;

  ${tablet(css`
    border-left: none;
    display: flex;
  `)}

  svg {
    margin-bottom: 0;
  }
`;

export const UnAuthHomeLink = styled('a')`
  ${linkStyles}
  border-bottom: none;

  ${tablet(css`
    height: ${rem(48)};
    min-width: ${rem(80)};
    border-left: 0;
  `)}

  svg {
    margin-bottom: 0;
    margin-left: 0;
  }

  :hover {
    border-bottom: none;
  }
`;

export const LockLink = styled('a')`
  ${linkStyles}

  color: black;
  border-bottom: none;
  height: ${rem(3)};

  ${tablet(css`
    height: ${rem(48)};
    min-width: ${rem(80)};
    border-left: 0;
  `)}

  svg {
    margin-bottom: 0;
    margin-right: ${rem(8)};
  }

  &:hover {
    color: black;
    border-bottom: none;
  }
`;

export const PhoneLink = styled('a')`
  ${linkStyles}

  border-bottom: none;
  height: ${rem(3)};

  ${tablet(css`
    height: ${rem(48)};
    min-width: ${rem(80)};
    border-left: 0;
  `)}

  svg {
    height: 1.5rem;
    margin-bottom: 0;
    margin-right: 1rem;
  }

  span {
    font-size: ${rem(14)};
  }

  &:hover {
    color: black;
    border-bottom: none;
  }
`;

export const Chevron = styled(ChevronIcon)`
  transform: rotate(0.25turn);
  width: ${rem(10)};
  height: ${rem(10)};
  margin-right: ${rem(10)};
  margin-bottom: ${() => gapSize(0.5)};
`;

export const Account = styled('div')`
  ${linkStyles}

  ${tablet(css`
    border-right: 1px solid ${(p) => p.theme.colors.grayL30};
  `)}
`;

export const DefaultLink = styled(Link)`
  flex-direction: column;
  justify-content: center;
  font-size: ${rem(9)};
`;

export const ToggleSearchMobile = styled('button')`
  background: transparent;
  border: none;

  cursor: pointer;
  color: ${(p) => p.theme.colors.black};

  flex-direction: column;
  justify-content: center;

  ${linkStyles}
`;

const animateSearchBarMobile = keyframes`
  from {
    transform: translateY(${rem(-20)});
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const SearchMobile = styled('div')`
  display: block;
  position: relative;
  z-index: 1;
  animation: ${animateSearchBarMobile} ${(p) => p.theme.transitions.default} forwards;
`;

export const ScreenReaderOnly = styled('span')`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;
