import { ChevronIcon } from 'assets/icons';
import { Item, Navigation } from 'components/BaseLayout/components/Navigation/navigation.styles';
import { Button as ButtonComponent } from 'components/Button';
import styled, { css, gapSize, hoverFocus, rem, screenMobilePlusAndSmaller, spacingBase } from 'theme';

export const Wrapper = styled('div')`
  position: relative;
  height: 100%;

  ${screenMobilePlusAndSmaller(css`
    position: inherit;
  `)}
`;

export const Toggle = styled.button`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${(p) =>
    screenMobilePlusAndSmaller(css`
      color: ${p.theme.colors.gray900};
    `)}

  span {
    max-width: ${rem(150)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${rem(9)};
    padding: 0 8px;
  }
`;

interface IMenuProps {
  opened?: boolean;
}

export const MenuWrapper = styled.nav<IMenuProps>`
  z-index: 2;
  position: relative;
  display: ${(p) => (p.opened ? 'block' : 'none')};

  ${Navigation} {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  ${Item} {
    padding: ${() => spacingBase} 0;
    width: 100%;
  }

  ${(p) =>
    screenMobilePlusAndSmaller(css`
      position: inherit;
      z-index: inherit;
      &::after {
        content: '';
        position: fixed;
        z-index: -2;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        opacity: ${p.opened ? 1 : 0};
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.23);
        transition: opacity ${p.theme.transitions.default};
        /* By setting pointer events to none, user clicks through it,
        which will effectually click outside the nav, and thus close it. */
        pointer-events: ${p.opened ? 'unset' : 'none'};
      }
    `)}
`;

export const Menu = styled.div<IMenuProps>`
  position: absolute;
  top: 100%;
  right: 0;
  width: ${rem(250)};
  background-color: ${(p) => p.theme.colors.white};
  padding: ${() => gapSize(2)};
  opacity: ${(p) => (p.opened ? '1' : '0')};
  transform: ${(p) => (p.opened ? 'translateY(0)' : 'translateY(-15px)')};
  transition:
    opacity ${(p) => p.theme.transitions.default},
    transform ${(p) => p.theme.transitions.default};
  pointer-events: ${(p) => (p.opened ? 'inherit' : 'none')};
  font-size: ${rem(14)};

  ${(p) =>
    screenMobilePlusAndSmaller(css`
      transform: ${p.opened ? 'translateX(0)' : 'translateX(-250px)'};
      width: 80%;
      right: unset;
      left: 0;
      padding: 0;
      z-index: -1;
    `)}
`;

const buttonStyle = css`
  margin: ${() => gapSize(2)} 0;
  display: block;
  border: ${rem(1)} solid;
  text-align: center;
  line-height: ${rem(40)};
  width: 100%;
  cursor: pointer;
`;

export const MyAccountLink = styled(ButtonComponent)`
  margin: 1rem 0 0 0 !important;
  text-transform: none;
`;

export const Chevron = styled(ChevronIcon)`
  transform: rotate(-90deg);
  margin-left: ${rem(12)};
  margin-bottom: 0 !important;
`;

export const LogoutButton = styled('button')`
  ${buttonStyle};

  background-color: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  border-color: ${(p) => p.theme.colors.black};

  ${(p) =>
    hoverFocus(css`
      background-color: ${p.theme.colors.grayD10};
      border-color: ${p.theme.colors.grayD20};
    `)}
`;
