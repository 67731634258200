import styled from 'styled-components';

export const Base = styled('div')`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Main = styled('main')`
  height: max-content;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.gray100};
`;
