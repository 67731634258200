import styled, { css, gapSize, rem, spacingBase } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

interface StyledInputProps {
  error: boolean;
}

export const Input = styled.input<StyledInputProps>`
  box-shadow: ${boxShadow.inset()};
  border-radius: ${borderRadius.input};
  position: relative;
  padding: ${rem(10)} ${rem(20)} ${rem(10)};
  width: 100%;
  height: ${rem(50)};
  border: ${rem(1)} solid;
  border-color: ${(p) => p.theme.colors.gray600};
  ${(p) => !!p.error && `border-bottom: ${rem(2)} solid ${p.theme.colors.red};`}

  &:disabled {
    box-shadow: ${(p) => boxShadow.inset(p.theme.colors.grayL10)};
  }
`;

export const Label = styled('label')`
  align-self: center;
  position: relative;
  width: 100%;
`;

export const ShowPasswordButton = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: ${rem(50)};
  width: ${rem(50)};
  background-color: ${(p) => p.theme.colors.grayD10};
  border-top-right-radius: ${borderRadius.input};
  border-bottom-right-radius: ${borderRadius.input};
  border: none;
`;

export const InputWrapper = styled.div<{ hideErrorLabel?: boolean }>`
  position: relative;
  margin-bottom: ${() => spacingBase};

  & + .error-message {
    margin-top: -${() => spacingBase};
    margin-bottom: ${() => spacingBase};
  }

  & + .password-checklist,
  & + .error-message + .password-checklist {
    margin-top: -${() => spacingBase};
    margin-bottom: ${() => gapSize(4)};
  }

  ${(p) =>
    p.hideErrorLabel &&
    css`
      & + .error-message {
        display: none;
      }
    `}
`;
