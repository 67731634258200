import styled, { contentPadding, rem, spacingBase } from 'theme';

export const PasswordChecklist = styled.div`
  background-color: ${(p) => p.theme.colors.gray100};
  ${contentPadding()}
`;

export const Title = styled.div`
  font-weight: ${(p) => p.theme.fonts.weights.bold};
  margin-bottom: ${() => spacingBase};
`;

export const Check = styled.div<{ check: boolean }>`
  color: ${(p) => (p.check ? p.theme.colors.success : p.theme.colors.gray900)};
  display: flex;
  align-items: center;

  svg {
    width: ${rem(20)};
    height: ${rem(20)};
    display: ${(p) => (p.check ? 'initial' : 'none')};
    fill: ${(p) => p.theme.colors.success};
    margin-left: ${() => spacingBase};
  }
`;
