import { ApiResponse, query } from './api';
import { getInternetSecurityLinkQuery } from './internet-security.query';
import { InternetSecurityLink } from './internet-security.types';

interface InternetSecurityApi {
  internetSecurityLink(billingCustomerId: string, assignedProductId: string): ApiResponse<InternetSecurityLink>;
}

export const InternetSecurityApi: InternetSecurityApi = {
  internetSecurityLink(billingCustomerId: string, assignedProductId: string): ApiResponse<InternetSecurityLink> {
    return query({
      query: getInternetSecurityLinkQuery,
      variables: { billingCustomerId, assignedProductId },
      name: 'getInternetSecurityLink',
    }).then(({ getInternetSecurityLink }) => ({ data: getInternetSecurityLink }));
  },
};
