import { composeValidators } from 'components/Form/validate';
import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import randomId from 'utils/random-id';

import { ChoiceError } from '../ChoiceError';
import { RadioButton } from './RadioButton';

interface RadioProps {
  name: string;
  options: { value: string; label: string | ReactNode; id?: string; sublabel?: string; disabled?: boolean }[];
  id?: string;
  validate?: Function[];
  disabled?: boolean;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  onChange?: Function;
  testid?: string;
  className?: string;
  sublabelIsLink?: boolean;
}

export class Radio extends React.Component<RadioProps> {
  onFieldChange = (e: any, FFonFieldChange: any) => {
    FFonFieldChange(e);
    if (typeof this.props.onChange === 'function') this.props.onChange(e);
  };

  render() {
    const { name, options, validate, disabled, testid, className, sublabelIsLink } = this.props;
    // Random ID is generated if id is not set (this way labels will stay accessible).
    const id = this.props.id || randomId();

    return (
      <>
        {options.map((option, index) => (
          <Field
            key={index}
            name={name}
            type="radio"
            value={option.value}
            validate={validate && !disabled ? composeValidators(validate) : undefined}
            render={({ input }) => (
              <RadioButton
                id={`${id}_${index}`}
                data-testid={testid}
                {...input}
                onChange={(e) => this.onFieldChange(e, input.onChange)}
                disabled={disabled || option.disabled}
                // @ts-expect-error TS(2322): Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
                label={option.label}
                sublabel={option.sublabel}
                sublabelIsLink={sublabelIsLink}
                className={className}
              />
            )}
          />
        ))}
        <ChoiceError name={name} />
      </>
    );
  }
}
