import { AxiosPromise } from 'axios';

import * as Q from './access-state.query';
import * as T from './access-state.types';
import { ApiResponse, query } from './api';

interface AccessApiInterfaceSelfservice {
  /**
   * Gets the current access state for a billing customer
   * @param {string} billingCustomerId Billing customer id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessApiInterface
   */
  getAccessState(billingCustomerId: string, options?: any): AxiosPromise<T.AccessState>;
}

interface AccessStateApiInterface extends Omit<AccessApiInterfaceSelfservice, 'getAccessState'> {
  getAccessState(billingCustomerId: string): ApiResponse<T.AccessState>;
}

export const AccessStateApi: AccessStateApiInterface = {
  getAccessState(billingCustomerId: string) {
    return query({
      query: Q.getAccessStateQuery,
      variables: { billingCustomerId },
      name: 'getAccessState',
    }).then(({ getAccessState }) => ({ data: getAccessState }));
  },
};
