import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import SupportCaseApi from 'api/support-case.api';
import SupportCase from 'api/support-case.types';
import { DEFAULT_OIDC_SCOPES } from 'config/app.config';
import { appSettings } from 'config/app.settings';
import React, { useState } from 'react';

import SupportCaseContext from './support-case.context';

const SupportCaseProvider: React.FC = ({ children }: { children: JSX.Element }) => {
  const [supportCases, setSupportCases] = useState<SupportCase[]>([]);
  const [hasSupportCases, setHasSupportCases] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [fetchCompleted, setFetchCompleted] = useState<boolean>(false);
  const [hasCompleted, setHasCompleted] = useState<boolean>(false);

  const fetchSupportCases = async (billingCustomerId: string): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await SupportCaseApi.get(billingCustomerId);
      // @ts-expect-error TS(2345): Argument of type '{ data: SupportCase[]; }' is not... Remove this comment to see the full error message
      setSupportCases(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setFetchCompleted(true);
      setIsLoading(false);
    }
  };

  const fetchHasSupportCases = async (billingCustomerId: string): Promise<void> => {
    if (supportCases.length) {
      setHasSupportCases(true);
    } else {
      try {
        // @ts-expect-error TS(2345): Argument of type '{ data: HasSupportCases; }' is n... Remove this comment to see the full error message
        setHasSupportCases(await SupportCaseApi.hasCases(billingCustomerId));
      } catch (err) {
        setError(err.message);
      }
    }

    setHasCompleted(true);
  };

  const findSupportCase = async (billingCustomerId: string, reference: string): Promise<SupportCase> => {
    // @ts-expect-error TS(2739): Type '{ data: SupportCase; }' is missing the follo... Remove this comment to see the full error message
    return SupportCaseApi.find(billingCustomerId, reference);
  };

  const addSupportCaseComment = async (
    billingCustomerId: string,
    reference: string,
    message: string
  ): Promise<SupportCase> => {
    // @ts-expect-error TS(2739): Type '{ data: SupportCaseSuccess; }' is missing th... Remove this comment to see the full error message
    return SupportCaseApi.addComment(billingCustomerId, reference, message);
  };

  const closeSupportCase = async (billingCustomerId: string, reference: string): Promise<SupportCase> => {
    // @ts-expect-error TS(2322): Type '{ data: SupportCaseSuccess; }' is not assign... Remove this comment to see the full error message
    return SupportCaseApi.close(billingCustomerId, reference);
  };

  const downloadAttachment = async (
    billingCustomerId: string,
    reference: string,
    attachmentId: string,
    fileName: string
  ) => {
    const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });

    if (token) {
      const authHeader = OidcService.getAuthHeader(token);

      if (authHeader) {
        const [, access_token] = authHeader.split(' ');

        // set timeout will force iOS safari to be run on the main thread and allows opening of the popup
        setTimeout(() => {
          window.open(
            `${appSettings.ENV_MBSS_REST_BASE_URL}/support-case/attachment/${billingCustomerId}/${reference}/${attachmentId}/${fileName}?access_token=${access_token}`
          );
        });

        return;
      }
    }

    throw new Error('Attachment not available');
  };

  return (
    <SupportCaseContext.Provider
      value={{
        isLoading,
        error,
        supportCases,
        hasSupportCases,
        fetchSupportCases,
        fetchHasSupportCases,
        fetchCompleted,
        hasCompleted,
        findSupportCase,
        addSupportCaseComment,
        closeSupportCase,
        downloadAttachment,
      }}>
      {children}
    </SupportCaseContext.Provider>
  );
};

export default SupportCaseProvider;
