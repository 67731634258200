import { gql } from 'graphql-request';

export const getSupportCaseListQuery = gql`
  query getSupportCaseList($billingCustomerId: String!) {
    getSupportCaseList(billingCustomerId: $billingCustomerId) {
      reference
      title
      date
      status
    }
  }
`;

export const hasSupportCaseQuery = gql`
  query hasSupportCase($billingCustomerId: String!) {
    hasSupportCase(billingCustomerId: $billingCustomerId)
  }
`;

export const findSupportCaseQuery = gql`
  query findSupportCase($billingCustomerId: String!, $reference: String!) {
    findSupportCase(billingCustomerId: $billingCustomerId, reference: $reference) {
      reference
      title
      description
      date
      status
      contactName
      comments {
        author
        message
        creationDate
      }
      closeable
      attachments {
        id
        fileName
        title
        mimeType
      }
    }
  }
`;

export const addSupportCaseCommentQuery = gql`
  mutation addSupportCaseComment($billingCustomerId: String!, $reference: String!, $message: String!) {
    addSupportCaseComment(billingCustomerId: $billingCustomerId, reference: $reference, message: $message) {
      success
    }
  }
`;

export const closeSupportCaseQuery = gql`
  mutation closeSupportCase($billingCustomerId: String!, $reference: String!) {
    closeSupportCase(billingCustomerId: $billingCustomerId, reference: $reference) {
      success
    }
  }
`;
