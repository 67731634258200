import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { ValidationErrors } from 'final-form';
import { IFormError } from 'models';
import React, { RefObject } from 'react';
import { Form as FinalForm, FormRenderProps, FormSpy, FormSpyRenderProps } from 'react-final-form';
import { ContentUtil, globalContentUtil } from 'utils/content';
import randomId from 'utils/random-id';
import { scrollTo } from 'utils/scroll';

import * as S from './form.styles';
import { validators } from './validate';

interface FormPropsBase {
  onSubmit: (values: Record<string, any>) => Promise<void>;
  buttonText?: string | React.ReactNode;
  buttonTextCancel?: string | React.ReactNode;
  buttonTextConfirm?: string;
  hasConfirmation?: boolean;
  confirmationMessage?: string;
  confirmationMessageOk?: string;
  confirmationMessageCancel?: string;
  id?: string;
  afterSuccessModelClose?: Function;
  successMessage?: string;
  successButtonText?: string;
  successButtonHref?: string;
  failedMessage?: string;
  initialValues?: {};
  buttonVariant?: ButtonVariant;
  render: (
    api: FormRenderProps<any>,
    formvalidators: { [key: string]: Function },
    itemValidation: Function,
    comparePasswords: Function
  ) => React.ReactNode;
  isSuccess?: boolean;
  isFailed?: boolean;
  isLoading?: boolean;
  itemValidationErrors?: IFormError[];
  onUpdate?: Function;
  modalButtonsStyled?: boolean;
  hideSubmitButton?: boolean;
  hideCancelButton?: boolean;
  onCancel?: Function;
  contentUtil?: ContentUtil;
  testid?: string;
  grid?: boolean;
  scrollToRef?: RefObject<any>;
  skipScrollToRef?: boolean;
  validate?: (
    values: any // this should be a generic type, better even get rid of our custom layer as final form has pretty good validation built-in
  ) => ValidationErrors | Promise<ValidationErrors> | undefined;
}

type FormProps = FormPropsBase;

interface FormState {
  values: any;
  closeModal?: boolean;
  passwordsNotEqual: boolean;
}

export class Form extends React.Component<FormProps, FormState> {
  state: FormState = {
    values: this.props.initialValues,
    passwordsNotEqual: false,
  };

  private id: string;

  private contentUtil: ContentUtil;

  public ref: RefObject<any>;

  constructor(props: FormProps) {
    super(props);
    this.id = props.id || randomId();
    this.ref = React.createRef();
    this.contentUtil = props.contentUtil || globalContentUtil;
  }

  getErrorTranslation(key: string) {
    return this.contentUtil.hasTranslation(`error.validation.${key}`) && key;
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>, formApi: FormRenderProps<any>) => {
    // Check if we need to show a confirmation message before we submit the form
    if (!this.props.hasConfirmation) {
      let coords = 0;
      const ref = (this.props.scrollToRef && this.props.scrollToRef.current) || (this.ref && this.ref.current);
      if (ref) {
        coords = ref.getBoundingClientRect().top;
      }
      await formApi.handleSubmit(e);

      // trigger tracking event
      this.id && this.ddmTrigger(this.props.isFailed ? 'fail' : 'success', this.id);

      !this.props.skipScrollToRef && scrollTo(coords);
    } else {
      e.preventDefault();
    }
  };

  /**
   * Add an possible 3party error message into the field based on the given errors
   * @param key :same name as the backend error response item_key
   * @param translateKey :default is the backend error repsonse error_code, you can override this.
   * @returns Message | undefined
   * @private
   */
  validateItem = (key: string, translateKey?: string) => {
    const { itemValidationErrors } = this.props;
    const foundError = itemValidationErrors && itemValidationErrors.find((err: IFormError) => err.field === key);
    if (foundError) {
      const errorKey =
        translateKey ||
        this.getErrorTranslation(foundError.code.toLowerCase()) ||
        this.getErrorTranslation(foundError.field.toLowerCase());

      return this.contentUtil.translateToString(`error.validation.${errorKey}`);
    }
    return undefined;
  };

  comparePasswords = (password: string, passwordRepeat: string, formApi: any) => {
    const { modified, values } = formApi;

    if (modified[password] && modified[passwordRepeat] && values[password] !== values[passwordRepeat]) {
      // only update the state if needed
      if (!this.state.passwordsNotEqual) {
        this.setState({ passwordsNotEqual: true });
      }
      return this.contentUtil.translate(`error.validation.passwordsMatch`);
    }

    // only update the state if needed
    if (this.state.passwordsNotEqual) {
      this.setState({ passwordsNotEqual: false });
    }

    return undefined;
  };

  /**
   * Send response for tracking purposes
   */
  ddmTrigger = (response: 'success' | 'fail', formName: string, err?: any) => {
    let errorArray;

    if (err && err.errorMessages) {
      errorArray = Object.keys(err.errorMessages);
    }

    if (window._ddm) {
      window._ddm.trigger('form.submit', {
        data: {
          result: response,
          name: formName,
          error: errorArray || undefined,
        },
      });
    }
  };

  onFormUpdate = (formSpy: FormSpyRenderProps<any>) => {
    if (this.props.onUpdate) {
      // check if the values have actually updated, as formspy also triggers on rerenders
      if (JSON.stringify(formSpy.values) !== JSON.stringify(this.state.values)) {
        this.setState({ values: formSpy.values }, () => {
          if (this.props.onUpdate) {
            this.props.onUpdate(formSpy.values);
          }
        });
      }
    }
    return null;
  };

  render() {
    const {
      buttonText,
      buttonTextCancel,
      failedMessage,
      hideSubmitButton,
      hideCancelButton,
      modalButtonsStyled,
      initialValues,
      isFailed,
      isLoading,
      isSuccess,
      onSubmit,
      onUpdate,
      render,
      onCancel,
      testid,
      validate,
      grid = true,
      buttonVariant,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        {isFailed && failedMessage && <S.FormError>{failedMessage}</S.FormError>}
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues || {}}
          validate={validate}
          render={(formApi) => {
            return (
              <S.Form
                ref={this.ref}
                grid={grid}
                id={this.id}
                data-testid={testid}
                onSubmit={(e) => this.handleSubmit(e, formApi)}
                noValidate
                {...rest}>
                {render(formApi, validators, this.validateItem, this.comparePasswords)}
                {!hideSubmitButton && (
                  <div className={`form-button ${modalButtonsStyled ? 'form-buttons-modal' : ''}`}>
                    <Button
                      type="submit"
                      testid="form-submit-button"
                      buttonVariant={buttonVariant || ButtonVariant.DARK}>
                      {!(isSuccess || isLoading) &&
                        (buttonText || globalContentUtil.translate('global.forms.button.submit'))}
                      {isSuccess && !isLoading && globalContentUtil.translate('global.forms.button.success')}
                      {isLoading && (
                        <React.Fragment>
                          {globalContentUtil.translate('global.forms.button.loading')}
                          <span>.</span>
                          <span>.</span>
                          <span>.</span>
                        </React.Fragment>
                      )}
                    </Button>
                    {!hideCancelButton && (
                      <Button
                        outline
                        buttonVariant={ButtonVariant.DARK}
                        className="btn-cancel"
                        testid="form-cancel-button"
                        onClick={() => onCancel && onCancel()}>
                        {buttonTextCancel || globalContentUtil.translate('global.forms.button.cancel')}
                      </Button>
                    )}
                  </div>
                )}
                {onUpdate && <FormSpy subscription={{ values: true, dirty: true }} onChange={this.onFormUpdate} />}
              </S.Form>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
