import { ApiResponse, query } from './api';
import { getPaymentArrangementQuery, setPaymentArrangementQuery } from './payment-arrangement.query';
import {
  CreatePaymentArrangementRequest,
  CreatePaymentArrangementResponse,
  PaymentArrangement,
} from './payment-arrangement.types';

interface PaymentArrangementInterface {
  getPaymentArrangement(billingCustomerId: string): ApiResponse<PaymentArrangement>;
  setPaymentArrangement(
    billingCustomerId: string,
    paymentArrangement: CreatePaymentArrangementRequest
  ): ApiResponse<CreatePaymentArrangementResponse>;
}

export const PaymentArrangementApi: PaymentArrangementInterface = {
  async getPaymentArrangement(billingCustomerId: string) {
    return query({
      query: getPaymentArrangementQuery,
      variables: { billingCustomerId },
      name: 'getPaymentArrangement',
    }).then(({ getPaymentArrangement }) => ({ data: getPaymentArrangement }));
  },
  async setPaymentArrangement(billingCustomerId: string, paymentArrangement: CreatePaymentArrangementRequest) {
    return query({
      query: setPaymentArrangementQuery,
      variables: { billingCustomerId, paymentArrangement },
      name: 'setPaymentArrangement',
    }).then(({ setPaymentArrangement }) => ({ data: setPaymentArrangement }));
  },
};
