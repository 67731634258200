import { ChevronIcon } from 'assets/icons';
import { Community } from 'assets/img/Community';
import { Construction } from 'assets/img/Construction';
import { EmailFlyingThroughTheSun } from 'assets/img/EmailFlyingThroughTheSun';
import { Telephony } from 'assets/img/Telephony';
import { Modal } from 'components/Modal';
import { ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { useFeature } from 'context/feature/feature.context';
import { FEATURES } from 'models/features.model';
import { WorkaroundCallSection } from 'pages/Workaround/components/WorkaroundCallSection';
import { WorkaroundContext } from 'pages/Workaround/models/WorkaroundContext';
import React, { useState } from 'react';
import { ContentUtil } from 'utils';

import { content } from './workaround-popup.content';
import * as S from './workaround-popup.styles';

interface IWorkaroundPopup {
  context: WorkaroundContext;
  onClose: () => void;
  phoneContent?: boolean;
}

const contentUtil = new ContentUtil({ content, componentName: 'WorkaroundPopup' });

export const WorkaroundPopup = ({ context, phoneContent = false, onClose, ...rest }: IWorkaroundPopup) => {
  const [showCallContent, setShowCallContent] = useState(phoneContent);
  const showCommunity = useFeature(FEATURES.WORKAROUND_COMMUNNITY);

  const title = contentUtil.translate(`title`);
  const isWorkaroundPage = window.location.href.indexOf('/workaround/') > -1;

  const linkProps = {
    to: isWorkaroundPage ? undefined : `/workaround/${context.url}`,
    onClick: !isWorkaroundPage ? undefined : () => onClose(),
  };

  return (
    <Modal onClose={onClose} {...rest}>
      <section>
        {!showCallContent ? (
          <>
            <ModalIcon>
              <Construction />
            </ModalIcon>

            <h4>{title}</h4>
            {contentUtil.translateHtml(showCommunity ? 'workaroundTextCommunity' : 'workaroundText')}
            <ModalButtons>
              {showCommunity ? (
                <S.Button
                  data-testid="workaround-popup-community"
                  outline
                  href="https://community.ziggo.nl/bright-collega-migratie-test-300">
                  <Community />
                  <span>{contentUtil.translate('community')}</span>
                </S.Button>
              ) : (
                <S.Button outline onClick={() => setShowCallContent(true)}>
                  <Telephony />
                  <span>{contentUtil.translate('call')}</span>
                </S.Button>
              )}
              <S.Button outline to={`/workaround/${context.url}`} data-testid="workaround-contact-form-butt">
                <EmailFlyingThroughTheSun />
                <span>{contentUtil.translate('onlineForm')}</span>
              </S.Button>
            </ModalButtons>
          </>
        ) : (
          <>
            <ModalIcon>
              <Telephony />
            </ModalIcon>

            <h4>{contentUtil.translate(`callTitle`)}</h4>
            <WorkaroundCallSection context={context} />

            <S.Link primary href={contentUtil.translateToString('contactZiggoBusinessUrl')}>
              <S.Rotate>
                <ChevronIcon />
              </S.Rotate>
              {contentUtil.translateHtml(`contactZiggoBusinessLink`)}
            </S.Link>
            <S.Link primary {...linkProps}>
              <S.Rotate>
                <ChevronIcon />
              </S.Rotate>
              {contentUtil.translate('webformLink')}
            </S.Link>
          </>
        )}
      </section>
    </Modal>
  );
};
