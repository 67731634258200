import React from 'react';

import * as S from './label.styles';

interface LabelProps {
  children: React.ReactNode;
  className?: string;
  htmlFor?: string;
  isRequired?: boolean;
  testid?: string;
}

export const Label = ({ children, className, htmlFor, isRequired, testid }: LabelProps) => {
  return (
    <S.Label className={className} htmlFor={htmlFor} data-testid={testid}>
      {children}
      {isRequired && <span> *</span>}
    </S.Label>
  );
};
