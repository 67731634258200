import { gql } from 'graphql-request';

export const getWifiRoamingStatusQuery = gql`
  query getWifiRoamingStatus($billingCustomerId: String!, $username: String!) {
    getWifiRoamingStatus(billingCustomerId: $billingCustomerId, username: $username) {
      customerId
      displayAction
      severity
      status
      statusCode
      statusDescription
    }
  }
`;

export const getWifiSpotQuery = gql`
  query getWifiSpot($billingCustomerId: String!, $username: String!) {
    getWifiSpot(billingCustomerId: $billingCustomerId, username: $username) {
      status
      username
      password
    }
  }
`;

export const changeWifiSpotQuery = gql`
  mutation patchWifiSpot($billingCustomerId: String!, $enabled: Boolean!, $username: String!) {
    patchWifiSpot(billingCustomerId: $billingCustomerId, enabled: $enabled, username: $username) {
      status
      username
      password
    }
  }
`;

export const resetWifiSpotPasswordQuery = gql`
  mutation resetWifiSpotPassword($billingCustomerId: String!, $username: String!) {
    resetWifiSpotPassword(billingCustomerId: $billingCustomerId, username: $username) {
      status
      username
      password
    }
  }
`;
