import { BillingCustomerTypeEnum } from './billingcustomer.types';

export enum AppointmentCancellationType {
  APPOINTMENTNOTCONVENIENT = 'APPOINTMENTNOTCONVENIENT',
  DISTURBANCEPREVENTED = 'DISTURBANCEPREVENTED',
  INSTALLATIONSOLVED = 'INSTALLATIONSOLVED',
  PROBLEMSOLVED = 'PROBLEMSOLVED',
}

export enum AppointmentStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ONSITE = 'ONSITE',
  ON_SITE = 'ON_SITE',
  PLANNED = 'PLANNED',
  TRAVELLING = 'TRAVELLING',
  UNKNOWN = 'UNKNOWN',
}

export enum InstallationGuaranteeInstallationGuaranteeStatusEnum {
  ALLOWED = 'GUARANTEE_ALLOWED',
  EXPIRED = 'GUARANTEE_EXPIRED',
  ERROR = 'GUARANTEE_ERROR',
}

export interface AppointmentCancel {
  cancellationReason?: AppointmentCancellationType | null;
}

export interface Appointment {
  allowCancel: boolean;
  allowReschedule: boolean;
  ticketId: string;
  caseId?: string;
  appointmentContact: AppointmentContact | null;
  appointmentSlot: AppointmentSlot;
  appointmentStatus: AppointmentStatus;
  address: InstallationAddress;
  needsCancellationReason?: boolean;
}

export interface AppointmentPermissions {
  needsCancellationReason: boolean;
}

export interface InstallationAddress {
  streetName: string;
  houseNumber: string;
  houseNumberExtension: string | null;
  roomNumber: string | null;
  postCode: string;
  city: string;
}

export interface AppointmentContact {
  email?: string | null;
  phone?: string | null;
}

export interface AppointmentSlot {
  startDateTime: Date;
  endDateTime: Date;
}

export interface AppointmentReschedule {
  newAppointmentId: string;
  slot: AppointmentSlot;
  subType: string;
  appointmentContactSettings?: AppointmentContact | null;
}

export interface CreateAppointment {
  appointmentId: string;
  orderId: string;
  slot: AppointmentSlot;
  appointmentContactSettings?: AppointmentContact | null;
}

export interface AppointmentSlotsAvailable {
  appointmentId: string;
  slots: AppointmentSlot[];
}

/* things we copy to avoid selfservice imports */

export interface AppointmentsUnauthenticated {
  appointments: Appointment[];
  installationGuarantee?: InstallationGuarantee | null;
}

export interface InstallationGuarantee {
  hasInstallationGuarantee: boolean;
  customerType: BillingCustomerTypeEnum;
  installationGuaranteeStatus?: InstallationGuaranteeInstallationGuaranteeStatusEnum;
  dateFrom: string | null;
  dateTo: string | null;
}

export interface AppointmentCreate {
  appointmentId: string;
  slot: AppointmentSlot;
  appointmentToken: string;
  appointmentContactSettings?: AppointmentContact | null;
}
