import * as Sentry from '@sentry/react';
import { BillingCustomerApi } from 'api';
import { IContractsAndContacts } from 'models';
import React, { useContext, useEffect, useState } from 'react';
import { getQueryStringParamByName } from 'utils/url';
import UserUtil from 'utils/user.util';

import { AuthContext } from './auth/auth.context';

export interface IBillingCustomerContext {
  contractsAndContacts: IContractsAndContacts | null;
  activeBcId: string;
  activeBcIdHash: string;
  isLoading: boolean;
  hasError: boolean;
  errorCode: Record<string, string>;
  completed: boolean;
}

const intialState: IBillingCustomerContext = {
  contractsAndContacts: null,
  activeBcId: '',
  activeBcIdHash: '',
  isLoading: false,
  hasError: false,
  errorCode: {},
  completed: false,
};

const BillingCustomerContext = React.createContext(intialState);

const BillingCustomerProvider = ({ children }: { children: React.ReactNode }) => {
  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<Record<string, string>>({});
  const [activeBcId, setActiveBcId] = useState<string>('');
  const [activeBcIdHash, setActiveBcIdHash] = useState<string>('');
  const [contractsAndContacts, setContractsAndContacts] = useState<IContractsAndContacts | null>(null);

  useEffect(() => {
    if (authContext.completed) init();
  }, [authContext.completed]);

  const init = async () => {
    setIsLoading(true);
    const bcIdHash = getQueryStringParamByName('bcId');
    const billingCustomers = await BillingCustomerApi.getBillingCustomers(bcIdHash as string)
      .then((response) => response.data)
      .catch((e) => {
        setNoBillingCustomerError(e?.headers);
        return null;
      });

    const user = UserUtil.getUserByBcHash(bcIdHash || '', billingCustomers, authContext.ziggoUsers);

    if (authContext.isLoading || !user) {
      return;
    }

    let customer = null;
    try {
      customer =
        billingCustomers?.length === 0
          ? (await BillingCustomerApi.getBillingCustomer(user.billing_customer.billing_customer_id)).data
          : null;

      if (!customer) {
        return;
      }
    } catch (e) {
      setNoBillingCustomerError(e?.headers);
      return;
    }

    // set customerType for Chat
    // @ts-expect-error TS(2339): Property 'subType' does not exist on type 'Billing... Remove this comment to see the full error message
    if (billingCustomers && user && customer.subType === 'B2C') {
      user.billing_customer.is_consumer = true;
    }

    const activeBcId = user?.billing_customer?.billing_customer_id;
    const activeBc = billingCustomers && billingCustomers.find((billingCustomer) => billingCustomer.id === activeBcId);

    Sentry.setUser({
      id: activeBcId,
    });

    try {
      setActiveBcId(activeBcId);
      setActiveBcIdHash(authContext.hashedCustomerId || customer?.hashedId || activeBc?.hashedId || '');
      setContractsAndContacts(user);
      setIsLoading(false);
      setCompleted(true);
    } catch (e) {
      setActiveBcId(activeBcId);
      setActiveBcIdHash(authContext.hashedCustomerId || customer?.hashedId || activeBc?.hashedId || '');
      setContractsAndContacts(user);
      setIsLoading(false);
      setHasError(true);
      setCompleted(true);
    }
  };

  const setNoBillingCustomerError = (errorHeaders: Record<string, string>) => {
    setIsLoading(false);
    setCompleted(false);
    setHasError(true);
    setErrorCode({
      'Correlation-Id': errorHeaders['Correlation-Id'],
      'X-Hawaii-Tx-Id': errorHeaders['X-Hawai-Tx-Id'],
    });
  };

  return (
    <BillingCustomerContext.Provider
      value={{
        isLoading,
        completed,
        hasError,
        contractsAndContacts,
        activeBcId,
        activeBcIdHash,
        errorCode,
      }}>
      {children}
    </BillingCustomerContext.Provider>
  );
};

export { BillingCustomerContext, BillingCustomerProvider };
