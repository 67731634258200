import { ApiResponse, query } from './api';
import * as Q from './component-identifier.query';
import * as T from './component-identifier.types';

interface ComponentIdentifierInterface {
  componentIdentifier(
    billingCustomerId: string,
    componentIdentifier: T.ComponentIdentifierParameter
  ): ApiResponse<T.ComponentIdentifier>;
  componentIdentifierForProduct(
    billingCustomerId: string,
    componentIdentifier: T.ComponentIdentifierParameter,
    assignedProductId: string | undefined
  ): ApiResponse<T.ComponentIdentifier>;
  canRefreshEntitlements(
    billingCustomerId: string,
    productId: string,
    subProductId: string
  ): ApiResponse<T.ComponentIdentifier>;
  canRequestPrivacy(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
  canComplaintPrivacy(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
  canDeletePrivacy(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
  canUpdatePhoneSettings(
    billingCustomerId: string,
    componentIdentifier: T.ComponentIdentifierParameter,
    assignedProductId: string | undefined
  ): ApiResponse<T.ComponentIdentifier>;
  canChangeSecretCircle(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
  canChangeWifiSpot(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
  canCreateWifiSpot(billingCustomerId: string): ApiResponse<T.ComponentIdentifier>;
}

export const ComponentIdentifierApi: ComponentIdentifierInterface = {
  componentIdentifier(billingCustomerId, componentIdentifier) {
    return query({
      query: Q.getComponentIdentifierQuery,
      variables: {
        billingCustomerId,
        componentIdentifier,
      },
      name: 'getComponentIdentifier',
    }).then(({ getComponentIdentifier }) => ({
      data: {
        enabled: getComponentIdentifier.enabled,
      },
    }));
  },

  componentIdentifierForProduct(billingCustomerId, componentIdentifier, assignedProductId) {
    return query({
      query: Q.getComponentIdentifierForProductQuery,
      variables: {
        billingCustomerId,
        componentIdentifier,
        assignedProductId,
      },
      name: 'getComponentIdentifierForProduct',
    }).then(({ getComponentIdentifierForProduct }) => ({
      data: {
        enabled: getComponentIdentifierForProduct.enabled,
      },
    }));
  },

  canRefreshEntitlements(billingCustomerId, productId, subProductId) {
    return query({
      query: Q.canRefreshEntitlementsQuery,
      variables: {
        billingCustomerId,
        productId,
        subProductId,
      },
      name: 'canRefreshEntitlements',
    }).then(({ canRefreshEntitlements }) => ({ data: canRefreshEntitlements }));
  },

  canRequestPrivacy(billingCustomerId) {
    return query({
      query: Q.canRequestPrivacyQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canRequestPrivacy',
    }).then(({ canRequestPrivacy }) => ({ data: canRequestPrivacy }));
  },

  canComplaintPrivacy(billingCustomerId) {
    return query({
      query: Q.canComplaintPrivacyQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canComplaintPrivacy',
    }).then(({ canComplaintPrivacy }) => ({ data: canComplaintPrivacy }));
  },

  canDeletePrivacy(billingCustomerId) {
    return query({
      query: Q.canDeletePrivacyQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canDeletePrivacy',
    }).then(({ canDeletePrivacy }) => ({ data: canDeletePrivacy }));
  },

  canUpdatePhoneSettings(billingCustomerId, componentIdentifier, assignedProductId) {
    return query({
      query: Q.canUpdatePhoneSettingsQuery,
      variables: {
        billingCustomerId,
        componentIdentifier,
        assignedProductId,
      },
      name: 'canUpdatePhoneSettings',
    }).then(({ canUpdatePhoneSettings }) => ({
      data: {
        enabled: canUpdatePhoneSettings.enabled,
      },
    }));
  },

  canChangeSecretCircle(billingCustomerId) {
    return query({
      query: Q.canChangeSecretCircleQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canChangeSecretCircle',
    }).then(({ canChangeSecretCircle }) => ({ data: canChangeSecretCircle }));
  },

  canChangeWifiSpot(billingCustomerId) {
    return query({
      query: Q.canChangeWifiSpotQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canChangeWifiSpot',
    }).then(({ canChangeWifiSpot }) => ({ data: canChangeWifiSpot }));
  },

  canCreateWifiSpot(billingCustomerId) {
    return query({
      query: Q.canCreateWifiSpotQuery,
      variables: {
        billingCustomerId,
      },
      name: 'canCreateWifiSpot',
    }).then(({ canCreateWifiSpot }) => ({ data: canCreateWifiSpot }));
  },
};
