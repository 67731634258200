import { OidcConfig, OidcService } from '@hawaii-framework/oidc-implicit-core';
import { DEFAULT_OIDC_SCOPES, GetOidcConfig } from 'config/app.config';
import { appSettings } from 'config/app.settings';
import { LocalStorage } from 'utils';
import api from 'utils/api';

const API_AUTH_HEADER = 'Authorization';
const SESSION_VALIDATE_TIMEOUT = 15 * 1000; // 15 seconds.
const SELECTED_PRODUCT = 'selectedProduct';
const PRODUCT_SUMMARIES = 'productSummaries';
const ACTIVE_BC_INDEX_KEY_IN_STORAGE = 'current_billing_customer';

export default class OidcUtil {
  public static async setOidcConfig() {
    const config = GetOidcConfig();

    if (process.env.NODE_ENV === 'development') {
      try {
        const urlParams = document.location.hash.split('billingCustomerId=')[1];
        const user = sessionStorage.getItem('billingCustomerId') || urlParams;

        if (user) {
          sessionStorage.setItem('billingCustomerId', user);

          // we add the user in the authorize endpoint,
          // so we know which token we have to return
          config.authorize_endpoint = `${config.authorize_endpoint}/${user}`;
        }
      } catch (e) {
        console.log(e);
      }
    }

    OidcService.OidcConfigService.config = { ...this.setRedirectUriConfig(config) };
  }

  public static initializeOidcSession(onSessionInvalid: () => void) {
    // Starts interval to check every 15 seconds if session token is still valid.
    setInterval(() => {
      this.checkIfSessionIsStillValid(onSessionInvalid);
    }, SESSION_VALIDATE_TIMEOUT);
  }

  public static checkSession() {
    return OidcService.checkSession();
  }

  private static checkIfSessionIsStillValid(onSessionInvalid: () => void) {
    OidcService.isSessionAlive()
      .then(
        (response: { status: number }) => {
          if (response && response.status === 204) return;
          this.logOutBecauseSessionIsInvalid(onSessionInvalid);
        },
        () => {
          this.logOutBecauseSessionIsInvalid(onSessionInvalid);
        }
      )
      .catch(() => {
        this.logOutBecauseSessionIsInvalid(onSessionInvalid);
      });
  }

  private static logOutBecauseSessionIsInvalid(onSessionInvalid: () => void) {
    // Logout when session is gone
    onSessionInvalid();
    localStorage.setItem('auto_logout', 'true');
    this.clearBcAndProductFromLocalStorage();

    window.location.href = GetOidcConfig().post_logout_redirect_uri;
  }

  public static clearBcAndProductFromLocalStorage = () => {
    LocalStorage.remove(ACTIVE_BC_INDEX_KEY_IN_STORAGE);
    LocalStorage.remove(PRODUCT_SUMMARIES);
    LocalStorage.remove(SELECTED_PRODUCT);
  };

  // Gets bearer token from OidcService and sets header on API.
  public static setHeaderOnAPI() {
    const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });

    if (token) {
      api.apiV1.setHeader(API_AUTH_HEADER, OidcService.getAuthHeader(token));
    }
  }

  private static setRedirectUriConfig(config: OidcConfig) {
    const { port, protocol, hostname } = window.location;
    const baseRedirectUri = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    // Set the current URL as redirect
    let redirectURI = `${baseRedirectUri}${location.pathname + location.search}`;

    // Check if we can redirect to this uri, if not, go to default
    config.restricted_redirect_uris.forEach((restrictedUriPart: string) => {
      if (window.location.pathname.indexOf(restrictedUriPart) !== -1) {
        redirectURI = `${baseRedirectUri}${appSettings.ENV_PUBLIC_URL}`;
      }
    });

    // Set the redirect uri in this instance
    // eslint-disable-next-line no-param-reassign
    config.redirect_uri = redirectURI;

    return config;
  }
}
