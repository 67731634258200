import { Col, Row } from 'components/Grid';
import React from 'react';
import { NavLink } from 'react-router-dom';

import * as S from './page-header.styles';

interface IParent {
  to: string;
  label: string;
}

interface IPageHeaderProps {
  title?: string;
  parents?: IParent[];
  children?: React.ReactNode;
  className?: string;
  hasTabs?: boolean;
}

export const PageHeader = ({ className, title, children, parents, hasTabs }: IPageHeaderProps) => (
  <S.PageHeader className={className} hasTabs={!!hasTabs}>
    <S.Wrapper>
      <Row noMargin>
        <Col>
          <S.PageTitle>
            {parents && (
              <S.BreadCrumbs>
                {parents.map((parent, index: number) => (
                  <React.Fragment key={index}>
                    <NavLink to={parent.to}>{parent.label}</NavLink> /{' '}
                  </React.Fragment>
                ))}
              </S.BreadCrumbs>
            )}
            {title && <S.Title data-testid="page-header-title">{title}</S.Title>}
          </S.PageTitle>
        </Col>
      </Row>
      <div>{children}</div>
    </S.Wrapper>
  </S.PageHeader>
);
