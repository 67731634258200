import { gql } from 'graphql-request';

export const getDaypassesQuery = gql`
  query getDaypasses {
    getDaypasses {
      name
      daypasses {
        day
        id
        name
        price
        type
        matches {
          channel
          matchType
          name
          startDate
        }
      }
    }
  }
`;
