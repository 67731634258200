import { LockIcon } from 'assets/icons';
import React from 'react';
import { ContentUtil } from 'utils';

import content from './support-case-closed-block.content';
import * as S from './support-case-closed-block.styles';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseClosedBlock' });

const SupportCaseClosedBlock = () => {
  return (
    <S.ClosedBlock data-testid="support-case-closed-block">
      <S.Title>
        <h4>
          <S.Icon>
            <LockIcon />
          </S.Icon>{' '}
          {contentUtil.translateToString('title')}
        </h4>
      </S.Title>
      <S.Description>{contentUtil.translateToString('description')}</S.Description>
      <S.NeedFurtherHelp>{contentUtil.translateToString('needFurtherHelp')}</S.NeedFurtherHelp>
    </S.ClosedBlock>
  );
};

export default SupportCaseClosedBlock;
