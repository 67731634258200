import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    service: 'Service',
    contactDetails: 'Gegevens',
    dashboard: 'Overzicht',
    invoices: 'Facturen',
    logout: 'Uitloggen',
    changeLogin: 'Login veranderen',
    changePassword: 'Wachtwoord wijzigen',
    myAccount: 'Mijn account instellingen',
    myVodafone: 'My Vodafone',
    myZiggo: 'MIJN ZIGGO',
    products: 'Producten',
    entertainment: 'Entertainment',
    usage: 'Verbruik',
    menu: 'MENU',
    close: 'CLOSE',
  },
  en: {},
};
