import { appSettings } from 'config/app.settings';
import { FeatureContext, useFeature } from 'context/feature/feature.context';
import { IContractsAndContacts, IUser, ROLES } from 'models';
import { FEATURES } from 'models/features.model';
import React, { useContext, useEffect, useState } from 'react';
import { SESSION_STORAGE_KEYS, SessionStorage } from 'utils';
import api from 'utils/api';
import { noop } from 'utils/noop';
import UserUtil from 'utils/user.util';

import OidcUtil from '../oidc.util';

// The properties we will expose in useContext or <AuthContext.Consumer />
export interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  errorType: string | null;
  completed: boolean;
  roles: ROLES[];
  groups: string[];
  userName?: string;
  contractsAndContacts?: IContractsAndContacts[];
  ziggoUsers?: IContractsAndContacts[];
  email?: string;
  has2Fa?: boolean;
  phone2Fa?: string;
  hashedCustomerId?: string;
  isMerged: boolean;
  requireAuthentication: () => void;
  updateRoles: (newRoles: ROLES[]) => void;
}

const initialState: IAuthContext = {
  isAuthenticated: false,
  isLoading: false,
  completed: false,
  hasErrors: false,
  errorType: null,
  roles: [],
  groups: [],
  isMerged: false,
  requireAuthentication: noop,
  updateRoles: noop,
};

const AuthContext = React.createContext<IAuthContext>(initialState);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errorType, setErrorType] = useState(null);
  const [roles, setRoles] = useState<ROLES[]>([]);
  const [groups, setGroup] = useState<string[]>([]);
  const [userName, setUserName] = useState<string>();
  const [contractsAndContacts, setContractsAndContacts] = useState<IContractsAndContacts[]>();
  const [ziggoUsers, setZiggoUsers] = useState<IContractsAndContacts[]>();
  const [email, setEmail] = useState<string>();
  const [hashedCustomerId, setHahedCustomerId] = useState<string>();
  const [has2Fa, setHas2Fa] = useState<boolean>();
  const [phone2Fa, setPhone2Fa] = useState<string>();
  const [authInitialized, setAuthInitialized] = useState(false);
  const requireAuthentication = () => !authInitialized && setAuthInitialized(true);
  const isAuthenticated = Boolean(userName);
  const hasMaintenance = useFeature(FEATURES.MAINTENANCE);
  const { isLoading: isLoadingFeature } = useContext(FeatureContext);

  useEffect(() => {
    if (authInitialized && !hasMaintenance && !isLoadingFeature) init();
  }, [authInitialized, hasMaintenance, isLoadingFeature]);

  const init = async () => {
    await OidcUtil.setOidcConfig();
    OidcUtil.initializeOidcSession(() => {
      resetState();
    });
    await checkSession();
  };

  function resetState() {
    setIsLoading(false);
    setCompleted(false);
    setHasErrors(false);
    setErrorType(null);
    setRoles([]);
    setGroup([]);
    setContractsAndContacts(undefined);
    setZiggoUsers(undefined);
    setEmail(undefined);
  }

  const checkSession = async () => {
    const authenticated = await OidcUtil.checkSession();

    if (authenticated) {
      OidcUtil.setHeaderOnAPI();
      await getNewAuthenticatedUser();
    }
  };

  const getNewAuthenticatedUser = async () => {
    try {
      const response = await api.apiV1.getAuth('userinfo');
      setUserData(response.data);
    } catch (error) {
      setHasErrors(true);
      setErrorType(error?.error?.data);

      if (appSettings.ENV_ZIGGO_ACCOUNT_URL) {
        window.location.href = appSettings.ENV_ZIGGO_ACCOUNT_URL;
      }
    }
  };

  const setUserData = (userData: IUser) => {
    let contractsAndContacts: IContractsAndContacts[] = userData.contracts_and_contacts || [];
    contractsAndContacts = contractsAndContacts.filter((user) => user.billing_customer);

    const ziggoUsers: IContractsAndContacts[] = contractsAndContacts.filter(
      (user) => user.billing_customer && user.billing_customer.brand.toUpperCase() === 'ZIGGO'
    );

    // find Vodafone users
    const vodafoneUsers = contractsAndContacts.filter(
      (user) =>
        // the user is either Vodafone branded
        (user.billing_customer && user.billing_customer.brand.toUpperCase() === 'VODAFONE') ||
        // or the user has no billing customer, but then there should be a contact id.
        (!user.billing_customer && user.contact.contact_id)
    );

    // if there are only Vodafone users, redirect to My Vodafone
    if (!ziggoUsers.length && vodafoneUsers.length) {
      window.location.replace(`${appSettings.ENV_VODAFONE_ACCOUNT_URL}/account/overzicht?messages=VODAFONE_ONLY_USER`);
    }

    if (userData?.cobrowsing_subject) {
      SessionStorage.set(SESSION_STORAGE_KEYS.agentId, userData.cobrowsing_subject);
    } else {
      SessionStorage.remove(SESSION_STORAGE_KEYS.agentId);
    }

    setContractsAndContacts(contractsAndContacts);
    setZiggoUsers(ziggoUsers);
    setUserName(userData.user_name);
    setIsLoading(false);
    setCompleted(true);
    setHahedCustomerId(userData.hashedCustomerId);
    setEmail(userData?.email);
    setHas2Fa(userData.two_factor_authentication);
    setPhone2Fa(userData.two_factor_msisdn);
  };

  const updateRoles = (newRoles: ROLES[]) => {
    setRoles(newRoles);
  };

  const isMerged = UserUtil.determineMergedStatus(contractsAndContacts);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        hasErrors,
        completed,
        roles,
        groups,
        userName,
        contractsAndContacts,
        ziggoUsers,
        email,
        has2Fa,
        phone2Fa,
        hashedCustomerId,
        errorType,
        isMerged,
        requireAuthentication,
        updateRoles,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
