export const formatZipcode = (zipcode: string) => {
  // this util is to create a presentation value for the dutch zipcode. BSL can return them with or without spaces, capitalized and not capitalized. Anything goes in BSL.
  // To prevent unnecessary updates to BSL, we only show a nicely formatted zipcode, but we don't post this nicely formatted zipcode.

  const regex = new RegExp('s|([0-9]{4})([a-zA-Z]{2})|\\1 \\2|g');

  // test the zipcode to see if it consists of 4 numbers and 2 letters
  if (regex.test(zipcode)) {
    // if letters are present, first remove all spaces
    const trimmedZipcode = zipcode.replace(/ /g, '');
    // return the zipcode with a space added in the correct position
    return trimmedZipcode.replace(/([0-9])([a-zA-Z])/g, '$1 $2').toUpperCase();
  }
  return zipcode;
};
