import { ApiResponse, query } from './api';
import { getPaymentsQuery } from './payment.query';
import { Payment } from './payment.types';

interface PaymentApiInterface {
  getPayments(billingCustomerId: string): ApiResponse<Payment[]>;
}

export const PaymentApi: PaymentApiInterface = {
  getPayments(billingCustomerId: string): ApiResponse<Payment[]> {
    return query({
      query: getPaymentsQuery,
      variables: { billingCustomerId },
      name: 'getPayments',
    }).then(({ getPayments }) => ({ data: getPayments }));
  },
};
