import { ErrorMessage } from 'components/Form/components/ErrorMessage/errormessage.styles';
import styled, { css, gapSize, rem, screenMobilePlusAndBigger, spacingBase, tablet } from 'theme';

import { RequiredNotice } from './components/RequiredNotice/required-notice.styles';

export const Form = styled.form<{ grid?: boolean }>`
  .form-buttons-modal {
    flex-direction: column !important;
    padding: ${rem(16)} 0 0 !important;

    ${() =>
      screenMobilePlusAndBigger(css`
        max-width: none !important;
        flex-direction: row !important;
        justify-content: space-between;
        margin: 0 0 -1rem 0 !important;
      `)}
  }

  ${(p) =>
    p.grid &&
    css`
      display: grid;
      grid-row-gap: ${rem(10)};
      align-items: center;
      grid-template-columns: 1fr;

      ${tablet(css`
        grid-template-columns: ${rem(700 * 0.33)} minmax(0, ${rem(700 * 0.67)}) minmax(0, auto);

        ${ErrorMessage},
        .radio-button,
        .checkbox {
          grid-column: 2;
        }

        .full-width {
          grid-column: 1 / span 3;
        }

        .form-button {
          grid-column: 2;
          display: flex;
          margin: ${gapSize(2)} 0;
        }

        ${RequiredNotice} {
          grid-column: 2;
        }
      `)}

      label {
        grid-column: 1;
        padding-right: ${spacingBase};
      }
    `}
`;

export const FormError = styled('div')`
  color: ${(p) => p.theme.colors.red};
  padding-top: calc(${() => spacingBase} * 2);
`;
