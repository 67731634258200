import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { ChildPage } from 'components/ChildPage';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { Loader } from 'components/Loader';
import OutageSuccessModal from 'components/Outage/OutageSuccessModal';
import { PageHeader } from 'components/PageHeader';
import { useFeature } from 'context/feature/feature.context';
import { useOutageContext } from 'context/outage/outage.context';
import { FEATURES } from 'models/features.model';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ContentUtil } from 'utils';

import OutageCard from './OutageCard';
import { content } from './outage-overview.content';

const contentUtil = new ContentUtil({ content, componentName: 'OutageOverview' });

const OutageList = () => {
  const { isLoading, error, outages, getOutages } = useOutageContext();
  const isOutagesSubscriptionEnabled = useFeature(FEATURES.OUTAGES_SUBSCRIPTION_ENABLED);

  useEffect(() => {
    if (!outages) {
      getOutages();
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && error && (
        <InlineAlert type="error" testid="no-outages-error">
          {contentUtil.translate(error)}
        </InlineAlert>
      )}
      {!isLoading &&
        !error &&
        outages &&
        outages.map((outage, index) => (
          <Col key={`outage-card-${outage.id}`}>
            <OutageCard
              outage={outage}
              showHeader={index === 0}
              isSubscribeFormEnabled={isOutagesSubscriptionEnabled}
            />
          </Col>
        ))}
      <OutageSuccessModal />
    </>
  );
};

const OutageOverview = () => {
  return (
    <ChildPage>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader title={contentUtil.translateToString('pageTitle')} />
      <Wrapper>
        <Row>
          <OutageList />
        </Row>
      </Wrapper>
    </ChildPage>
  );
};

export default OutageOverview;
