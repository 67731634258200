import { PaymentArrangementPaymentMethodEnum } from 'api';
import { Message } from 'models';
import { globalContentUtil } from 'utils';
import { isValidEmail } from 'utils/valid-email.util';

import { isValidAllCountriesIBAN } from './validators/iban';

type validatorReturnType = undefined | Message;

const translateIfMessage = (message?: Message) =>
  message && globalContentUtil.translateToString(message.translationKey, message.value);

/**
 * Method to combine all validators.
 * Returns first error that appears as string.
 */
export const composeValidators = (validators: Function[]) => (inputValue: any) =>
  validators.reduce((error, validator) => error || translateIfMessage(validator(inputValue)), undefined);

export const checkPassword = (password: string) => ({
  length: password.length >= 8,
  upperCase: /[A-Z]+/.test(password),
  lowerCase: /[a-z]+/.test(password),
  number: /[0-9]+/.test(password),
  specialCharacter: /[!?€><;:|\\~@#$%^&*)(+=._{}-]+/.test(password),
});

const cleanTelephoneNumber = (phoneNumber: string) => {
  const cleanRegexp = /[+.-\s]+/gi;
  return phoneNumber.replace(cleanRegexp, '');
};

/**
 * Object with all validators available.
 */
export const validators = {
  // Required.
  required: (value: any): validatorReturnType => {
    if (value && typeof value === 'object') {
      return value.length > 0 ? undefined : { translationKey: 'error.validation.required' };
    }

    return value ? undefined : { translationKey: 'error.validation.required' };
  },

  // Accept terms.
  acceptTerms: (value: any): validatorReturnType => {
    if (value && typeof value === 'object') {
      return value.length > 0 ? undefined : { translationKey: 'error.validation.acceptTerms' };
    }

    return value ? undefined : { translationKey: 'error.validation.acceptTerms' };
  },

  // Checks for number or null/undefined
  number: (value: any): validatorReturnType => {
    // null and undefined are valid values. Let those be handled by required.
    return (!isNaN(parseFloat(value)) && isFinite(value)) || value === undefined || value === null
      ? undefined
      : { translationKey: 'error.validation.number' };
  },

  // Accepts an integer as minimum value it should match, which returns a new validator function.
  minValue:
    (min: number): Function =>
    (value: any): validatorReturnType =>
      isNaN(value) || value >= min ? undefined : { translationKey: 'error.validation.min-value', value: min },

  // Accepts an integer as minimum number of items that should be in array. Returns new validator function.
  minItemsSelected:
    (min: number): Function =>
    (value: any[]): validatorReturnType =>
      value && value.length >= min ? undefined : { translationKey: 'error.validation.min-items-selected', value: min },

  // Checks if the given value match with the email regexp
  email: (value: any): validatorReturnType => {
    if (!value) {
      return undefined;
    }
    return isValidEmail(value) ? undefined : { translationKey: 'error.validation.email' };
  },

  // Checks only the username in username@domain.nl
  emailUserName: (value: any): validatorReturnType => {
    if (!value) return undefined;

    if (/^[a-z0-9_][a-z0-9_-]*(\.[a-z0-9_-]+)*$/i.test(value)) {
      return undefined;
    }

    return { translationKey: 'error.validation.emailUserName' };
  },

  noSpecialCharacters: (value: any): validatorReturnType => {
    if (!value) return undefined;

    if (/^[a-z0-9-'`\s]*$/i.test(value)) {
      return undefined;
    }

    return { translationKey: 'error.validation.noSpecialCharacters' };
  },

  emailUserNameMaxLength: (value: any): validatorReturnType => {
    if (!value) return undefined;

    return value.length > 50 ? { translationKey: 'error.validation.emailUserNameMaxLength' } : undefined;
  },

  emailFirstNameMaxLength: (value: any): validatorReturnType => {
    if (!value) return undefined;

    return value.length > 50 ? { translationKey: 'error.validation.emailFirstNameMaxLength' } : undefined;
  },

  emailLastNameMaxLength: (value: any): validatorReturnType => {
    if (!value) return undefined;

    return value.length > 50 ? { translationKey: 'error.validation.emailLastNameMaxLength' } : undefined;
  },

  // Checks if the given value match with the zipcode regexp
  zipcode: (value: any): validatorReturnType => {
    if (!value) {
      return undefined;
    }
    // strip it of all spaces
    const spacelessZipcode = value.replace(/ /g, '');
    const regexpNL = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
    const regexDE = /^[0-9]{5}$/;
    const regexBE = /^[0-9]{4}$/;
    const validZipcode =
      regexpNL.test(spacelessZipcode) || regexDE.test(spacelessZipcode) || regexBE.test(spacelessZipcode);
    return validZipcode ? undefined : { translationKey: 'error.validation.zipcode' };
  },

  // Check if the given value is a valid IBAN with any SEPA country code
  ibanAll: (value: any): validatorReturnType => isValidAllCountriesIBAN(value),

  // Checks if the given value match with 06 mobile numbers
  mobile: (value: any) => {
    if (!value) {
      return undefined;
    }

    const regexp = /^[0-9]+$/;
    const mobile = cleanTelephoneNumber(value);

    if (mobile.startsWith('06') && regexp.test(mobile) && mobile.length === 10) {
      return undefined;
    }

    return { translationKey: 'error.validation.mobile' };
  },

  fixed: (value: any) => {
    if (!value) {
      return undefined;
    }

    const regexp = /^[0-9]+$/;
    const fixed = cleanTelephoneNumber(value);

    if (fixed.startsWith('06') || fixed.length !== 10 || !regexp.test(fixed)) {
      return { translationKey: 'error.validation.fixed' };
    }

    return undefined;
  },

  // Checks if the given value match a phone-number
  phone: (value: any) => {
    if (!value) {
      return undefined;
    }

    const numberRegexp = /^[0-9]{7,20}$/gi;
    const phone = cleanTelephoneNumber(value);
    // After that, test if the remaining chars are all digits
    if (numberRegexp.test(phone)) return undefined;

    return { translationKey: 'error.validation.phone' };
  },

  // Checks if the given value matches the password policy
  password: (value: string) => {
    if (!value) {
      return undefined;
    }

    const passwordChecks = checkPassword(value);

    if (
      passwordChecks.length &&
      passwordChecks.lowerCase &&
      passwordChecks.upperCase &&
      passwordChecks.specialCharacter &&
      passwordChecks.number
    ) {
      return undefined;
    }

    return { translationKey: 'error.validation.password' };
  },

  maxLength: (value: any) => {
    if (!value) {
      return undefined;
    }
    return value.length > 2000 ? { translationKey: `error.validation.maxLength` } : undefined;
  },

  kvkLength: (value: any): validatorReturnType => {
    if (!value) {
      return undefined;
    }
    return value.length !== 8 ? { translationKey: `error.validation.kvkLength` } : undefined;
  },

  // Accepts a string as bankAccount value that compared with the given paymentMethod. Returns a new validator function.
  directDebitHasBankAccount:
    (bankAccount: any): Function =>
    (value: any): validatorReturnType =>
      (bankAccount && value === PaymentArrangementPaymentMethodEnum.DIRECT_DEBIT) ||
      value === PaymentArrangementPaymentMethodEnum.CASH
        ? undefined
        : { translationKey: 'error.validation.DirectDebitBankAccountMissing' },
};
