import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import {
  CreatePaymentArrangementRequest,
  CreatePaymentArrangementResponse,
  HashPaymentResponse,
  InvoiceApi,
  InvoicePayment,
  InvoicePaymentMethod,
  InvoicePaymentMethodEnum,
  InvoicePaymentStatus,
  InvoiceSummary,
  PaymentArrangement,
  PaymentArrangementApi,
  PaymentArrangementTypeEnum,
  TokenPaymentResponse,
} from 'api';
import { DEFAULT_OIDC_SCOPES } from 'config/app.config';
import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { BillingCustomerContext } from './billing-customer.context';

export interface IContext {
  invoices: InvoiceSummary[] | null;
  paymentArrangement: PaymentArrangement | null;
  paymentArrangementType: PaymentArrangementTypeEnum;
  isLoading?: boolean;
  initialized: boolean;
  isDownloading: string | undefined;
  hasError: boolean;
  errorCode?: string;
  showDownloadInfo?: boolean;
  setShowDownloadInfo: Dispatch<SetStateAction<boolean>>;
  downloadInvoice: (invoiceId: string, fullInvoice: boolean) => Promise<void>;
  getInvoiceById: (invoiceId: string) => Promise<InvoiceSummary>;
  getPaymentMethods: (invoiceId: string) => Promise<InvoicePaymentMethod[]>;
  getPaymentLink: (invoiceId: string, paymentType: InvoicePaymentMethodEnum) => Promise<InvoicePayment>;
  getIdealIframeLink: (invoiceId: string) => Promise<string>;
  getCreditcardIframeLink: (invoiceId: string) => Promise<string>;
  getPaymentResponse: (
    paymentResponseVariables: TokenPaymentResponse | HashPaymentResponse
  ) => Promise<InvoicePaymentStatus>;
  getInvoices: () => Promise<void>;
  createPaymentArrangement: (
    paymentArrangement: CreatePaymentArrangementRequest
  ) => Promise<CreatePaymentArrangementResponse>;
  verifyPaymentArrangement: () => Promise<void>;
}

const intialState: IContext = {
  invoices: null,
  paymentArrangement: null,
  paymentArrangementType: PaymentArrangementTypeEnum.NO_HISTORY,
  isLoading: true,
  isDownloading: undefined,
  initialized: false,
  hasError: false,
  setShowDownloadInfo: () => Promise.reject(),
  downloadInvoice: () => Promise.reject(),
  getInvoiceById: () => Promise.reject(),
  getPaymentMethods: () => Promise.reject(),
  getPaymentLink: () => Promise.reject(),
  getIdealIframeLink: () => Promise.reject(),
  getCreditcardIframeLink: () => Promise.reject(),
  getPaymentResponse: () => Promise.reject(),
  getInvoices: () => Promise.reject(),
  createPaymentArrangement: () => Promise.reject(),
  verifyPaymentArrangement: () => Promise.reject(),
};

const InvoicesContext = React.createContext(intialState);

const InvoicesProvider = ({ children }: { children: React.ReactNode }) => {
  const bcContext = useContext(BillingCustomerContext);

  const activeCustomerId = useMemo(() => {
    const { activeBcId } = bcContext;
    return activeBcId;
  }, [bcContext.activeBcId]);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<string | undefined>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [showDownloadInfo, setShowDownloadInfo] = useState<boolean>(true);
  const [errorCode, setErrorCode] = useState<string>();
  const [invoices, setInvoices] = useState<InvoiceSummary[]>([]);
  const [paymentArrangement, setPaymentArrangement] = useState<PaymentArrangement | null>(null);
  const [paymentArrangementType, setPaymentArrangementType] = useState<PaymentArrangementTypeEnum>(
    PaymentArrangementTypeEnum.NO_HISTORY
  );
  /**
   * This method first checks if the pdf for the given invoice is available.
   * If it is, it will open a new tab with the invoice pdf. If not, it will notify the user.
   */
  const downloadInvoice = async (invoiceId: string, fullInvoice: boolean) => {
    if (isDownloading || isLoading) return;
    setHasError(false);
    const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });

    if (token) {
      const authHeader = OidcService.getAuthHeader(token);

      if (authHeader) {
        setIsDownloading(invoiceId + (fullInvoice ? '-full' : '-spec'));
        try {
          const response = await InvoiceApi.isInvoicePdfAvailable(activeCustomerId, invoiceId, fullInvoice);
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;

          let filename = `Ziggo-factuur-${parseInt(invoiceId, 10)}`;

          if (!fullInvoice) {
            filename += '-specificatie';
          }

          a.setAttribute('download', `${filename}.pdf`);
          a.setAttribute('data-testid', `invoice-download-${invoiceId}`);
          a.setAttribute('target', '_blank');

          document.body.appendChild(a);
          a.style.display = 'none';
          a.click();

          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          setHasError(true);
        } finally {
          setIsDownloading(undefined);
        }
      }
    }
  };

  const getInvoiceById = (invoiceId: string) => {
    return InvoiceApi.getInvoice(activeCustomerId, invoiceId).then((response) => response.data);
  };

  const getPaymentMethods = (invoiceId: string) => {
    return InvoiceApi.getPaymentMethods(activeCustomerId, invoiceId).then(({ data }) => data);
  };

  const getPaymentLink = (invoiceId: string, paymentType: InvoicePaymentMethodEnum) => {
    return InvoiceApi.getPaymentLink(activeCustomerId, invoiceId, paymentType).then(({ data }) => data);
  };

  const getIdealIframeLink = (invoiceId: string) => {
    return InvoiceApi.invoicePaymentLinkIdeal(activeCustomerId, invoiceId).then((response) => response.data.link);
  };

  const getCreditcardIframeLink = (invoiceId: string) => {
    return InvoiceApi.invoicePaymentLinkCreditCard(activeCustomerId, invoiceId).then((response) => response.data.link);
  };

  const getPaymentResponse = (paymentResponseVariables: TokenPaymentResponse | HashPaymentResponse) => {
    return InvoiceApi.getPaymentResponse(paymentResponseVariables).then((response) => {
      return response.data;
    });
  };

  const createPaymentArrangement = (paymentArrangement: CreatePaymentArrangementRequest) => {
    return PaymentArrangementApi.setPaymentArrangement(activeCustomerId, paymentArrangement).then(
      (response) => response.data
    );
  };

  /**
   * Get contact details for current billing customer.
   */
  const getInvoices = async () => {
    if (invoices?.length || isLoading || hasError) return;

    setIsLoading(true);
    setHasError(false);

    try {
      const months = 18;
      const { data: invoices } = await InvoiceApi.getInvoiceList(activeCustomerId, months);
      setInvoices(invoices);
    } catch (e) {
      if (e.code) setErrorCode(e.code);
      setHasError(true);
    } finally {
      setIsLoading(false);
      setInitialized(true);
    }
  };

  const verifyPaymentArrangement = async () => {
    try {
      const { data: paymentArrangement } = await PaymentArrangementApi.getPaymentArrangement(activeCustomerId);
      const { activePaymentArrangement, paymentArrangementHistory } = paymentArrangement;

      if (paymentArrangement.isEligible && !activePaymentArrangement) {
        setPaymentArrangementType(PaymentArrangementTypeEnum.ELIGIBLE);
      } else if (
        activePaymentArrangement &&
        (activePaymentArrangement.status === 'OPEN' || activePaymentArrangement.status === 'GRACE_PERIOD')
      ) {
        setPaymentArrangementType(PaymentArrangementTypeEnum.ACTIVE);
      } else if (paymentArrangementHistory && paymentArrangementHistory?.length > 0) {
        setPaymentArrangementType(PaymentArrangementTypeEnum.HISTORY);
      } else {
        setPaymentArrangementType(PaymentArrangementTypeEnum.NO_HISTORY);
      }
      setPaymentArrangement(paymentArrangement);
    } catch (e) {
      setPaymentArrangement(null);
      setPaymentArrangementType(PaymentArrangementTypeEnum.NO_HISTORY);
    }
  };

  return (
    <InvoicesContext.Provider
      value={{
        isLoading,
        isDownloading,
        hasError,
        errorCode,
        invoices,
        paymentArrangement,
        paymentArrangementType,
        downloadInvoice,
        getInvoiceById,
        getPaymentMethods,
        getPaymentLink,
        getIdealIframeLink,
        getCreditcardIframeLink,
        getPaymentResponse,
        getInvoices,
        initialized,
        createPaymentArrangement,
        verifyPaymentArrangement,
        setShowDownloadInfo,
        showDownloadInfo,
      }}>
      {children}
    </InvoicesContext.Provider>
  );
};

export { InvoicesContext, InvoicesProvider };
