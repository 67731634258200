import { gql } from 'graphql-request';

export const getMarketingSettingsQuery = gql`
  query getMarketingSettings($billingCustomerId: String!) {
    getMarketingSettings(billingCustomerId: $billingCustomerId) {
      category
      settings {
        id
        value
        enabled
      }
    }
  }
`;

export const setMarketingSettingsQuery = gql`
  mutation setMarketingSettings($billingCustomerId: String!, $marketingSettings: [MarketingSettingsInput!]!) {
    setMarketingSettings(billingCustomerId: $billingCustomerId, marketingSettings: $marketingSettings) {
      success
    }
  }
`;

export const marketingSettingsSubscribeMutation = gql`
  mutation changeMarketingSettingsSubscription($token: String!, $type: String!, $subscribe: Boolean!) {
    changeMarketingSettingsSubscription(token: $token, type: $type, subscribe: $subscribe) {
      success
    }
  }
`;
