import { ApiResponse, query, rest } from './api';
import * as Q from './privacy.query';
import * as T from './privacy.types';

interface PrivacyApiInterface {
  getPrivacyRequest(billingCustomerId: string, requestId?: string): ApiResponse<T.PrivacyRequestSummary>;
  getPrivacyRequestPdf(billingCustomerId: string, requestId: string, accessToken: string): ApiResponse<never>;
  privacyComplaint(
    billingCustomerId: string,
    contactId: string,
    privacyComplaint: T.PrivacyComplaint
  ): ApiResponse<T.TicketReference>;
  privacyDelete(billingCustomerId: string, contactId: string): ApiResponse<T.TicketReference>;
  sendPrivacyRequest(billingCustomerId: string, contactId: string): ApiResponse<T.TicketReference>;
}

export const PrivacyApi: PrivacyApiInterface = {
  getPrivacyRequest(billingCustomerId: string, requestId?: string) {
    return query({
      query: Q.getPrivacyRequestQuery,
      variables: { billingCustomerId, requestId },
      name: 'getPrivacyRequest',
    }).then(({ getPrivacyRequest }) => ({ data: getPrivacyRequest }));
  },
  getPrivacyRequestPdf(billingCustomerId: string, requestId: string, accessToken: string) {
    return rest({
      url: `/privacy/pdf/${billingCustomerId}/${requestId}`,
      params: {
        access_token: accessToken,
      },
      method: 'GET',
    });
  },
  privacyComplaint(billingCustomerId: string, contactId: string, privacyComplaint: T.PrivacyComplaint) {
    return query({
      query: Q.privacyComplaintQuery,
      variables: { billingCustomerId, contactId, privacyComplaint },
      name: 'privacyComplaint',
    }).then(({ privacyComplaint }) => ({ data: privacyComplaint }));
  },
  privacyDelete(billingCustomerId: string, contactId: string) {
    return query({
      query: Q.privacyDeleteQuery,
      variables: { billingCustomerId, contactId },
      name: 'privacyDelete',
    }).then(({ privacyDelete }) => ({ data: privacyDelete }));
  },
  sendPrivacyRequest(billingCustomerId: string, contactId: string) {
    return query({
      query: Q.sendPrivacyRequestQuery,
      variables: { billingCustomerId, contactId },
      name: 'sendPrivacyRequest',
    }).then(({ sendPrivacyRequest }) => ({ data: sendPrivacyRequest }));
  },
};
