// @todo: Remove this file as this polyfill is now redundant

/* eslint-disable */
if (!Element.prototype.closest)
  Element.prototype.closest = function (s: any) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      // @ts-expect-error TS(2322): Type 'ParentNode | null' is not assignable to type... Remove this comment to see the full error message
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
