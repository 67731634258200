import { EmailFlyingThroughTheSun } from 'assets/img/EmailFlyingThroughTheSun';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { PiiMask } from 'components/PiiMask';
import { content } from 'pages/Products/pages/InternetProducts/pages/EmailSettings/components/SectionEmail/section-email.content';
import React from 'react';
import { ContentUtil } from 'utils/content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionEmail' });

interface IRemovalSuccessModalProps {
  isCeasedCustomer?: boolean;
  emailAddress: string;
  onClose(): void;
}

export const RemovalSuccessModal = ({ isCeasedCustomer = false, emailAddress, onClose }: IRemovalSuccessModalProps) => (
  <Modal onClose={onClose}>
    <section data-testid="modal-removal-success">
      <ModalIcon>
        <EmailFlyingThroughTheSun />
      </ModalIcon>
      <h4>{contentUtil.translate('removalSuccessModal.header')}</h4>
      <PiiMask>{contentUtil.translateHtml('removalSuccessModal.content', { emailAddress })}</PiiMask>
      <ModalButtons>
        <Button to={isCeasedCustomer ? '/overzicht' : "/producten/internet#email"}>
          {contentUtil.translate('removalSuccessModal.button')}
        </Button>
      </ModalButtons>
    </section>
  </Modal>
);
