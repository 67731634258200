import { ApiResponse, query } from './api';
import { Order, OrderValidDateList } from './order-status.types';
import {
  getInstallationGuaranteeOrder,
  getOrder,
  getOrderValidDateList,
  getOrders,
  mutateActivationDateQuery,
  mutateDeliveryAddressQuery,
} from './order.query';

interface OrderApiInterface {
  getOrder(billingCustomerId: string, orderId: string): ApiResponse<Order>;
  getOrders(billingCustomerId: string, limit: number, dateFrom: string): ApiResponse<Order[]>;
  getOrderValidDateList(billingCustomerId: string, orderId: string): ApiResponse<OrderValidDateList>;
  getInstallationGuaranteeOrder(billingCustomerId: string): ApiResponse<{ id: string }>;
  mutateActivationDate(
    billingCustomerId: string,
    orderId: string,
    activationDate: string
  ): ApiResponse<{ success: boolean }>;
  mutateDeliveryAddress(
    billingCustomerId: string,
    orderId: string,
    deliveryAddressId: string
  ): ApiResponse<{ success: boolean }>;
}

const OrderApi: OrderApiInterface = {
  getOrder(billingCustomerId: string, orderId: string) {
    return query({
      query: getOrder,
      variables: { billingCustomerId, orderId },
      name: 'getOrder',
    }).then(({ getOrder }) => ({ data: getOrder }));
  },
  getOrders(billingCustomerId: string, limit: number, dateFrom: string) {
    return query({
      query: getOrders,
      variables: {
        billingCustomerId,
        limit,
        dateFrom,
      },
      name: 'getOrderList',
    }).then(({ getOrderList }) => ({ data: getOrderList }));
  },
  getOrderValidDateList(billingCustomerId: string, orderId: string) {
    return query({
      query: getOrderValidDateList,
      variables: { billingCustomerId, orderId },
      name: 'getOrderValidDateList',
    }).then(({ getOrderValidDateList }) => ({ data: getOrderValidDateList }));
  },
  getInstallationGuaranteeOrder(billingCustomerId: string) {
    return query({
      query: getInstallationGuaranteeOrder,
      variables: { billingCustomerId },
      name: 'getInstallationGuaranteeOrder',
    }).then(({ getInstallationGuaranteeOrder }) => ({ data: getInstallationGuaranteeOrder }));
  },
  mutateActivationDate(billingCustomerId: string, orderId: string, activationDate: string) {
    return query({
      query: mutateActivationDateQuery,
      variables: {
        billingCustomerId,
        orderId,
        activationDate,
      },
      name: 'mutateActivationDate',
    }).then(({ mutateActivationDate }) => ({ data: mutateActivationDate }));
  },
  mutateDeliveryAddress(billingCustomerId: string, orderId: string, deliveryAddressId: string) {
    return query({
      query: mutateDeliveryAddressQuery,
      variables: {
        billingCustomerId,
        orderId,
        deliveryAddressId,
      },
      name: 'mutateDeliveryAddress',
    }).then(({ mutateDeliveryAddress }) => ({ data: mutateDeliveryAddress }));
  },
};

export { OrderApi };
