import React from 'react';

interface ICssClass {
  className?: string;
}

export const ExternalLinkIcon = ({ className }: ICssClass) => (
  <svg role="none" width="16" height="16" viewBox="0 0 16 16" className={className}>
    <path d="M13 5.19h-.63v-.63a3 3 0 0 0-3-3H0v6.25a3 3 0 0 0 3 3h.63v.63a3 3 0 0 0 3 3H16V8.19a3 3 0 0 0-3-3zm-9.37 0v3.62H3a1 1 0 0 1-1-1V3.56h7.37a1 1 0 0 1 1 1v.63zM14 12.44H6.63a1 1 0 0 1-1-1V7.19H13a1 1 0 0 1 1 1z" />
  </svg>
);
