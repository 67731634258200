import { useFeature } from 'context/feature/feature.context';
import { FEATURES } from 'models/features.model';
import React, { ReactElement } from 'react';

interface FeatureToggleProps {
  feature: FEATURES;
  children: ((viewAllowed: boolean) => React.ReactNode) | React.ReactNode;
}

export const FeatureToggle = ({ feature, children }: FeatureToggleProps) => {
  const enabled = useFeature(feature);

  if (children instanceof Function) {
    return children(enabled) as ReactElement;
  }

  return enabled ? (children as ReactElement) : null;
};
