import { ApiResponse, query } from './api';
import {
  addEmailAddressAliasQuery,
  addEmailAddressQuery,
  changeEmailAddressPasswordQuery,
  deleteEmailAddressAliasQuery,
  deleteEmailAddressQuery,
  getEmailAddressesQuery,
} from './email.query';
import { EmailAddress, GetEmailAddress } from './email.types';

interface EmailApiInterface {
  getEmailAddresses(billingCustomerId: string): ApiResponse<GetEmailAddress>;
  changePasswordEmailAddress(
    billingCustomerId: string,
    emailAddress: string,
    password?: string
  ): ApiResponse<{ success: boolean }>;
  createEmailAddress(billingCustomerId: string, emailAddress: EmailAddress): ApiResponse<GetEmailAddress>;
  deleteEmailAddress(billingCustomerId: string, emailAddress: string): ApiResponse<{ success: boolean }>;
}
interface EmailAliasApiInterface {
  createEmailAddressAlias(
    billingCustomerId: string,
    emailAddress: string,
    alias?: string
  ): ApiResponse<{ success: boolean }>;
  deleteEmailAddressAlias(
    billingCustomerId: string,
    emailAddress: string,
    alias?: string
  ): ApiResponse<{ success: boolean }>;
}

export const EmailApi: EmailApiInterface = {
  getEmailAddresses(billingCustomerId: string) {
    return query({
      query: getEmailAddressesQuery,
      variables: { billingCustomerId },
      name: 'getEmailAddresses',
    }).then(({ getEmailAddresses }) => ({ data: getEmailAddresses }));
  },
  changePasswordEmailAddress(billingCustomerId: string, emailAddress: string, password: string) {
    return query({
      query: changeEmailAddressPasswordQuery,
      variables: { billingCustomerId, emailAddress, password },
      name: 'changePasswordEmailAddress',
    }).then(({ changeEmailAddressPassword }) => ({ data: changeEmailAddressPassword }));
  },
  createEmailAddress(billingCustomerId: string, emailAddress: EmailAddress) {
    return query({
      query: addEmailAddressQuery,
      variables: { billingCustomerId, emailAddress },
      name: 'addEmailAddress',
    }).then(({ addEmailAddress }) => ({
      data: addEmailAddress,
    }));
  },
  deleteEmailAddress(billingCustomerId: string, emailAddress: string) {
    return query({
      query: deleteEmailAddressQuery,
      variables: { billingCustomerId, emailAddress },
      name: 'deleteEmailAddress',
    }).then(({ deleteEmailAddress }) => ({ data: deleteEmailAddress }));
  },
};

export const EmailAliasApi: EmailAliasApiInterface = {
  createEmailAddressAlias(billingCustomerId: string, emailAddress: string, alias?: string) {
    return query({
      query: addEmailAddressAliasQuery,
      variables: { billingCustomerId, emailAddress, alias },
      name: 'createEmailAddressAlias',
    }).then(({ addEmailAddressAlias }) => ({ data: addEmailAddressAlias }));
  },
  deleteEmailAddressAlias(billingCustomerId: string, emailAddress: string, alias?: string) {
    return query({
      query: deleteEmailAddressAliasQuery,
      variables: { billingCustomerId, emailAddress, alias },
      name: 'deleteEmailAddressAlias',
    }).then(({ deleteEmailAddressAlias }) => ({ data: deleteEmailAddressAlias }));
  },
};
