import { Expander } from 'components/Expander';
import React from 'react';

import bankCodes from './iban-bank-codes';
import * as S from './iban-input.styles';

interface BankCodeSuggestionsProps {
  value: string;
  expanded: boolean;
  onClick: (event: React.MouseEvent<HTMLSpanElement>, code: string) => void;
}

const BankCodeSuggestions = ({ value, onClick, expanded }: BankCodeSuggestionsProps) => (
  <Expander expanded={expanded}>
    {bankCodes
      .filter((b) => b.startsWith(value))
      .sort()
      .slice(0, 4)
      .map((bankCode) => (
        <S.BankCode onMouseDown={(event) => onClick(event, bankCode)} key={bankCode}>
          {bankCode}
        </S.BankCode>
      ))}
  </Expander>
);

export default BankCodeSuggestions;
