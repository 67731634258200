import { gql } from 'graphql-request';

export const getAddressInfosQuery = gql`
  query getAddresses($billingCustomerId: String!) {
    getAddresses(billingCustomerId: $billingCustomerId) {
      id
      type
      address {
        id
        streetName
        houseNumber
        houseNumberExtension
        roomNumber
        postCode
        city
        country
      }
    }
    getAddressPermissions {
      canEditCountry
    }
  }
`;

export const putAddressInfosQuery = gql`
  mutation putAddress($billingCustomerId: String!, $addressInfo: [AddressInfos!]) {
    putAddress(billingCustomerId: $billingCustomerId, addressInfo: $addressInfo) {
      success
    }
  }
`;

export const lookupDeliveryAddressQuery = gql`
  query lookupAddress($postalCode: String!, $houseNumber: Float!, $houseNumberAddition: String) {
    lookupAddress(postalCode: $postalCode, houseNumber: $houseNumber, houseNumberAddition: $houseNumberAddition) {
      id
      houseNumber
      houseNumberExtension
      streetName
      city
      postCode
      roomNumber
      country
    }
  }
`;
