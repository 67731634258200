import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import { composeValidators } from 'components/Form/validate';
import React from 'react';
import { Field } from 'react-final-form';
import randomId from 'utils/random-id';

import * as S from './textarea.styles';

interface TextareaProps {
  name: string;
  id?: string;
  label?: string;
  validate?: Function[];
  isRequired?: boolean;
  rows?: number;
}

export const TextArea = (props: TextareaProps) => {
  const { name, label, validate, rows = 5, isRequired, ...rest } = props;
  // Random ID is generated if id is not set (this way labels will stay accessible).
  const id = props.id || randomId();

  return (
    <>
      {label && (
        <S.Label htmlFor={id}>
          {label} {isRequired && <span> *</span>}
        </S.Label>
      )}
      <Field
        name={name}
        validate={validate ? composeValidators(validate) : undefined}
        render={({ input, meta }) => (
          <React.Fragment>
            <S.Textarea {...input} rows={rows} id={id} {...rest} />
            {meta.error && meta.touched && <ErrorMessage message={meta.error} />}
          </React.Fragment>
        )}
      />
    </>
  );
};
