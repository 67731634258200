import React from 'react';

export const MalfunctionCircleIcon = () => (
  <svg role="none" width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9608 29.3982V23.0352L15.8703 18.1257H9.51629L23.0388 4.60098C24.796 6.35823 24.796 9.20898 23.0388 10.9662L18.1293 15.8757H24.4833L10.9608 29.3982ZM16.9998 0.125732C7.68029 0.125732 0.124786 7.68123 0.124786 17.0007C0.124786 26.3202 7.68029 33.8757 16.9998 33.8757C26.3193 33.8757 33.8748 26.3202 33.8748 17.0007C33.8748 7.68123 26.3193 0.125732 16.9998 0.125732Z"
    />
  </svg>
);
