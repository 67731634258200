import { NavLink } from 'react-router-dom';
import styled, { gapSize, rem } from 'theme';

export const Navigation = styled('nav')`
  width: 100%;
  background-color: rgb(47, 48, 49);
  text-transform: uppercase;
  font-size: ${rem(14)};
  position: relative;
  z-index: 0;
`;

export const List = styled('ul')`
  list-style-type: none;
  align-items: center;
  padding: 0;
  margin: 0 ${() => gapSize(3)};
  display: flex;
  gap: ${gapSize(2.5)};
  align-items: center;
`;

export const Item = styled.li<{ isActive: boolean }>`
  display: inline-block;
  border-bottom-color: ${p => p.isActive ? p.theme.colors.primary : 'transparent'};
  border-bottom-width: ${rem(4)};
  border-bottom-style: solid;
  transition-property: border-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .3s;
`;

export const Link = styled(NavLink)`
  display: block;
  line-height: ${rem(68)};
  color: ${(p) => p.theme.colors.white};

  &.active {
    font-weight: ${(p) => p.theme.fonts.weights.bold};
  }
`;
