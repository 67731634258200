import { ApiResponse, query } from './api';
import { putContactDetails, putContactDetailsQuery } from './contact.query';
import { NameCorrection, UpdateContact } from './contact.types';

interface ContactApiInterface {
  /** Request a name correction for the specified contact. A ticket is created and a ticketReference object is returned. */
  correctContactDetails(
    billingCustomerId: string,
    contactId: string,
    nameCorrection: NameCorrection
  ): Promise<{ putContactDetails: { success: boolean } }>;

  /** Update a contact. Be aware that only phone and email address will be updated. Phone needs to be a valid number according to the ITU-T E.123 standard. Both e-mail and phone can be cleared by leaving them empty. */
  updateContact(billingCustomerId: string, contactId: string, updateContact: UpdateContact): ApiResponse<never>;
}

export const ContactApi: ContactApiInterface = {
  correctContactDetails(billingCustomerId, contactId, nameCorrection) {
    return query({
      query: putContactDetails,
      variables: { billingCustomerId, contactId, ...nameCorrection },
      name: 'putContactDetails',
    });
  },

  updateContact(billingCustomerId, contactId, updateContact) {
    const { email, phone, mobile } = updateContact;

    return query({
      query: putContactDetailsQuery,
      variables: {
        billingCustomerId,
        contactId,
        email,
        phone,
        mobile,
      },
      name: 'putContactDetailsQuery',
    });
  },
};
