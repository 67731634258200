import { IContent } from 'models/content.model';

const content: IContent = {
  nl: {
    pageTitle: 'Mijn vraag of verzoek',
    parentPage: {
      service: 'Service',
      overview: 'Vragen en verzoeken',
    },
    somethingWrong: 'Er is iets fout gegaan bij het laden van je ticket.',
    yourComment: 'Je opmerking',
    addComment: 'Opmerking toevoegen',
    addToCase: 'Toevoegen aan verzoek',
    closeSupportCase: 'Vraag of verzoek afsluiten',
    backToOverview: 'Terug naar overzicht',
    backToCaseDetail: 'Terug naar je vraag of verzoek',
    confirmClosureModal: {
      header: 'Weet je zeker dat je deze case wilt sluiten?',
      content: 'Je kan niet meer reageren als een case gesloten is.',
      confirm: 'Ja, sluit mijn case',
    },
  },
  en: {},
};

export default content;
