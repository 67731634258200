import * as url from 'url';

import { AxiosPromise } from 'axios';

import RequiredError from 'error/RequiredError';
import { ApiResponse, query, rest } from './api';
import ApiFactory from './api-factory';
import {
  getInvoiceListQuery,
  getInvoiceQuery,
  getPaymentMethodsQuery,
  getPaymentLinkQuery,
  getPaymentResponseQuery,
} from './invoice.query';
import {
  InvoiceSummary,
  InvoicePaymentMethod,
  InvoicePaymentMethodEnum,
  InvoicePayment,
  InvoicePaymentStatus,
  TokenPaymentResponse,
  HashPaymentResponse,
  PaymentLink,
} from './invoice.types';

// TODO: getInvoicePdf is omitted here since the endpoint isn't called like the others but a link
// to the PDF is opened in a new window in the invoices container, remove it when the last
// call from the InvoiceApi has been implemented. Other calls may need to be omitted since the original interface
// pretends we're returning an AxiosPromise while we're not and TS will warn about incorrect implementation
interface InvoiceApiInterface {
  getPaymentMethods(billingCustomerId: string, invoiceId: string): ApiResponse<InvoicePaymentMethod[]>;
  getPaymentLink(
    billingCustomerId: string,
    invoiceId: string,
    paymentMethod: InvoicePaymentMethodEnum
  ): ApiResponse<InvoicePayment>;
  getInvoiceList(billingCustomerId: string, months: number): ApiResponse<InvoiceSummary[]>;
  getInvoice(billingCustomerId: string, invoiceId: string): ApiResponse<InvoiceSummary>;
  isInvoicePdfAvailable(
    billingCustomerId: string,
    invoiceId: string,
    fullInvoice: boolean,
  ): ApiResponse<never>;
  getPaymentResponse(variables: TokenPaymentResponse | HashPaymentResponse): ApiResponse<InvoicePaymentStatus>;
  invoicePaymentLinkCreditCard(billingCustomerId: string, invoiceId: string, options?: any): AxiosPromise<PaymentLink>;
  invoicePaymentLinkIdeal(billingCustomerId: string, invoiceId: string, options?: any): AxiosPromise<PaymentLink>;
}

const InvoiceApi: InvoiceApiInterface = {
  invoicePaymentLinkCreditCard(billingCustomerId: string, invoiceId: string, options: any = {}): AxiosPromise {
    // verify required parameter 'billingCustomerId' is not null or undefined
    if (billingCustomerId === null || billingCustomerId === undefined) {
      throw new RequiredError(
        'billingCustomerId',
        'Required parameter billingCustomerId was null or undefined when calling invoicePaymentLinkCreditCard.'
      );
    }
    // verify required parameter 'invoiceId' is not null or undefined
    if (invoiceId === null || invoiceId === undefined) {
      throw new RequiredError(
        'invoiceId',
        'Required parameter invoiceId was null or undefined when calling invoicePaymentLinkCreditCard.'
      );
    }

    const localVarQueryParameter = {} as any;

    const localVarPath =
      `/selfservice/rest/v1/billing-customers/{billingCustomerId}/invoices/{invoiceId}/payment-links/credit-card`
        .replace(`{${'billingCustomerId'}}`, encodeURIComponent(String(billingCustomerId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
    const localVarUrlObj = url.parse(localVarPath, true);
    const method = 'GET';

    const contentType = 'application/json';
    let data;

    localVarUrlObj.query = (<any>Object).assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
    return ApiFactory.getInstance()
      .getApi()
      .api({
        ...options,
        url: url.format(localVarUrlObj),
        method,
        data,
        headers: {
          'Content-Type': contentType,
        },
      });
  },

  invoicePaymentLinkIdeal(billingCustomerId: string, invoiceId: string, options: any = {}): AxiosPromise {
    // verify required parameter 'billingCustomerId' is not null or undefined
    if (billingCustomerId === null || billingCustomerId === undefined) {
      throw new RequiredError(
        'billingCustomerId',
        'Required parameter billingCustomerId was null or undefined when calling invoicePaymentLinkIdeal.'
      );
    }
    // verify required parameter 'invoiceId' is not null or undefined
    if (invoiceId === null || invoiceId === undefined) {
      throw new RequiredError(
        'invoiceId',
        'Required parameter invoiceId was null or undefined when calling invoicePaymentLinkIdeal.'
      );
    }

    const localVarQueryParameter = {} as any;

    const localVarPath =
      `/selfservice/rest/v1/billing-customers/{billingCustomerId}/invoices/{invoiceId}/payment-links/ideal`
        .replace(`{${'billingCustomerId'}}`, encodeURIComponent(String(billingCustomerId)))
        .replace(`{${'invoiceId'}}`, encodeURIComponent(String(invoiceId)));
    const localVarUrlObj = url.parse(localVarPath, true);
    const method = 'GET';

    const contentType = 'application/json';
    let data;

    localVarUrlObj.query = (<any>Object).assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);

    return ApiFactory.getInstance()
      .getApi()
      .api({
        ...options,
        url: url.format(localVarUrlObj),
        method,
        data,
        headers: {
          'Content-Type': contentType,
        },
      });
  },

  isInvoicePdfAvailable(billingCustomerId, invoiceId, fullInvoice) {
    return rest({
      url: `/invoice/pdf/${billingCustomerId}/${invoiceId}`,
      params: {
        fullInvoice,
      },
      method: 'GET',
      responseType: 'blob'
    });
  },

  getPaymentResponse(variables: TokenPaymentResponse | HashPaymentResponse) {
    return query({
      query: getPaymentResponseQuery,
      variables: { ...variables },
      name: 'getPaymentResponse',
    }).then(({ getPaymentResponse }) => ({ data: getPaymentResponse }));
  },

  getPaymentMethods(billingCustomerId, invoiceId) {
    return query({
      query: getPaymentMethodsQuery,
      variables: { billingCustomerId, invoiceId },
      name: 'getPaymentMethods',
    }).then(({ getPaymentMethods }) => ({ data: getPaymentMethods }));
  },

  getPaymentLink(billingCustomerId, invoiceId, paymentMethod) {
    return query({
      query: getPaymentLinkQuery,
      variables: { billingCustomerId, invoiceId, paymentMethod },
      name: 'getPaymentLink',
    }).then(({ getPaymentLink }) => ({ data: getPaymentLink }));
  },

  getInvoiceList(billingCustomerId, months) {
    return query({
      query: getInvoiceListQuery,
      variables: { billingCustomerId, months },
      name: 'getInvoiceList',
    }).then(({ getInvoiceList }) => ({ data: getInvoiceList }));
  },

  getInvoice(billingCustomerId, invoiceId) {
    return query({
      query: getInvoiceQuery,
      variables: { billingCustomerId, invoiceId },
      name: 'getInvoice',
    }).then(({ getInvoice }) => ({ data: getInvoice }));
  },
};

export { InvoiceApi };
