import { PaymentArrangementPaymentMethodEnum } from 'api';
import { Radio } from 'components/Form';
import React, { ReactNode } from 'react';

import * as S from './payment-arrangement.styles';

export interface IPaymentMethodsProps {
  options: { value: PaymentArrangementPaymentMethodEnum; label: ReactNode | string; disabled?: boolean }[];
}
export const PaymentMethodsRadioGroup = ({ options }: IPaymentMethodsProps) => {
  return (
    <S.RadioContent>
      <S.ChangeAccountNumberContent>
        <Radio id="selectedPaymentMethod" name="selectedPaymentMethod" options={options} sublabelIsLink />
      </S.ChangeAccountNumberContent>
    </S.RadioContent>
  );
};
