import { Card } from '@vodafoneziggo/sandwich';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { ContentBackground } from 'components/ContentBackground';
import { ErrorMessage } from 'components/Form';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { PageHeader } from 'components/PageHeader';
import { PayMeansContext } from 'context/pay-means.context';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentUtil } from 'utils';
import { Helmet } from 'react-helmet';

import content from './invoice-change-type.content';

const contentUtil = new ContentUtil({ content, componentName: 'InvoiceChangeType' });

const InvoiceChangeType = () => {
  const { changeToDigitalInvoiceType } = useContext(PayMeansContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const changeInvoiceType = async () => {
    try {
      setSubmitting(true);
      const response = await changeToDigitalInvoiceType();

      if (response) {
        setSubmitSuccess(true);
      } else {
        setHasError(true);
      }
    } catch (err) {
      setHasError(true);
    }

    setSubmitting(false);
  };

  return (
    <ContentBackground>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader title={contentUtil.translateToString('pageTitle')} />
      <Row>
        <Col>
          <Card>
            <Row noMargin>
              <h5>{contentUtil.translateToString('title')}</h5>
            </Row>
            {(submitSuccess && (
              <>
                <Row>
                  <InlineAlert type="success">
                    <span>{contentUtil.translateToString('success')}</span>
                  </InlineAlert>
                </Row>
                <Row>
                  <Button outline buttonVariant={ButtonVariant.DARK} onClick={() => navigate(-1)}>
                    {contentUtil.translateToString('buttonBack')}
                  </Button>
                </Row>
              </>
            )) || (
              <>
                <Row noMargin>
                  <p>{contentUtil.translateHtml('content')}</p>
                </Row>
                {hasError && (
                  <Row>
                    <ErrorMessage>{contentUtil.translateToString('error')}</ErrorMessage>
                  </Row>
                )}
                <Row>
                  <Button
                    disabled={submitting}
                    onClick={changeInvoiceType}
                    buttonVariant={ButtonVariant.DARK}
                    data-testid="invoice-change-type-submit-button">
                    {contentUtil.translateToString('buttonConfirm')}
                  </Button>
                  <Button outline buttonVariant={ButtonVariant.DARK} onClick={() => navigate(-1)}>
                    {contentUtil.translateToString('buttonCancel')}
                  </Button>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </ContentBackground>
  );
};

export default InvoiceChangeType;
