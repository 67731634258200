import { CheckIcon, CloseIcon, ExclamationTriangleIcon, InformationCircleIcon } from 'assets/icons';
import React, { ReactNode } from 'react';

import * as S from './inline-alert.styles';

interface IInlineAlertProps {
  type: 'success' | 'warning' | 'error' | 'info';
  children: ReactNode;
  onClose?: () => void;
  testid?: string;
  fitContent?: boolean;
}

export const InlineAlert = ({ type, children, onClose, testid, fitContent = false, ...rest }: IInlineAlertProps) => {
  let AlertComponent;
  let Icon;

  switch (type) {
    case 'error':
      AlertComponent = S.Error;
      Icon = ExclamationTriangleIcon;
      break;
    case 'info':
      AlertComponent = S.Info;
      Icon = InformationCircleIcon;
      break;
    case 'warning':
      AlertComponent = S.Warning;
      Icon = ExclamationTriangleIcon;
      break;
    case 'success':
    default:
      AlertComponent = S.Success;
      Icon = CheckIcon;
      break;
  }

  return (
    <AlertComponent fitContent={fitContent} role="alert" className="inline-alert" data-testid={testid} {...rest}>
      <S.Icon>
        <Icon />
      </S.Icon>
      <S.Content>{children}</S.Content>
      {onClose && (
        <S.CloseIcon onClick={onClose}>
          <CloseIcon />
        </S.CloseIcon>
      )}
    </AlertComponent>
  );
};
