import styled, { gapSize, rem, spacingBase } from 'theme';

export const Page = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

export const Title = styled('h1')`
  color: ${(p) => p.theme.colors.primary};
`;

export const Content = styled('div')`
  width: 100%;
  max-width: calc(${rem(500)} + ${() => spacingBase});
  margin: 0 auto;
  padding: ${() => gapSize(2)} ${() => spacingBase} 0;
  height: auto;
`;

export const Hr = styled('hr')`
  width: 100%;
  margin-top: ${() => spacingBase};
  margin-bottom: ${() => gapSize(2)};
  border: ${rem(0.5)} solid ${(p) => p.theme.colors.grayL30};
`;

export const SpecificationRow = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: ${() => spacingBase} 0;

  &:last-of-type {
    margin-bottom: ${() => gapSize(3)};
  }
`;

export const CardBackground = styled('div')`
  width: 100%;
  background-color: ${(p) => p.theme.colors.gray100};
  flex: 1 1 auto;
  padding-bottom: ${() => gapSize(5)};
  margin-top: ${() => gapSize(5)};
`;
