import { NavLink } from 'react-router-dom';
import styled, { css, rem, tablet } from 'theme';

export const BackLink = styled(NavLink)`
  text-decoration: underline;
  text-underline-offset: 4px;
  margin-bottom: ${rem(16)};

  ${tablet(css`
    margin-bottom: 0;
  `)};

  svg {
    position: relative;
    top: 3px;
    scale: 0.8;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: ${rem(16)};
`;
