export const appSettings = {
  ENV_LOGIN_PAGE_TIMEOUT: '1740000',
  ENV_REST_BASE_URL_IDM: process.env.ENV_REST_BASE_URL_IDM,
  ENV_REST_BASE_URL_SELFSERVICE: process.env.ENV_REST_BASE_URL_SELFSERVICE,
  ENV_FEATURE_CONFIG_BASE_URL: process.env.ENV_FEATURE_CONFIG_BASE_URL,
  ENV_GRAPHQL_BASE_URL_SELFSERVICE: process.env.ENV_GRAPHQL_BASE_URL_SELFSERVICE,
  ENV_MBSS_REST_BASE_URL: process.env.ENV_MBSS_REST_BASE_URL,
  ENV_REST_SSO_URL: process.env.ENV_REST_SSO_URL,
  ENV_SSO_CLIENT_ZIGGO_SELFSERVICE: process.env.ENV_SSO_CLIENT_ZIGGO_SELFSERVICE,
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
  ENV_VODAFONE_URL: process.env.ENV_VODAFONE_URL,
  ENV_VODAFONE_ACCOUNT_URL: process.env.ENV_VODAFONE_ACCOUNT_URL,
  ENV_ZIGGO_ACCOUNT_URL: process.env.ENV_ZIGGO_ACCOUNT_URL,
  ENV_ZIGGO_SHOP_URL: process.env.ENV_ZIGGO_SHOP_URL,
  ENV_ZIGGO_ENTERTAINMENT_URL: process.env.ENV_ZIGGO_ENTERTAINMENT_URL,
  ENV_ZIGGO_URL: process.env.ENV_ZIGGO_URL,
  ENV_ZIGGO_PERIPHERALS_URL: process.env.ENV_ZIGGO_PERIPHERALS_URL,
  AEM_ZIGGO_URL: process.env.AEM_ZIGGO_URL,
  ENV_REDIRECT_URI: process.env.ENV_REDIRECT_URI,
  ENV_ACCOUNT_URL: process.env.ENV_ACCOUNT_URL,
  ENV_APP_BILLING_URL: process.env.ENV_APP_BILLING_URL,
  ENV_PUBLIC_URL: process.env.ENV_PUBLIC_URL || '/mijn-ziggo/',
  TOKEN_ALMOST_EXPIRED_THRESHOLD: 300,
  ENV_MAINTENANCE_PAGE_URL: process.env.ENV_MAINTENANCE_PAGE_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  VODAFONE_CHOOSE_PACKAGE_URL: process.env.VODAFONE_CHOOSE_PACKAGE_URL,
  VIMEO_INVOICE_HELP: process.env.VIMEO_INVOICE_HELP,
  VIMEO_INVOICE_CHANGED: process.env.VIMEO_INVOICE_CHANGED,
  VIMEO_INVOICE_CEASED: process.env.VIMEO_INVOICE_CEASED,
  CSP_DEFAULT_SRC: process.env.CSP_DEFAULT_SRC,
  CSP_STYLE_SRC: process.env.CSP_STYLE_SRC,
  CSP_SCRIPT_SRC: process.env.CSP_SCRIPT_SRC,
  CSP_OBJECT_SRC: process.env.CSP_OBJECT_SRC,
  CSP_FONT_SRC: process.env.CSP_FONT_SRC,
  CSP_IMG_SRC: process.env.CSP_IMG_SRC,
  CSP_FRAME_SRC: process.env.CSP_FRAME_SRC,
  CSP_CONNECT_SRC: process.env.CSP_CONNECT_SRC,
  CSP_WORKER_SRC: process.env.CSP_WORKER_SRC,
};
