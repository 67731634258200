import { Address } from 'api';

export const compareAddresses = (a: Address, b: Address): boolean => {
  const { id: _id1, ...addressA } = a;
  const { id: _id2, ...addressB } = b;

  return Object.keys(addressA).every((key: keyof Omit<Address, 'id'>) => {
    return addressA[key] === addressB[key];
  });
};
