export default interface SupportCase {
  reference: string;
  title: string;
  description?: string;
  date: string;
  status: SupportCaseStatus;
  contactName?: string;
  comments?: SupportCaseComment[];
  closeable: boolean;
  attachments?: Attachment[];
}

export interface SupportCaseComment {
  author: string;
  message: string;
  creationDate: string;
}

export interface HasSupportCases {
  hasSupportCase: boolean;
}

export interface SupportCaseSuccess {
  success: boolean;
}

export enum SupportCaseStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
}

export interface SupportCaseNewComment {
  message: string;
}

export interface Attachment {
  id: string;
  fileName: string;
  title: string;
  mimeType: string;
}
