import { AuthContext } from 'context/auth/auth.context';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { history } from 'utils';
import { BrowserEnum, deviceUtils } from 'utils/device';

import * as S from './base-layout.styles';
import { Footer } from './components/Footer';
import { Header } from './components/Header';

interface IBaseLayoutProps {
  children: React.ReactNode;
}

export const BaseLayoutContext = React.createContext({
  setShowHeaderAndFooter: (_show: boolean): void => {
    return undefined;
  },
  setShowHeader: (_show: boolean): void => {
    return undefined;
  },
  showHeaderAndFooter: true,
  showHeader: true,
});

const BaseLayoutContextProvider = (props: IBaseLayoutProps) => {
  const { children } = props;
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    // reset the show header and footer on route change
    history.listen(() => {
      setShowHeaderAndFooter(true);
      setShowHeader(true);
    });
  }, []);

  return (
    <BaseLayoutContext.Provider
      value={{
        setShowHeaderAndFooter,
        setShowHeader,
        showHeader,
        showHeaderAndFooter,
      }}>
      <BaseLayout>{children}</BaseLayout>
    </BaseLayoutContext.Provider>
  );
};

export const BaseLayout = (props: IBaseLayoutProps) => {
  const { children } = props;
  const baseLayoutContext = useContext(BaseLayoutContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const tagManagerArgs = {
      // eslint-disable-next-line no-underscore-dangle
      gtmId: window?._ddi?.page?.gtm_id || 'GTM_000000',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (window?.OneTrust) {
      window.OneTrust.OnConsentChanged((e: any) => {
        const groups = parseGroups(e.detail);
        let callbackSettings;
        if (groups && groups !== 'error') {
          callbackSettings = groups;
        } else {
          callbackSettings = {};
        }
        window?._ddm.trigger('privacy.consent_updated', { data: { cookie_settings: callbackSettings } });
      });

      // parse OT groups to an object with consent values per category
      // returns either an object with consent-values or a string with error
      const parseGroups = function (activeGroupsString: any) {
        if (!activeGroupsString) {
          return 'error';
        }
        // All are false by default except for necessary cookies
        const newSettings = { necessary: true, performance: false, relevance: false, marketing: false };
        const mapping = {
          necessary: 'C0001',
          performance: 'C0002',
          relevance: 'C0003',
          marketing: 'C0004',
        };

        const consentSettings: string[] = activeGroupsString.toString().split(',');

        // Loop through the consent settings taken from OT and update new settings to true
        Object.keys(mapping).forEach((key) => {
          if (consentSettings.includes(mapping[key as keyof typeof mapping])) {
            newSettings[key as keyof typeof mapping] = true;
          }
        });
        return newSettings;
      };
    }
  }, [window?.OneTrust]);

  return (
    <S.Base>
      {/* The direct use of the AuthContainer here is disgusting, find a proper use for it when we move away from unstated */}
      {!deviceUtils.isBrowser(BrowserEnum.app) &&
        baseLayoutContext.showHeaderAndFooter &&
        baseLayoutContext.showHeader && <Header isAuthenticated={isAuthenticated} />}
      <S.Main id="main-content">{children}</S.Main>
      {!deviceUtils.isBrowser(BrowserEnum.app) && baseLayoutContext.showHeaderAndFooter && <Footer />}
    </S.Base>
  );
};
export default BaseLayoutContextProvider;
