import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    paymentOptionIdeal: 'Betalen via iDEAL',
    paymentOptionCreditcard: 'Betalen met creditcard',
    paymentOptionIngenico: 'Betalen via iDEAL',
  },
  en: {},
};
