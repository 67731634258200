import { Button } from 'components/Button';
import { Col as GridCol, colWidth } from 'components/Grid';
import styled, { css, desktop, gapSize, rem, screenMobilePlusAndSmaller } from 'theme';
import boxShadow from 'theme/global/box-shadow';

export const QuickLinks = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${boxShadow.section};
  padding: ${() => gapSize(2.5)} 0 ${() => gapSize(4)} 0;

  ${screenMobilePlusAndSmaller(css`
    ${GridCol} {
      &:last-child {
        margin-top: 0;
      }
    }
  `)};
`;

export const Col = styled(GridCol)`
  ${(p) => p.l && desktop(colWidth(p.l || p.m || p.s!, gapSize(2.25), 12))}

  ${desktop(css`
    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  `)}
`;

export const QuickLink = styled(Button)`
  display: block;

  ${desktop(css`
    white-space: nowrap;
    text-overflow: clip;
    overflow: visible;
  `)}

  svg {
    vertical-align: middle;
    margin-right: ${rem(16)};

    &:last-child {
      position: relative;
      top: ${rem(-1)};

      margin-left: ${rem(8)};
      rotate: -90deg;
    }
  }
`;
