import { ApiResponse, query } from './api';
import * as Q from './dashboard.query';
import * as T from './dashboard.types';

interface DashboardApiInterface {
  getDashboardInfo(billingCustomerId: string): ApiResponse<T.Dashboard>;
  getDashboardInfoV2(billingCustomerId: string): ApiResponse<T.Dashboard>;
}

export const DashboardApi: DashboardApiInterface = {
  getDashboardInfo(billingCustomerId: string) {
    return query({
      query: Q.getDashboardInfoQuery,
      variables: { billingCustomerId },
      name: 'getDashboardInfo',
    }).then(({ getDashboardInfo }) => ({ data: getDashboardInfo }));
  },
  getDashboardInfoV2(billingCustomerId: string) {
    return query({
      query: Q.getDashboardInfoV2Query,
      variables: { billingCustomerId },
      name: 'getDashboardInfoV2',
    }).then(({ getDashboardInfoV2 }) => ({ data: getDashboardInfoV2 }));
  },
};
