import React from 'react';
import { convert } from 'utils/price';

interface PriceProps {
  price: number | null; // In cents
}

export const Price = ({ price }: PriceProps) => {
  if (price === null) return <span data-testid="price-amount-no-price">-</span>;

  const value = convert(price);
  return <span data-testid="price-amount">€&nbsp;{value}</span>;
};
