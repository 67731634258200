import React from 'react';

export const CircleWithCross = () => (
  <svg role="none" width="26" height="26" viewBox="0 0 26 26">
    <defs />
    <defs>
      <path
        id="cwc-a"
        d="M13 .8a12 12 0 00-4.8 1 12.2 12.2 0 00-3.8 2.5 12.2 12.2 0 00-2.6 3.8 11.8 11.8 0 000 9.4 12.1 12.1 0 002.6 3.9A12.2 12.2 0 008.2 24c1.5.6 3 .9 4.8.9 1.6 0 3.2-.3 4.7-1a12.2 12.2 0 003.8-2.5 12 12 0 002.6-3.8 11.9 11.9 0 000-9.5 12 12 0 00-2.6-3.8 12.2 12.2 0 00-3.8-2.5c-1.5-.7-3-1-4.7-1zm2.5 12l3.7 3.7-2.6 2.5-3.6-3.7-3.8 3.8-2.5-2.6 3.8-3.7-3.8-3.7 2.5-2.5 3.8 3.7 3.6-3.7 2.6 2.5-3.7 3.7z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="cwc-b" fill="#fff">
        <use href="#cwc-a" />
      </mask>
      <use fill="#BCC1C5" href="#cwc-a" />
      <g fill="#D52B1E" mask="url(#cwc-b)">
        <path d="M0 0h26v26H0z" />
      </g>
    </g>
  </svg>
);
