// @ts-expect-error TS(2305): Module '"api"' has no exported member 'Success'.
import { Success } from 'api';

import { ApiResponse, query } from './api';
import * as Q from './secret-circle.query';
import * as T from './secret-circle.types';

interface SecretCircleApiInterface {
  getSecretCircle(billingCustomerId: string): ApiResponse<T.SecretCircleStatus>;
  updateSecretCircle(billingCustomerId: string, secretCircleStatus?: T.SecretCircleStatus): ApiResponse<Success>;
}

export const SecretCircleApi: SecretCircleApiInterface = {
  getSecretCircle(billingCustomerId: string) {
    return query({
      query: Q.getSecretCircleQuery,
      variables: { billingCustomerId },
      name: 'getSecretCircle',
    }).then(({ getSecretCircle }) => ({ data: getSecretCircle }));
  },
  updateSecretCircle(billingCustomerId: string, secretCircleStatus: T.SecretCircleStatus) {
    return query({
      query: Q.updateSecretCircleQuery,
      variables: { billingCustomerId, secretCircleStatus },
      name: 'updateSecretCircle',
    }).then(({ updateSecretCircle }) => ({ data: updateSecretCircle }));
  },
};
