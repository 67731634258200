import { AddressInfo, AddressInfoApi, AddressInfoTypeEnum, OrderAddress } from 'api';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { compareAddresses } from 'utils/addresses';
import { delay } from 'utils/delay';
import { formatZipcode } from 'utils/zipcode';

import { BillingCustomerContext } from './billing-customer.context';

export interface IAddressesContext {
  addresses: AddressInfo[] | null;
  foundDeliveryAddress: OrderAddress[] | null;
  isLoading: boolean;
  hasError: boolean;
  identicalBaAndInstallationAddresses: boolean;
  identicalBcAndInstallationAddresses: boolean;
  clearFoundDeliveryAddress: () => void;
  lookupDeliveryAddress: (postalCode: string, houseNumber: number, extension?: string, room?: string) => Promise<void>;
  updateAddress: Function;
}

const initialState: IAddressesContext = {
  addresses: null,
  foundDeliveryAddress: null,
  isLoading: true,
  hasError: false,
  identicalBaAndInstallationAddresses: false,
  identicalBcAndInstallationAddresses: false,
  lookupDeliveryAddress: () => Promise.resolve(),
  clearFoundDeliveryAddress: () => null,
  updateAddress: () => {
    throw new Error('This function was not bound correctly please check addresses.context');
  },
};

const AddressesContext = createContext<IAddressesContext>(initialState);

const AddressesProvider = ({ children }: { children: React.ReactNode }) => {
  const billingCustomerContext = useContext(BillingCustomerContext);

  const [addresses, setAddresses] = useState<AddressInfo[] | null>(null);
  const [foundDeliveryAddress, setFoundDeliveryAddress] = useState<OrderAddress[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [identicalBaAndInstallationAddresses, setIdenticalBaAndInstallationAddresses] = useState<boolean>(false);
  const [identicalBcAndInstallationAddresses, setIdenticalBcAndInstallationAddresses] = useState<boolean>(false);

  useEffect(() => {
    if (billingCustomerContext.completed) {
      getAddresses();
    }
  }, [billingCustomerContext.completed]);

  /**
   * Update addresses for contact in current billing customer.
   */
  const updateAddress = async (addresses: AddressInfo): Promise<void> => {
    const { activeBcId } = billingCustomerContext;

    const billingArrangementAddress = getBillingArangementAddress();

    const newAddress = {
      id: addresses.id,
      type: addresses.type,
      address: {
        ...addresses.address,
        id: billingArrangementAddress?.address.id || addresses.address.id,
        postCode: formatZipcode(addresses.address.postCode),
        houseNumber: Number(addresses.address.houseNumber),
      },
    };

    const { data } = await AddressInfoApi.updateAddressInfos(activeBcId, newAddress);

    if (!data.success) {
      throw Error();
    }

    await delay(1000);

    await getAddresses();
  };

  const getBillingArangementAddress = (): AddressInfo | undefined => {
    return addresses?.find((address) => address.type === AddressInfoTypeEnum.BILLINGARRANGEMENT);
  };

  /**
   * Get addresses for current billing customer.
   */
  const getAddresses = async (): Promise<void> => {
    const { activeBcId } = billingCustomerContext;

    setIsLoading(true);
    setHasError(false);

    try {
      const { data: addresses } = await AddressInfoApi.getAddressInfos(activeBcId);

      setAddresses(addresses);
      setIsLoading(false);

      checkForIdenticalAddresses(addresses);
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  /**
   * Lookup address for given postal code and house number with optional house number extension.
   */
  const lookupDeliveryAddress = async (postalCode: string, houseNumber: number, extension?: string): Promise<void> => {
    setIsLoading(true);
    setFoundDeliveryAddress(null);
    setHasError(false);

    try {
      const { data } = await AddressInfoApi.lookupDeliveryAddress(postalCode, houseNumber, extension);

      setFoundDeliveryAddress(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setFoundDeliveryAddress(null);
      setHasError(true);
    }
  };

  const clearFoundDeliveryAddress = () => {
    setFoundDeliveryAddress(null);
  };

  const checkForIdenticalAddresses = (addresses: AddressInfo[]) => {
    const currentBaAddress = addresses.find((address) => address.type === 'BILLING_ARRANGEMENT') || null;
    const currentInstallationAddress = addresses.find((address) => address.type === 'ASSIGNED_PRODUCT') || null;
    const currentBcAddress = addresses.find((address) => address.type === 'BILLING_CUSTOMER') || null;

    if (currentInstallationAddress && currentBcAddress) {
      setIdenticalBcAndInstallationAddresses(
        compareAddresses(currentBcAddress?.address, currentInstallationAddress?.address)
      );
    }

    if (currentInstallationAddress && currentBaAddress) {
      setIdenticalBaAndInstallationAddresses(
        compareAddresses(currentBaAddress?.address, currentInstallationAddress?.address)
      );
    }
  };

  return (
    <AddressesContext.Provider
      value={{
        addresses,
        foundDeliveryAddress,
        isLoading,
        hasError,
        identicalBaAndInstallationAddresses,
        identicalBcAndInstallationAddresses,
        clearFoundDeliveryAddress,
        lookupDeliveryAddress,
        updateAddress,
      }}>
      {children}
    </AddressesContext.Provider>
  );
};

export { AddressesContext, AddressesProvider };
