import { NavLink } from 'react-router-dom';
import styled, { css, rem, spacingBase } from 'theme';

interface LinkProps {
  primary?: number;
  listitem?: number;
  inline?: number;
  disabled?: boolean;
}

const LinkStyles = css<LinkProps>`
  display: ${(p) => (p.inline ? 'inline-flex' : 'flex')};
  line-height: 1.8;
  color: ${(p) => (p.primary ? p.theme.colors.primary : p.theme.colors.black)};
  font-weight: ${(p) => p.theme.fonts.weights.bold};

  > span {
    margin-right: ${() => spacingBase};
  }

  svg {
    fill: ${(p) => (p.primary ? p.theme.colors.primary : p.theme.colors.black)};
    align-self: center;

    &.list-item {
      width: ${rem(12)};
      height: ${rem(12)};
      transform: rotate(0.75turn);
      margin-right: ${() => spacingBase};
    }

    &.external {
      width: ${rem(16)};
      height: ${rem(16)};
    }
  }

  &:hover {
    text-decoration: underline;
  }

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
`;

export const Link = styled.a`
  ${LinkStyles}
`;

export const InternalLink = styled(NavLink)`
  ${LinkStyles}
`;

export const Button = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  text-align: left;
  ${LinkStyles}
`;
