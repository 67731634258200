import { gql } from 'graphql-request';

export const getPhoneSettingsQuery = gql`
  query getPhoneSettings($billingCustomerId: String!, $assignedProductId: String!) {
    getPhoneSettings(billingCustomerId: $billingCustomerId, assignedProductId: $assignedProductId) {
      outgoingCallerId
    }
  }
`;

export const postPhoneSettingsQuery = gql`
  mutation postPhoneSettings(
    $billingCustomerId: String!
    $assignedProductId: String!
    $phoneSettings: PhoneSettingsInput!
  ) {
    postPhoneSettings(
      billingCustomerId: $billingCustomerId
      assignedProductId: $assignedProductId
      phoneSettings: $phoneSettings
    ) {
      success
    }
  }
`;
