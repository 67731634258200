import { ApiResponse, query, rest } from './api';
import * as Q from './order-status.query';
import { Order } from './order-status.types';

export interface PublicQuery {
  zipcode: string;
  orderId: string;
  'g-recaptcha-response': string;
}

interface OrderStatusApiInterface {
  getOrderByTokenQuery(orderToken: string, hash: string): ApiResponse<Order>;
  getOrderByZipcodeQuery(query: PublicQuery): ApiResponse<Order>;
}

const OrderStatusApi: OrderStatusApiInterface = {
  getOrderByZipcodeQuery(publicQuery: PublicQuery) {
    return query(
      {
        query: Q.getOrderByZipcodeQuery,
        variables: {
          orderId: publicQuery.orderId,
          zipcode: publicQuery.zipcode,
        },
        headers: {
          'X-recaptcha': publicQuery['g-recaptcha-response'],
        },
        name: 'getOrderByZipcodeQuery',
      },
      false
    ).then(({ getPublicOrder }) => ({ data: getPublicOrder }));
  },
  getOrderByTokenQuery(orderToken: string, hash: string) {
    return rest({
      url: '/orders',
      params: {
        orderToken,
        hash,
      },
      method: 'GET',
      authenticated: false,
    });
  },
};

export { OrderStatusApi };
