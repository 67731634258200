import { ROLES } from 'models/permissions.model';

/**
 * Matches if ANY of the given roles matches ANY of the current user's roles.
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */

export const some = (allowedRoles: ROLES[]) => {
  return (currentUserRoles: ROLES[]) => {
    return allowedRoles.some((role) => currentUserRoles.includes(role));
  };
};

/**
 * Matches if NONE of the given roles exist in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */
export const none = (allowedRoles: ROLES[]) => {
  return (currentUserRoles: ROLES[]) => {
    return allowedRoles.every((role) => !currentUserRoles.includes(role));
  };
};

/**
 * Matches if ALL of the given roles are present in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */
export const all = (allowedRoles: ROLES[]) => {
  return (currentUserRoles: ROLES[]) => {
    return allowedRoles.every((role) => currentUserRoles.includes(role));
  };
};
