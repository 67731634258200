import React from 'react';

export const PasswordEyeIcon = ({ title }: { title: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414">
    <title>{title}</title>
    <path
      d="M10 14.493c-4.835 0-8.124-3.48-8.33-4.493.206-1.013 3.495-4.493 8.33-4.493s8.124 3.48 8.33 4.493c-.206 1.013-3.495 4.493-8.33 4.493M10 3.84C4.262 3.84 0 8.12 0 10s4.262 6.16 10 6.16S20 11.88 20 10s-4.262-6.16-10-6.16m0 8.174a2.025 2.025 0 0 1-2.014-2.013c0-1.11.903-2.013 2.014-2.013 1.11 0 2.014.903 2.014 2.013 0 1.11-.904 2.013-2.014 2.013m0-5.692c-2.03 0-3.68 1.65-3.68 3.68A3.684 3.684 0 0 0 10 13.681a3.684 3.684 0 0 0 3.68-3.68A3.703 3.703 0 0 0 10 6.321"
      fill="#fff"
    />
  </svg>
);
