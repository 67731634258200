import React from 'react';
import { ContentUtil } from 'utils';

import { content } from './required-notice.content';
import * as S from './required-notice.styles';

const contentUtil = new ContentUtil({ content, componentName: 'RequiredNotice' });

export const RequiredNotice = () => {
  return <S.RequiredNotice>{contentUtil.translateToString('isRequired')}</S.RequiredNotice>;
};
