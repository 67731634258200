import { LogoIconZiggo } from 'assets/img/Logo';
import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { appSettings } from 'config/app.settings';
import React from 'react';
import { ContentUtil } from 'utils/content';
import { DateFormat, dateUtils } from 'utils/date';

import { content } from './footer.content';
import * as S from './footer.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Footer' });

export const Footer = () => (
  <S.Footer data-testid="footer">
    <Wrapper>
      <S.List>
        <S.ItemLogo>
          <LogoIconZiggo className="logo" /> Ziggo B.V.
        </S.ItemLogo>
        <S.Item className="copyright">
          {contentUtil.translate('copyright')} {dateUtils.format(new Date(), DateFormat.YEAR)}
        </S.Item>
        <S.Item>
          <a href={`${appSettings.ENV_ZIGGO_URL}/disclaimer`}>{contentUtil.translate('disclaimer')}</a>
        </S.Item>
        <S.Item>
          <a href={`${appSettings.ENV_ZIGGO_URL}/privacy`}>{contentUtil.translate('privacy')}</a>
        </S.Item>
        <S.Item>
          <a href={`${appSettings.ENV_ZIGGO_URL}/cookies`}>{contentUtil.translate('cookies')}</a>
        </S.Item>
        <S.Item>
          <a href={`${appSettings.ENV_ZIGGO_URL}/voorwaarden`}>{contentUtil.translate('terms')}</a>
        </S.Item>
      </S.List>
    </Wrapper>
  </S.Footer>
);
