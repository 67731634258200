import { Col, Row } from 'components/Grid';
import styled from 'styled-components';
import borderRadius from 'theme/global/border-radius';

export const Info = styled(Col)`
  display: flex;
`;

export const Spacer = styled.span`
  flex: 1;
`;

export const Author = styled.div`
  flex: 1;
  flex-grow: 0;
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray900};

  svg {
    width: 15px;
    height: 15px;
    margin-top: 2px;
    margin-right: 5px;
    fill: ${(p) => p.theme.colors.gray900};
  }
`;

export const Date = styled.div`
  flex: 1;
  flex-grow: 0;
  margin-left: 24px;
`;

export const Message = styled.p`
  padding: 8px 16px;

  border: 1px solid ${(p) => p.theme.colors.gray200};
  background-color: ${(p) => p.theme.colors.gray100};
`;

export const Comment = styled(Row)`
  border: 1px solid ${(p) => p.theme.colors.gray200};
  border-radius: ${borderRadius.base};
  background: white;
`;
