import { ApiResponse, query } from './api';
import * as Q from './outage.query';
import { IOutage } from './outage.types';

interface OutageApiInterface {
  getOutages(billingCustomerId: string): ApiResponse<IOutage[]>;
  subscribeOutage(billingCustomerId: string, id: string, telephoneNumbers: string[]): ApiResponse<{ success: boolean }>;
  unsubscribeOutage(billingCustomerId: string, id: string): ApiResponse<{ success: boolean }>;
}

export const OutageApi: OutageApiInterface = {
  getOutages(billingCustomerId: string) {
    return query({
      query: Q.getOutagesQuery,
      variables: { billingCustomerId },
      name: 'getOutages',
    }).then(({ getOutages }) => ({ data: getOutages }));
  },
  subscribeOutage(billingCustomerId: string, id: string, telephoneNumbers: string[]) {
    return query({
      query: Q.subscribeOutageQuery,
      variables: { billingCustomerId, id, telephoneNumbers },
      name: 'subscribeOutage',
    }).then(({ subscribeOutage }) => ({ ...subscribeOutage }));
  },
  unsubscribeOutage(billingCustomerId: string, id: string) {
    return query({
      query: Q.unsubscribeOutageQuery,
      variables: { billingCustomerId, id },
      name: 'unsubscribeOutage',
    }).then(({ unsubscribeOutage }) => ({ ...unsubscribeOutage }));
  },
};
