import { ApiResponse, rest } from './api';
import { IdealPaymentStatusEnum } from './ideal-payment-status.types';

interface IdealPaymentStatusInterface {
  getIdealPaymentStatus(
    transactionId: string,
    paymentId: string
  ): ApiResponse<{ status: IdealPaymentStatusEnum; suspended: boolean }>;
}

export const IdealPaymentStatusApi: IdealPaymentStatusInterface = {
  getIdealPaymentStatus(transactionId: string, paymentId: string) {
    return rest({
      url: '/ideal-payment-status',
      params: {
        transactionId,
        paymentId,
      },
      method: 'GET',
      authenticated: false,
    });
  },
};
