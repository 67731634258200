import { appSettings } from 'config/app.settings';
import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    header: 'Even geen verbinding...',
    text: 'We kunnen deze pagina niet laden. Probeer je de website te vernieuwen?',
    refresh: 'Nu vernieuwen',
    supportHeader: 'Of kies een hulplijn',
    supportLinks: {
      klantenservice: {
        text: 'Naar de klantenservice',
        url: `${appSettings.ENV_ZIGGO_URL}/klantenservice/`,
      },
      storingschecker: {
        text: 'Storing checken',
        url: `${appSettings.ENV_ZIGGO_URL}/klantenservice/storingen/`,
      },
      community: {
        text: 'Ziggo Community',
        url: 'https://community.ziggo.nl/',
      },
    },
  },
  en: {},
};
