import { AuthContext } from 'context/auth/auth.context';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { FeatureContext } from 'context/feature/feature.context';
import { FEATURES } from 'models/features.model';
import { PermissionSettings, ROLES } from 'models/permissions.model';
import { useContext } from 'react';

import { some } from './permission-conditions';

/**
 * Method checks if settings are a object with a 'consumer' and 'business' property,
 * if if it is an array. If it is an array rules are the same for consumer and business
 * users, so that array is returned. If it has these props, the proper array for the
 * current user is returned.
 * @param isConsumer
 * @param allowedRoles
 */
const filterRulesIfDifferenceBetweenConsumerBusiness = (isConsumer: boolean, allowedRoles: PermissionSettings) => {
  if (Array.isArray(allowedRoles)) return allowedRoles;

  return allowedRoles[isConsumer ? 'consumer' : 'business'];
};

/**
 * Compares users roles with allowed roles to see if the current
 * user is allowed to view / do something.
 */
export const isUserAllowed = (
  isConsumer: boolean,
  currentUserRoles: ROLES[],
  allowedRoles: PermissionSettings
): boolean => {
  const allowedRolesForUser = filterRulesIfDifferenceBetweenConsumerBusiness(isConsumer, allowedRoles);

  // Checks each array in the roles array. These values can either be a combination
  // method, which is then ran, or an array of allowes roles (strings), which is than treated
  // as a some() (which means there should match at least one).
  const allowedBooleans = allowedRolesForUser.map((roles) => {
    if (typeof roles === 'function') {
      return roles(currentUserRoles);
    }
    return some(roles)(currentUserRoles);
  });

  // Also check if there are allowedBooleans in the array, otherwise there are no allowedRoles set,
  // And access should be denied.
  return allowedBooleans.length > 0 && allowedBooleans.every((bool) => bool === true);
};

export const useHasAccess = (feature?: FEATURES, permissions?: PermissionSettings) => {
  const { isEnabled: isFeatureEnabled } = useContext(FeatureContext);
  const billingCustomerContext = useContext(BillingCustomerContext);
  const authContext = useContext(AuthContext);

  let hasAccess = true;
  let isEnabled = true;

  if (
    permissions &&
    billingCustomerContext.contractsAndContacts &&
    billingCustomerContext.contractsAndContacts.billing_customer
  ) {
    hasAccess = isUserAllowed(
      billingCustomerContext.contractsAndContacts.billing_customer.is_consumer,
      authContext.roles,
      permissions
    );
  }

  if (feature) {
    isEnabled = isFeatureEnabled(feature);
  }

  return hasAccess && isEnabled;
};
