import { gql } from 'graphql-request';

export const getBillingCustomerQuery = gql`
  query getBillingCustomer($billingCustomerId: String!) {
    getBillingCustomer(billingCustomerId: $billingCustomerId) {
      id
      hashedId
      name
      type
      subType
      company {
        name
        kvk
      }
      primaryContact {
        id
        birthDate
        salutation
        initials
        firstName
        middleName
        lastName
        email
        phone
        mobile
      }
    }
    getBillingCustomerPermissions {
      hasSecondPhone
    }
  }
`;

export const getBillingCustomerListQuery = gql`
  query getBillingCustomerList($billingCustomerId: String!) {
    getBillingCustomerList(billingCustomerId: $billingCustomerId) {
      id
      hashedId
      name
      type
      subType
      company {
        name
        kvk
      }
      primaryContact {
        id
        birthDate
        salutation
        initials
        firstName
        middleName
        lastName
        email
        phone
        mobile
      }
    }
  }
`;
