import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import React from 'react';

import { IGenericModalPrimaryButtonProps, IGenericModalProps } from './IGenericModalProps';
import { Actions, Content, Heading, Section } from './generic-modal.styles';

function isButtonType(type: IGenericModalProps): type is IGenericModalPrimaryButtonProps {
  return 'primaryButtonAction' in type;
}

export default function ModalContent(props: IGenericModalProps & { closeModal: () => void }) {
  const {
    title,
    text,
    children,
    buttonFullWidth,
    primaryButtonText,
    secondaryButtonText,
    secondaryButtonAction,
    onClose,
    closeModal,
  } = props;

  const handlePrimaryButtonClick = () => {
    if (isButtonType(props) && props.primaryButtonAction) {
      props.primaryButtonAction();
    } else if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };

  const handleSecondaryButtonClick = () => {
    if (secondaryButtonAction) {
      secondaryButtonAction();
    } else {
      closeModal();
    }
  };

  return (
    <Section>
      <Heading>{title}</Heading>
      <Content>{text || children}</Content>
      <Actions>
        <Button
          external={!isButtonType(props) ? props.external : undefined}
          href={!isButtonType(props) ? props.href : undefined}
          to={!isButtonType(props) ? props.to : undefined}
          isFullWidth={buttonFullWidth}
          onClick={handlePrimaryButtonClick}>
          {primaryButtonText}
        </Button>
        {secondaryButtonText && (
          <Button
            outline
            buttonVariant={ButtonVariant.SECONDARY}
            isFullWidth={buttonFullWidth}
            onClick={handleSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
      </Actions>
    </Section>
  );
}
