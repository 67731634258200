import { ArrowIcon, CheckIcon } from 'assets/icons';
import React from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './payment-success.content';
import * as S from './payment-success.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentSuccess' });

export const PaymentSuccess = () => (
  <S.PageWrapper data-testid="payment-success">
    <S.Title>
      <CheckIcon />
      <h1>{contentUtil.translate('title')}</h1>
    </S.Title>
    <S.Link to="/facturen">
      <ArrowIcon />
      {contentUtil.translate('linkText')}
    </S.Link>
  </S.PageWrapper>
);
