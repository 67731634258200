import { LoaderIcon } from 'assets/icons';
import React, { useEffect, useState } from 'react';

import * as S from './loader.styles';

interface ILoaderProps extends React.SVGAttributes<SVGElement> {
  delay?: number;
  inline?: boolean;
  noPadding?: boolean;
}

export const Loader = ({ delay = 300, inline = false, noPadding = false, ...props }: ILoaderProps) => {
  const [show, setShow] = useState(false);
  let timeout: number;

  useEffect(() => {
    if (delay === 0) {
      setShow(true);
    }

    // @ts-expect-error TS(2322): Type 'Timeout' is not assignable to type 'number'.
    timeout = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <S.SpinnerContainer data-testid="loader" inline={inline} noPadding={noPadding}>
      <LoaderIcon {...props} />
    </S.SpinnerContainer>
  );
};
