import {
  ActivePaymentArrangement as ActivePaymentArrangementEntity,
  ActivePaymentArrangementInstallment,
  ActivePaymentArrangementInstallmentStatusEnum,
  ActivePaymentArrangementStatusEnum,
  PaymentArrangementPaymentMethodEnum,
} from 'api';
import { Col, Row } from 'components/Grid';
import { BillingCustomerContext } from 'context/billing-customer.context';
import React, { useContext } from 'react';
import { ContentUtil } from 'utils';
import { DateFormat, dateUtils } from 'utils/date';

import { content } from './payment-arrangement.content';
import * as S from './payment-arrangement.styles';

const contentUtil = new ContentUtil({ content, componentName: 'ActivePaymentArrangement' });

interface IInstallmentProps {
  installment: ActivePaymentArrangementInstallment;
  index: number;
  bankAccountNumber: string;
  paymentMethod: PaymentArrangementPaymentMethodEnum;
}

const Installment = ({ installment, index, bankAccountNumber, paymentMethod }: IInstallmentProps) => {
  const { amount, dueDate, status } = installment;
  const terminNumber = (index + 1).toString();
  const installmentAmount = amount.toFixed(2);
  const paDueDate = dateUtils.format(dueDate, DateFormat.DATE);

  const terminPendingDescription =
    paymentMethod === PaymentArrangementPaymentMethodEnum.CASH
      ? 'activePA.terminPendingCash'
      : 'activePA.terminPending';
  const dataTestId =
    status === ActivePaymentArrangementInstallmentStatusEnum.PAID
      ? `installment-activePA.terminPaid`
      : `installment-${terminPendingDescription}`;

  return (
    <S.Termin>
      <S.TerminTitle>{contentUtil.translate('activePA.terminTitle', { terminNumber })}</S.TerminTitle>
      <S.TerminDescription data-testid={dataTestId}>
        {status === ActivePaymentArrangementInstallmentStatusEnum.PAID
          ? contentUtil.translateHtml('activePA.terminPaid', { installmentAmount })
          : contentUtil.translateHtml(terminPendingDescription, { installmentAmount, bankAccountNumber, paDueDate })}
      </S.TerminDescription>
    </S.Termin>
  );
};

interface IActivePaymentDetailsProps {
  activeBcId: string;
}

const ActivePaymentDetails = ({ activeBcId }: IActivePaymentDetailsProps) => (
  <div data-testid="active-payment-arrangement-manual-payment-details">
    <S.DetailsTitle>{contentUtil.translate('activePA.paymentDetailsTitle')}</S.DetailsTitle>
    <div>{contentUtil.translate('activePA.paymentDetailsContent', { activeBcId })}</div>
  </div>
);

export interface IActivePaymentArrangementProps {
  activePaymentArrangement: ActivePaymentArrangementEntity | null;
}

export const ActivePaymentArrangement = ({ activePaymentArrangement }: IActivePaymentArrangementProps) => {
  if (
    !activePaymentArrangement ||
    (activePaymentArrangement?.status !== ActivePaymentArrangementStatusEnum.OPEN &&
      activePaymentArrangement?.status !== ActivePaymentArrangementStatusEnum.GRACE_PERIOD)
  ) {
    return (
      <Row>
        <Col l={9} data-testid="no-active-payment-arrangement">
          <S.NoActivePaymentArrangement>
            <S.NoActivePATitle>{contentUtil.translateToString('noActivePA.title')}</S.NoActivePATitle>
          </S.NoActivePaymentArrangement>
        </Col>
      </Row>
    );
  }

  const { activeBcId } = useContext(BillingCustomerContext);
  const {
    bankAccountNumber,
    installments,
    paidAmount,
    startDate,
    endDate,
    status,
    paymentMethod,
    agreedDueAmount: totalAmount,
  } = activePaymentArrangement;

  const paStatus = contentUtil.translateToString(`activePAStatus.${status}`);
  const paStartDate = dateUtils.format(startDate, DateFormat.DATE);
  const paEndDate = dateUtils.format(
    dateUtils.isValid(endDate) ? endDate : installments.at(-1)?.dueDate,
    DateFormat.DATE
  );

  return (
    <Row>
      <Col l={9} data-testid="payment-arrangement-active">
        <S.PaymentArrangementCard>
          <S.PaymentArrangementForm
            id="active-payment-arrangement-form"
            testid="active-payment-arrangement-form"
            hideCancelButton
            hideSubmitButton
            // @ts-expect-error TS(2769): No overload matches this call.
            onSubmit={() => Promise<void>}
            render={() => {
              return (
                <>
                  <S.Title>{contentUtil.translateToString('activePA.title')}</S.Title>
                  <div>
                    {contentUtil.translateHtml('activePA.totalAmount', {
                      totalAmount: totalAmount ? totalAmount.toString() : (0).toFixed(2),
                    })}
                  </div>
                  <div>
                    {contentUtil.translateHtml('activePA.paidAmount', {
                      paidAmount: paidAmount ? paidAmount.toString() : (0).toFixed(2),
                    })}
                  </div>
                  <div>{contentUtil.translateHtml('activePA.status', { paStatus, paStartDate, paEndDate })}</div>
                  <S.ActiveOverviewTitle>
                    {contentUtil.translateToString('activePA.overviewTitle')}
                  </S.ActiveOverviewTitle>
                  {installments.map((installment, index) => (
                    <Installment
                      key={index}
                      index={index}
                      installment={installment}
                      bankAccountNumber={bankAccountNumber}
                      paymentMethod={paymentMethod}
                    />
                  ))}
                  {paymentMethod === PaymentArrangementPaymentMethodEnum.CASH ? (
                    <ActivePaymentDetails activeBcId={activeBcId} />
                  ) : null}
                </>
              );
            }}
          />
        </S.PaymentArrangementCard>
      </Col>
    </Row>
  );
};
