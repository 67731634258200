import { useRest } from 'context/hooks/rest.hook';
import { FEATURES, FEATURE_STATES } from 'models/features.model';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import API from 'utils/api';

// The properties we will expose in useContext or <FeatureContext.Consumer />
export interface IFeatureContext {
  isLoading: boolean;
  hasError: boolean;
  isEnabled: (feature: FEATURES) => boolean;
}

// the initial state, note that actually setting hasError here is ignored due to the doRest hook
const initialState: IFeatureContext = {
  isLoading: true,
  hasError: false,
  isEnabled: (_: FEATURES) => true,
};

// Feature response should be a map of Features by name
type FeaturesType = { [key in FEATURES]: FEATURE_STATES };

const FeatureContext = React.createContext(initialState);

const FeatureProvider = ({ children }: PropsWithChildren<any>) => {
  const [features, setFeatures] = useState<FeaturesType | undefined>(undefined);
  const { isLoading, hasError, doRest } = useRest({ isLoading: true });

  // On Provider load, fetch the settings so isEnabled will return sensible data
  useEffect(() => {
    doRest(
      () => API.apiV1.getFromFeConfigs('features/feature-settings.json'),
      ({ data }) => setFeatures(data)
    );
  }, []);

  // if feature is defined and on, or feature state default is true isEnabled will be true.
  const isEnabled = (feature: FEATURES) => {
    // do not show maintenance page in case feature flag do not exist or features failed to load
    if (feature === FEATURES.MAINTENANCE && (!features || !features[feature])) {
      return false;
    }

    return ((features && features[feature]) || FEATURE_STATES.DEFAULT) === FEATURE_STATES.ON;
  };

  return <FeatureContext.Provider value={{ isLoading, hasError, isEnabled }}>{children}</FeatureContext.Provider>;
};

// Convenience hook, just use: const productPageEnabled = useFeature(FEATURES.PRODUCT_PAGE); on top of your page!
const useFeature = (feature: FEATURES) => {
  const { isEnabled } = useContext(FeatureContext);
  return isEnabled(feature);
};

export { useFeature, FeatureContext, FeatureProvider };
