import { ApiResponse, rest } from './api';
import { CobrowsingSession } from './cobrowse.types';

interface CoBrowsingApiInterface {
  isCobrowseAgent(): ApiResponse<CobrowsingSession>;
}

export const CoBrowsingApi: CoBrowsingApiInterface = {
  async isCobrowseAgent() {
    return rest({
      url: `/cobrowsing/is-cobrowse-agent`,
      method: 'GET',
    });
  },
};
