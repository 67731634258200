import styled, { contentMargins, css, gapSize, rem, screenMobilePlusAndSmaller, spacingBase } from 'theme';

export const Footer = styled('footer')`
  background-color: ${(p) => p.theme.colors.black};
`;

export const List = styled('div')`
  display: flex;
  align-items: center;
  font-size: ${rem(13)};

  ${contentMargins(1)}

  ${screenMobilePlusAndSmaller(css`
    align-items: flex-start;
    flex-direction: column;
  `)};
`;

export const Item = styled('div')`
  color: ${(p) => p.theme.colors.gray300};
  padding-right: ${() => spacingBase};

  a {
    color: ${(p) => p.theme.colors.gray300};
    text-decoration: underline;
  }
`;

export const ItemLogo = styled(Item)`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.primary};

  svg {
    width: ${rem(15)};
    height: ${rem(15)};
    margin-right: ${() => gapSize(0.5)};
  }
`;
