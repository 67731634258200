import { appSettings } from 'config/app.settings';
import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    title: 'Wil je contact met ons opnemen?',
    workaroundText: `Het is nog niet mogelijk om online een bestelling of wijziging door te geven. Bel ons of gebruik het contactformulier. We helpen je snel verder.`,
    workaroundTextCommunity:
      'Je kunt online nog geen bestelling of wijziging doorgeven. Stel je vraag in onze community of gebruik het contactformulier.',
    call: 'Bellen',
    callTitle: 'Bel ons',
    community: 'Community',
    onlineForm: 'Contactformulier',
    webformLink: 'Naar het contactformulier',
    contactZiggoBusinessLink: 'Contact over Ziggo Zakelijk',
    contactZiggoBusinessUrl: `${appSettings.ENV_ZIGGO_URL}/zakelijk/klantenservice`,
  },
  en: {},
};
