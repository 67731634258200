import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { CloseIcon, MenuIcon, UserIcon } from 'assets/icons';
import { DisableBodyScroll } from 'components/DisableBodyScroll';
import { PiiMask } from 'components/PiiMask';
import { appSettings } from 'config/app.settings';
import { AuthContext } from 'context/auth/auth.context';
import React, { useContext, useEffect, useState } from 'react';
import Media from 'react-media';
import { mobilePlusEnd, tabletPortraitStart } from 'theme';
import { ContentUtil } from 'utils/content';
import documentEvents from 'utils/document-events';
import { history } from 'utils/history';

import { content } from '../../base-layout.content';
import { NavigationMobile } from '../NavigationMobile';
import * as S from './account.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Account' });

export const Account: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { isMerged } = useContext(AuthContext);

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleBodyListener = ({ target }: Event) => {
    if ((target as HTMLElement)?.closest('#sidenav')) {
      handleCloseMenu();
    }
  };

  const handleLogout = () => {
    const csrfToken = sessionStorage.getItem('_csrf');
    const tokenHint = OidcService.getIdTokenHint();

    sessionStorage.clear();

    window.location.href = `${appSettings.ENV_REST_SSO_URL}/account/uitloggen?login=ziggo-selfcare&csrf_token=${csrfToken}&id_token_hint=${tokenHint}`;
  };

  useEffect(() => {
    history.listen(handleCloseMenu);
    documentEvents.escapeListener(handleCloseMenu);
    documentEvents.bodyListener(handleBodyListener);

    return () => {
      documentEvents.removeEscapeListener(handleCloseMenu);
      documentEvents.removeBodyListener(handleBodyListener);
    };
  }, []);

  return (
    <S.Wrapper>
      <PiiMask>
        <S.Toggle onClick={handleToggleMenu} aria-controls="sidenav" data-testid="account-toggle">
          <Media
            query={`(max-width:${mobilePlusEnd}px)`}
            render={() => (openMenu ? <CloseIcon width="64" height="18" /> : <MenuIcon width="64" height="20" />)}
          />
          <Media query={`(max-width:${mobilePlusEnd}px)`}>{contentUtil.translate(openMenu ? 'close' : 'menu')}</Media>
          <Media query={`(min-width:${tabletPortraitStart}px)`}>
            <>
              <UserIcon width="80" height="20" viewBox="0 0 25 25" />
              <span>{contentUtil.translateToString('myZiggo')}</span>
            </>
          </Media>
        </S.Toggle>
      </PiiMask>

      <S.MenuWrapper id="sidenav" opened={openMenu} aria-expanded={openMenu} data-testid="account-status">
        <S.Menu opened={openMenu}>
          <Media query={`(max-width:${mobilePlusEnd}px)`}>
            <>
              <NavigationMobile isMerged={isMerged} onClickLogout={handleLogout} />
              {openMenu && <DisableBodyScroll />}
            </>
          </Media>
          <Media query={`(min-width:${tabletPortraitStart}px)`}>
            <>
              <S.MyAccountLink
                buttonVariant={ButtonVariant.LINK}
                testid="account-change-login-link"
                to={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account/verander-login`}>
                {contentUtil.translate('changeLogin')} <S.Chevron />
              </S.MyAccountLink>
              <S.MyAccountLink
                buttonVariant={ButtonVariant.LINK}
                testid="account-change-password-link"
                to={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account/wachtwoord-wijzigen`}>
                {contentUtil.translate('changePassword')} <S.Chevron />
              </S.MyAccountLink>
              <S.MyAccountLink
                buttonVariant={ButtonVariant.LINK}
                testid="my-account-link"
                to={`${appSettings.ENV_ZIGGO_ACCOUNT_URL}/account`}>
                {contentUtil.translate('myAccount')} <S.Chevron />
              </S.MyAccountLink>
              {isMerged && (
                <S.MyAccountLink
                  buttonVariant={ButtonVariant.LINK}
                  testid="my-vodafone-account-link"
                  href={`${appSettings.ENV_VODAFONE_URL}/my/`}>
                  {contentUtil.translate('myVodafone')} <S.Chevron />
                </S.MyAccountLink>
              )}
              <S.LogoutButton onClick={handleLogout} data-testid="logout">
                {' '}
                {contentUtil.translate('logout')}{' '}
              </S.LogoutButton>
            </>
          </Media>
        </S.Menu>
      </S.MenuWrapper>
    </S.Wrapper>
  );
};
