import { gql } from 'graphql-request';

export const getPayMeansQuery = gql`
  query getPayMeans($billingCustomerId: String!) {
    getPayMeans(billingCustomerId: $billingCustomerId) {
      paymentMethod
      directDebit {
        accountHolder
        accountNumber
      }
      creditcard {
        brand
        lastFourDigits
        validityEndYear
        validityEndMonth
      }
      invoiceDeliveryMethod
      allowUpdateAccountHolder
    }
  }
`;

export const putPayMeansQuery = gql`
  mutation putPayMeans(
    $billingCustomerId: String!
    $accountHolder: String
    $accountNumber: String!
    $paymentMethod: PayMeansPayMentMethod!
  ) {
    putPayMeans(
      billingCustomerId: $billingCustomerId
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      paymentMethod: $paymentMethod
    ) {
      paymentMethod
      directDebit {
        accountHolder
        accountNumber
      }
      creditcard {
        brand
        lastFourDigits
        validityEndYear
        validityEndMonth
      }
      invoiceDeliveryMethod
      allowUpdateAccountHolder
    }
  }
`;

export const changeToDigitalInvoiceTypeMutation = gql`
  mutation changeToDigitalInvoiceType($billingCustomerId: String!) {
    changeToDigitalInvoiceType(billingCustomerId: $billingCustomerId) {
      success
    }
  }
`;

export const isInvoiceTypeChangedQuery = gql`
  query isInvoiceTypeChanged($billingCustomerId: String!) {
    isInvoiceTypeChanged(billingCustomerId: $billingCustomerId) {
      changed
    }
  }
`;
