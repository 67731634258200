import React, { SVGProps } from 'react';

export const OrderBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <rect width="24" height="24" fill="white" />
    <path
      d="M21.5289 7.47003V16.14C21.5289 16.86 21.1407 17.52 20.5075 17.88L12.7751 22.25V12.42L18.5565 9.15003L21.5289 7.47003Z"
      fill="black"
    />
    <path
      d="M3 7.47003V16.14C3 16.86 3.38815 17.52 4.02144 17.88L11.7537 22.25V12.42L5.97239 9.15003L3 7.47003Z"
      fill="black"
    />
    <path
      d="M13.2343 2.27C12.9177 2.09 12.5602 2 12.2027 2C11.8452 2 11.4877 2.09 11.171 2.27L3.5 6.6L12.2537 11.55L20.9053 6.6L13.2343 2.26V2.27Z"
      fill="black"
    />
    <path d="M8.54588 9.48711L17.2215 4.43081L18.1431 5.0302L9.46744 10.0865L8.54588 9.48711Z" fill="white" />
    <rect x="6" y="9" width="1" height="3" fill="white" />
    <path
      d="M6.07285 12.0404L6.00263 10.8582L9.32619 12.7004C9.05844 13.1835 8.4498 13.358 7.96677 13.0902L6.07285 12.0404Z"
      fill="white"
    />
    <path d="M8.5 10H9.5V12.2C9.5 12.6418 9.14183 13 8.7 13H8.5V10Z" fill="white" />
    <path d="M6.24486 8.09356L14.8311 3.03257L15.7422 3.63141L7.1559 8.6924L6.24486 8.09356Z" fill="white" />
    <path d="M6.57017 7.90002L7.07007 8.5H5.57007L6.57017 7.90002Z" fill="white" />
  </svg>
);
