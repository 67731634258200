/**
 * Utility scrolls complete body to any element you give it.
 * Optionally you can give a default from top. By default it will pick 15% of document height as its offset.
 * Based on https://gist.github.com/andjosh/6764939
 */

// Very tiny RAF polyfill, which is used when browser doesn't support RAF.
const RAFPolyfill = (() => {
  let clock = Date.now();

  return (callback: (timestamp: number) => void) => {
    const currentTime = Date.now();

    if (currentTime - clock > 16) {
      clock = currentTime;
      callback(currentTime);
    } else {
      setTimeout(() => {
        RAFPolyfill(callback);
      }, 0);
    }
  };
})();

/*
 * window.requestAnimationFrame.bind(window) is needed for a weird IE and Edge bug, which otherwise
 * sometimes won't find the requestAnimationFrame method.
 */
const requestAnimationFrame =
  window.requestAnimationFrame.bind(window) ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  RAFPolyfill;

/**
 * EaseInOutQuad calculation for smoother easing.
 */
const easeInOutQuad = (currentTime: number, startPosition: number, positionDifference: number, duration: number) => {
  let c = currentTime / (duration / 2);
  if (c < 1) return (positionDifference / 2) * c * c + startPosition;
  c -= 1;
  return (-positionDifference / 2) * (c * (c - 2) - 1) + startPosition;
};

const DISTANCE_FROM_TOP_IN_PERCENTAGE = 0.15;

export const scrollTo = (
  scrollToElement: Element | number | null = null,
  duration = 500,
  givenScrollingElement?: Element | null,
  offsetFromTop?: number
) => {
  const scrollingElement = givenScrollingElement || document.scrollingElement || document.documentElement;

  if (!scrollingElement || scrollToElement === null) return;

  // Default value is 15% of document height.
  const givenOrDefaultOffsetFromTop =
    typeof offsetFromTop !== 'undefined'
      ? offsetFromTop
      : scrollingElement.clientHeight * DISTANCE_FROM_TOP_IN_PERCENTAGE;

  // Current scroll position of document from top of document.
  const start = scrollingElement.scrollTop;

  // Current position of the element to scroll to **relative to viewort!**
  // Subtracts the offset from top off it.
  const top = typeof scrollToElement === 'number' ? scrollToElement : scrollToElement.getBoundingClientRect().top;
  const scrollToElementOffsetTopFromViewport = top - givenOrDefaultOffsetFromTop;

  // Point to scroll to.
  const to = start + scrollToElementOffsetTopFromViewport;

  // Difference between start and end.
  const change = to - start;

  // We use time to make sure we reach our destination within the given duration.
  const startDate = +new Date();

  const animateScroll = () => {
    const currentDate: number = +new Date();
    const currentTime: number = currentDate - startDate;
    scrollingElement.scrollTop = easeInOutQuad(currentTime, start, change, duration);

    if (currentTime < duration) {
      // Use requestAnimationFrame to make the scrolling even smoother.
      requestAnimationFrame(animateScroll);
    } else {
      scrollingElement.scrollTop = to;
    }
  };

  // Kick off the animation.
  animateScroll();
};
