import { Card as CardComponent } from 'components/Card';
import styled, { css, gapSize, rem, screenMobilePlusAndBigger } from 'theme';

export const Card = styled(CardComponent)`
  flex-direction: column;
  max-width: ${rem(620)};

  margin: ${() => gapSize(2)};

  ${screenMobilePlusAndBigger(css`
    margin: ${() => gapSize(4)} auto;
  `)}

  padding: ${() => gapSize(3)};
  text-align: center;
  display: flex;

  img {
    margin: ${() => gapSize(3)};
    max-width: 100%;
  }
`;

export const CardTitle = styled.h3`
  em {
    font-style: normal;
  }
`;
