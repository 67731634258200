import { InterpolationValue, SimpleInterpolation } from 'styled-components';
import { css, screenTabletLandscapeAndBigger } from 'theme';

/**
 * Hover focus only shows hover and focus stylse on devices bigger
 * than tablet. This is to prevent sticky hovers on touch devices.
 */
export const hoverFocus = (strings: InterpolationValue[] | SimpleInterpolation) => css`
  ${screenTabletLandscapeAndBigger(css`
    &:hover,
    &:focus {
      ${strings};
    }
  `)};
`;
