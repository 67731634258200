import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import { css } from 'theme';

export const ieEdgeSpecific = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @supports (-ms-ime-align: auto) and (not (grid-area: auto)) {
    ${strings};
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${strings};
  }
`;

export const notIeEdge = (strings: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  @supports (grid-area: auto) and (not (-ms-ime-align: auto)) {
    ${strings};
  }
`;
