import { gql } from 'graphql-request';

export const getSecretCircleQuery = gql`
  query getSecretCircle($billingCustomerId: String!) {
    getSecretCircle(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const updateSecretCircleQuery = gql`
  mutation updateSecretCircle($billingCustomerId: String!, $secretCircleStatus: SecretCircleStatusInput!) {
    updateSecretCircle(billingCustomerId: $billingCustomerId, secretCircleStatus: $secretCircleStatus) {
      success
    }
  }
`;
