import React, { SVGProps } from 'react';

export const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg role="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.75 23c2.613 0 4.75 2.375 4.75 4.75H6.75A4.764 4.764 0 012 23zm0-9.5c2.613 0 4.75 2.375 4.75 4.75H6.75A4.764 4.764 0 012 13.5zm0-9.5c2.613 0 4.75 2.375 4.75 4.75H6.75A4.764 4.764 0 012 4z"
    />
  </svg>
);
