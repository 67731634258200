import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    usp1: 'Stabiel, snel en veilig internet',
    usp2: '24/7 service',
    usp3: 'Het beste entertainment voor jou',
    isLoading: 'Mijn Ziggo wordt geladen...',
    errorCardTitle: 'We kunnen op dit moment jouw gegevens niet ophalen. Probeer het later nog een keer.',
    errorCardLink: 'Terug naar ziggo.nl',
  },
  en: {},
};
