import React from 'react';

export const EmailFlyingThroughTheSun = () => (
  <svg role="none" xmlns="http://www.w3.org/2000/svg" width="192" height="192" viewBox="0 0 192 192">
    <g fill="none" transform="rotate(-15 284.787 12.532)">
      <g transform="translate(29.172)">
        <ellipse cx="42.99" cy="43" fill="#ABB2B2" rx="42.99" ry="43" />
        <ellipse cx="42.99" cy="43" fill="#F48C00" rx="36.848" ry="36.857" />
      </g>
      <rect width="35.313" height="4.607" x="12.283" y="32.25" fill="#5D6363" rx="2.304" />
      <rect width="42.99" height="4.607" y="44.536" fill="#5D6363" rx="2.304" />
      <path
        fill="#C8D1D4"
        d="M61.414 16.893h81.37a7.68 7.68 0 0 1 7.68 7.68v46.07h-81.37a7.68 7.68 0 0 1-7.68-7.68v-46.07z"
      />
      <path
        fill="#5D6363"
        d="M62.95 18.428v44.536a6.142 6.142 0 0 0 6.141 6.143h79.839V24.571a6.142 6.142 0 0 0-6.142-6.143H62.95zm-1.536-3.071h81.374c5.088 0 9.212 4.125 9.212 9.214v46.072c0 .848-.687 1.535-1.535 1.535H69.09c-5.088 0-9.212-4.125-9.212-9.214V16.893c0-.848.687-1.536 1.535-1.536z"
      />
      <path
        fill="#8D9BA0"
        d="M103.755 58.393L63.853 21.019l42.087 34.969 39.919-32.475-37.926 34.898a3.07 3.07 0 0 1-4.178-.018z"
      />
      <path
        fill="#5D6363"
        d="M106.777 56.63a1.535 1.535 0 0 1-2-.008L88.84 42.652l-24.804 24.81-2.171-2.172L86.51 40.638l-26.1-22.584a1.536 1.536 0 0 1 2.009-2.323l43.52 37.575 40.46-34.512a1.535 1.535 0 1 1 1.99 2.34l-25.095 21.355 27.401 27.408-2.171 2.172-27.577-27.583-14.17 12.144z"
      />
    </g>
  </svg>
);
