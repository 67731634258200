import { css, desktopGapMultiply, gapSize, mobileGapMultiply, tablet } from 'theme';

const paddingMultiplier = 2;

/**
 * Most blocks of content will require margins in a simular fashion, this prevents duplicate code
 */
export const contentMargins = (top = 0, right = 1, bottom = 1, left = 1) => css`
  margin: ${() => gapSize(top * mobileGapMultiply)} ${() => gapSize(right * mobileGapMultiply)}
    ${() => gapSize(bottom * mobileGapMultiply)} ${() => gapSize(left * mobileGapMultiply)};
  ${() =>
    tablet(css`
      margin: ${gapSize(top * desktopGapMultiply)} ${gapSize(right * desktopGapMultiply)}
        ${gapSize(bottom * desktopGapMultiply)} ${gapSize(left * desktopGapMultiply)};
    `)};
`;

export const contentPadding = (top = 1, right = 1, bottom = 1, left = 1) => css`
  padding: ${() => gapSize(top * paddingMultiplier)} ${() => gapSize(right * paddingMultiplier)}
    ${() => gapSize(bottom * paddingMultiplier)} ${() => gapSize(left * paddingMultiplier)};
`;
