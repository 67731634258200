import { AISearchApi } from 'api';
import React, { createContext, useContext, useState } from 'react';

export interface IAISearchContext {
  isLoading: boolean;
  hasError: boolean;
  resetError: () => void;
  aiSearchResult: string | null;
  getAISearchResult: (query: string) => void;
}

interface AISearchProviderProps {
  children: JSX.Element;
}

const AISearchContext = createContext<IAISearchContext | undefined>(undefined);

const useAISearchContext = () => useContext(AISearchContext) as IAISearchContext;

const AISearchProvider = ({ children }: AISearchProviderProps) => {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [aiSearchResult, setAISearchResult] = useState<string | null>('');

  const getAISearchResult = async (query: string) => {
    setLoading(true);
    try {
      const response = await AISearchApi.getSearchResult(query);
      setAISearchResult(response?.data?.message || null);
    } catch (e) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const resetError = () => {
    setAISearchResult('');
    setHasError(false);
  };

  return (
    <AISearchContext.Provider
      value={{
        isLoading,
        aiSearchResult,
        hasError,
        resetError,
        getAISearchResult,
      }}>
      {children}
    </AISearchContext.Provider>
  );
};

export { AISearchContext, AISearchProvider, useAISearchContext };
