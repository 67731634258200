export enum BillingCustomerTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum CustomerProductType {
  B2C = 'B2C',
  SOHO = 'SOHO',
}

export interface Company {
  name: string;
  kvk: string;
}

export enum ContactSalutationEnum {
  SIR = 'SIR',
  MADAM = 'MADAM',
  SIR_MADAM = 'SIR_MADAM',
  NO_TITLE = 'NO_TITLE',
  UNKNOWN = 'UNKNOWN',
}

export interface Contact {
  id: string;
  birthDate?: string;
  salutation: ContactSalutationEnum;
  initials: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string | null;
  phone: string | null;
  mobile?: string | null;
  hasSecondPhone?: boolean;
}

export interface BillingCustomer {
  id: string;
  hashedId: string;
  name: string;
  type: BillingCustomerTypeEnum;
  company: Company | null;
  primaryContact: Contact;
}
