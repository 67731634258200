import { Payment, PaymentApi } from 'api';
import React, { useContext, useEffect, useState } from 'react';

import { BillingCustomerContext } from './billing-customer.context';

interface IContext {
  isLoading: boolean;
  hasError: boolean;
  payments: Payment[];
}

const intialState: IContext = {
  isLoading: true,
  hasError: false,
  payments: [],
};

const PaymentsContext = React.createContext(intialState);

const PaymentsProvider = ({ children }: { children: React.ReactNode }) => {
  const bcContext = useContext(BillingCustomerContext);

  useEffect(() => {
    if (bcContext.completed) {
      init();
    }
  }, [bcContext.completed]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [payments, setPayments] = useState<Payment[]>([]);

  const init = async () => {
    try {
      setIsLoading(true);
      setHasError(false);
      const paymentResponse = await PaymentApi.getPayments(bcContext.activeBcId);
      setPayments(paymentResponse.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        isLoading,
        hasError,
        payments,
      }}>
      {children}
    </PaymentsContext.Provider>
  );
};

export { PaymentsContext, PaymentsProvider };
