import { PaymentArrangementHistory as PaymentArrangementHistoryEntity } from 'api';
import { Col, Row } from 'components/Grid';
import React from 'react';
import { ContentUtil } from 'utils/content';
import { DateFormat, dateUtils } from 'utils/date';

import { content } from './payment-arrangement.content';
import * as S from './payment-arrangement.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentArrangementHistory' });

export interface IPaymentArrangementHistoryProps {
  paymentArrangementHistory: PaymentArrangementHistoryEntity[];
}

export const PaymentArrangementHistory = ({ paymentArrangementHistory }: IPaymentArrangementHistoryProps) => {
  if (!paymentArrangementHistory || !paymentArrangementHistory.length) {
    return null;
  }

  return (
    <Row>
      <Col l={9} data-testid="payment-arrangement-history">
        <S.PaymentArrangementCard>
          <S.HistoryTitle>{contentUtil.translateToString('historyPA.title')}</S.HistoryTitle>
          <S.HistoryGrid>
            <span>{contentUtil.translateToString('historyPA.status')}</span>
            <span>{contentUtil.translateToString('historyPA.amount')}</span>
            <span>{contentUtil.translateToString('historyPA.startDate')}</span>
            <span>{contentUtil.translateToString('historyPA.endDate')}</span>
            {paymentArrangementHistory.map((row: PaymentArrangementHistoryEntity) => (
              <React.Fragment key={row.PAId}>
                <span>{contentUtil.translate(`historyStatus.${row.status}`)}</span>
                <span>{`€ ${row.pastDueAmount ? row.pastDueAmount.toFixed(2) : (0).toFixed(2)}`}</span>
                <span>{dateUtils.format(row.startDate, DateFormat.DATE)}</span>
                <span>{dateUtils.format(row.lastUpdateDate, DateFormat.DATE)}</span>
              </React.Fragment>
            ))}
          </S.HistoryGrid>
        </S.PaymentArrangementCard>
      </Col>
    </Row>
  );
};
