import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import styled, { css, gapSize, rem, screenTabletLandscapeAndBigger } from 'theme';

export const Hr = styled('hr')`
  width: 100%;
  margin-top: ${() => gapSize(2)};
  margin-bottom: ${() => gapSize(4)};
  border: ${rem(0.5)} solid ${(p) => p.theme.colors.grayL30};
`;

export const InlineError = styled(ErrorMessage)`
  ${screenTabletLandscapeAndBigger(css`
    grid-column: 2;
  `)}
`;

export const Context = styled.div`
  padding: ${() => gapSize(2)};
  background: ${(p) => p.theme.colors.gray100};
`;
