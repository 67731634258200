import { IContent } from 'models/content.model';

const content: IContent = {
  nl: {
    name: 'Naam',
    description: 'Omschrijving',
    date: 'Datum',
    reference: 'Referentienummer',
    status: 'Status',
    attachments: 'Bijlage(n)',
  },
  en: {},
};

export default content;
