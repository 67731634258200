import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { ChildPage } from 'components/ChildPage';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { PageHeader } from 'components/PageHeader';
import { BillingCustomerContext } from 'context/billing-customer.context';
import ISupportCaseContext from 'context/support-case/interface/support-case-context';
import SupportCaseContext from 'context/support-case/support-case.context';
import React, { useContext, useEffect } from 'react';
import { ContentUtil } from 'utils';
import { Helmet } from 'react-helmet';

import SupportCaseTable from './SupportCaseTable';
import content from './support-case-overview.content';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseOverview' });

const SupportCaseOverview = () => {
  const { isLoading, error, supportCases, fetchCompleted, fetchSupportCases } =
    useContext<ISupportCaseContext>(SupportCaseContext);
  const { activeBcId } = useContext(BillingCustomerContext);

  useEffect(() => {
    if (!isLoading && !error && fetchSupportCases && !fetchCompleted && activeBcId) {
      fetchSupportCases(activeBcId);
    }
  }, [isLoading, fetchCompleted, supportCases, activeBcId]);

  return (
    <ChildPage>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader title={contentUtil.translateToString('pageTitle')} />
      <Wrapper>
        <Row>
          <Col>
            {!isLoading && error && (
              <InlineAlert type="error" testid="loading-support-cases-failed-error">
                {contentUtil.translate('somethingWrong')}
              </InlineAlert>
            )}
            {!isLoading && !error && <SupportCaseTable isLoading={isLoading} supportCases={supportCases} />}
          </Col>
        </Row>
      </Wrapper>
    </ChildPage>
  );
};

export default SupportCaseOverview;
