import { createContext } from 'react';

import ISupportCaseContext from './interface/support-case-context';

const SupportCaseContext = createContext<ISupportCaseContext>({
  isLoading: false,
  fetchCompleted: false,
  hasCompleted: false,
  supportCases: [],
  hasSupportCases: false,
  findSupportCase: Function,
  addSupportCaseComment: Function,
  closeSupportCase: Function,
  downloadAttachment: Function,
});

export default SupportCaseContext;
