import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { Input } from 'components/Form';
import * as R from 'ramda';
import React, { useState } from 'react';
import { ContentUtil } from 'utils';

import IOutageSubscribeFormProps from './Interface/IOutageSubscribeFormProps';
import { content } from './outage-overview.content';
import * as Styles from './outage-overview.styles';

const contentUtil = new ContentUtil({ content, componentName: 'OutageOverview' });

const OutageSubscribeForm = (props: IOutageSubscribeFormProps) => {
  const [maxNumbers, setMaxNumbers] = useState<boolean>(false);
  const [numberInputs, setNumberInputs] = useState([{ label: contentUtil.translateToString('phoneNumberLabel') }]);

  const submit = async (values: Record<string, any>): Promise<void> => {
    props.onSubmit({ ...props.outage, telephoneNumbers: values.telephoneNumbers });
  };

  const addNumberField = () => {
    const numberInputsCopy = [...numberInputs];
    numberInputsCopy.push({ label: contentUtil.translateToString('phoneNumberLabel') });
    setNumberInputs(numberInputsCopy);
    setMaxNumbers(numberInputsCopy.length >= 10);
  };

  const renderNumberInputs = (validators: { [key: string]: Function }) => {
    return numberInputs.map((input, index) => (
      <Input
        key={`number-input-${index}`}
        label={input.label}
        name={`telephoneNumbers[${index}]`}
        validate={[validators.mobile]}
        type="tel"
      />
    ));
  };

  const isSubscribeButtonDisabled = (formApi: any): boolean => {
    const { values } = formApi;

    return Boolean(
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      !R.is(Array, values.telephoneNumbers) ||
        (R.is(Array, values.telephoneNumbers) && R.isEmpty(R.filter(Boolean, values.telephoneNumbers)))
    );
  };

  return (
    <Styles.Form
      onSubmit={submit}
      data-testid="outage-subscribe-form"
      hideSubmitButton
      hideCancelButton
      render={(formApi, validators) => (
        <>
          {renderNumberInputs(validators)}

          <div className="form-button">
            <Button
              testid="form-cancel-button"
              onClick={addNumberField}
              buttonVariant={ButtonVariant.DARK}
              outline
              disabled={maxNumbers}>
              {contentUtil.translateToString('addNumber')}
            </Button>

            <Button
              type="submit"
              testid="form-submit-button"
              buttonVariant={ButtonVariant.DARK}
              disabled={isSubscribeButtonDisabled(formApi)}>
              {contentUtil.translateToString('subscribe')}
            </Button>
          </div>
        </>
      )}
    />
  );
};

export default OutageSubscribeForm;
