import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    pageTitle: 'Betaalregeling',
    activePA: {
      title: 'Je lopende betaalregeling',
      totalAmount: 'Het totale openstaande bedrag is: <strong>€ #{totalAmount}</strong>',
      paidAmount: 'Tot nu toe betaald: <strong>€ #{paidAmount}</strong>',
      status: 'Status: <strong>#{paStatus}</strong> (loopt van #{paStartDate} t/m #{paEndDate})',

      overviewTitle: 'Overzicht termijnen',
      terminTitle: 'Termijn #{terminNumber}:',
      terminPaid: '€ #{installmentAmount} is voldaan.',
      terminPending:
        '€ #{installmentAmount} wordt automatisch afgeschreven van rekeningnummer <strong>#{bankAccountNumber}</strong> op <strong>#{paDueDate}</strong>.',
      terminPendingCash:
        'Maak € #{installmentAmount} handmatig over op ons rekeningnummer voor <strong>#{paDueDate}</strong>.',

      paymentDetailsTitle: 'Betaalgegevens voor alle termijnen',
      paymentDetailsContent:
        'Maak het bedrag over naar rekening NL98INGB0000845745 ten name van Ziggo Services B.V. onder vermelding van je klantnummer #{activeBcId}.',
    },
    activePAStatus: {
      BROKEN: 'Betalingsregeling niet nagekomen',
      CANCELLED: 'Betalingsregeling geannuleerd',
      COMPLETED: 'Betalingsregeling succesvol voldaan',
      COMPLETED_BEFORE_DUE_DATE: 'Betalingsregeling succesvol voldaan',
      OPEN: 'Betalingsregeling actief',
      RENEGOTIATED: 'Betalingsregeling geannuleerd',
      STOPCOLLECTION: 'Betalingsregeling succesvol voldaan',
      GRACE_PERIOD: 'Betalingsregeling actief',
    },
    installmentStatus: {
      BACKED_OUT: 'Open',
      CANCELLED: 'Termijn geannuleerd',
      DD_REQ_SENT: 'Open',
      PAID: 'Betaald',
      PAID_BEFORE_DUE_DATE: 'Betaald',
      PENDING: 'Open',
      RENEGOTIATED: 'Betalingsregeling geannuleerd',
      UNPAID: 'Open',
    },
    historyStatus: {
      OPEN: 'Afgebroken',
      CLOSED: 'Voldaan',
      BROKEN: 'Betalingsregeling niet nagekomen',
      CANCELLED: 'Betalingsregeling geannuleerd',
      COMPLETED: 'Betalingsregeling succesvol voldaan',
      COMPLETED_BEFORE_DUE_DATE: 'Betalingsregeling succesvol voldaan',
      RENEGOTIATED: 'Betalingsregeling geannuleerd',
      STOPCOLLECTION: 'Betalingsregeling succesvol voldaan',
      GRACE_PERIOD: 'Betalingsregeling actief',
    },
    noActivePA: {
      title: 'Er is momenteel geen lopende betaalregeling',
    },
    historyPA: {
      title: 'Betaalregelingen historie',
      amount: 'Bedrag',
      startDate: 'Ingangsdatum',
      endDate: 'Einddatum',
      status: 'Status',
      statusSuccess: 'Voldaan',
      statusFailure: 'Afgebroken',
    },
    createPA: {
      title: 'Je komt in aanmerking voor een betaalregeling',
      subTitle: 'Er is een betaalregeling beschikbaar om het openstaande bedrag te voldoen.',
      totalAmount: 'Het totale openstaande bedrag is: <strong>€ #{pastDueAmount}</strong>',
      confirmationEmail: 'We bevestigen deze betaalregeling op je e-mailadres:',
      changeEmail: 'Wijzig e-mailadres',

      noEmail: 'Wil je je emailadres doorgeven voor de bevestiging?',
      fillInEmail: 'Contactgegevens aanvullen',

      chooseNumberOfPayments: '1. Kies aantal termijnen',
      paymentOptions: 'Je kunt kiezen uit de volgende opties:',
      paymentInstallment: 'Betaal in #{installmentCount} van € #{installmentAmount} *',
      paymentClarification: '* De laatste termijn kan een aantal centen afwijken',

      choosePaymentMethod: '2. Kies je betaalmethode',
      automaticPayment: 'Automatische afschrijving van <strong>#{bankAccountNumber}</strong>',
      automaticPaymentNoAccountNumber: 'Automatische afschrijving',
      manualPayment: 'Handmatige betaling',
      changeAccountNumber: 'Wijzig rekeningnummer',
      fillInAccountNumber: 'Voeg eerst een rekeningnummer toe',

      chooseStartDate: '3. Ingangsdatum',
      automaticDate: 'Automatisch over 6 dagen',
      manualDate: 'Kies een datum tussen 6 en 30 dagen vanaf vandaag',
      mouseOverTooltip: 'De ingangsdatum van de 1e termijn kan niet in een weekend of op een feestdag vallen',

      automaticCustomerAgreement:
        'Met de acceptatie van de betaalregeling ga ik akkoord met de volgende voorwaarden en ' +
        'machtig ik Ziggo om de termijnen van mijn bankrekening af te schrijven:',
      manualCustomerAgreement:
        'Met de acceptatie van de betaalregeling ga ik akkoord met' +
        ' <a href="https://www.ziggo.nl/voorwaarden">de voorwaarden</a> van Ziggo.',
      addendumPayEveryMonth:
        '+ Je betaalt elke maand zoals afgesproken. Sla je een betaling over of duurt het langer? ' +
        'Dan vervalt de regeling.',
      addendumPayOnTime: '+  Je bent zelf verantwoordelijk voor het op tijd betalen van nieuwe facturen.',
      addendumStopArrangement: '+ Onstaat er een nieuwe achterstand? Dan stopt de regeling.',
      button: 'Accepteer betaalregeling',

      successTitle: 'Bevestiging ontvangen',
      successDescription:
        'We hebben je verzoek tot betaalregeling goed ontvangen. Je ontvangt een bevestiging per email.',

      errorTitle: 'Er is iets misgegaan met het aanmaken van de betalingsregeling',
      errorStartDateTitle:
        'De ingangsdatum voor deze regeling kan helaas niet binnen 14 dagen plaatsvinden. Kies alsjeblieft een datum van meer dan 14 dagen vanaf vandaag.',
      errorStartDateRange: 'Vul een geldige datum in, tussen 14 en 30 dagen vanaf vandaag.',
    },
    footer: {
      description: 'Uitleg over betaalregelingen',
      url: 'https://www.ziggo.nl/klantenservice/abonnement/factuur/betaalachterstand',
    },
  },
  en: {},
};
