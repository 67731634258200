import styled, { css, gapSize, rem, screenMobilePlusAndSmaller, spacingBase, tablet } from 'theme';

export const SectionHeader = styled('h4')`
  line-height: 1;
  margin: ${() => spacingBase} 0;
  * + & {
    margin-top: ${() => gapSize(2)};
  }

  ${() =>
    tablet(css`
      margin: 0 0 ${gapSize(2)} 0;
      * + & {
        margin-top: ${gapSize(4)};
      }
    `)}

  ${screenMobilePlusAndSmaller(css`
    font-size: ${rem(16)};
  `)}
`;
