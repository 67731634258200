import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { EmailFlyingThroughTheSun } from 'assets/img/EmailFlyingThroughTheSun';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { DetailContentRed, ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { PiiMask } from 'components/PiiMask';
import { content } from 'pages/Products/pages/InternetProducts/pages/EmailSettings/components/SectionAlias/section-alias.content';
import React from 'react';
import { ContentUtil } from 'utils/content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionAlias' });

interface IConfirmRemovalModalProps {
  onCancel(): void;
  onConfirm(): void;
  closeModal: boolean;
  alias: string;
}

export const ConfirmRemovalModal = ({ onCancel, onConfirm, closeModal, alias }: IConfirmRemovalModalProps) => (
  <Modal onClose={onCancel}>
    {(onClose) => {
      if (closeModal) onClose();

      return (
        <section data-testid="confirm-removal-modal">
          <ModalIcon>
            <EmailFlyingThroughTheSun />
          </ModalIcon>
          <h4>{contentUtil.translate('confirmModal.header')}</h4>
          <PiiMask>{contentUtil.translateHtml('confirmModal.content')}</PiiMask>
          <DetailContentRed>{contentUtil.translateHtml('confirmModal.contentRed', { alias })}</DetailContentRed>
          <ModalButtons>
            <Button onClick={onConfirm} testid="button-confirm-removal">
              {contentUtil.translate('confirmModal.confirmButton')}
            </Button>
            <Button onClick={onClose} outline testid="button-cancel-removal" buttonVariant={ButtonVariant.DARK}>
              {contentUtil.translate('confirmModal.cancelButton')}
            </Button>
          </ModalButtons>
        </section>
      );
    }}
  </Modal>
);
