export interface CallBars {
  callBars: string;
  allowChange: boolean;
  reason: CallBarringsReason | null;
}

export enum CallBarsErrorCodes {
  PRODUCT_4 = 'PRODUCT-4',
}

export enum CallBarringsReason {
  FIRST_90_DAYS = 'FIRST_90_DAYS',
  COLLECTION = 'COLLECTION',
  FRAUD = 'FRAUD',
}
