import { gql } from 'graphql-request';

export const getNbaQuery = gql`
  query getNbaList($email: String, $customerNumber: String, $pageId: String!, $treatmentIds: [String!]) {
    getNbaList(email: $email, customerNumber: $customerNumber, pageId: $pageId, treatmentIds: $treatmentIds) {
      id
      type
      icon_src
      image_src
      title
      summary
      content
      primary_button_text
      primary_button_url
      interactionId
      propositionId
      treatmentId
    }
  }
`;

export const setNbaEventQuery = gql`
  mutation setNbaEvent(
    $customerNumber: String!
    $interactionId: String!
    $treatmentId: String!
    $pageId: String!
    $propositionId: String!
  ) {
    setNbaEvent(
      customerNumber: $customerNumber
      interactionId: $interactionId
      treatmentId: $treatmentId
      pageId: $pageId
      propositionId: $propositionId
    ) {
      success
    }
  }
`;
