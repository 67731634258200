import { ApiResponse, query } from './api';
import { getNbaQuery, setNbaEventQuery } from './guru.query';
import { Nba } from './guru.types';

interface GuruInterface {
  getNba(email: string, customerNumber: string, pageId: string, treatmentIds?: string[]): ApiResponse<Nba[]>;
  setNbaEvent(
    customerNumber: string,
    pageId: string,
    interactionId: string,
    treatmentId: string,
    propositionId: string
  ): ApiResponse<{ success: boolean }>;
}

export const GuruApi: GuruInterface = {
  async getNba(email: string, customerNumber: string, pageId: string, treatmentIds?: string[]) {
    return query({
      query: getNbaQuery,
      variables: { email, customerNumber, pageId, treatmentIds },
      name: 'getNbaList',
    }).then(({ getNbaList }) => ({ data: getNbaList }));
  },
  async setNbaEvent(
    customerNumber: string,
    pageId: string,
    interactionId: string,
    treatmentId: string,
    propositionId: string
  ) {
    return query({
      query: setNbaEventQuery,
      variables: { customerNumber, pageId, treatmentId, interactionId, propositionId },
      name: 'setNbaEvent',
    }).then(({ setNbaEvent }) => ({ data: setNbaEvent }));
  },
};
