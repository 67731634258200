import { InvoiceSummary } from 'api';
import { Price } from 'components/Price';
import { InvoicePaymentCard } from 'pages/Invoices/pages/InvoicePayment/components/InvoicePaymentCard';
import React from 'react';
import { ContentUtil } from 'utils/content';
import { DateFormat, dateUtils } from 'utils/date';
import transformers from 'utils/transformers';

import { content } from './invoice-payment-details.content';
import * as S from './invoice-payment-details.styles';

const contentUtil = new ContentUtil({ content, componentName: 'InvoicePaymentDetails' });

interface InvoicePaymentDetailsProps {
  invoice: InvoiceSummary;
}

export const InvoicePaymentDetails = ({ invoice }: InvoicePaymentDetailsProps) => {
  const totalAmount = invoice.totalAmount!;
  const dueAmount = invoice.dueAmount!;
  const payedAmount = totalAmount - dueAmount;

  return (
    <>
      <S.Content>
        <S.Hr />
        <S.SpecificationRow>
          <span>
            {transformers.capitalizeFirstCharOfString(dateUtils.format(invoice.period, DateFormat.MONTH_YEAR))}
          </span>
          <span data-testid="price-totalAmount">
            <Price price={totalAmount} />
          </span>
        </S.SpecificationRow>
        {payedAmount > 0 && (
          <S.SpecificationRow>
            <span>{contentUtil.translate('payedAmount')}</span>
            <span data-testid="price-payedAmount">
              <Price price={payedAmount} />
            </span>
          </S.SpecificationRow>
        )}
        <S.Hr />
        <S.SpecificationRow>
          <strong>{contentUtil.translate('dueAmount')}</strong>
          <strong data-testid="price-dueAmount">
            <Price price={dueAmount} />
          </strong>
        </S.SpecificationRow>
      </S.Content>
      <S.CardBackground>
        <InvoicePaymentCard invoice={invoice} />
      </S.CardBackground>
    </>
  );
};
