import { ApiResponse, query } from './api';
import {
  getUnbilledStreamingEvents,
  getUnbilledStreamingUsage,
  getUnbilledTelephonyEvents,
  getUnbilledTelephonyUsage,
  getUnbilledTelevisionEvents,
  getUnbilledTelevisionUsage,
} from './unbilled-usage.query';
import {
  UnbilledStreamingEvent,
  UnbilledStreamingUsage,
  UnbilledTelephonyEventPage,
  UnbilledTelephonyUsage,
  UnbilledTelevisionEvent,
  UnbilledTelevisionUsage,
} from './unbilled-usage.types';

interface UnbilledUsageApiInterface {
  getUnbilledTelephonyUsage(billingCustomerId: string): ApiResponse<UnbilledTelephonyUsage[]>;

  getUnbilledTelephonyEvents(
    billingCustomerId: string,
    assignedProductId: string,
    pageSize: number,
    pageOffset: number,
    dateFrom?: string,
    dateTo?: string,
    durationFrom?: string,
    durationTo?: string,
    priceFrom?: number,
    priceTo?: number,
    destinationNumber?: string
  ): ApiResponse<UnbilledTelephonyEventPage>;

  getUnbilledTelevisionUsage(billingCustomerId: string): ApiResponse<UnbilledTelevisionUsage[]>;

  getUnbilledTelevisionEvents(
    billingCustomerId: string,
    assignedProductId: string
  ): ApiResponse<UnbilledTelevisionEvent[]>;

  getUnbilledStreamingUsage(billingCustomerId: string): ApiResponse<UnbilledStreamingUsage[]>;
  getUnbilledStreamingEvents(billingCustomerId: string): ApiResponse<UnbilledStreamingEvent[]>;
}

const UnbilledUsageApi: UnbilledUsageApiInterface = {
  getUnbilledTelephonyEvents(
    billingCustomerId: string,
    assignedProductId: string,
    pageSize: number,
    pageOffset: number,
    dateFrom?: string,
    dateTo?: string,
    durationFrom?: string,
    durationTo?: string,
    priceFrom?: number,
    priceTo?: number,
    destinationNumber?: string
  ): ApiResponse<UnbilledTelephonyEventPage> {
    return query({
      query: getUnbilledTelephonyEvents,
      variables: {
        billingCustomerId,
        assignedProductId,
        pageSize,
        pageOffset,
        dateFrom,
        dateTo,
        durationFrom,
        durationTo,
        priceFrom,
        priceTo,
        destinationNumber,
      },
      name: 'getUnbilledTelephonyEvents',
    }).then(({ getUnbilledTelephonyEvents }) => ({ data: getUnbilledTelephonyEvents }));
  },

  getUnbilledTelephonyUsage(billingCustomerId: string): ApiResponse<UnbilledTelephonyUsage[]> {
    return query({
      query: getUnbilledTelephonyUsage,
      variables: { billingCustomerId },
      name: 'getUnbilledTelephonyUsage',
    }).then(({ getUnbilledTelephonyUsage }) => ({ data: getUnbilledTelephonyUsage }));
  },

  getUnbilledTelevisionEvents(
    billingCustomerId: string,
    assignedProductId: string
  ): ApiResponse<UnbilledTelevisionEvent[]> {
    return query({
      query: getUnbilledTelevisionEvents,
      variables: { billingCustomerId, assignedProductId },
      name: 'getUnbilledTelevisionEvents',
    }).then(({ getUnbilledTelevisionEvents }) => ({ data: getUnbilledTelevisionEvents }));
  },

  getUnbilledTelevisionUsage(billingCustomerId: string): ApiResponse<UnbilledTelevisionUsage[]> {
    return query({
      query: getUnbilledTelevisionUsage,
      variables: { billingCustomerId },
      name: 'getUnbilledTelevisionUsage',
    }).then(({ getUnbilledTelevisionUsage }) => ({ data: getUnbilledTelevisionUsage }));
  },

  getUnbilledStreamingUsage(billingCustomerId: string): ApiResponse<UnbilledStreamingUsage[]> {
    return query({
      query: getUnbilledStreamingUsage,
      variables: { billingCustomerId },
      name: 'getUnbilledStreamingUsage',
    }).then(({ getUnbilledStreamingUsage }) => ({ data: getUnbilledStreamingUsage }));
  },

  getUnbilledStreamingEvents(billingCustomerId: string): ApiResponse<UnbilledStreamingEvent[]> {
    return query({
      query: getUnbilledStreamingEvents,
      variables: { billingCustomerId },
      name: 'getUnbilledStreamingEvents',
    }).then(({ getUnbilledStreamingEvents }) => ({ data: getUnbilledStreamingEvents }));
  },
};

export { UnbilledUsageApi };
