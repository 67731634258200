import { SupportCaseStatus } from 'api/support-case.types';
import { ClockIcon, LockIcon } from 'assets/icons';
import React from 'react';
import { ContentUtil } from 'utils';

import content from './support-case-status.content';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseStatus' });

const formatSupportCaseStatusUtil = (status: SupportCaseStatus) => {
  const { OPEN, CLOSED, AWAITING_RESPONSE } = SupportCaseStatus;

  const formattedSupportCases = {
    [OPEN]: {
      icon: <ClockIcon />,
      value: contentUtil.translateToString(OPEN),
    },
    [CLOSED]: {
      icon: <LockIcon />,
      value: contentUtil.translateToString(CLOSED),
    },
    [AWAITING_RESPONSE]: {
      icon: <ClockIcon />,
      value: contentUtil.translateToString(AWAITING_RESPONSE),
    },
  };

  return (
    <>
      {formattedSupportCases[status].icon} {formattedSupportCases[status].value}
    </>
  );
};

export default formatSupportCaseStatusUtil;
