import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { ContentBackground } from 'components/ContentBackground';
import { PageHeader } from 'components/PageHeader';
import { useFeature } from 'context/feature/feature.context';
import { InvoicesContext } from 'context/invoices.context';
import { FEATURES } from 'models/features.model';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ContentUtil } from 'utils/content';

import { ActivePaymentArrangement } from './ActivePaymentArrangement';
import { CreatePaymentArrangement } from './CreatePaymentArrangement';
import { PaymentArrangementFooter } from './PaymentArrangementFooter';
import { PaymentArrangementHistory } from './PaymentArrangementHistory';
import { content } from './payment-arrangement.content';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentArrangementOverview' });

export const PaymentArrangementOverview = () => {
  const { paymentArrangement, createPaymentArrangement, verifyPaymentArrangement } = useContext(InvoicesContext);
  const isPaymentArrangementEnabled = useFeature(FEATURES.PAYMENT_ARRANGEMENT_ENABLED);

  useEffect(() => {
    if (!paymentArrangement) verifyPaymentArrangement();
  }, [paymentArrangement, verifyPaymentArrangement]);

  if (!isPaymentArrangementEnabled || !paymentArrangement) return null;

  const { activePaymentArrangement, recommendedPaymentArrangement, paymentArrangementHistory } = paymentArrangement;

  return (
    <>
      <React.Fragment>
        <ContentBackground>
          <Helmet>
            <title>{contentUtil.translateToString('pageTitle')}</title>
          </Helmet>
          <PageHeader title={contentUtil.translateToString('pageTitle')} />
          <Wrapper>
            {paymentArrangement.isEligible && !activePaymentArrangement && recommendedPaymentArrangement && (
              <CreatePaymentArrangement
                paymentArrangement={paymentArrangement}
                createPaymentArrangement={createPaymentArrangement}
                verifyPaymentArrangement={verifyPaymentArrangement}
                recommendedInstallments={recommendedPaymentArrangement?.installments}
              />
            )}
            {!paymentArrangement.isEligible && (
              <ActivePaymentArrangement activePaymentArrangement={activePaymentArrangement} />
            )}
            {!paymentArrangement.isEligible && paymentArrangementHistory && (
              <PaymentArrangementHistory paymentArrangementHistory={paymentArrangementHistory} />
            )}
          </Wrapper>
        </ContentBackground>
      </React.Fragment>
      <PaymentArrangementFooter />
    </>
  );
};
