import React from 'react';

interface ProviderProviderProps {
  children: React.ReactNode;
  providers: React.FunctionComponent[];
}

export const ProviderProvider = ({ children, providers }: ProviderProviderProps) => {
  const [Provider, ...remainingProviders] = providers;

  if (remainingProviders.length) {
    return (
      // @ts-expect-error TS(2559): Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message
      <Provider>
        <ProviderProvider providers={remainingProviders}>{children}</ProviderProvider>
      </Provider>
    );
  }

  // @ts-expect-error TS(2559): Type '{ children: ReactNode; }' has no properties ... Remove this comment to see the full error message
  return <Provider>{children}</Provider>;
};
