import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    title: 'Oeps, dat ging mis...',
    cardTitle: 'We hebben het wel <em>404</em> keer geprobeerd, maar niet gevonden wat je zocht.',
    backToPortal: 'Terug naar Dashboard',
  },
  en: {},
};
