import { AxiosResponse } from 'axios';
import { useState } from 'react';

export const useRest = (defaults?: { isLoading: boolean }) => {
  const [isLoading, setLoading] = useState((defaults && defaults.isLoading) || false);
  const [hasError, setError] = useState(false);

  // helper function to handle all the setError and setLoadings
  const doRest = (call: () => Promise<any>, onSuccess: (response: AxiosResponse<any>) => void) => {
    setLoading(true);
    setError(false);
    // Do the actual call
    return (
      call()
        // when the response was successful set the callBar state
        .then((response) => onSuccess(response))
        // in case of error set the error state, note that this allows the promise chain to continue to the last statement
        .catch(() => setError(true))
        // clear the loading flag as it is done processing the api call
        .then(() => setLoading(false))
    );
  };
  return { isLoading, hasError, doRest };
};
