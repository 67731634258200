import { ResponseType } from 'axios';
import { SESSION_STORAGE_KEYS, SessionStorage } from 'utils';
import api from 'utils/api';
import { v4 as uuid } from 'uuid';

export type ApiResponse<T> = Promise<{ data: T }>;

interface GraphQLQuery {
  /** GraphQL query string */
  query: string;
  /** Object with variables that GraphQL will parse into the query */
  variables?: Record<string, unknown>;
  /** Name or identifier that is passed as a query parameter to the request for easier debugging */
  name: string;
  headers?: Record<string, unknown>;
}

export const query = ({ query, variables = {}, name, headers }: GraphQLQuery, authenticated = true) => {
  return api.apiV1.getFromGraphQL(
    {
      url: name,
      data: {
        query,
        variables,
      },
      headers: {
        ...headers,
        agentId: SessionStorage.get(SESSION_STORAGE_KEYS.agentId) || '',
        'Correlation-Id': uuid(),
      },
    },
    authenticated
  );
};

interface RestOptions {
  url: string;
  method: string;
  params?: Record<string, unknown>;
  data?: Record<string, unknown>;
  headers?: Record<string, string>;
  authenticated?: boolean;
  responseType?: ResponseType | undefined;
}

export const rest = ({ url, method, params, headers, data, authenticated, responseType }: RestOptions) => {
  return api.apiV1.getFromRest(
    {
      url,
      method,
      params,
      data,
      headers: {
        'Content-Type': 'application/json',
        'Correlation-Id': uuid(),
        ...headers,
      },
      responseType,
    },
    authenticated
  );
};
