import { GuruApi, Nba } from 'api';
import { AuthContext } from 'context/auth/auth.context';
import { BillingCustomerContext } from 'context/billing-customer.context';
import React, { createContext, useContext, useState } from 'react';
import { PageIds, getGuruPageId } from 'utils/guru';

export interface IGuruContext {
  isLoading: boolean;
  hasError: boolean;
  nbas: Nba[];
  fetch(treatmentIds?: string[], pageId?: PageIds): void;
  setNbaEvent(treatmentId: string, interactionId: string, propositionId: string, pageId?: PageIds): void;
}

interface GuruProviderProps {
  children: JSX.Element;
}

const GuruContext = createContext<IGuruContext | undefined>(undefined);

const useGuruContext = () => useContext(GuruContext) as IGuruContext;

const GuruProvider: React.FC = ({ children }: GuruProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const authContext = useContext(AuthContext);

  const { activeBcId } = billingCustomerContext;
  const { email = '' } = authContext;
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [nbas, setNbas] = useState<undefined | Nba[]>(undefined);

  const fetch = (treatmentIds: string[], pageId?: PageIds) => getAttachedNbas(treatmentIds, pageId);

  const getAttachedNbas = async (treatmentIds: string[], pageId?: PageIds) => {
    setLoading(true);
    try {
      const response = await GuruApi.getNba(email, activeBcId, getGuruPageId(pageId), treatmentIds);
      setNbas(response?.data);
    } catch (ex) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const setNbaEvent = (treatmentId: string, interactionId: string, propositionId: string, pageId?: PageIds) => {
    GuruApi.setNbaEvent(activeBcId, getGuruPageId(pageId), interactionId, treatmentId, propositionId);
  };

  return (
    <GuruContext.Provider
      value={{
        isLoading,
        // @ts-expect-error TS(2322): Type 'Nba[] | undefined' is not assignable to type... Remove this comment to see the full error message
        nbas,
        hasError,
        fetch,
        setNbaEvent,
      }}>
      {children}
    </GuruContext.Provider>
  );
};

export { GuruContext, GuruProvider, useGuruContext };
