const iso8601DurationRegex =
  /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

export class Duration {
  positive: boolean;

  years: number;

  months: number;

  weeks: number;

  days: number;

  hours: number;

  minutes: number;

  seconds: number;

  constructor(iso8601Duration: string) {
    const matches = iso8601Duration.match(iso8601DurationRegex) || [];

    Object.assign(this, {
      positive: matches[1] === undefined,
      years: matches[2] === undefined ? 0 : +matches[2],
      months: matches[3] === undefined ? 0 : +matches[3],
      weeks: matches[4] === undefined ? 0 : +matches[4],
      days: matches[5] === undefined ? 0 : +matches[5],
      hours: matches[6] === undefined ? 0 : +matches[6],
      minutes: matches[7] === undefined ? 0 : +matches[7],
      seconds: matches[8] === undefined ? 0 : +matches[8],
    });
  }

  toString() {
    const s = (total: number, separator: string) => (total ? `${total}${separator}` : '');
    const positive = this.positive ? '' : '-';
    const Y = s(this.years, 'Y');
    const M = s(this.months, 'M');
    const W = s(this.weeks, 'W');
    const D = s(this.days, 'D');
    const H = s(this.hours, 'H');
    const MI = s(this.minutes, 'M');
    const S = `${this.seconds}S`;
    return `${positive}P${Y}${M}${W}${D}T${H}${MI}${S}`;
  }

  static fromSeconds(seconds: number) {
    const duration = new Duration('');
    duration.hours = Math.floor(seconds / 3600);
    duration.minutes = Math.floor((seconds % 3600) / 60);
    duration.seconds = seconds % 60;
    return duration;
  }
}
