import { Link, NavLink } from 'components/Button/button.styles';
import { Card as CardComponent } from 'components/Card';
import { SectionHeader } from 'components/SectionHeader';
import styled, { contentMargins, css, gapSize, hoverFocus, rem, screenTabletLandscapeAndBigger } from 'theme';

export const SideCard = styled(CardComponent)`
  display: flex;
  flex-direction: column;

  > svg {
    align-self: center;
  }

  > p:first-child {
    margin-top: 0;
  }

  > a {
    font-weight: ${(p) => p.theme.fonts.weights.bold};
  }
`;

export const RowContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  > section {
    margin-bottom: ${rem(8)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Side = styled('div')`
  a:not(${/* sc-selector */ Link}):not(${/* sc-selector */ NavLink}) {
    text-decoration: none;
    transition: color ${(p) => p.theme.transitions.default};

    ${hoverFocus(css`
      text-decoration: underline;
    `)}
  }

  ${screenTabletLandscapeAndBigger(css`
    align-self: flex-start;
    flex: 3 0 auto;
  `)};
`;

export const Wrapper = styled('section')`
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  flex: 1 0 auto;

  ${screenTabletLandscapeAndBigger(css`
    flex: 9 0 auto;
  `)};

  & > ${SectionHeader} {
    ${contentMargins(2, 1, 0.5, 1)}
  }
`;

export const ContentCard = styled(CardComponent)`
  overflow: hidden;
`;

export const CardHr = styled('hr')`
  margin: ${() => gapSize(2)} 0 ${() => gapSize(2)} ${() => gapSize(-2)};
  width: calc(100% + ${() => gapSize(4)});
  border: ${rem(1)} solid ${(p) => p.theme.colors.grayL40};
  border-width: ${rem(1)} 0;
`;

export const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${screenTabletLandscapeAndBigger(css`
    flex-direction: row;

    ${Side},
    ${Wrapper} {
      flex-basis: 0;
    }
  `)};
`;
