import React, { SVGProps } from 'react';

export const InvoiceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg role="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M19,8V22H7a2,2,0,0,1-2-2V2h8V6a2,2,0,0,0,2,2ZM15,7h4L14,2V6A1,1,0,0,0,15,7ZM13.29,19.06a3.62,3.62,0,0,1-3.87-2.78H8.24V15.11h1c0-.14,0-.28,0-.42a3.66,3.66,0,0,1,0-.38h-1V13.15H9.42a3.62,3.62,0,0,1,3.78-2.83,6.39,6.39,0,0,1,1.82.24L14.67,12a4.42,4.42,0,0,0-1.26-.19,2,2,0,0,0-2.16,1.34H14l-.13,1.17H11a3.68,3.68,0,0,0,0,.38c0,.14,0,.29,0,.42H13.8l-.1,1.17H11.28a2.05,2.05,0,0,0,2.12,1.29,4.9,4.9,0,0,0,1.45-.2l.28,1.41A5.61,5.61,0,0,1,13.29,19.06Z"
      fillRule="evenodd"
    />
  </svg>
);
