import { CheckIcon } from 'assets/icons';
import { checkPassword } from 'components/Form/validate';
import React from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './password-checklist.content';
import * as S from './password-checklist.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PasswordChecklist' });

interface IPasswordChecklistProps {
  password: string;
}

export const PasswordChecklist = ({ password }: IPasswordChecklistProps) => {
  const passwordChecks = checkPassword(password);

  return (
    <S.PasswordChecklist className="password-checklist">
      <S.Title>{contentUtil.translate('policy')}</S.Title>
      <S.Check check={passwordChecks.length}>
        {contentUtil.translate('length')}
        <CheckIcon />
      </S.Check>
      <S.Check check={passwordChecks.lowerCase}>
        {contentUtil.translate('lowerCase')}
        <CheckIcon />
      </S.Check>
      <S.Check check={passwordChecks.upperCase}>
        {contentUtil.translate('upperCase')}
        <CheckIcon />
      </S.Check>
      <S.Check check={passwordChecks.number}>
        {contentUtil.translate('number')}
        <CheckIcon />
      </S.Check>
      <S.Check check={passwordChecks.specialCharacter}>
        {contentUtil.translate('specialCharacter')}
        <CheckIcon />
      </S.Check>
    </S.PasswordChecklist>
  );
};
