import styled from 'theme';

export const Attachment = styled.a`
  font-weight: 700;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg {
    position: relative;
  }
`;
