import React from 'react';

export const PhoneIcon = ({ fill = '#000', width, height }: { fill?: string; width?: string; height?: string }) => (
  <svg role="none" viewBox="0 0 17 22" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      d="M9.5336 5.3505c.2065.4396.0707.8365-.3517 1.033-.4249.1996-.8171.0507-1.0236-.3898L5.8746 1.1291C5.665.6839 5.7992.283 6.224.085c.4233-.1982.817-.0444 1.026.4015l2.2836 4.8639zm7.0616 14.2355c.2057.4396.0698.8365-.3526 1.033-.4248.1995-.8164.0498-1.0236-.3899l-2.2839-4.8643c-.2089-.4451-.0746-.846.3495-1.044.4224-.1988.817-.0452 1.026.4008l2.2846 4.8644zm-5.4154-4.821c.2209.0725.35.2542.4485.462l2.4134 5.0345c.1992.4164.015.9142-.411 1.1089-1.3372.612-2.5919.735-3.733.5517-.5178-.2045-1.098-.7872-.4553-1.8243.7903.2574 1.6815.3332 2.6627.057l-1.7632-3.6788c-.8353-.0252-2.1375-.479-3.1987-2.6928l-1.7115-3.5696c-.5335-1.1163-.6478-2.5095.1367-3.3667L3.9027 3.371C2.709 4.3064.6931 6.5112 2.3288 9.9251l3.373 7.0373c.1267.2054.8295 1.2923 1.9707 2.1764.1551.2534.3393.6868.0677 1.0339-.5362.6836-1.1664.2379-1.1664.2379l-.07-.0496c-1.0354-.8157-1.8249-1.7959-2.296-2.5886a.6264.6264 0 0 1-.034-.066l-3.3906-7.074c-2.2992-4.7974.9953-8.1397 3.0587-9.2363.2042-.1125.451-.1312.6744-.0596.2234.0717.4068.2298.507.4392l2.3825 4.971c.1992.4172.0159.9134-.411 1.1089-.6127.2803-.0225 1.6369-.0159 1.6508l1.71 3.568c.9453 1.9725 1.8348 1.7345 1.8723 1.724.2126-.1084.396-.105.6186-.0334z"
      fillRule="evenodd"
      fill={fill}
    />
  </svg>
);
