// TODO check if this needs to be changesd sans _
export enum AddressInfoTypeEnum {
  BILLINGCUSTOMER = 'BILLING_CUSTOMER',
  BILLINGARRANGEMENT = 'BILLING_ARRANGEMENT',
  ASSIGNEDPRODUCT = 'ASSIGNED_PRODUCT',
}

export enum AddressCountryEnum {
  NLD = 'NLD',
  DEU = 'DEU',
  BEL = 'BEL',
}

export interface Address {
  id: string;
  streetName: string | null;
  houseNumber: number | null;
  houseNumberExtension: string | null;
  roomNumber: string | null;
  /** Postcode of address in NLD must have a space. E.g. \'9999 AA\'. */
  postCode: string;
  city: string;
  country: AddressCountryEnum;
}

export interface AddressInfo {
  id: string;
  type: AddressInfoTypeEnum;
  address: Address;
  canEditCountry?: boolean;
}
