import React from 'react';

export const ZDeviceIcon = () => (
  <svg role="none" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28625 27.9C4.28625 28.9508 4.71375 29.9048 5.4045 30.5933C6.09525 31.284 7.047 31.7115 8.09775 31.7115H27.9023C28.953 31.7115 29.907 31.284 30.5955 30.5933C31.2863 29.9048 31.7138 28.9508 31.7138 27.9V18L31.7115 11.8778C31.7115 9.855 33.7523 7.97175 36 7.96725V18V27.9C36 30.1365 35.0933 32.1615 33.6285 33.6263C32.1638 35.091 30.1388 36 27.9023 36H8.09775C5.8635 36 3.8385 35.091 2.3715 33.6263C0.90675 32.1615 0 30.1365 0 27.9V8.0955C0 5.86125 0.90675 3.83625 2.3715 2.36925C3.8385 0.9045 5.8635 0 8.09775 0H18H27.9C28.9958 0 30.0442 0.216 30.9982 0.61425C32.3078 1.15425 33.1447 1.82475 33.9525 2.69775C34.272 3.04425 34.5802 3.5595 34.5802 4.21425C34.5802 4.761 34.371 5.31 33.9525 5.7285C33.534 6.147 32.985 6.35625 32.436 6.35625C31.8893 6.35625 31.3335 6.14025 30.9217 5.7285C30.348 5.157 30.0353 4.8645 29.3805 4.5855C28.926 4.392 28.4242 4.28625 27.9 4.28625H18H8.09775C7.04925 4.28625 6.09525 4.7115 5.4045 5.4045C4.71375 6.09525 4.28625 7.04475 4.28625 8.0955V27.9ZM27.8347 28.0305C27.7582 26.4667 26.703 24.0592 24.048 23.742H14.6025L25.4835 13.3245C25.9425 12.888 26.9753 11.4008 26.2418 9.801C25.6523 8.51625 24.6645 7.96725 22.9388 7.96725H9.3825C9.43875 9.765 10.3342 11.9137 12.7192 12.2962H20.466L9.018 23.0557C8.55225 23.5012 7.7895 24.8715 8.3925 26.262C8.937 27.5175 9.85275 28.0305 11.556 28.0305H27.8347Z"
    />
  </svg>
);
