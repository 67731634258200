import { Card } from 'components/Card';
import { Checkbox, Form } from 'components/Form';
import { Link } from 'components/Link';
import styled, { css, rem, tablet } from 'theme';

export const PaymentArrangementForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PaymentArrangementCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  flex-grow: 1;
`;

export const EmailContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: ${rem(8)} 0 ${rem(24)};
`;

export const EmailTitle = styled.div`
  font-weight: bold;
  margin-right: ${rem(32)};
`;

export const ChangeEmail = styled(Link)`
  font-weight: bold;
`;

export const AgreementCheckbox = styled(Checkbox)`
  margin: ${rem(32)} 0 ${rem(8)};
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const HistoryTitle = styled.div`
  font-weight: bold;
  margin-bottom: ${rem(16)};
`;

export const OverviewTitle = styled.div`
  font-weight: bold;
`;

export const ActiveOverviewTitle = styled.div`
  font-weight: bold;
  margin-top: ${rem(16)};
`;

export const DetailsTitle = styled.div`
  margin-top: ${rem(32)};
  margin-bottom: ${rem(16)};
`;

export const Text = styled.div`
  margin-bottom: ${rem(8)};
`;

export const RadioContent = styled.div`
  font-size: ${rem(14)};
  margin: 0 0 ${rem(8)} ${rem(20)};

  ${() =>
    tablet(css`
      font-size: ${rem(16)};
    `)}

  > label {
    font-size: ${rem(14)};
    align-items: center;
    margin-bottom: ${rem(8)};

    ${() =>
      tablet(css`
        font-size: ${rem(16)};
      `)}
  }
`;

interface StyledInputProps {
  error?: boolean;
}

export const DatePicker = styled.div<StyledInputProps>`
  margin: ${rem(8)} 0 0 ${rem(28)};

  > input {
    flex-direction: row-reverse;
    border-radius: ${rem(8)};
    border-width: 1px;
    padding: ${rem(8)};
    width: ${rem(200)};
    height: ${rem(38)};
    ${(p) => !!p.error && `border-bottom: ${rem(2)} solid ${p.theme.colors.red};`}
  }
`;

export const ChangeAccountNumberContent = styled(RadioContent)`
  display: flex;
  flex-direction: column;
  font-size: ${rem(14)};
  margin: 0 0 ${rem(8)};

  ${() =>
    tablet(css`
      flex-direction: column;
      font-size: ${rem(16)};
    `)}
`;

export const FillEmailAddressLink = styled(Link)`
  flex: none;
`;

export const ChangeAccountNumberLink = styled(Link)`
  margin-left: 0;
  flex: none;

  ${() =>
    tablet(css`
      margin-left: ${rem(28)};
    `)}
`;

export const AddendumText = styled.div`
  margin-left: ${rem(28)};
`;

export const Termin = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(8)};
`;

export const TerminDescription = styled.div`
  margin-left: ${rem(32)};
`;

export const TerminTitle = styled.div`
  flex-shrink: 0;
`;

export const HistoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  > span {
    padding: 4px;
  }
`;

export const NoActivePaymentArrangement = styled(Card)`
  display: flex;
  padding: ${rem(24)};
`;

export const NoActivePATitle = styled.div`
  font-weight: bold;
`;
