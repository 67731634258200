import { ApiResponse, query } from './api';
import * as Q from './wifi-spots.query';
import * as T from './wifi-spots.types';

interface WifiSpotApiInterface {
  getWifiRoamingStatus(billingCustomerId: string, username: string): ApiResponse<T.IWifiRoamingStatus | null>;
  getWifiSpot(billingCustomerId: string, username: string): ApiResponse<T.WifiSpot>;
  patchWifiSpot(billingCustomerId: string, username: string, args?: T.WifiSpotStatus): ApiResponse<T.WifiSpot>;
  resetWifiSpotPassword(billingCustomerId: string, username: string): ApiResponse<T.WifiSpot>;
}

export const WifiSpotApi: WifiSpotApiInterface = {
  async getWifiRoamingStatus(billingCustomerId: string, username: string): ApiResponse<T.IWifiRoamingStatus> {
    const { getWifiRoamingStatus } = await query({
      query: Q.getWifiRoamingStatusQuery,
      variables: { billingCustomerId, username },
      name: 'getWifiRoamingStatus',
    });
    return { data: getWifiRoamingStatus };
  },

  async getWifiSpot(billingCustomerId: string, username: string): ApiResponse<T.WifiSpot> {
    const { getWifiSpot } = await query({
      query: Q.getWifiSpotQuery,
      variables: { billingCustomerId, username },
      name: 'getWifiSpot',
    });
    return { data: getWifiSpot };
  },
  async patchWifiSpot(billingCustomerId: string, username: string, args?: T.WifiSpotStatus): ApiResponse<T.WifiSpot> {
    const { patchWifiSpot } = await query({
      query: Q.changeWifiSpotQuery,
      variables: { billingCustomerId, ...args, username },
      name: 'patchWifiSpot',
    });
    return { data: patchWifiSpot };
  },
  async resetWifiSpotPassword(billingCustomerId: string, username: string): ApiResponse<T.WifiSpot> {
    const { resetWifiSpotPassword } = await query({
      query: Q.resetWifiSpotPasswordQuery,
      variables: { billingCustomerId, username },
      name: 'resetWifiSpotPassword',
    });
    return { data: resetWifiSpotPassword };
  },
};
