import { ApiResponse, query } from './api';
import { getDaypassesQuery } from './daypass.query';
import { DaypassProvider } from './daypass.types';

interface DaypassApiInterface {
  getDaypasses(): ApiResponse<DaypassProvider[]>;
}

const DaypassApi: DaypassApiInterface = {
  getDaypasses() {
    return query({
      query: getDaypassesQuery,
      name: 'getDaypasses',
    }).then(({ getDaypasses }) => ({ data: getDaypasses }));
  },
};

export { DaypassApi };
