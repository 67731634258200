import { ArrowIcon } from 'assets/icons';
import React from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './payment-pending.content';
import * as S from './payment-pending.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentPending' });

export const PaymentPending = () => {
  return (
    <S.PageWrapper data-testid="payment-pending">
      <S.Title>
        <h1>{contentUtil.translate('title')}</h1>
      </S.Title>
      <S.Content>{contentUtil.translateHtml('content')}</S.Content>
      <S.Link to="/facturen">
        <ArrowIcon />
        {contentUtil.translate('linkText')}
      </S.Link>
    </S.PageWrapper>
  );
};
