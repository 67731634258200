import { ApiResponse, query } from './api';
import { getPhoneSettingsQuery, postPhoneSettingsQuery } from './phone-settings.query';
import { PhoneSettings } from './phone-settings.types';

interface PhoneSettingsApiInterface {
  getPhoneSettings(bcId: string, apId: string): ApiResponse<PhoneSettings>;
  postPhoneSettings(bcId: string, apId: string, phoneSettings?: PhoneSettings): ApiResponse<{}>;
}

export const PhoneSettingsApi: PhoneSettingsApiInterface = {
  getPhoneSettings(billingCustomerId: string, assignedProductId: string) {
    return query({
      query: getPhoneSettingsQuery,
      variables: { billingCustomerId, assignedProductId },
      name: 'getPhoneSettings',
    }).then(({ getPhoneSettings }) => ({ data: getPhoneSettings }));
  },
  postPhoneSettings(billingCustomerId: string, assignedProductId: string, phoneSettings?: PhoneSettings) {
    return query({
      query: postPhoneSettingsQuery,
      variables: { billingCustomerId, assignedProductId, phoneSettings },
      name: 'postPhoneSettings',
    }).then(({ postPhoneSettings }) => ({ data: postPhoneSettings }));
  },
};
