import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { Button } from 'components/Button';
import { Form, Input } from 'components/Form';
import { InlineAlert } from 'components/InlineAlert';
import { PiiMask } from 'components/PiiMask';
import { Restricted } from 'components/Restricted';
import { NONE_CEASED_CUSTOMER } from 'config/access-state-features.config';
import { useEmailContext } from 'context/email/email.context';
import * as S from 'pages/Products/pages/InternetProducts/pages/EmailSettings/email-settings.styles';
import React, { useState } from 'react';
import { ContentUtil } from 'utils/content';

import { ConfirmRemovalModal } from './components/ConfirmRemovalModal';
import { RemovalFailedModal } from './components/RemovalFailedModal';
import { RemovalSuccessModal } from './components/RemovalSuccessModal';
import { content } from './section-alias.content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionAlias' });

interface ISectionAliasProps {
  emailAddress: string;
  aliases: string[];
  maxAliasesPerEmailBoxAllowed: number;
}

export const SectionAlias = ({ emailAddress, aliases, maxAliasesPerEmailBoxAllowed }: ISectionAliasProps) => {
  const [createAliasSuccess, setCreateAliasSuccess] = useState(false);
  const [createAliasFailed, setCreateAliasFailed] = useState(false);
  const [emailAddressInUse, setEmailAddressInUse] = useState(false);
  const [deleteAliasSuccess, setDeleteAliasSuccess] = useState(false);
  const [deleteAliasFailed, setDeleteAliasFailed] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [aliasToDelete, setAliasToDelete] = useState<string | null>(null);
  const { deleteEmailAddressAlias, createEmailAddressAlias } = useEmailContext();

  const deleteAlias = async () => {
    if (!aliasToDelete) return;
    try {
      await deleteEmailAddressAlias(emailAddress, aliasToDelete);
      setShowConfirmationModal(false);
      setDeleteAliasSuccess(true);
      setDeleteAliasFailed(false);
    } catch (e) {
      setShowConfirmationModal(false);
      setDeleteAliasSuccess(false);
      setDeleteAliasFailed(true);
    }
    setCreateAliasSuccess(false);
    setCreateAliasFailed(false);
    setEmailAddressInUse(false);
  };

  const onAliasCreateSubmit = (data: { alias: string }) => {
    return createEmailAddressAlias(emailAddress, data.alias.toLowerCase())
      .then((success) => {
        if (success) {
          setCreateAliasSuccess(true);
          setCreateAliasFailed(false);
          setEmailAddressInUse(false);
        } else {
          setCreateAliasFailed(true);
          setEmailAddressInUse(false);
        }
      })
      .catch((e) => {
        if (e.message === 'address not available') {
          setEmailAddressInUse(true);
          setCreateAliasFailed(false);
        } else {
          setCreateAliasFailed(true);
          setEmailAddressInUse(false);
        }
        setCreateAliasSuccess(false);
      });
  };

  return (
    <>
      {contentUtil.translateHtml('aliasesContent')}
      <br />
      {aliases.map((alias) => (
        <PiiMask key={alias}>
          <S.EmailAddress data-testid={`email-alias-${alias}`}>
            {alias}
            <Button
              onClick={() => {
                setAliasToDelete(alias);
                setShowConfirmationModal(true);
              }}
              buttonVariant={ButtonVariant.LINK}
              testid="button-delete-alias">
              {contentUtil.translate('delete')}
            </Button>
          </S.EmailAddress>
        </PiiMask>
      ))}
      <Restricted permissions={NONE_CEASED_CUSTOMER}>
        {aliases.length < maxAliasesPerEmailBoxAllowed && (
          <S.Form key={aliases.length} emailDomain={emailAddress.split('@')[1]}>
            <Form
              id="create-email-alias-form"
              testid="create-email-alias-form"
              hideCancelButton
              onSubmit={onAliasCreateSubmit}
              buttonText={contentUtil.translateToString('add')}
              render={(_, validators, validateItem) => (
                <>
                  {createAliasSuccess && (
                    <InlineAlert
                      type="success"
                      onClose={() => setCreateAliasSuccess(false)}
                      testid="create-alias-success">
                      {contentUtil.translateToString('successText')}
                    </InlineAlert>
                  )}
                  {createAliasFailed && (
                    <InlineAlert type="error" onClose={() => setCreateAliasFailed(false)} testid="create-alias-failed">
                      {contentUtil.translateToString('errorText')}
                    </InlineAlert>
                  )}
                  {emailAddressInUse && (
                    <InlineAlert type="error" onClose={() => setEmailAddressInUse(false)} testid="email-address-in-use">
                      {contentUtil.translateToString('emailAddressInUse')}
                    </InlineAlert>
                  )}
                  <Input
                    name="alias"
                    className="input-email"
                    label={contentUtil.translate('aliasLabel')}
                    autoComplete="new-password"
                    id="alias"
                    testid="alias"
                    validate={[validators.required, validators.emailUserName, validators.emailUserNameMaxLength]}
                    error={validateItem('alias')}
                  />
                </>
              )}
            />
          </S.Form>
        )}
      </Restricted>
      {showConfirmationModal && aliasToDelete && (
        <ConfirmRemovalModal
          onConfirm={() => deleteAlias()}
          onCancel={() => setShowConfirmationModal(false)}
          closeModal={deleteAliasFailed || deleteAliasSuccess}
          alias={aliasToDelete}
        />
      )}
      {deleteAliasFailed && <RemovalFailedModal closeModal={() => setDeleteAliasFailed(false)} />}
      {deleteAliasSuccess && aliasToDelete && (
        <RemovalSuccessModal
          alias={aliasToDelete}
          onClose={() => {
            setDeleteAliasSuccess(false);
          }}
        />
      )}
    </>
  );
};

export default SectionAlias;
