import { ApiResponse, query } from './api';
import * as Q from './assigned-product.query';
import * as T from './assigned-product.types';

interface AssignedProductApiInterface {
  getFullTVProducts(billingCustomerId: string): ApiResponse<T.TelevisionProduct[]>;
  getFullInternetProducts(billingCustomerId: string): ApiResponse<T.InternetProduct[]>;
  getFullTelephonyProducts(billingCustomerId: string): ApiResponse<T.TelephonyProduct[]>;
  getAssignedProductsDashboard(billingCustomerId: string): ApiResponse<T.Products>;
  getAssignedProductsOverview(billingCustomerId: string): ApiResponse<T.Products>;
  resetPincode(billingCustomerId: string, productId: string): ApiResponse<{ success: boolean }>;
  refreshEntitlements(
    billingCustomerId: string,
    productId: string,
    subProductId: string
  ): ApiResponse<{ success: boolean }>;
}

export const AssignedProductApi: AssignedProductApiInterface = {
  getFullTVProducts(billingCustomerId) {
    return query({
      query: Q.getFullTvProductsQuery,
      variables: { billingCustomerId },
      name: 'getFullTvProducts',
    }).then(({ getAssignedProducts }) => ({
      data: getAssignedProducts.televisionProducts,
    }));
  },
  getFullInternetProducts(billingCustomerId) {
    return query({
      query: Q.getFullInternetProductsQuery,
      variables: { billingCustomerId },
      name: 'getFullInternetProducts',
    }).then(({ getAssignedProducts }) => ({
      data: getAssignedProducts.internetProducts,
    }));
  },
  getFullTelephonyProducts(billingCustomerId) {
    return query({
      query: Q.getFullTelephonyProductsQuery,
      variables: { billingCustomerId },
      name: 'getFullTelephonyProducts',
    }).then(({ getAssignedProducts }) => ({
      data: getAssignedProducts.telephonyProducts,
    }));
  },
  getAssignedProductsDashboard(billingCustomerId) {
    return query({
      query: Q.getAssignedProductsDashboardQuery,
      variables: { billingCustomerId },
      name: 'getAssignedProductsDashboard',
    }).then(({ getAssignedProducts }) => ({
      data: {
        groupLevelProduct: getAssignedProducts.groupLevelProduct,
        televisionProducts: getAssignedProducts.televisionProducts,
        telephonyProducts: getAssignedProducts.telephonyProducts,
        internetProducts: getAssignedProducts.internetProducts,
        benefitInfo: getAssignedProducts.benefitInfo,
        hasDigitalTV: getAssignedProducts.hasDigitalTV,
      },
    }));
  },
  getAssignedProductsOverview(billingCustomerId) {
    return query({
      query: Q.getAssignedProductsOverviewQuery,
      variables: { billingCustomerId },
      name: 'getAssignedProductsOverview',
    }).then(({ getAssignedProducts }) => ({
      data: {
        groupLevelProduct: getAssignedProducts.groupLevelProduct,
        televisionProducts: getAssignedProducts.televisionProducts,
        telephonyProducts: getAssignedProducts.telephonyProducts,
        internetProducts: getAssignedProducts.internetProducts,
        benefitInfo: null,
      },
    }));
  },
  resetPincode(billingCustomerId, assignedProductId) {
    return query({
      query: Q.resetPincodeQuery,
      variables: {
        billingCustomerId,
        assignedProductId,
      },
      name: 'resetPincode',
    }).then(({ resetPincode }) => ({ data: resetPincode }));
  },
  refreshEntitlements(billingCustomerId, productId, subProductId) {
    return query({
      query: Q.refreshEntitlementsQuery,
      variables: {
        billingCustomerId,
        productId,
        subProductId,
      },
      name: 'refreshEntitlements',
    }).then(({ refreshEntitlements }) => ({ data: refreshEntitlements }));
  },
};
