import styled, {
  css,
  gapSize,
  ieEdgeSpecific,
  keyframes,
  rem,
  screenMobilePlusAndSmaller,
  spacingBase,
  tablet,
} from 'theme';
import boxShadow from 'theme/global/box-shadow';

const TRANSLATE_AMOUNT = rem(30);
const ANIMATE_IN_DURATION = '200ms';
const ANIMATE_OUT_DURATION = '200ms';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const translate = keyframes`
  from {
    transform: translateY(${TRANSLATE_AMOUNT});
  }
`;

export const ModalWrapper = styled.div<{ isClosing: boolean }>`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  transition: opacity ${ANIMATE_OUT_DURATION};
  animation: ${fadeIn} ${ANIMATE_IN_DURATION} ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: ${(p) => (p.isClosing ? 0 : 1)};
  overflow-y: scroll;

  ${() =>
    tablet(css`
      padding: ${gapSize(8)};
    `)}

  & > section {
    align-self: flex-start;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
  }
`;
export const CloseButton = styled('button')`
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  right: ${() => gapSize(2)};
  top: ${() => gapSize(2)};
  color: ${(p) => p.theme.colors.gray900};

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
  }
`;

export const ModalIcon = styled('div')`
  svg {
    width: ${rem(120)};
    height: ${rem(120)};
  }
`;

export const ModalButtons = styled('div')`
  display: flex;
  margin-top: ${() => gapSize(3)};

  button:first-of-type {
    margin-right: ${() => gapSize(2)};
  }
`;

export const Modal = styled.div<{ isClosing: boolean; ignoreFormStyling?: boolean }>`
  width: ${rem(808)};
  max-width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  text-align: left;
  font-family: ${(p) => p.theme.fonts.stacks.primary};
  border: ${rem(1)} solid ${(p) => p.theme.colors.grayL40};
  transform: translateY(${(p) => (p.isClosing ? rem(50) : '0')});
  transition: transform ${ANIMATE_OUT_DURATION};
  animation: ${translate} ${ANIMATE_IN_DURATION} ease-in-out;
  box-shadow: ${boxShadow.modal};
  margin: auto;
  display: flex;
  flex-direction: column;

  ${ieEdgeSpecific(css`
    display: inline-table;
  `)};

  > section {
    padding: ${() => gapSize(4)};
    padding-bottom: ${() => gapSize(2)};

    ${() =>
      tablet(css`
        padding: ${gapSize(6)} ${gapSize(6)} ${gapSize(3)};
      `)}

    h4 {
      margin-bottom: ${() => gapSize(2)};
    }

    svg {
      max-width: ${rem(125)};
      max-height: ${rem(125)};
    }

    p {
      a {
        text-decoration: underline;
        font-weight: ${(p) => p.theme.fonts.weights.bold};
      }
    }
  }

  ${(p) =>
    !p.ignoreFormStyling
      ? `form { 
        flex: auto;
        display: block;
        padding: ${() => gapSize(2)} ${() => gapSize(4)} ${() => gapSize(3)};
        background: ${p.theme.colors.grayL10};

        .form-button {
          display: flex;
          flex-direction: row;
          width: 100%;
          max-width: ${rem(370)};
          margin: calc(${() => spacingBase} * 4) auto 0;
    
          button {
            width: 100%;
            flex: 0 1 auto;
            margin-bottom: ${() => spacingBase};
    
            &:last-child {
              margin-left: ${() => spacingBase};
            }
          }
        }
      }`
      : ''}

  form {
    ${(p) =>
      !p.ignoreFormStyling
        ? tablet(css`
            padding: ${gapSize(4)} ${gapSize(8)} ${gapSize(8)};
          `)
        : ''}
  }

  /* first is close icon */
  > *:nth-child(2) {
    padding-top: ${() => gapSize(2)};
  }

  > *:last-child {
    padding-bottom: ${() => gapSize(6)};
  }

  ${screenMobilePlusAndSmaller(css`
    min-height: 100vh;
  `)}
`;

export const DetailSection = styled.div`
  background: ${(p) => p.theme.colors.gray100};
  padding: ${() => gapSize(2)};
  max-width: ${rem(565)};

  & + & {
    margin-top: ${() => gapSize(2)};
  }
`;

export const DetailTitle = styled.div`
  display: inline-block;
  min-width: ${rem(100)};
  margin-right: ${() => spacingBase};
  vertical-align: top;
`;
export const DetailContent = styled.div`
  display: inline-block;
  > span {
    font-weight: ${(p) => p.theme.fonts.weights.bold};
  }
`;

export const DetailContentRed = styled.div`
  color: ${(p) => p.theme.colors.red};
`;
