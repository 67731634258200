import { gql } from 'graphql-request';

export const getPaymentsQuery = gql`
  query getPayments($billingCustomerId: String!) {
    getPayments(billingCustomerId: $billingCustomerId) {
      amount
      date
      directDebitRejectionReason
      directDebitStatus
      id
      invoiceReferences {
        id
        period
      }
      method
      originalAmount
      refundReason
      status
      submethod
      type
    }
  }
`;
