import { ApiResponse, query } from './api';
import { cancelDaypassQuery, getPlannedDaypassesQuery, orderDaypassQuery } from './daypass-customer.query';
import { DaypassOrder, DaypassOrderSuccess, PlannedDaypassInfo } from './daypass-customer.types';

interface DaypassCustomerApiInterface {
  cancelDaypass(
    billingCustomerId: string,
    daypassOrderId: string,
    daypassEventId: string
  ): ApiResponse<{ success: boolean }>;

  orderDaypass(billingCustomerId: string, daypassOrder?: DaypassOrder): ApiResponse<DaypassOrderSuccess>;

  getPlannedDaypasses(billingCustomerId: string): ApiResponse<PlannedDaypassInfo[]>;
}

const DaypassCustomerApi: DaypassCustomerApiInterface = {
  getPlannedDaypasses(billingCustomerId: string) {
    return query({
      query: getPlannedDaypassesQuery,
      variables: { billingCustomerId },
      name: 'getPlannedDaypasses',
    }).then(({ getPlannedDaypasses }) => ({ data: getPlannedDaypasses }));
  },

  orderDaypass(billingCustomerId: string, daypassOrder?: DaypassOrder) {
    return query({
      query: orderDaypassQuery,
      variables: { billingCustomerId, daypassOrder },
      name: 'orderDaypass',
    }).then(({ orderDaypass }) => ({ data: orderDaypass }));
  },

  cancelDaypass(billingCustomerId: string, daypassOrderId: string, daypassEventId: string) {
    return query({
      query: cancelDaypassQuery,
      variables: { billingCustomerId, daypassOrderId, daypassEventId },
      name: 'cancelDaypass',
    }).then(({ cancelDaypass }) => ({ data: cancelDaypass }));
  },
};

export { DaypassCustomerApi };
