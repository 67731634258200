import styled, { rem } from 'theme';

export const ErrorMessage = styled('div')`
  color: ${(p) => p.theme.colors.danger};
  width: 100%;
  display: flex;
  align-items: center;
  fill: ${(p) => p.theme.colors.danger};
  padding-top: ${rem(4)};

  svg {
    margin-right: ${rem(4)};
    width: ${rem(24)};
    height: ${rem(24)};
  }

  a {
    color: ${(p) => p.theme.colors.danger};
    text-decoration: underline;
  }
`;
