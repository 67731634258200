import { gql } from 'graphql-request';

export const getPlannedDaypassesQuery = gql`
  query getPlannedDaypasses($billingCustomerId: String!) {
    getPlannedDaypasses(billingCustomerId: $billingCustomerId) {
      cancellationAllowed
      daypassEventId
      daypassOrderId
      orderStatus
      price
      startDate
      title
      type
    }
  }
`;

export const orderDaypassQuery = gql`
  mutation orderDaypass($billingCustomerId: String!, $daypassOrder: DaypassOrderInput!) {
    orderDaypass(billingCustomerId: $billingCustomerId, daypassOrder: $daypassOrder) {
      daypassOrderId
    }
  }
`;

export const cancelDaypassQuery = gql`
  mutation cancelDaypass($billingCustomerId: String!, $daypassOrderId: String!, $daypassEventId: String!) {
    cancelDaypass(
      billingCustomerId: $billingCustomerId
      daypassOrderId: $daypassOrderId
      daypassEventId: $daypassEventId
    ) {
      success
    }
  }
`;
