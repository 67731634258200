import { ExclamationTriangleIcon } from 'assets/icons';
import React from 'react';

import * as S from './errormessage.styles';

interface ErrorMessageProps {
  message?: string;
  children?: React.ReactNode;
  className?: string;
  title?: string;
}

export const ErrorMessage = ({ message, children, className, ...rest }: ErrorMessageProps) => (
  <S.ErrorMessage data-testid="error-message" className={className} {...rest}>
    <ExclamationTriangleIcon /> {message || children}
  </S.ErrorMessage>
);
