import styled, { contentMargins, contentPadding, css } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

export enum CardVariant {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
}

export interface CardProps {
  flex?: boolean;
  variant?: CardVariant;
  contentPadding?: number[];
  hidden?: boolean;
}

export const CardStyles = css<CardProps>`
  box-shadow: ${boxShadow.section};
  border-radius: ${borderRadius.base};
  ${(p) => contentPadding(...(p.contentPadding || [1, 1, 1, 1]))}

  & + & {
    ${contentMargins(1, 0, 0, 0)}
  }

  & > *:first-child {
    margin-top: 0;
  }

  ${(p) => p.flex && `flex: 1 1 auto;`}
`;

const CardDarkStyles = css`
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.gray900};
`;

const CardDefaultStyles = css`
  color: ${(p) => p.theme.colors.gray900};
  background-color: ${(p) => p.theme.colors.white};
`;

const CardHiddenStyles = css`
  display: none;
`;

const Card = styled.div<CardProps>`
  ${CardStyles}

  ${(p) => {
    switch (p.variant) {
      case CardVariant.DARK:
        return CardDarkStyles;
      default:
        return CardDefaultStyles;
    }
  }}

  ${(p) => {
    if (p.hidden) {
      return CardHiddenStyles;
    }

    return null;
  }}
`;

export default Card;
