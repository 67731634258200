import { AxiosPromise, AxiosRequestConfig } from 'axios';

export interface IApi {
  api(options: AxiosRequestConfig): AxiosPromise;
}

export default class ApiFactory {
  private static intance: ApiFactory;

  private api: IApi;

  public static getInstance(): ApiFactory {
    if (!ApiFactory.intance) {
      ApiFactory.intance = new ApiFactory();
    }
    return ApiFactory.intance;
  }

  public setApi(api: IApi): void {
    this.api = api;
  }

  public getApi(): IApi {
    return this.api;
  }
}
