import { gql } from 'graphql-request';

export const getUnbilledTelephonyUsage = gql`
  query getPlannedDaypasses($billingCustomerId: String!) {
    getUnbilledTelephonyUsage(billingCustomerId: $billingCustomerId) {
      id
      phoneNumber
      telephonyUsageCategories {
        price {
          excludingVat
          includingVat
        }
        type
      }
    }
  }
`;

export const getUnbilledTelephonyEvents = gql`
  query getUnbilledTelephonyEvents(
    $billingCustomerId: String!
    $assignedProductId: String!
    $dateFrom: String
    $dateTo: String
    $durationFrom: String
    $durationTo: String
    $priceFrom: Float
    $priceTo: Float
    $pageSize: Float!
    $pageOffset: Float!
    $destinationNumber: String
  ) {
    getUnbilledTelephonyEvents(
      billingCustomerId: $billingCustomerId
      assignedProductId: $assignedProductId
      dateFrom: $dateFrom
      dateTo: $dateTo
      durationFrom: $durationFrom
      durationTo: $durationTo
      priceFrom: $priceFrom
      priceTo: $priceTo
      pageSize: $pageSize
      pageOffset: $pageOffset
      destinationNumber: $destinationNumber
    ) {
      items {
        category
        destinationNumber
        duration
        vatPrice {
          excludingVat
          includingVat
        }
        start
      }
      totalRecords
    }
  }
`;

export const getUnbilledTelevisionEvents = gql`
  query getUnbilledTelevisionEvents($billingCustomerId: String!, $assignedProductId: String!) {
    getUnbilledTelevisionEvents(billingCustomerId: $billingCustomerId, assignedProductId: $assignedProductId) {
      chargeAmount
      chargeCategory
      id
      movieTitle
      smartcardNumber
      startTime
    }
  }
`;

export const getUnbilledTelevisionUsage = gql`
  query getUnbilledTelevisionUsage($billingCustomerId: String!) {
    getUnbilledTelevisionUsage(billingCustomerId: $billingCustomerId) {
      price {
        excludingVat
        includingVat
      }
      type
    }
  }
`;

export const getUnbilledStreamingUsage = gql`
  query getUnbilledStreamingUsage($billingCustomerId: String!) {
    getUnbilledStreamingUsage(billingCustomerId: $billingCustomerId) {
      type
      price {
        excludingVat
        includingVat
      }
    }
  }
`;

export const getUnbilledStreamingEvents = gql`
  query getUnbilledStreamingEvents($billingCustomerId: String!) {
    getUnbilledStreamingEvents(billingCustomerId: $billingCustomerId) {
      id
      description
      startTime
      chargeCategory
      chargeAmount
    }
  }
`;
