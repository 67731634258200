import { EmailFlyingThroughTheSun } from 'assets/img/EmailFlyingThroughTheSun';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { PiiMask } from 'components/PiiMask';
import { content } from 'pages/Products/pages/InternetProducts/pages/EmailSettings/components/SectionAlias/section-alias.content';
import React from 'react';
import { ContentUtil } from 'utils/content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionAlias' });

interface IRemovalSuccessModalProps {
  alias: string;
  onClose(): void;
}

export const RemovalSuccessModal = ({ alias, onClose }: IRemovalSuccessModalProps) => (
  <Modal onClose={onClose}>
    {(closeModal) => (
      <section data-testid="removal-success-modal">
        <ModalIcon>
          <EmailFlyingThroughTheSun />
        </ModalIcon>
        <h4>{contentUtil.translate('removalSuccessModal.header')}</h4>
        <PiiMask>{contentUtil.translateHtml('removalSuccessModal.content', { alias })}</PiiMask>
        <ModalButtons>
          <Button onClick={closeModal}>{contentUtil.translate('removalSuccessModal.button')}</Button>
        </ModalButtons>
      </section>
    )}
  </Modal>
);
