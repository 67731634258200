import styled, { css, ieEdgeSpecific, keyframes, rem } from 'theme';

const curve = 'cubic-bezier(0.650, 0.000, 0.450, 1.000)';

const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(${rem(10)});
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Children = styled('div')`
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 0.7s 0.75s cubic-bezier(0.3, 0.19, 0, 0.99) forwards;
  color: ${(p) => p.theme.colors.gray900};

  ${ieEdgeSpecific(css`
    opacity: 1;
    animation: initial;
  `)}
`;

export const Cross = styled('svg')`
  margin: 0 auto ${rem(20)};
  width: ${rem(100)};
  height: ${rem(100)};
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${(p) => p.theme.colors.red};
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 ${(p) => p.theme.colors.red};
  animation: ${scale} 0.3s ease-in-out 0.7s both;

  ${ieEdgeSpecific(css`
    animation: initial;
  `)}
`;

export const Circle = styled('circle')`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${(p) => p.theme.colors.red};
  animation: ${stroke} 0.6s ${curve} forwards;

  ${ieEdgeSpecific(css`
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: initial;
  `)}
`;

export const Path = styled('path')`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.2s ${curve} 0.6s forwards;

  ${ieEdgeSpecific(css`
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    animation: initial;
  `)}

  &:nth-child(2) {
    animation: ${stroke} 0.2s ${curve} 0.7s forwards;

    ${ieEdgeSpecific(css`
      animation: initial;
    `)}
  }
`;
