import { HashPaymentResponse, InvoicePaymentDetailStatusEnum, InvoicePaymentStatus, TokenPaymentResponse } from 'api';
import { BaseLayoutContext } from 'components/BaseLayout/BaseLayout';
import { ErrorCross } from 'components/ErrorCross';
import { Loader } from 'components/Loader';
import { PaymentHeader } from 'components/PaymentHeader';
import { InvoicesContext } from 'context/invoices.context';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalContentUtil } from 'utils';
import { getQueryStringParams } from 'utils/url';

import { PaymentFailed } from './components/PaymentFailed';
import { PaymentPending } from './components/PaymentPending';
import { PaymentSuccess } from './components/PaymentSuccess';
import * as S from './payment-result.styles';

const getPaymentParams = ({
  token,
  orderID,
  STATUS,
  NCERROR,
  amount,
  PM,
  PAYID,
  ACCEPTANCE,
  SHASIGN,
}: Partial<{ [key: string]: string }>) => {
  const params = token
    ? ({ token } as TokenPaymentResponse)
    : ({
        amount,
        acceptance: ACCEPTANCE,
        error: NCERROR,
        orderId: orderID,
        paymentMethod: PM,
        shasign: SHASIGN,
        status: STATUS,
        paymentId: PAYID,
      } as HashPaymentResponse);

  const isEvertyValueFilled = !Object.values(params).every((value) => !value);
  return isEvertyValueFilled ? params : null;
};

export const PaymentResult = () => {
  const baseLayoutContext = useContext(BaseLayoutContext);
  baseLayoutContext.setShowHeaderAndFooter(false);
  const [payment, setPayment] = useState<InvoicePaymentStatus | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const invoicesContext = useContext(InvoicesContext);

  const getPaymentResponse = async (args: TokenPaymentResponse | HashPaymentResponse) => {
    try {
      setIsLoading(true);
      const invoicePaymentStatus = await invoicesContext.getPaymentResponse(args);

      setPayment(invoicePaymentStatus);
      setHasError(false);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = getPaymentParams(getQueryStringParams(location?.search || ''));
    if (params) {
      getPaymentResponse(params);
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  if (!location) {
    return null;
  }

  let ResultComponent;

  // we only check the Detailed status code because the status code only contains
  // SUCCESS and FAILURE, but SUCCESS doesn't necessarily mean success and FAILURE can
  // still be success....
  switch (payment?.detailedPaymentStatusCode) {
    case InvoicePaymentDetailStatusEnum.CREDIT_CARD_BANK_DENIED:
    case InvoicePaymentDetailStatusEnum.CREDIT_CARD_VESTA_DENIED:
    case InvoicePaymentDetailStatusEnum.IDEAL_CANCELLED:
    case InvoicePaymentDetailStatusEnum.IDEAL_EXPIRED:
    case InvoicePaymentDetailStatusEnum.IDEAL_FAILURE:
      ResultComponent = PaymentFailed;
      break;
    case InvoicePaymentDetailStatusEnum.IDEAL_OPEN:
      ResultComponent = PaymentPending;
      break;
    case InvoicePaymentDetailStatusEnum.CREDIT_CARD_SUCCESS:
    case InvoicePaymentDetailStatusEnum.IDEAL_SUCCESS:
    case InvoicePaymentDetailStatusEnum.FULFILMENT_PERMANENT_FAILURE:
    case InvoicePaymentDetailStatusEnum.FULFILMENT_TEMPORARY_FAILURE:
    default:
      ResultComponent = PaymentSuccess;
      break;
  }

  return (
    <S.Page>
      <PaymentHeader />
      {isLoading && <Loader />}
      {hasError && !isLoading && <ErrorCross>{globalContentUtil.translate('error.general.no_data')}</ErrorCross>}
      {!hasError && !isLoading && payment?.invoiceId && <ResultComponent invoiceId={payment.invoiceId} />}
    </S.Page>
  );
};
