import { ChevronIcon } from 'assets/icons';
import { Link } from 'components/Link';
import { NavLink } from 'react-router-dom';
import styled, { css, gapSize } from 'theme';

export const NavigationMobile = styled.nav`
  height: 100vh;
`;

const itemStyles = css`
  display: flex;
  justify-content: space-between;
  padding: ${() => gapSize(2)};
  text-transform: uppercase;
`;

export const NavigationLink = styled(NavLink)`
  ${itemStyles};

  &:first-of-type {
    border-top: solid 1px ${(p) => p.theme.colors.grayD20};
  }
`;

export const ExternalLink = styled(Link)`
  ${itemStyles};

  &:first-of-type {
    border-top: solid 1px ${(p) => p.theme.colors.grayD20};
  }
`;

export const Hr = styled.hr`
  border: none;
  background-color: ${(p) => p.theme.colors.grayL30};
  height: 1px;
`;

export const LogoutButton = styled.button`
  border: none;
  background-color: unset;
  ${itemStyles};

  width: 100%;
  text-align: left;
`;

export const Chevron = styled(ChevronIcon)`
  transform: rotate(0.75turn);
`;
