import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import { IInputProps } from 'components/Form/components/Input/Input';
import { Label } from 'components/Form/components/Label';
import { composeValidators } from 'components/Form/validate';
import { PiiMask } from 'components/PiiMask';
import formatString from 'format-string-by-pattern';
import React from 'react';
import { Field } from 'react-final-form';
import { ContentUtil } from 'utils';
import randomId from 'utils/random-id';

import BankCodeSuggestions from './BankCodeSuggestions';
import { content } from './iban-input.content';
import * as S from './iban-input.styles';

const contentUtil = new ContentUtil({ content, componentName: 'IbanInput' });

const format = formatString(contentUtil.translateToString('mask'));

export const IbanInput = ({ name, label, validate, isDisabled, isRequired, error, id, testid }: IInputProps) => {
  // Random ID is generated if id is not set (this way labels will stay accessible).
  const inputId = id || randomId();
  const ref: React.RefObject<any> = React.createRef();

  return (
    <Field
      name={name}
      validate={validate && !isDisabled ? composeValidators(validate) : undefined}
      parse={(s) => s.replace(/\s+/g, '').toUpperCase()}
      format={(s) => format(s || '').toUpperCase()}
      render={({ input, meta }) => {
        const valueWithoutMask = (input.value && input.value.replace(/\s+/g, '')) || '';
        const isNL = valueWithoutMask.substr(0, 2).toUpperCase() === 'NL';
        const bankCodeInput = valueWithoutMask.substr(4, 4);

        const setBankCode = (event: React.MouseEvent<HTMLSpanElement>, code: string) => {
          event.stopPropagation();
          event.preventDefault();
          ref && ref.current && ref.current.focus();
          input.onChange(valueWithoutMask.substr(0, 4) + code);
        };

        return (
          <React.Fragment>
            {label && (
              <Label htmlFor={id} isRequired={isRequired}>
                {label}
              </Label>
            )}
            <PiiMask>
              <S.IbanInput
                {...input}
                name={name}
                ref={ref}
                disabled={isDisabled}
                id={inputId}
                error={!!((meta.touched && meta.error) || error)}
                data-testid={testid}
              />
            </PiiMask>
            <S.BankCodeSuggestions>
              {input.value === '' && !(meta.error && meta.touched) && !error && (
                <span>{contentUtil.translateToString('placeholder')}</span>
              )}
              {meta.error && meta.touched && <ErrorMessage message={meta.error} data-testid="error-message" />}
              {error && !(meta.error && meta.touched) && <ErrorMessage message={error} data-testid="error-message" />}
              <BankCodeSuggestions
                expanded={bankCodeInput.length < 4 && valueWithoutMask.length >= 4 && isNL}
                onClick={(event, code) => setBankCode(event, code)}
                value={bankCodeInput}
              />
            </S.BankCodeSuggestions>
          </React.Fragment>
        );
      }}
    />
  );
};
