import { BillingCustomerContext } from 'context/billing-customer.context';
import React, { useContext, useEffect, useState } from 'react';

// The interface of read type context values
// eager determines the context to call execute on init
// execute should return a promise, bcid will be provided by baseContext
// other params can be provided and is only defined for typing
interface ReadState<T> {
  eager?: boolean;
  execute: (bcid: string, ...parameters: any) => Promise<T>;
}

// The interface returned by createBaseContext mapped from ReadState
// state contains values for errors, loading, the value itself and the call to execute the promise
interface MappedReadState<T> {
  isLoading: boolean;
  hasError: boolean;
  resetError: () => void;
  value?: T;
  execute: (...parameters: any) => any;
}

// write state is a simple interface containing the loadstate, errorState and means to execute
// bcid is provided by createBaseContext
interface WriteState {
  execute: (bcid: string, ...parameters: any) => Promise<any>;
}

interface MappedWriteState {
  isLoading: boolean;
  hasError: boolean;
  execute: (...parameters: any) => Promise<any>;
}

// maps the ReadState to MappedReadState, utilises hooks to create custom state management per ReadState defined
function mapToStatemanagedReadPromise<T>(properties: ReadState<T>): MappedReadState<T> {
  const billingCustomerContext = useContext(BillingCustomerContext);

  // once we get rid of BillingCustomerContainer, this should be provided by useContext
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [value, setValue] = useState<T>();

  const resetError = () => setHasError(false);

  return {
    // the loading state
    isLoading,
    // the error state
    hasError,
    // setter for the error state
    resetError,
    // the value returned by execute()
    value,
    // map the given execute call to include error handling and setting values on hooks
    execute: (...parameters: any) => {
      setIsLoading(true);
      setHasError(false);
      properties
        .execute(billingCustomerContext.activeBcId, ...parameters)
        .then((val) => {
          setValue(val);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          setHasError(true);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
  };
}

// Map to Same interface WriteState, just wrap the execute call to utilse hooks
function mapToStateManagedWritePromise(properties: WriteState): MappedWriteState {
  const billingCustomerContext = useContext(BillingCustomerContext);
  // once we get rid of BillingCustomerContainer, this should be provided by useContext
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  return {
    // the loading state
    isLoading,
    // the error stae
    hasError,
    // mapping the execute to use state hoooks
    execute: (...parameters: any) => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        properties
          .execute(billingCustomerContext.activeBcId, ...parameters)
          .then((val) => {
            resolve(val);
          })
          .catch((e: Error) => {
            setHasError(true);
            reject(e);
          })
          .finally(() => setIsLoading(false));
      });
    },
  };
}

// Interface which contains read and write states based on a key.
// example in order.context.ts
interface ProviderProperties<T, RK extends string, WK extends string> {
  read: Record<RK, ReadState<T>>;
  write: Record<WK, WriteState>;
}

// Interface which is returned by function (MappedReadState instead of ReadState)
interface BaseContext<T, RK extends string, WK extends string> {
  read: Record<RK, MappedReadState<T>>;
  write: Record<WK, MappedWriteState>;
}

// create our custom provider
export function createBaseContext<T, RK extends string, WK extends string>(properties: ProviderProperties<T, RK, WK>) {
  // we will overwrite these in our own provider below
  const Context = React.createContext<BaseContext<T, RK, WK>>(undefined as any as BaseContext<T, RK, WK>);
  // Crate the Provider Component
  const Provider = ({ children }: { children: React.ReactNode }) => {
    // Reduce the current object into the mapped objects,
    // note that the eager value here are calls to be executed
    const { read: readValues, eager: eagerValues } = Object.keys(properties.read).reduce(
      // RK is the key defined
      ({ read, eager }, key: RK) => {
        const property = properties.read[key];
        // eslint-disable-next-line no-param-reassign
        read[key] = mapToStatemanagedReadPromise(property);
        // when eager is defined push into the eager array so we can do some calls
        if (property.eager) eager.push(read[key].execute);
        return { read, eager };
      },
      { read: {} as Record<RK, MappedReadState<T>>, eager: [] as Function[] }
    );

    // Similar to above, WK are the defined write keys
    const write = Object.keys(properties.write).reduce(
      (acc, writeKey: WK) => {
        acc[writeKey] = mapToStateManagedWritePromise(properties.write[writeKey]);
        return acc;
      },
      {} as Record<WK, MappedWriteState>
    );

    // call th execute fn for all the eager beavers
    useEffect(() => eagerValues.forEach((fn) => fn()), []);

    // return the Provider Component
    return <Context.Provider value={{ read: readValues, write }}>{children}</Context.Provider>;
  };

  // Returns the context and the Provider
  return { Context, Provider };
}
