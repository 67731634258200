import { ApiResponse, query } from './api';
import {
  getMarketingSettingsQuery,
  marketingSettingsSubscribeMutation,
  setMarketingSettingsQuery,
} from './marketing-settings.query';
import { MarketingSettings, MarketingSettingsSubscribeType } from './marketing-settings.types';

interface MarketingSettingsApiInterface {
  getMarketingSettings(billingCustomerId: string): ApiResponse<MarketingSettings[]>;
  updateMarketingSettings(billingCustomerId: string, marketingSettings: MarketingSettings[]): ApiResponse<{}>;
  changeMarketingSettingsSubscription(
    token: string,
    type: MarketingSettingsSubscribeType,
    subscribe: boolean
  ): ApiResponse<{ success: boolean }>;
}

const MarketingSettingsApi: MarketingSettingsApiInterface = {
  getMarketingSettings(billingCustomerId: string) {
    return query({
      query: getMarketingSettingsQuery,
      variables: {
        billingCustomerId,
      },
      name: 'getMarketingSettings',
    }).then(({ getMarketingSettings }) => ({ data: getMarketingSettings }));
  },
  updateMarketingSettings(billingCustomerId: string, marketingSettings: MarketingSettings[]) {
    return query({
      query: setMarketingSettingsQuery,
      variables: {
        billingCustomerId,
        marketingSettings,
      },
      name: 'setMarketingSettings',
    }).then(({ setMarketingSettings }) => ({ ...setMarketingSettings }));
  },
  async changeMarketingSettingsSubscription(
    token: string,
    type: MarketingSettingsSubscribeType,
    subscribe: boolean
  ): ApiResponse<{ success: boolean }> {
    const { changeMarketingSettingsSubscription } = await query(
      {
        query: marketingSettingsSubscribeMutation,
        variables: { token, type, subscribe },
        name: 'changeMarketingSettingsSubscription',
      },
      false
    );

    return { data: changeMarketingSettingsSubscription };
  },
};

export { MarketingSettingsApi };
