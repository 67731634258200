import { gql } from 'graphql-request';

export const orderFragment = gql`
  fragment orderFields on Order {
    address {
      id
      postCode
      city
      country
      streetName
      houseNumber
      houseNumberExtension
      roomNumber
    }
    contact {
      birthDate
      email
      firstName
      id
      initials
      lastName
      middleName
      phone
      salutation
    }
    creationDate
    startDate
    id
    numberPorting {
      phoneNumber
      portingStatus
    }
    orderDelivery {
      deliveryMethod
      trackAndTrace
      address {
        id
        postCode
        city
        country
        streetName
        houseNumber
        houseNumberExtension
        roomNumber
      }
    }
    orderLines {
      action
      category
      onetimeCost {
        excludingVat
        includingVat
      }
      productName
      recurringCost {
        excludingVat
        includingVat
      }
    }
    orderMove {
      moveAddress {
        id
        postCode
        city
        country
        streetName
        houseNumber
        houseNumberExtension
        roomNumber
      }
      moveDate
      phoneNumberChanges
    }
    orderStatus
    providerTransfer {
      provider
      transferStatus
    }
    totalOnetime {
      excludingVat
      includingVat
    }
    totalRecurring {
      excludingVat
      includingVat
    }
  }
`;

export const getOrderByZipcodeQuery = gql`
  ${orderFragment}
  query getPublicOrder($orderId: String!, $zipcode: String!) {
    getPublicOrder(orderId: $orderId, zipcode: $zipcode) {
      ...orderFields
    }
  }
`;
