import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { EmailFlyingThroughTheSun } from 'assets/img/EmailFlyingThroughTheSun';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { DetailContentRed, ModalButtons, ModalIcon } from 'components/Modal/modal.styles';
import { PiiMask } from 'components/PiiMask';
import { content } from 'pages/Products/pages/InternetProducts/pages/EmailSettings/components/SectionEmail/section-email.content';
import React from 'react';
import { ContentUtil } from 'utils/content';

const contentUtil = new ContentUtil({ content, componentName: 'SectionEmail' });

interface IConfirmRemovalModalProps {
  onCancel(): void;
  onConfirm(): void;
  closeModal: boolean;
  emailAddress: string;
  emailAliasList: string;
}

export const ConfirmRemovalModal = ({
  onCancel,
  onConfirm,
  closeModal,
  emailAddress,
  emailAliasList,
}: IConfirmRemovalModalProps) => (
  <Modal onClose={onCancel}>
    {(onClose) => {
      if (closeModal) onClose();

      return (
        <section data-testid="confirm-removal-modal">
          <ModalIcon>
            <EmailFlyingThroughTheSun />
          </ModalIcon>
          <h4>{contentUtil.translate('confirmModal.header')}</h4>
          <PiiMask>{contentUtil.translateHtml('confirmModal.content')}</PiiMask>
          <DetailContentRed>{contentUtil.translateHtml('confirmModal.contentRed', { emailAddress })}</DetailContentRed>
          {emailAliasList && (
            <PiiMask>{contentUtil.translateHtml('confirmModal.contentAliases', { emailAliasList })}</PiiMask>
          )}
          <ModalButtons>
            <Button onClick={onConfirm} testid="button-confirm-removal" name="foo">
              {contentUtil.translate('confirmModal.confirmButton')}
            </Button>
            <Button onClick={onClose} outline testid="button-cancel-removal" buttonVariant={ButtonVariant.DARK}>
              {contentUtil.translate('confirmModal.cancelButton')}
            </Button>
          </ModalButtons>
        </section>
      );
    }}
  </Modal>
);
