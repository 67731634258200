import { gql } from 'graphql-request';

export const getPrivacyRequestQuery = gql`
  query getPrivacyRequest($billingCustomerId: String!, $requestId: String) {
    getPrivacyRequest(billingCustomerId: $billingCustomerId, requestId: $requestId) {
      creationDate
      id
      lastChangedDate
      pdfAvailable
    }
  }
`;

export const privacyComplaintQuery = gql`
  mutation privacyComplaint(
    $billingCustomerId: String!
    $contactId: String!
    $privacyComplaint: PrivacyComplaintInput!
  ) {
    privacyComplaint(
      billingCustomerId: $billingCustomerId
      contactId: $contactId
      privacyComplaint: $privacyComplaint
    ) {
      id
    }
  }
`;

export const privacyDeleteQuery = gql`
  mutation privacyDelete($billingCustomerId: String!, $contactId: String!) {
    privacyDelete(billingCustomerId: $billingCustomerId, contactId: $contactId) {
      id
    }
  }
`;

export const sendPrivacyRequestQuery = gql`
  mutation sendPrivacyRequest($billingCustomerId: String!, $contactId: String!) {
    sendPrivacyRequest(billingCustomerId: $billingCustomerId, contactId: $contactId) {
      id
    }
  }
`;
