import { gql } from 'graphql-request';

export const invoiceFragment = gql`
  fragment invoiceFields on Invoice {
    id
    status
    type
    period
    totalAmount
    paidDate
    dueDate
    dueAmount
    allowPayment
    pdfAvailable
    directDebit {
      attemptsLeft
      rejectionReason
    }
    paymentReference
  }
`;

export const getInvoiceListQuery = gql`
  ${invoiceFragment}
  query getInvoiceList($billingCustomerId: String!, $months: Float!) {
    getInvoiceList(billingCustomerId: $billingCustomerId, months: $months) {
      ...invoiceFields
    }
  }
`;

export const getInvoiceQuery = gql`
  ${invoiceFragment}
  query getInvoice($billingCustomerId: String!, $invoiceId: String!) {
    getInvoice(billingCustomerId: $billingCustomerId, invoiceId: $invoiceId) {
      ...invoiceFields
    }
  }
`;

export const getPaymentMethodsQuery = gql`
  query getPaymentMethods($billingCustomerId: String!, $invoiceId: String!) {
    getPaymentMethods(billingCustomerId: $billingCustomerId, invoiceId: $invoiceId) {
      type
      enabled
    }
  }
`;

export const getPaymentLinkQuery = gql`
  query getPaymentLink($billingCustomerId: String!, $invoiceId: String!, $paymentMethod: String!) {
    getPaymentLink(billingCustomerId: $billingCustomerId, invoiceId: $invoiceId, paymentMethod: $paymentMethod) {
      hash
      link
      locale
      currency
      merchant
      acceptUrl
      declineUrl
      cancelUrl
      exceptionUrl
      backUrl
      homeUrl
    }
  }
`;

export const getPaymentResponseQuery = gql`
  query getPaymentResponse(
    $token: String
    $acceptance: String
    $amount: String
    $error: String
    $orderId: String
    $paymentMethod: String
    $shasign: String
    $status: String
    $paymentId: String
  ) {
    getPaymentResponse(
      token: $token
      acceptance: $acceptance
      amount: $amount
      error: $error
      orderId: $orderId
      paymentMethod: $paymentMethod
      shasign: $shasign
      status: $status
      paymentId: $paymentId
    ) {
      amount
      detailedPaymentStatusCode
      invoiceId
      paymentStatusCode
    }
  }
`;
