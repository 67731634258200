import React from 'react';

const cloneWithMaskClassName = (child: React.ReactNode) => {
  if (React.isValidElement(child)) {
    const baseClasses = child.props.className;

    return React.cloneElement(child, {
      // @ts-expect-error | Argument of type '{ className: string; }' is not assignable to parameter of type 'Partial<unknown> & Attributes'.
      className: baseClasses ? `${baseClasses} ctHidden` : 'ctHidden',
    });
  }

  return child;
};

interface IPiiMask {
  children: any;
}

export const PiiMask = ({ children }: IPiiMask) => {
  return React.Children.map(children, cloneWithMaskClassName);
};
