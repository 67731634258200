import { getSearchResultQuery } from './ai-search.query';
import { AISearchResult } from './ai-search.types';
import { ApiResponse, query as graphQLQuery } from './api';

interface AISearchInterface {
  getSearchResult(query: string): ApiResponse<AISearchResult>;
}

export const AISearchApi: AISearchInterface = {
  async getSearchResult(query: string) {
    return graphQLQuery({
      query: getSearchResultQuery,
      variables: { query },
      name: 'getSearchResult',
    }).then(({ getSearchResult }) => ({ data: getSearchResult }));
  },
};
