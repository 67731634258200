import { SupportCaseComment } from 'api/support-case.types';
import { UserIcon, ZDeviceIcon } from 'assets/icons';
import { Col } from 'components/Grid';
import React from 'react';
import { DateFormat, dateUtils } from 'utils/date';

import * as S from './support-case-comments.styles';

interface ISupportCaseCommentsArguments {
  comments: SupportCaseComment[];
}

const SupportCaseComments = ({ comments }: ISupportCaseCommentsArguments) => {
  function getAuthorIcon(author: string) {
    if (author.toLowerCase() === 'ziggo klantenservice') {
      return <ZDeviceIcon />;
    }

    return <UserIcon />;
  }
  return (
    <>
      {comments.map((comment, index) => (
        <S.Comment data-testid="support-case-comment" key={`support-case-comment-${index}`}>
          <S.Info m={4} data-testid="support-case-comment-info">
            <S.Spacer />
            <S.Author>
              {getAuthorIcon(comment.author)} {comment.author}
            </S.Author>
            <S.Date>{dateUtils.format(comment.creationDate, DateFormat.FULL_DATE)}</S.Date>
            <S.Spacer />
          </S.Info>
          <Col m={8} data-testid="support-case-comment-message">
            <S.Message>{comment.message}</S.Message>
          </Col>
        </S.Comment>
      ))}
    </>
  );
};

export default SupportCaseComments;
