export interface ComponentIdentifier {
  enabled: boolean;
}

export enum ComponentIdentifierParameter {
  RESET_PINCODE = 'RESET_PINCODE',
  REFRESH_ENTITLEMENTS = 'REFRESH_ENTITLEMENTS',
  PRIVACY_COMPLAINT = 'PRIVACY_COMPLAINT',
  PRIVACY_REQUEST = 'PRIVACY_REQUEST',
  PRIVACY_DELETE = 'PRIVACY_DELETE',
  CALL_BARS = 'CALL_BARS',
  WIFI_SPOT_STATUS = 'WIFI_SPOT_STATUS',
  CREATE_WIFI_SPOT = 'CREATE_WIFI_SPOT',
  SECRET_CIRCLE = 'SECRET_CIRCLE',
  CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER',
  PHONE_SETTINGS = 'PHONE_SETTINGS',
}
