import { Col, Row } from 'components/Grid';
import styled, { rem } from 'theme';
import borderRadius from 'theme/global/border-radius';

export const Title = styled(Col)`
  color: ${(p) => p.theme.colors.success};
`;
export const Icon = styled.span`
  margin-right: ${rem(4)};
`;

export const Description = styled(Col)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const NeedFurtherHelp = styled(Col)`
  margin-top: 0;
  font-weight: bold;
`;

export const ClosedBlock = styled(Row)`
  border: 1px solid ${(p) => p.theme.colors.gray200};
  border-radius: ${borderRadius.base};
  background: white;
`;
