import {
  Address,
  AppointmentInfo,
  BenefitInfo,
  BillingCustomerTypeEnum,
  CustomerProductType,
  DashboardApi,
  InstallationAddress,
  ProductCategories,
  ProductCategoryInternet,
  ProductCategoryTelephony,
  ProductCategoryTelevision,
  TelephonyProductExtraServicesEnum,
  TvPackage,
} from 'api';
import { AddressesContext } from 'context/addresses.context';
import { BillingCustomerContext } from 'context/billing-customer.context';
import { useProductContext } from 'context/product/product.context';
import { useContactDetailsContext } from 'pages/ContactDetails/contact-details.context';
import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { userStreamingServicesTracker } from 'utils/analytics';

export interface IDashboardContext {
  hasError: boolean;
  isAddressesLoading: boolean;
  isProductsOverviewLoading: boolean;
  isContactDetailsLoading: boolean;
  benefitInfo?: BenefitInfo | null;
  brand?: string;
  customerNumber?: string;
  customerProductType?: string;
  customerType: BillingCustomerTypeEnum;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string | null;
  primaryPhone?: string | null;
  installationAddress: InstallationAddress | {};
  customerAddress?: Address;
  productCategories: ProductCategories;
  appointmentInfo?: AppointmentInfo;
}

interface DashboardProviderProps {
  children: ReactElement;
}

const DashboardContext = createContext<IDashboardContext | undefined>(undefined);

const useDashboardContext = () => useContext(DashboardContext) as IDashboardContext;

const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const billingCustomerContext = useContext(BillingCustomerContext);
  const addressesContext = useContext(AddressesContext);

  const {
    hasTv,
    hasCableTV,
    hasDigitalTV,
    hasPhone,
    hasInternet,
    hasError,
    isLoading: isProductsOverviewLoading,
    products,
    productType,
  } = useProductContext();
  const { addresses, isLoading: isAddressesLoading } = addressesContext;
  const { contractsAndContacts } = billingCustomerContext;
  const { contactDetails, isLoading: isContactDetailsLoading } = useContactDetailsContext();

  const [appointmentInfo, setAppointmentInfo] = useState<AppointmentInfo>();

  let customerProductType;
  const customerType = contractsAndContacts?.billing_customer.is_consumer
    ? BillingCustomerTypeEnum.INDIVIDUAL
    : BillingCustomerTypeEnum.BUSINESS;

  // eslint-disable-next-line default-case
  switch (customerType) {
    case BillingCustomerTypeEnum.BUSINESS:
      customerProductType = productType === CustomerProductType.B2C ? 'consumer' : 'business';
      break;
    case BillingCustomerTypeEnum.INDIVIDUAL:
      customerProductType = productType === CustomerProductType.SOHO ? 'business' : 'consumer';
      break;
  }

  const getAppointmentInfo = async () => {
    const { data } = await DashboardApi.getDashboardInfoV2(billingCustomerContext.activeBcId);

    if (data?.appointmentInfo) {
      setAppointmentInfo(data?.appointmentInfo);
    }
  };

  const setCustomerAddress = () => {
    const bcAddress = addresses?.find((address) => address.type === 'BILLING_CUSTOMER');

    if (bcAddress) return bcAddress.address;
    return undefined;
  };

  const setTelevisionProductInfo = (): ProductCategoryTelevision | null => {
    if (products?.televisionProducts && products.televisionProducts[0]) {
      const { TVEXTRA, TVSTANDAARD, KABELTV } = TvPackage;
      const tvPackagesInLargestOrder = [TVEXTRA, TVSTANDAARD, KABELTV];

      const tvPackagesTypeInOrderNumber = products.televisionProducts[0].packages.map((tvPackage) => {
        return tvPackagesInLargestOrder.indexOf(tvPackage.type as TvPackage);
      });

      return {
        largestTelevisionPackage: tvPackagesInLargestOrder[Math.min(...tvPackagesTypeInOrderNumber)],
      };
    }

    return null;
  };

  const setTelephonyProductInfo = (): ProductCategoryTelephony | null => {
    if (products?.telephonyProducts?.length) {
      const { BELLEN } = TelephonyProductExtraServicesEnum;
      let hasVolopBellenService = false;

      products.telephonyProducts.forEach((telephonyProduct) => {
        if (telephonyProduct.extraServices?.type === BELLEN) {
          hasVolopBellenService = true;
        }
      });

      return {
        hasVolopBellenService,
      };
    }

    return null;
  };

  const setInternetProductInfo = (): ProductCategoryInternet | null => {
    if (products?.internetProducts && products.internetProducts[0]) {
      const { downloadSpeed, uploadSpeed } = products.internetProducts[0];

      return {
        downloadSpeed,
        uploadSpeed,
      };
    }

    return null;
  };

  useEffect(() => {
    if (billingCustomerContext.completed) {
      getAppointmentInfo();
    }
  }, [billingCustomerContext.completed]);

  useEffect(() => {
    if (billingCustomerContext.completed && products) {
      // Log DDM event here to track if users have streaming services and have non-activated streaming services
      userStreamingServicesTracker(products, billingCustomerContext.activeBcIdHash);
    }
  }, [billingCustomerContext.completed, products]);

  return (
    <DashboardContext.Provider
      value={{
        isAddressesLoading,
        isContactDetailsLoading,
        isProductsOverviewLoading,
        hasError,
        benefitInfo: products?.benefitInfo,
        brand: contractsAndContacts?.billing_customer.brand,
        customerNumber: contractsAndContacts?.billing_customer.billing_customer_id,
        customerType,
        customerProductType,
        firstName: contractsAndContacts?.contact.first_name,
        middleName: contractsAndContacts?.contact.middle_name,
        lastName: contractsAndContacts?.contact.last_name,
        email: contactDetails?.primaryContact?.email,
        primaryPhone: contactDetails?.primaryContact?.phone,
        // TODO: installationAddress API is not implemented yet
        installationAddress: {},
        customerAddress: setCustomerAddress(),
        productCategories: {
          catv: hasCableTV,
          dtv: hasDigitalTV,
          television: hasTv(),
          televisionProductInfo: setTelevisionProductInfo(),
          telephony: hasPhone(),
          telephonyProductInfo: setTelephonyProductInfo(),
          internet: hasInternet(),
          internetProductInfo: setInternetProductInfo(),
        },
        appointmentInfo,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider, useDashboardContext };
