import { ArrowIcon, LockIcon } from 'assets/icons';
import { HideOnMobile } from 'components/HideOnMobile';
import React from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './payment-header.content';
import * as S from './payment-header.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentHeader' });

export const PaymentHeader = () => {
  return (
    <S.Header>
      <S.LinkPaymentOverview to="/facturen">
        <ArrowIcon />
        <HideOnMobile> {contentUtil.translate('paymentOverview')}</HideOnMobile>
      </S.LinkPaymentOverview>
      <S.Logo width="40" height="40" />
      <S.SecureConnection>
        <LockIcon />
        <HideOnMobile> {contentUtil.translate('secureConnection')}</HideOnMobile>
      </S.SecureConnection>
    </S.Header>
  );
};
