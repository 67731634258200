import { appSettings } from 'config/app.settings';

export enum PageIds {
  'dashboard' = 'dashboard',
}

export function getGuruPageId(subject?: PageIds): string {
  const baseUrl = appSettings.ENV_FEATURE_CONFIG_BASE_URL;

  switch (subject) {
    case PageIds.dashboard:
      return `${baseUrl}/overzicht`;
    default:
      return location.href;
  }
}
