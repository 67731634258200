import { IContent } from 'models/content.model';

const content: IContent = {
  nl: {
    CLOSED: 'Gesloten',
    OPEN: 'In behandeling',
    AWAITING_RESPONSE: 'Meer informatie nodig',
  },
  en: {},
};

export default content;
