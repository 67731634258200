import { appSettings } from 'config/app.settings';
import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    CARE: {
      phoneNumber: {
        internal: '1200',
        external: '0900 - 1884',
      },
      tariff: {
        internal: 'Gratis vanaf je telefoonaansluiting van Ziggo',
        external: 'Normaal tarief',
      },
    },
    SALES: {
      phoneNumber: '0900 - 0730',
      tariff: 'Normaal tarief',
    },
    tariffLink: `${appSettings.ENV_ZIGGO_URL}/klantenservice/abonnement/tarieven/wat-is-het-normale-tarief/`,
    callCenterAvailability: 'Bellen kan dagelijks van 8.00 tot 20.00 uur. Ook op feestdagen.',
  },
  en: {},
};
