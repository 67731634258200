import { gql } from 'graphql-request';

export const getEmailAddressesQuery = gql`
  query getEmailAddresses($billingCustomerId: String!) {
    getEmailAddresses(billingCustomerId: $billingCustomerId) {
      maxEmailBoxesAllowed
      maxAliasesPerEmailBoxAllowed
      emailAddresses {
        identifier
        emailAddress
        aliases
        status
      }
    }
  }
`;

export const addEmailAddressQuery = gql`
  mutation addEmailAddress($billingCustomerId: String!, $emailAddress: EmailAddressInput!) {
    addEmailAddress(billingCustomerId: $billingCustomerId, emailAddress: $emailAddress) {
      emailAddress
      aliases
      status
    }
  }
`;

export const deleteEmailAddressQuery = gql`
  mutation deleteEmailAddress($billingCustomerId: String!, $emailAddress: String!) {
    deleteEmailAddress(billingCustomerId: $billingCustomerId, emailAddress: $emailAddress) {
      success
    }
  }
`;

export const addEmailAddressAliasQuery = gql`
  mutation addEmailAddressAlias($billingCustomerId: String!, $emailAddress: String!, $alias: String!) {
    addEmailAddressAlias(billingCustomerId: $billingCustomerId, emailAddress: $emailAddress, alias: $alias) {
      success
    }
  }
`;

export const deleteEmailAddressAliasQuery = gql`
  mutation deleteEmailAddressAlias($billingCustomerId: String!, $emailAddress: String!, $alias: String!) {
    deleteEmailAddressAlias(billingCustomerId: $billingCustomerId, emailAddress: $emailAddress, alias: $alias) {
      success
    }
  }
`;

export const changeEmailAddressPasswordQuery = gql`
  mutation changeEmailAddressPassword($billingCustomerId: String!, $emailAddress: String!, $password: String!) {
    changeEmailAddressPassword(
      billingCustomerId: $billingCustomerId
      emailAddress: $emailAddress
      password: $password
    ) {
      success
    }
  }
`;
