import { gql } from 'graphql-request';

export const getAppointmentsQuery = gql`
  query getAppointments($billingCustomerId: String!) {
    getAppointments(billingCustomerId: $billingCustomerId) {
      address {
        city
        houseNumber
        houseNumberExtension
        postCode
        roomNumber
        streetName
      }
      allowCancel
      allowReschedule
      appointmentContact {
        email
        phone
      }
      appointmentSlot {
        endDateTime
        startDateTime
      }
      appointmentStatus
      ticketId
      caseId
    }
    getAppointmentPermissions {
      needsCancellationReason
    }
  }
`;

export const getAppointmentSlotsQuery = gql`
  query getAppointmentSlots($billingCustomerId: String!, $requestId: String!, $dateFrom: String!) {
    getAppointmentSlots(billingCustomerId: $billingCustomerId, requestId: $requestId, dateFrom: $dateFrom) {
      appointmentId
      slots {
        endDateTime
        startDateTime
      }
    }
  }
`;

export const createAppointmentSlotListQuery = gql`
  query createAppointmentSlotList($billingCustomerId: String!, $orderId: String!, $dateFrom: String!) {
    createAppointmentSlotList(billingCustomerId: $billingCustomerId, orderId: $orderId, dateFrom: $dateFrom) {
      appointmentId
      slots {
        endDateTime
        startDateTime
      }
    }
  }
`;

export const createAppointmentMutation = gql`
  mutation createAppointment($billingCustomerId: String!, $appointmentCreate: AppointmentCreateInput!) {
    createAppointment(billingCustomerId: $billingCustomerId, appointmentCreate: $appointmentCreate) {
      success
    }
  }
`;

export const cancelAppointmentMutation = gql`
  mutation cancelAppointment(
    $billingCustomerId: String!
    $appointmentCancel: AppointmentCancelInput!
    $requestId: String!
    $caseId: String!
  ) {
    cancelAppointment(
      billingCustomerId: $billingCustomerId
      appointmentCancel: $appointmentCancel
      requestId: $requestId
      caseId: $caseId
    ) {
      success
    }
  }
`;

export const rescheduleAppointmentMutation = gql`
  mutation rescheduleAppointment(
    $billingCustomerId: String!
    $appointmentReschedule: AppointmentRescheduleInput!
    $requestId: String!
  ) {
    rescheduleAppointment(
      billingCustomerId: $billingCustomerId
      appointmentReschedule: $appointmentReschedule
      requestId: $requestId
    ) {
      success
    }
  }
`;
