export enum EmailStatus {
  ENABLED = 'ENABLED',
  LOCKED = 'LOCKED',
  DISABLED = 'DISABLED',
}

export interface EmailAddressInfo {
  identifier: string;

  /**
   *
   * @type {string}
   * @memberof EmailAddressInfo
   */
  emailAddress: string;
  /**
   *
   * @type {Array<string>}
   * @memberof EmailAddressInfo
   */
  aliases: Array<string>;
  /**
   *
   * @type {EmailStatus}
   * @memberof EmailAddressInfo
   */
  status: EmailStatus;
}

/**
 *
 * @export
 * @interface GetEmailAddress
 */
export interface GetEmailAddress {
  /**
   *
   * @type {number}
   * @memberof GetEmailAddress
   */
  maxEmailBoxesAllowed: number;
  /**
   *
   * @type {number}
   * @memberof GetEmailAddress
   */
  maxAliasesPerEmailBoxAllowed: number;
  /**
   *
   * @type {Array<EmailAddressInfo>}
   * @memberof GetEmailAddress
   */
  emailAddresses: Array<EmailAddressInfo>;
}

export interface EmailAddress {
  /**
   * Backend checks if the email address ends on \'@ziggo.nl\'. Supported special characters: !#$%&*+-=?^-_.
   * @type {string}
   * @memberof EmailAddress
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof EmailAddress
   */
  firstName: string;
  /**
   * Because the external email system does not accept a middle name, it is merged with the last name
   * @type {string}
   * @memberof EmailAddress
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof EmailAddress
   */
  password: string;
}
