import { Form as FormComponent } from 'components/Form';
import styled from 'theme';

export const SubHeader = styled.div`
  & > p {
    color: ${(p) => p.theme.colors.grayD10};
  }
`;

export const Divider = styled.span`
  display: block;
  height: 3px;

  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1em;

  background-color: ${(p) => p.theme.colors.gray100};
`;

export const InfoContainer = styled.span`
  & > p {
    margin-top: 0px;
  }
`;

export const FormContainer = styled.div`
  & > strong {
    display: block;

    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;

export const Form = styled(FormComponent)`
  & button {
    width: auto;
  }

  .form-button {
    order: 1;
    button:first {
      order: 2;
    }
  }
`;
