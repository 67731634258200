import { InvoiceSummary } from 'api';
import { ErrorCross } from 'components/ErrorCross';
import { InvoicesContext } from 'context/invoices.context';
import { InvoicePaymentDetails } from 'pages/Invoices/components/InvoicePaymentDetails';
import React, { useContext, useEffect, useState } from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './payment-failed.content';
import * as S from './payment-failed.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentFailed' });

interface PaymentFailedProps {
  invoiceId: string;
}

export const PaymentFailed = ({ invoiceId }: PaymentFailedProps) => {
  const [invoice, setInvoice] = useState<InvoiceSummary | null>(null);
  const [hasError, setHasError] = useState(false);

  const { getInvoiceById } = useContext(InvoicesContext);

  useEffect(() => {
    getInvoiceById(invoiceId)
      .then((response) => setInvoice(response))
      .catch(() => setHasError(true));
  }, [invoiceId]);

  return (
    <>
      <S.TopContent data-testid="payment-failed">
        <S.Title>
          <h1>{contentUtil.translate('title')}</h1>
        </S.Title>
        <S.Content>{contentUtil.translateHtml('content')}</S.Content>
        {/* <S.Link><a href="https://www.ziggo.nl/klantenservice/abonnement/factuur/betalen/" target="_blank">{contentUtil.translateHtml('linkTextBetaalmogelijkheden')}</a></S.Link> */}
        {/* <S.Link><NavLink to="/facturen">{contentUtil.translateHtml('linkTextFacturen')}</NavLink></S.Link> */}
        {hasError && <ErrorCross>{contentUtil.translate('error')}</ErrorCross>}
      </S.TopContent>
      {invoice && <InvoicePaymentDetails invoice={invoice} />}
    </>
  );
};
