import { createGlobalStyle } from '@vodafoneziggo/sandwich';
import { css, rem, screenMobilePlusAndBigger } from 'theme';

export const Headings = createGlobalStyle`
  h1, h2, h3, h4, h5 {
    font-family: ${(p) => p.theme.fonts.stacks.secondary};
    margin: 0;
    line-height: 2;
  }

  h1 {
    font-size: ${rem(28)};

    ${screenMobilePlusAndBigger(css`
      font-size: ${rem(36)};
      line-height: ${rem(44)};
    `)};
  }

  h2 {
    font-size: ${rem(20)};
    margin: 0;

    ${screenMobilePlusAndBigger(css`
      font-size: ${rem(30)};
      line-height: ${rem(32)};
    `)};
  }

  h3 {
    font-size: ${rem(18)};

    ${screenMobilePlusAndBigger(css`
      font-size: ${rem(24)};
      line-height: ${rem(32)};
    `)}
  }

  h4 {
    font-size: ${rem(20)};
    line-height: ${rem(24)};

  }

  h5 {
    font-size: ${rem(16)};
    margin: 0;

      ${screenMobilePlusAndBigger(css`
        font-size: ${rem(18)};
        line-height: ${rem(20)};
      `)};
  }
`;
