export enum MarketingSettingCategoryEnum {
  MARKETING = 'MARKETING',
  CUSTOMERSURVEY = 'CUSTOMERSURVEY',
  SHARE = 'SHARE',
}

export enum PrivacySettingEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  LETTER = 'LETTER',
  DOORTODOOR = 'DOOR_TO_DOOR',
  WIFIUSAGE = 'WIFI_USAGE',
  PHONEUSAGE = 'PHONE_USAGE',
  LOCATION = 'LOCATION',
  PROFILING = 'PROFILING',
  THIRDPARTY = 'THIRD_PARTY',
  CUSTOMERRESEARCH = 'CUSTOMER_RESEARCH',
}

export interface PrivacySetting {
  id: PrivacySettingEnum;
  value: boolean;
  enabled: boolean;
}

export interface MarketingSettings {
  category: MarketingSettingCategoryEnum;
  settings: PrivacySetting[];
}

export type MarketingSettingsSubscribeType =
  | 'deur-tot-deur'
  | 'sms'
  | 'telefoon'
  | 'online-partners'
  | 'email'
  | 'post'
  | 'wifi-gebruik'
  | 'locatiegegevens'
  | 'profielen'
  | 'telefoon-gebruik'
  | 'klant-onderzoek';
