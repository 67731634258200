import { Radio } from 'components/Form';
import React from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './payment-arrangement.content';
import * as S from './payment-arrangement.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentInstallmentsRadioGroup' });

export interface IPaymentInstallmentsProps {
  options: { value: string; label: string }[];
}
export const PaymentInstallmentsRadioGroup = ({ options }: IPaymentInstallmentsProps) => {
  return (
    <S.RadioContent>
      <S.Text>{contentUtil.translateToString('createPA.paymentOptions')}</S.Text>
      <Radio id="selectedInstallmentId" name="selectedInstallmentId" options={options} />
      <div>{contentUtil.translateToString('createPA.paymentClarification')}</div>
    </S.RadioContent>
  );
};
