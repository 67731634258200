import styled from 'theme';

export const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: ${(p) => p.theme.grid.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
