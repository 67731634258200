import { WorkaroundTypeEnum } from 'api/workaround.types';
import { FEATURES } from 'models/features.model';

export interface WorkaroundContext {
  feature: FEATURES;
  url: string;
  workaroundType: WorkaroundTypeEnum;
}

export const CHANGE_INSTALLATION_ADDRESS: WorkaroundContext = {
  feature: FEATURES.CHANGE_INSTALLATION_ADDRESS,
  url: 'verhuizing-doorgeven',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_PRODUCT: WorkaroundContext = {
  feature: FEATURES.CHANGE_PRODUCT,
  url: 'pakket-wijzigen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_PRODUCT_TV: WorkaroundContext = {
  feature: FEATURES.CHANGE_PRODUCT_TV,
  url: 'tv-abbonement-wijzigen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_TV_PACKAGES: WorkaroundContext = {
  feature: FEATURES.CHANGE_TV_PACKAGES,
  url: 'zenderpakketten-wijzigen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_RECEIVERS: WorkaroundContext = {
  feature: FEATURES.CHANGE_RECEIVERS,
  url: 'digitale-ontvanger-aanvragen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_PRODUCT_INTERNET: WorkaroundContext = {
  feature: FEATURES.CHANGE_PRODUCT_INTERNET,
  url: 'internet-product-wijzigen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_INTERNET_EQUIPMENT: WorkaroundContext = {
  feature: FEATURES.CHANGE_INTERNET_EQUIPMENT,
  url: 'wifi-booster-aanvragen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_SAFE_ONLINE: WorkaroundContext = {
  feature: FEATURES.CHANGE_SAFE_ONLINE,
  url: 'change-safe-online',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const CHANGE_EXTRA_SERVICES: WorkaroundContext = {
  feature: FEATURES.CHANGE_EXTRA_SERVICES,
  url: 'change-extra-services',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const ORDER_DAY_PASS: WorkaroundContext = {
  feature: FEATURES.ORDER_DAY_PASS,
  url: 'dagpas-bestellen',
  workaroundType: WorkaroundTypeEnum.SALES,
};

export const workaroundContexts: WorkaroundContext[] = [
  CHANGE_INSTALLATION_ADDRESS,
  CHANGE_PRODUCT,
  CHANGE_PRODUCT_TV,
  CHANGE_TV_PACKAGES,
  CHANGE_RECEIVERS,
  CHANGE_PRODUCT_INTERNET,
  CHANGE_INTERNET_EQUIPMENT,
  CHANGE_SAFE_ONLINE,
  CHANGE_EXTRA_SERVICES,
  ORDER_DAY_PASS,
];
