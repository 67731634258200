import styled, { rem } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

interface SelectProps {
  variant?: string;
}

export const Chevron = styled('div')`
  text-align: center;
  top: calc(50% - ${rem(20)});
  left: calc(100% - ${rem(42)});
  position: absolute;
  height: 30px;
  width: 39px;
  display: inline-block;
  pointer-events: none;

  svg {
    width: ${rem(15)};
    height: ${rem(30)};
  }
`;

export const Select = styled.select<SelectProps>`
  appearance: none;
  background: transparent;
  box-shadow: ${boxShadow.inset()};
  border-radius: ${borderRadius.input};
  position: relative;
  padding: ${rem(10)} ${rem(20)} ${rem(10)};
  width: 100%;
  height: ${rem(50)};
  border: ${rem(1)} solid;
  border-color: ${(p) => p.theme.colors.gray600};

  &:disabled {
    box-shadow: ${(p) => boxShadow.inset(p.theme.colors.grayL10)};
  }

  &::-ms-expand {
    display: none;
  }
`;

export const Label = styled('label')`
  align-self: center;
  position: relative;
  width: 100%;
`;
