import { OidcConfig } from '@hawaii-framework/oidc-implicit-core';

import { appSettings } from './app.settings';

export const DEFAULT_OIDC_SCOPES = [
  'openid',
  'email',
  'email_confirmation',
  'self_service',
  'hawaii_sso',
  'hawaii_sso_fixed',
  'idm',
  'ziggo',
  'two_factor',
];

// Authentication config for the oidc Service
export const GetOidcConfig = (): OidcConfig => ({
  response_type: 'id_token token',
  authorisation: `${appSettings.ENV_REST_SSO_URL}/sso/`,
  post_logout_redirect_uri: `${appSettings.ENV_VODAFONE_ACCOUNT_URL}/account/uitgelogd?login=ziggo-selfcare`,
  token_type: 'Bearer',
  authorize_endpoint: `${appSettings.ENV_REST_SSO_URL}/sso/authorize`,
  csrf_token_endpoint: `${appSettings.ENV_REST_SSO_URL}/sso/csrf`,
  validate_token_endpoint: `${appSettings.ENV_REST_BASE_URL_IDM}/check-tokens`,
  is_session_alive_endpoint: `${appSettings.ENV_REST_BASE_URL_IDM}/user-sessions`,
  upgrade_session_endpoint: `${appSettings.ENV_REST_SSO_URL}/sso/session-upgrade`,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`,
  login_endpoint: `${appSettings.ENV_REST_SSO_URL}/sso/j_spring_security_check`,
  logout_endpoint: `${appSettings.ENV_REST_SSO_URL}/sso/logout`,
  restricted_redirect_uris: ['uitloggen', 'uitgelogd', 'inloggen', 'maak-een-keuze'],
  silent_refresh_uri: `${appSettings.ENV_FEATURE_CONFIG_BASE_URL}/static/silent.html`,
  silent_logout_uri: `${appSettings.ENV_VODAFONE_ACCOUNT_URL}/account/uitloggen`,
  provider_id: 'ziggo-selfservice',
  client_id: `${appSettings.ENV_SSO_CLIENT_ZIGGO_SELFSERVICE}`,
  scope: DEFAULT_OIDC_SCOPES.join(' '),
  debug: true,
});
