import { IContent } from 'models/content.model';

const content: IContent = {
  nl: {
    pageTitle: 'Vragen en verzoeken',
    somethingWrong: 'Er is iets fout gegaan bij het laden van je tickets.',
    table: {
      headings: {
        reference: 'Referentienummer',
        title: 'Titel',
        date: 'Datum',
        status: 'Status',
        details: 'Details',
      },
      viewDetails: 'Bekijk details',
      noContent: 'Je hebt geen tickets',
    },
  },
  en: {},
};

export default content;
