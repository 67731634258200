import React from 'react';

export const PasswordEyeCrossedIcon = ({ title }: { title: string }) => (
  <svg role="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <title>{title}</title>
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M10.176 12.992l-.609 1.09c.144.017.285.045.433.045 2.112 0 3.889-1.723 3.889-3.888 0-.892-.316-1.716-.836-2.377l-.611 1.09c.21.388.338.823.338 1.287 0 1.44-1.133 2.614-2.604 2.753zm1.27-6.356A3.84 3.84 0 0 0 10 6.348c-2.111 0-3.888 1.723-3.888 3.89 0 1.25.632 2.375 1.582 3.09l-.852 1.519a10.426 10.426 0 0 1-1.871-.822c-1.593-.91-2.877-2.13-3.8-3.597a.44.44 0 0 1-.071-.19c0-.045.025-.111.055-.164C2.094 8.58 3.378 7.36 4.971 6.45 6.555 5.548 8.246 5.089 10 5.089a9.81 9.81 0 0 1 2.166.259l-.72 1.288zM8.235 12.36a2.771 2.771 0 0 1-1.012-2.122A2.785 2.785 0 0 1 10 7.459c.317 0 .613.065.893.16l-2.658 4.742zm5.898-9.496a.5.5 0 0 0-.682.19l-.718 1.285A10.866 10.866 0 0 0 10 3.988c-1.957 0-3.814.503-5.575 1.508-1.76 1.007-3.159 2.337-4.2 3.993-.15.254-.225.503-.225.749s.075.496.225.749c1.041 1.656 2.44 2.987 4.2 3.993.61.348 1.237.615 1.87.842l-.621 1.108a.504.504 0 0 0 .438.746.504.504 0 0 0 .437-.255l.298-.532c.002-.002.001-.005.003-.008l7.476-13.34a.498.498 0 0 0-.193-.676zm5.643 6.624c-1.041-1.656-2.442-2.986-4.202-3.993a10.03 10.03 0 0 0-.932-.47l-.54.964c.312.136.622.288.926.46 1.595.912 2.878 2.13 3.8 3.598a.428.428 0 0 1 .073.19.392.392 0 0 1-.057.166c-.938 1.498-2.221 2.718-3.814 3.625-1.583.902-3.276 1.359-5.03 1.359-.379 0-.752-.027-1.123-.075l-.576 1.024c.56.09 1.123.15 1.699.15 1.957 0 3.815-.5 5.574-1.502 1.76-1.002 3.161-2.334 4.202-3.998.15-.253.224-.503.224-.75 0-.245-.074-.494-.224-.748z"
    />
  </svg>
);
