import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    title: 'Betaling in behandeling',
    linkText: 'Naar je facturen',
    content:
      'De verwerking van je betaling kan 24 uur duren.<br/>Daarna zie je in Mijn Ziggo of je betaling is gelukt.',
  },
  en: {},
};
