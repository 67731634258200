import { ChevronIcon } from 'assets/icons';
import React from 'react';

import * as S from './link.styles';

export interface ILinkProps {
  children: React.ReactNode;
  href?: string;
  testid?: string;
  to?: string;
  onClick?: () => void;
  external?: boolean;
  primary?: boolean;
  listitem?: boolean;
  inline?: boolean;
  disabled?: boolean;
}

export const Link = ({
  external,
  children,
  href,
  to,
  testid,
  onClick,
  listitem,
  primary,
  inline,
  ...rest
}: ILinkProps) => {
  // If we pass boolean values here, we will get pesky console errors. So we convert it to a number first.
  // Have not found a desirable solution for it. More info: https://github.com/styled-components/styled-components/issues/1198
  const listItemNr = listitem ? 1 : 0;
  const primaryNr = primary ? 1 : 0;
  const inlineNr = inline ? 1 : 0;

  if (href) {
    return (
      <S.Link
        href={href}
        // @ts-expect-error TS(2769): No overload matches this call.
        testid={testid}
        target={external ? '_blank' : '_self'}
        listitem={listItemNr}
        primary={primaryNr}
        inline={inlineNr}
        {...rest}>
        {children} {listitem && <ChevronIcon className="list-item" />}
      </S.Link>
    );
  }

  if (to) {
    return (
      <S.InternalLink to={to} listitem={listItemNr} primary={primaryNr} {...rest}>
        {children} {listitem && <ChevronIcon className="list-item" />}
      </S.InternalLink>
    );
  }

  return (
    <S.Button onClick={onClick} listitem={listItemNr} primary={primaryNr} {...rest}>
      {children} {listitem && <ChevronIcon className="list-item" />}
    </S.Button>
  );
};
