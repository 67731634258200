import { rem } from 'theme/mixins/calculate';

export const desktopGapMultiply = 2;
export const mobileGapMultiply = 1;
export const gapSizeValue = 8;

export const desktopSize = rem(gapSizeValue * desktopGapMultiply);
export const mobileSize = rem(gapSizeValue * mobileGapMultiply);
export const spacingBase = rem(gapSizeValue);
export const gapSize = (gap: number) => (gap ? rem(gap * 8) : 0);
