import { gql } from 'graphql-request';

export const getSearchResultQuery = gql`
  query getSearchResult($query: String!) {
    getSearchResult(query: $query) {
      context_ids
      message
      timestamp
    }
  }
`;
