import { Button as ButtonComponent } from 'components/Button';
import { Link as LinkComponent } from 'components/Link';
import styled, { css, gapSize, rem, tablet } from 'theme';

export const Button = styled(ButtonComponent)`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: ${() => gapSize(2)};
  position: relative;

  > svg {
    display: none;
  }

  ${tablet(css`
    margin-right: ${() => gapSize(2)};
    height: ${rem(184)};
    width: ${rem(184)};
    display: inline-flex;

    > svg {
      display: inline-block;
      width: ${rem(118)};
      height: ${rem(118)};
    }
  `)}

  > span {
    margin: auto;
  }
`;

export const Rotate = styled('div')`
  transform: rotate(0.75turn);

  svg {
    margin: 0 0 ${() => gapSize(1)} 0;
  }
`;

export const Link = styled(LinkComponent)`
  text-decoration: none;
`;
