import * as url from 'url';

import { AxiosPromise } from 'axios';

import RequiredError from 'error/RequiredError';
import ApiFactory from './api-factory';
import { TicketReference } from './privacy.types';
import { Workaround } from './workaround.types';

export interface WorkaroundApiInterface {
  /**
   * Submit workaround.
   * @param {string} billingCustomerId Billing customer id.
   * @param {Workaround} [workaround]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkaroundApiInterface
   */
  submitWorkaround(billingCustomerId: string, workaround?: Workaround, options?: any): AxiosPromise<TicketReference>;
}

export const WorkaroundApi: WorkaroundApiInterface = {
  /**
   * Submit workaround.
   * @param {string} billingCustomerId Billing customer id.
   * @param {Workaround} [workaround]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  submitWorkaround(billingCustomerId: string, workaround?: Workaround, options: any = {}): AxiosPromise {
    // verify required parameter 'billingCustomerId' is not null or undefined
    if (billingCustomerId === null || billingCustomerId === undefined) {
      throw new RequiredError(
        'billingCustomerId',
        'Required parameter billingCustomerId was null or undefined when calling submitWorkaround.'
      );
    }

    const localVarQueryParameter = {} as any;

    const localVarPath = `/selfservice/rest/v1/billing-customers/{billingCustomerId}/workaround`.replace(
      `{${'billingCustomerId'}}`,
      encodeURIComponent(String(billingCustomerId))
    );
    const localVarUrlObj = url.parse(localVarPath, true);
    const method = 'POST';

    const contentType = 'application/json';
    const data = workaround;

    localVarUrlObj.query = (<any>Object).assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
    return ApiFactory.getInstance()
      .getApi()
      .api({
        ...options,
        url: url.format(localVarUrlObj),
        method,
        data,
        headers: {
          'Content-Type': contentType,
        },
      });
  },
};
