import { getAddressInfosQuery, lookupDeliveryAddressQuery, putAddressInfosQuery } from './address-info.query';
import { AddressInfo } from './address-info.types';
import { ApiResponse, query } from './api';
import { OrderAddress } from './order-status.types';

interface AddressInfoApiInterface {
  getAddressInfos(billingCustomerId: string): ApiResponse<AddressInfo[]>;
  lookupDeliveryAddress(postalCode: string, houseNumber: number, extension?: string): ApiResponse<OrderAddress[]>;
  updateAddressInfos(billingCustomerId: string, addressInfo: AddressInfo): ApiResponse<{ success: boolean }>;
}

const AddressInfoApi: AddressInfoApiInterface = {
  getAddressInfos(billingCustomerId) {
    return query({
      query: getAddressInfosQuery,
      variables: {
        billingCustomerId,
      },
      name: 'getAddressInfos',
    }).then(
      ({
        getAddresses,
        getAddressPermissions,
      }: {
        getAddresses: AddressInfo[];
        getAddressPermissions: Record<string, boolean>;
      }) => {
        const { canEditCountry } = getAddressPermissions;

        return {
          data: getAddresses.map((address) => ({
            ...address,
            canEditCountry,
          })),
        };
      }
    );
  },
  lookupDeliveryAddress(postalCode: string, houseNumber: number, houseNumberAddition?: string) {
    return query({
      query: lookupDeliveryAddressQuery,
      variables: {
        postalCode,
        houseNumber,
        houseNumberAddition,
      },
      name: 'lookupDeliveryAddress',
    }).then(({ lookupAddress }) => ({ data: lookupAddress }));
  },
  updateAddressInfos(billingCustomerId, addressInfo) {
    return query({
      query: putAddressInfosQuery,
      variables: {
        billingCustomerId,
        addressInfo: [addressInfo],
      },
      name: 'putAddressInfos',
    }).then(({ putAddress }) => ({ data: putAddress }));
  },
};

export { AddressInfoApi };
