import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalButtons } from 'components/Modal/modal.styles';
import { SectionHeader } from 'components/SectionHeader';
import { useOutageContext } from 'context/outage/outage.context';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from '../../pages/Outage/pages/outage-overview.content';

const contentUtil = new ContentUtil({ content, componentName: 'OutageOverview' });

const OutageSuccessModal = () => {
  const { showSuccessModal, successModalType, hideSuccessModal, getOutages } = useOutageContext();

  const closeModalAndRefresh = () => {
    hideSuccessModal();
    getOutages();
  };

  return (
    <>
      {showSuccessModal && (
        <Modal onClose={() => closeModalAndRefresh()}>
          {(closeModal) => (
            <section>
              <SectionHeader>{contentUtil.translateToString(`${successModalType}SuccessModal.header`)}</SectionHeader>
              <p>{contentUtil.translateToString(`${successModalType}SuccessModal.content`)}</p>

              <ModalButtons>
                <Button onClick={closeModal}>
                  {contentUtil.translateToString(`${successModalType}SuccessModal.confirm`)}
                </Button>
              </ModalButtons>
            </section>
          )}
        </Modal>
      )}
    </>
  );
};

export default OutageSuccessModal;
