export enum BrowserEnum {
  opera = 'opera',
  edge = 'edge',
  chrome = 'chrome',
  safari = 'safari',
  firefox = 'firefox',
  ie = 'ie',
  app = 'app',
}

const isMobile = (): boolean => {
  const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
  return regexs.some((b) => navigator.userAgent.match(b));
};

const isTablet = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(ip|ap|wp))))/;
  return regex.test(navigator.userAgent.toLowerCase());
};

const isDesktop = (): boolean => {
  return !isMobile() && !isTablet();
};

const getBrowser = (): BrowserEnum | 'unknown' => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return BrowserEnum.opera;
  }
  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return BrowserEnum.edge;
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return BrowserEnum.chrome;
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return BrowserEnum.safari;
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return BrowserEnum.firefox;
  }
  if (navigator.userAgent.indexOf('MSIE') !== -1 || !!(document as any).documentMode === true) {
    return BrowserEnum.ie;
  }
  if (navigator.userAgent.indexOf('vz-app') !== -1) {
    return BrowserEnum.app;
  }

  return 'unknown';
};

const isBrowser = (expectedBrowser: BrowserEnum): boolean => {
  const browser = getBrowser();

  return browser === expectedBrowser;
};

export const deviceUtils = {
  isMobile,
  isTablet,
  isDesktop,
  getBrowser,
  isBrowser,
};
