import { css, desktop, tablet } from 'theme';

interface IBackgroundImageSettings {
  mobile: string | null;
  tablet: string | null;
  desktop: string | null;
}

/**
 * Will add a background image based on screen size to the css
 * @param settings object with url's for mobile, tablet and desktop variants. If any are set to `null`, no image will be shown for that screen size
 */
export const backgroundImage = (settings: IBackgroundImageSettings) => css`
  ${settings.mobile && `background-image: url('${settings.mobile}');`}
  ${settings.tablet &&
  tablet(css`
    background-image: url('${settings.tablet}');
  `)}
  ${settings.desktop &&
  desktop(css`
    background-image: url('${settings.desktop}');
  `)}
`;
