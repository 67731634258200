import { CrossIcon } from 'assets/icons';
import { DisableBodyScroll } from 'components/DisableBodyScroll';
import { useClickOutsideAndKeyNavigation } from 'hooks/use-click-outside-and-key-navigation.hook';
import React, { RefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import * as S from './modal.styles';

interface IModalProps {
  onClose?: () => void;
  ignoreFormStyling?: boolean;
  children: ((closeModal: () => void) => React.ReactNode) | React.ReactNode;
}

const ModalComp = ({ onClose, children, ignoreFormStyling }: IModalProps) => {
  const [isClosing, setIsClosing] = useState(false);

  const ref = useClickOutsideAndKeyNavigation((_event: MouseEvent | TouchEvent | KeyboardEvent) => {
    closeModal();
  }, true);

  const closeModal = () => {
    if (!isClosing) {
      setIsClosing(true);
    }
    onClose && onClose();
  };

  if (isClosing) return null;

  return (
    <S.ModalWrapper isClosing={isClosing} data-testid="modal-wrapper">
      <S.Modal
        ref={ref as RefObject<HTMLDivElement>}
        isClosing={isClosing}
        ignoreFormStyling={ignoreFormStyling}
        data-testid="modal">
        <S.CloseButton onClick={closeModal} data-testid="modal-close-button">
          <CrossIcon />
        </S.CloseButton>
        {children instanceof Function ? children(closeModal) : children}
        <DisableBodyScroll />
      </S.Modal>
    </S.ModalWrapper>
  );
};

export const Modal = ({ children, onClose, ignoreFormStyling }: IModalProps) => {
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'));
  }, []);

  if (!modalRoot) return null;

  return createPortal(
    <ModalComp onClose={onClose} ignoreFormStyling={ignoreFormStyling}>
      {children}
    </ModalComp>,
    modalRoot
  );
};
