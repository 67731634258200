import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { FeatureToggle } from 'components/FeatureToggle';
import { Restricted } from 'components/Restricted';
import { PRODUCTS, USAGE_DASHBOARD } from 'config/access-state-features.config';
import { appSettings } from 'config/app.settings';
import { AccessStateContext } from 'context/access-state.context';
import { useProductContext } from 'context/product/product.context';
import { FEATURES } from 'models/features.model';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentUtil } from 'utils';

import { content } from '../../base-layout.content';
import * as S from './navigation.styles';

const contentUtil = new ContentUtil({ content, componentName: 'Navigation' });

const Navigation = () => {
  const { hasProducts } = useProductContext();
  const { currentUserCeased } = useContext(AccessStateContext);
  const location = useLocation();
  const isActive = (pathname: string) => location.pathname.startsWith(pathname);
  const homeActiveclass = location.pathname === '/' || isActive('/overzicht') ? 'active' : '';

  return (
    <S.Navigation>
      <Wrapper>
        <S.List>
          <S.Item isActive={Boolean(homeActiveclass)}>
            <S.Link to="/overzicht" className={homeActiveclass}>
              {contentUtil.translate('dashboard')}
            </S.Link>
          </S.Item>
          <Restricted permissions={PRODUCTS}>
            <S.Item data-testid="navigation-products" isActive={isActive('/producten')}>
              <S.Link end={false} to="/producten">
                {contentUtil.translate('products')}
              </S.Link>
            </S.Item>
          </Restricted>
          <S.Item isActive={isActive('/gegevens')}>
            <S.Link to="/gegevens">{contentUtil.translate('contactDetails')}</S.Link>
          </S.Item>
          <S.Item isActive={isActive('/facturen')}>
            <S.Link to="/facturen">{contentUtil.translate('invoices')}</S.Link>
          </S.Item>
          <Restricted permissions={USAGE_DASHBOARD}>
            <S.Item data-testid="navigation-usage" isActive={isActive('/verbruik')}>
              <S.Link to="/verbruik">{contentUtil.translate('usage')}</S.Link>
            </S.Item>
          </Restricted>
          <FeatureToggle feature={FEATURES.PAGE_ENTERTAINMENT}>
            <S.Item isActive={isActive('/entertainment')}>
              <S.Link to="/entertainment">{contentUtil.translate('entertainment')}</S.Link>
            </S.Item>
          </FeatureToggle>
          {hasProducts() && !currentUserCeased && (
            <FeatureToggle feature={FEATURES.SERVICE_PAGE}>
              <S.Item isActive={false}>
                {' '}
                {/* Since this is an external route */}
                <S.Link
                  as="a"
                  href={
                    appSettings.ENV_REDIRECT_URI
                      ? `${appSettings.ENV_REDIRECT_URI}/service`
                      : `${appSettings.ENV_ZIGGO_URL}/mijn-ziggo/service`
                  }>
                  {contentUtil.translate('service')}
                </S.Link>
              </S.Item>
            </FeatureToggle>
          )}
        </S.List>
      </Wrapper>
    </S.Navigation>
  );
};

export default Navigation;
