import { MarketingSettings, MarketingSettingsApi } from 'api';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { BillingCustomerContext } from './billing-customer.context';
import { useProductContext } from './product/product.context';

export interface IContext {
  privacySettings: MarketingSettings[];
  hasError: boolean;
  isLoading: boolean;
  updateSettings: (settings: MarketingSettings[]) => Promise<boolean>;
  equalsCurrentState: (settings: MarketingSettings[]) => boolean;
}

const intialState: IContext = {
  isLoading: false,
  hasError: false,
  privacySettings: [],
  updateSettings: (_settings: MarketingSettings[]) => Promise.reject(),
  equalsCurrentState: (_settings: MarketingSettings[]) => true,
};

const MarketingSettingsContext = React.createContext(intialState);

const MarketingSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const bcContext = useContext(BillingCustomerContext);
  const { hasDashboardProductsLoaded } = useProductContext();

  const activeCustomerId = useMemo(() => {
    const { activeBcId } = bcContext;
    return activeBcId;
  }, [bcContext.activeBcId]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [privacySettings, setPrivacySettings] = useState<MarketingSettings[]>([]);

  useEffect(() => {
    if (hasDashboardProductsLoaded) getSettings();
  }, [hasDashboardProductsLoaded]);

  const getSettings = () => {
    setHasError(false);
    setIsLoading(true);
    return MarketingSettingsApi.getMarketingSettings(activeCustomerId)
      .then((response) => {
        setPrivacySettings(response.data);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSettings = async (settings: MarketingSettings[]) => {
    setHasError(false);
    setIsLoading(true);

    // @ts-expect-error TS(2339): Property 'success' does not exist on type '{ data:... Remove this comment to see the full error message
    const { success } = await MarketingSettingsApi.updateMarketingSettings(activeCustomerId, settings);
    setIsLoading(false);
    setHasError(!success);

    return success;
  };

  const equalsCurrentState = (modifiedSettings: MarketingSettings[]) => {
    const currentSettings = privacySettings.flatMap(({ category, settings }) =>
      settings.map((setting) => ({ ...setting, id: `${category}___${setting.id}` }))
    );

    const newSettings = modifiedSettings.flatMap(({ category, settings }) =>
      settings.map((setting) => ({ ...setting, id: `${category}___${setting.id}` }))
    );

    return currentSettings.length === newSettings.length
      ? currentSettings.every((s) => {
          const newSetting = newSettings.find((p) => p.id === s.id);
          return newSetting !== undefined && newSetting.value === s.value;
        })
      : false;
  };

  return (
    <MarketingSettingsContext.Provider
      value={{
        isLoading,
        hasError,
        privacySettings,
        updateSettings,
        equalsCurrentState,
      }}>
      {children}
    </MarketingSettingsContext.Provider>
  );
};

export { MarketingSettingsContext, MarketingSettingsProvider };
