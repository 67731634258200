import { gql } from 'graphql-request';

export const getComponentIdentifierQuery = gql`
  query getComponentIdentifier($billingCustomerId: String!, $componentIdentifier: String!) {
    getComponentIdentifier(billingCustomerId: $billingCustomerId, componentIdentifier: $componentIdentifier) {
      enabled
    }
  }
`;

export const getComponentIdentifierForProductQuery = gql`
  query getComponentIdentifierForProduct(
    $billingCustomerId: String!
    $componentIdentifier: String!
    $assignedProductId: String!
  ) {
    getComponentIdentifierForProduct(
      billingCustomerId: $billingCustomerId
      componentIdentifier: $componentIdentifier
      assignedProductId: $assignedProductId
    ) {
      enabled
    }
  }
`;

export const canRefreshEntitlementsQuery = gql`
  query canRefreshEntitlements($billingCustomerId: String!, $productId: String!, $subProductId: String!) {
    canRefreshEntitlements(billingCustomerId: $billingCustomerId, productId: $productId, subProductId: $subProductId) {
      enabled
    }
  }
`;

export const canRequestPrivacyQuery = gql`
  query canRequestPrivacy($billingCustomerId: String!) {
    canRequestPrivacy(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const canComplaintPrivacyQuery = gql`
  query canComplaintPrivacy($billingCustomerId: String!) {
    canComplaintPrivacy(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const canDeletePrivacyQuery = gql`
  query canDeletePrivacy($billingCustomerId: String!) {
    canDeletePrivacy(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const canUpdatePhoneSettingsQuery = gql`
  query canUpdatePhoneSettings(
    $billingCustomerId: String!
    $componentIdentifier: String!
    $assignedProductId: String!
  ) {
    canUpdatePhoneSettings(
      billingCustomerId: $billingCustomerId
      componentIdentifier: $componentIdentifier
      assignedProductId: $assignedProductId
    ) {
      enabled
    }
  }
`;

export const canChangeSecretCircleQuery = gql`
  query canChangeSecretCircle($billingCustomerId: String!) {
    canChangeSecretCircle(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const canChangeWifiSpotQuery = gql`
  query canChangeWifiSpot($billingCustomerId: String!) {
    canChangeWifiSpot(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;

export const canCreateWifiSpotQuery = gql`
  query canCreateWifiSpot($billingCustomerId: String!) {
    canCreateWifiSpot(billingCustomerId: $billingCustomerId) {
      enabled
    }
  }
`;
