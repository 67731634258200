import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import {
  ApiResponse,
  ComponentIdentifierApi,
  PrivacyApi,
  PrivacyComplaint,
  PrivacyRequestSummary,
  TicketReference,
} from 'api';
import { DEFAULT_OIDC_SCOPES } from 'config/app.config';
import { appSettings } from 'config/app.settings';
import React, { useContext, useMemo, useState } from 'react';

import { BillingCustomerContext } from './billing-customer.context';

export interface IContext {
  isLoading: boolean;
  hasError: boolean;
  sendPrivacyRequest: (contactId: string) => ApiResponse<TicketReference>;
  sendPrivacyDeletionRequest: (contactId: string) => ApiResponse<TicketReference>;
  sendPrivacyComplaint: (contactId: string, complaint: PrivacyComplaint) => ApiResponse<TicketReference>;
  getPrivacyRequestSummary: (requestId?: string) => Promise<PrivacyRequestSummary>;
  downloadPrivacyReport: (requestId?: string) => Promise<void>;
  canRequestPrivacy: () => Promise<boolean>;
  canComplaintPrivacy: () => Promise<boolean>;
  canDeletePrivacy: () => Promise<boolean>;
}

const intialState: IContext = {
  isLoading: true,
  hasError: false,
  sendPrivacyRequest: () => Promise.reject(),
  sendPrivacyDeletionRequest: () => Promise.reject(),
  sendPrivacyComplaint: () => Promise.reject(),
  getPrivacyRequestSummary: () => Promise.reject(),
  downloadPrivacyReport: () => Promise.reject(),
  canRequestPrivacy: () => Promise.reject(),
  canComplaintPrivacy: () => Promise.reject(),
  canDeletePrivacy: () => Promise.reject(),
};

const PrivacyContext = React.createContext(intialState);

const PrivacyProvider = ({ children }: { children: React.ReactNode }) => {
  const bcContext = useContext(BillingCustomerContext);

  // TODO: Check if this actuall works
  const activeCustomerId = useMemo(() => {
    const { activeBcId } = bcContext;
    return activeBcId;
  }, [bcContext.activeBcId]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const sendPrivacyRequest = (contactId: string) => {
    return PrivacyApi.sendPrivacyRequest(activeCustomerId, contactId);
  };

  const sendPrivacyDeletionRequest = (contactId: string) => {
    return PrivacyApi.privacyDelete(activeCustomerId, contactId);
  };

  const sendPrivacyComplaint = (contactId: string, complaint: PrivacyComplaint) => {
    return PrivacyApi.privacyComplaint(activeCustomerId, contactId, complaint);
  };

  const getPrivacyRequestSummary = async (requestId?: string) => {
    setIsLoading(true);
    setHasError(false);
    const response = await PrivacyApi.getPrivacyRequest(activeCustomerId, requestId);
    setIsLoading(false);

    return response.data;
  };

  const downloadPrivacyReport = (requestId?: string): Promise<void> => {
    const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });

    if (token) {
      const authHeader = OidcService.getAuthHeader(token);

      if (authHeader) {
        // remove 'Bearer ' from the authHeader as we only should send the token itself, not the included 'Bearer ' string.
        const [, accessToken] = authHeader.split(' ');
        const downloadLink = requestId
          ? `${appSettings.ENV_MBSS_REST_BASE_URL}/privacy/pdf/${activeCustomerId}/${requestId}?access_token=${accessToken}`
          : `${appSettings.ENV_MBSS_REST_BASE_URL}/privacy/pdf/${activeCustomerId}?access_token=${accessToken}`;

        window.open(downloadLink);
        return Promise.resolve();
      }
    }
    throw new Error('Privacy report not available');
  };

  const canRequestPrivacy = async () => {
    try {
      const response = await ComponentIdentifierApi.canRequestPrivacy(activeCustomerId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const canComplaintPrivacy = async () => {
    try {
      const response = await ComponentIdentifierApi.canComplaintPrivacy(activeCustomerId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  const canDeletePrivacy = async () => {
    try {
      const response = await ComponentIdentifierApi.canDeletePrivacy(activeCustomerId);
      return response.data.enabled;
    } catch (e) {
      return true;
    }
  };

  return (
    <PrivacyContext.Provider
      value={{
        isLoading,
        hasError,
        sendPrivacyRequest,
        sendPrivacyDeletionRequest,
        sendPrivacyComplaint,
        getPrivacyRequestSummary,
        downloadPrivacyReport,
        canRequestPrivacy,
        canComplaintPrivacy,
        canDeletePrivacy,
      }}>
      {children}
    </PrivacyContext.Provider>
  );
};

export { PrivacyContext, PrivacyProvider };
