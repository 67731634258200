import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    title: 'Verander je login',
    primaryButtonText: 'Ok, e-mail instellen',
    secondaryButtonText: 'Herinner mij later',
    body: {
      title: 'Kies een e-mailadres om in te loggen',
      content:
        'Je gebruikt nu <b>#{userName}</b> om in te loggen. Vervang deze gebruikersnaam door je e-mailadres. Dan log je in de toekomst nog makkelijker in.',
      list: {
        title: 'Voordelen:',
        benefit1: 'je e-mailadres is makkelijk te onthouden, want die gebruik je vaak',
        benefit2: 'inloggen met een eenmalige code wordt mogelijk',
        benefit3: 'je kan Ziggo en Vodafone samenvoegen: één account voor al je producten',
      },
    },
  },
  en: {},
};
