import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    close: 'Sluiten',
    home: 'ziggo.nl',
    ziggoGo: 'Ziggo Go',
    search: 'Zoeken',
    ziggoMail: 'Ziggo Mail',
    phone: '0900-1884',
    secureConnection: 'Veilige verbinding',
    overview: 'Overzicht',
  },
  en: {},
};
