import { ROLES } from 'models/permissions.model';
import { all, none, some } from 'utils/permissions/permission-conditions';

const { CEASED_BC, COLLECTION_BARRED, COLLECTION_CEASED } = ROLES;

// callee which requires all the children to be true
const allFn =
  (...fn: Function[]) =>
  (roles: ROLES[]) =>
    fn.every((f) => f(roles));
// callee which requires only one of the children to be true
const someFn =
  (...fn: Function[]) =>
  (roles: ROLES[]) =>
    fn.some((f) => f(roles));

export const PRODUCTS = [allFn(none([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED]))];

export const USAGE = [
  someFn(
    allFn(none([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED])),
    allFn(none([CEASED_BC]), all([COLLECTION_BARRED])),
    all([CEASED_BC, COLLECTION_CEASED])
  ),
];

// user is able to delete the data under GDPR
export const PRIVACY_REMOVAL_REQUEST = [
  someFn(
    allFn(
      all([CEASED_BC])
      // none([COLLECTION_CEASED, COLLECTION_BARRED])
    )
  ),
];

// user is not able to remove the data under GDPR because some products are still in use or barred
export const PRIVACY_REMOVAL_CONTENT = [
  someFn(allFn(none([CEASED_BC])), allFn(all([CEASED_BC]), some([COLLECTION_BARRED]))),
];

export const CHANGE_PAYMENT_DETAILS = [
  someFn(
    allFn(none([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED])),
    allFn(none([CEASED_BC]), all([COLLECTION_BARRED])),
    all([CEASED_BC, COLLECTION_CEASED])
  ),
];

export const USAGE_DASHBOARD = [allFn(allFn(none([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED])))];

export const PRODUCTS_DASHBOARD = [
  someFn(none([CEASED_BC]), (all([CEASED_BC]), some([COLLECTION_CEASED, COLLECTION_BARRED]))),
];

export const INVOICES_DASHBOARD = [
  someFn(
    allFn(all([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED])),
    allFn(none([CEASED_BC]), none([COLLECTION_BARRED]))
  ),
];

export const CEASED_PRODUCT_DASHBOARD = [
  someFn(someFn(all([CEASED_BC]), all([COLLECTION_CEASED])), allFn(none([CEASED_BC]), all([COLLECTION_BARRED]))),
];

export const CEASED_OPEN_INVOICES = [allFn(allFn(all([CEASED_BC]), all([COLLECTION_CEASED])))];

export const NOT_CEASED_OPEN_INVOICES = [allFn(allFn(none([CEASED_BC]), all([COLLECTION_BARRED])))];

export const NBA = [allFn(none([CEASED_BC]), none([COLLECTION_CEASED, COLLECTION_BARRED]))];

export const CEASED_CUSTOMER = [allFn(all([CEASED_BC]))];

export const NONE_CEASED_CUSTOMER = [allFn(none([CEASED_BC]))];

export const TEST_FEATURE_WITHOUT_ACCESS = [];
