import { ErrorMessage } from 'components/Form/components/ErrorMessage';
import React from 'react';
import { Field } from 'react-final-form';

interface ChoiceErrorProps {
  name: string;
}

/**
 * ChoiceError component subscribes to a value of a certain name from input
 * and shows the corresponding error. Used for choices since they can have multiple
 * options, while you want to show the error always only once.
 */
export const ChoiceError = ({ name }: ChoiceErrorProps) => {
  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) => (touched && error ? <ErrorMessage message={error} /> : null)}
    />
  );
};
