import { ApiResponse, query } from './api';
import * as Q from './call-barring.query';
import * as T from './call-barring.types';

interface CallBarsApiInterface {
  getCallBars(billingCustomerId: string, assignedProductId: string): ApiResponse<T.CallBars>;
  postCallBars(
    billingCustomerId: string,
    assignedProductId: string,
    callBars?: string,
    existingCallBars?: string
  ): ApiResponse<{ success: boolean }>;
}

export const CallBarsApi: CallBarsApiInterface = {
  getCallBars(billingCustomerId: string, assignedProductId: string) {
    return query({
      query: Q.getCallBarsQuery,
      variables: { billingCustomerId, assignedProductId },
      name: 'getCallBars',
    }).then(({ getCallBars }) => ({ data: getCallBars }));
  },
  postCallBars(billingCustomerId: string, assignedProductId: string, callBars?: string, existingCallBars?: string) {
    return query({
      query: Q.postCallBarsQuery,
      variables: { billingCustomerId, assignedProductId, callBars, existingCallBars },
      name: 'postCallBars',
    }).then(({ postCallBars }) => ({ data: postCallBars }));
  },
};
