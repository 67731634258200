import styled, { gapSize, rem, spacingBase } from 'theme';

export const TopContent = styled('div')`
  width: 100%;
  max-width: calc(${rem(500)} + ${() => spacingBase});
  margin: 0 auto;
  padding: 0 ${() => spacingBase};
`;

export const Title = styled('div')`
  margin-top: ${() => gapSize(5)};

  h1 {
    color: ${(p) => p.theme.colors.red};
  }
`;

export const Content = styled('div')`
  color: ${(p) => p.theme.colors.red};

  a {
    color: ${(p) => p.theme.colors.red};
    text-decoration: underline;
  }
`;

export const Link = styled('div')`
  text-decoration: underline;
`;
