import { Link as LinkComponent } from 'components/Link';
import styled from 'theme';

export const TarifText = styled.span``;

export const PhoneNumber = styled.h3`
  color: ${(p) => p.theme.colors.primary};

  a {
    color: ${(p) => p.theme.colors.primary} !important;
  }
`;

export const Link = styled(LinkComponent)`
  color: currentColor;
`;
