import { LoaderIcon } from 'assets/icons';
import ErrorImage from 'assets/img/ZiggoCustomerErrorCat.svg';
import { Link } from 'components/Link';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from './splash-screen.content';
import * as S from './splash-screen.styles';

const contentUtil = new ContentUtil({ content, componentName: 'ProgressTracker' });
const defaultUsps = [
  contentUtil.translateToString('usp1'),
  contentUtil.translateToString('usp2'),
  contentUtil.translateToString('usp3'),
];

interface SplashScreenProps {
  hasError?: boolean;
  errorType?: Record<string, string>;
  headerText?: string;
  usps?: string[];
}

export const SplashScreen = ({ hasError, errorType = {}, headerText, usps = defaultUsps }: SplashScreenProps) => {
  return (
    <S.SplashScreenWrapper>
      {hasError && (
        <S.Card data-testid="error-card">
          <S.CardTitle>{contentUtil.translateToString('errorCardTitle')}</S.CardTitle>
          <p>
            <div data-testid="error-correlation-id">Correlation-Id: {errorType['Correlation-Id'] || 'N/A'}</div>
            <div data-testid="error-x-hawaii-tx-id">X-Hawai-Tx-Id: {errorType['X-Hawai-Tx-Id'] || 'N/A'}</div>
          </p>
          <img src={ErrorImage} />
          <div>
            <Link href="/" inline>
              {contentUtil.translateToString('errorCardLink')}
            </Link>
          </div>
        </S.Card>
      )}
      {!hasError && (
        <>
          <LoaderIcon />
          <S.Header data-testid="loading-header">{headerText ?? contentUtil.translateToString('isLoading')}</S.Header>
          <S.List>
            {usps.map((usp, index) => (
              <S.ListItem key={usp} delay={index}>
                <S.Check /> {usp}
              </S.ListItem>
            ))}
          </S.List>
        </>
      )}
    </S.SplashScreenWrapper>
  );
};
