import ErrorImage from 'assets/img/ZiggoCustomerErrorCat.svg';
import { ContentBackground } from 'components/ContentBackground';
import { Link } from 'components/Link';
import { PageHeader } from 'components/PageHeader';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ContentUtil } from 'utils';

import { content } from './page-not-found.content';
import * as S from './page-not-found.styles';

const contentUtil = new ContentUtil({ content, componentName: 'PageNotFound' });

export const PageNotFound = () => {
  return (
    <ContentBackground>
      <Helmet>
        <title>{contentUtil.translateToString('title')}</title>
      </Helmet>
      <PageHeader title={contentUtil.translateToString('title')} />
      <S.Card>
        <S.CardTitle data-testid="404-page">{contentUtil.translateHtml('cardTitle')}</S.CardTitle>
        <img src={ErrorImage} />
        <Link listitem to="/">
          {contentUtil.translate('backToPortal')}
        </Link>
      </S.Card>
    </ContentBackground>
  );
};
