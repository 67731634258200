import { AccessStateApi, AccessStateCollectionStateEnum } from 'api';
import { ROLES } from 'models/permissions.model';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from './auth/auth.context';
import { BillingCustomerContext } from './billing-customer.context';

interface IContext {
  isLoading: boolean;
  hasError: boolean;
  currentUserCollectionState: null | AccessStateCollectionStateEnum;
  currentUserCeased: null | boolean;
  completed: boolean;
}

const intialState: IContext = {
  isLoading: false,
  hasError: false,
  currentUserCollectionState: null,
  currentUserCeased: null,
  completed: false,
};

const AccessStateContext = React.createContext(intialState);

const AccessStateProvider = ({ children }: { children: React.ReactNode }) => {
  const authContext = useContext(AuthContext);
  const bcContext = useContext(BillingCustomerContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentUserCollectionState, setCurrentUserCollectionState] = useState<AccessStateCollectionStateEnum | null>(
    null
  );
  const [currentUserCeased, setCurrentUserCeased] = useState<boolean | null>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (authContext.completed && bcContext.completed) init();
  }, [authContext.completed, bcContext.completed]);

  const init = async () => {
    setIsLoading(true);

    try {
      const response = await AccessStateApi.getAccessState(bcContext.activeBcId);
      const roles = [...authContext.roles];

      if (response.data.ceased) {
        roles.push(ROLES.CEASED_BC);
      }

      switch (response.data.collectionState) {
        case AccessStateCollectionStateEnum.BARRED:
          roles.push(ROLES.COLLECTION_BARRED);
          break;
        case AccessStateCollectionStateEnum.CEASED:
          roles.push(ROLES.COLLECTION_CEASED);
          break;
        default:
      }

      authContext.updateRoles(roles);

      setCurrentUserCeased(response.data.ceased);
      setCurrentUserCollectionState(response.data.collectionState);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setHasError(true);
      setCompleted(true);
    }
  };

  return (
    <AccessStateContext.Provider
      value={{
        isLoading,
        hasError,
        currentUserCeased,
        currentUserCollectionState,
        completed,
      }}>
      {children}
    </AccessStateContext.Provider>
  );
};

export { AccessStateContext, AccessStateProvider };
