import { Wrapper as WrapperComponent } from 'components/BaseLayout/components/Wrapper';
import { InlineAlert as InlineAlertComponent } from 'components/InlineAlert';
import styled, {
  contentMargins,
  contentPadding,
  css,
  gapSize,
  rem,
  screenMobilePlusAndSmaller,
  screenTabletLandscapeAndBigger,
  spacingBase,
  tablet,
} from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

export const Wrapper = styled(WrapperComponent)`
  ${contentPadding(1, 0, 1, 0)};
`;

export const EmailAddress = styled('div')`
  background-color: ${(p) => p.theme.colors.gray100};
  border-radius: ${borderRadius.button};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${(p) => p.theme.fonts.weights.bold};
  ${contentPadding()};
  ${contentMargins(0.5, 0, 0.5, 0)};

  ${screenMobilePlusAndSmaller(css`
    flex-direction: column;
    align-items: flex-start;

    button {
      font-size: ${rem(14)};
    }
  `)}
`;

export const SectionEmail = styled('div')`
  a {
    color: ${(p) => p.theme.colors.primary};
    font-weight: ${(p) => p.theme.fonts.weights.bold};
    cursor: pointer;
  }
`;

export const SectionPassword = styled('div')`
  max-width: ${rem(700)};

  p {
    margin-top: 0;
  }
`;

export const SectionAliases = styled('div')`
  a {
    color: ${(p) => p.theme.colors.black};
    cursor: pointer;
    text-decoration-line: underline;
  }
`;

export const SideCardHeader = styled('div')`
  font-weight: ${(p) => p.theme.fonts.weights.bold};
`;

export const SideCardContent = styled('div')`
  svg {
    align-self: center;
    margin-left: ${() => spacingBase};
  }
`;

export const SideCardImage = styled('div')`
  margin: 0 auto;
  height: fit-content;
  width: fit-content;

  svg {
    width: ${rem(150)};
    height: auto;
  }
`;

interface IFormProps {
  emailDomain?: string;
}

export const Form = styled('div')<IFormProps>`
  max-width: ${rem(700)};
  margin-top: ${() => gapSize(2)};

  ${() =>
    tablet(css`
      form {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }

      .form-button,
      .error-message,
      .password-checklist,
      .input-wrapper {
        grid-column: 2;
      }

      .input-label {
        grid-column: 1;

        ${screenTabletLandscapeAndBigger(css`
          margin-bottom: ${spacingBase};
        `)}
      }

      .title,
      .inline-alert {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `)}

  .btn-submit {
    border: ${rem(2)} solid ${(p) => p.theme.colors.black};
    box-shadow: ${boxShadow.buttonLight};
    background-color: ${(p) => p.theme.colors.black};

    ${screenTabletLandscapeAndBigger(css`
      width: ${rem(150)};
    `)}
  }

  .input-email {
    position: relative;

    .input {
      padding-right: ${rem(125)};
    }

    &::after {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: ${rem(105)};
      padding: ${() => spacingBase};
      background-color: ${(p) => p.theme.colors.grayL10};
      height: ${rem(48)};
      position: absolute;
      content: '@${(p) => p.emailDomain}';
      top: ${rem(1)};
      right: ${rem(1)};
      border-left: ${rem(1)} solid ${(p) => p.theme.colors.gray300};

      border-top-right-radius: ${borderRadius.input};
      border-bottom-right-radius: ${borderRadius.input};
    }
  }
`;

export const InlineAlert = styled(InlineAlertComponent)`
  margin: 0;
  p {
    margin: 0;
  }
`;
