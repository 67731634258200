import { Row } from 'components/Grid';
import styled, { css, gapSize, rem, tablet } from 'theme';
import borderRadius from 'theme/global/border-radius';

export const SupportCaseDetailsBlock = styled(Row)`
  margin-top: -${gapSize(2 * 2)};
  border: 1px solid ${(p) => p.theme.colors.gray200};
  border-radius: ${borderRadius.base};
  background: white;
`;

export const Data = styled.div`
  font-weight: 700;
  margin-bottom: ${rem(8)};

  svg {
    position: relative;
    top: 2px;
    scale: 0.9;
  }
`;

export const Attachment = styled.div`
  font-weight: 700;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg {
    position: relative;
  }
`;

export const ExtraData = styled('div')`
  padding-top: ${rem(16)};
  border-top: 1px solid ${(p) => p.theme.colors.gray200};

  ${tablet(css`
    padding-top: 0;
    padding-left: ${rem(16)};
    border-top: 0;
    border-left: 1px solid ${(p) => p.theme.colors.gray200};
  `)};
`;
