import styled, { css, rem, screenMobilePlusAndBigger } from 'theme';

export const Label = styled('label')`
  display: block;
  padding-bottom: ${rem(5)};
  margin: ${rem(10)} auto;
  line-height: ${rem(20)};
  position: relative;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  ${screenMobilePlusAndBigger(css`
    align-self: flex-start;
  `)}
`;
