import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    loadMoreResults: 'Laad meer resultaten',
    placeholder: 'Stel je vraag. AI helpt ons om je een antwoord te geven',
    disclaimer: 'We doen een proef met AI-antwoorden. Is het antwoord niet wat je zocht? Stel dan een nieuwe vraag.',
  },
  en: {},
};
