import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    CARE: {
      title: 'Contactformulier',
      askYourQuestion: 'Een wijziging of verzoek',
      askYourQuestionText: `Vul het onderstaande formulier in en we helpen je zo snel mogelijk verder.`,
      preferPhone: 'Toch liever bellen?',
    },
    SALES: {
      title: 'Contactformulier',
      askYourQuestion: 'Bel je ons?',
      askYourQuestionText: `Deze wijziging kan nog niet online. Bel onze klantenservice en we helpen je meteen.`,
    },
  },
  en: {},
};
