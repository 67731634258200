import { gql } from 'graphql-request';

export const getAccessStateQuery = gql`
  query getAccessState($billingCustomerId: String!) {
    getAccessState(billingCustomerId: $billingCustomerId) {
      ceased
      collectionState
    }
  }
`;
