import styled, { css, rem, tablet } from 'theme';

export const RadioSvg = styled.svg<{ checked: boolean; disabled: boolean }>`
  opacity: ${(p) => (p.disabled ? '0.5' : '1')};

  .outer {
    stroke: ${(p) => p.theme.colors.grayD20};
  }

  .inner {
    transition: 100ms fill;
    fill: ${(p) => (p.checked ? p.theme.colors.gray900 : 'rgba(0, 0, 0, 0)')};
  }

  margin-right: ${rem(8)};

  & + input {
    display: none;
  }
`;

export const Label = styled.label`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const SubLabel = styled.div`
  color: ${(p) => p.theme.colors.gray300};
`;

export const SubLabelLink = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${() =>
    tablet(css`
      flex-direction: row;
      align-items: center;
    `)}
`;
