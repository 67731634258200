import styled, { css, hoverFocus, rem, spacingBase, tablet } from 'theme';

export const Form = styled.form`
  position: relative;
`;

export const SearchContainer = styled.div`
  position: absolute;
  height: ${rem(64)};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: ${rem(1)} solid ${(p) => p.theme.colors.grayD20};
  background-color: ${(p) => p.theme.colors.white};

  padding: ${rem(0)} ${() => spacingBase};

  ${tablet(css`
    padding: ${rem(0)} ${rem(182)};
  `)}
`;

interface SearchInputProps {
  hasResults: boolean;
}

export const SearchInput = styled.div<SearchInputProps>`
  display: flex;
  flex: 1 0 0;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  background: ${(p) => p.theme.colors.white};

  ${(p) =>
    p.hasResults &&
    css`
      border: ${rem(1)} solid ${(p) => p.theme.colors.grayD20};
      border-radius: ${rem(4)} ${rem(4)} 0 0;
      border-bottom: none;
    `}
  ${(p) =>
    !p.hasResults &&
    css`
      border: ${rem(1)} solid ${(p) => p.theme.colors.grayD20};
      border-radius: ${rem(4)};
    `}

  gap: ${() => spacingBase};
  padding: ${rem(4)} ${rem(8)} ${rem(4)} ${rem(16)};
  height: ${rem(42)};
  margin: ${rem(11)} 0;

  ${tablet(css`
    gap: ${rem(10)};
  `)}
`;

export const SearchResult = styled.div`
  display: flex;
  flex-direction: column;

  border: ${rem(1)} solid ${(p) => p.theme.colors.grayD30};
  border-radius: 0 0 ${rem(4)} ${rem(4)};
  border-top: none;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 ${rem(4)} ${rem(4)} 0 #00000040;
  padding: 0 ${rem(16)} ${rem(16)};

  position: relative;
  top: ${rem(-11)};
  width: 100%;

  a {
    color: ${(p) => p.theme.colors.black};
    font-weight: ${(p) => p.theme.fonts.weights.bold};
    ${(p) =>
      hoverFocus(css`
        text-decoration: ${p.theme.buttons.hoverTextDecoration};
      `)}
  }
`;

export const Disclaimer = styled.div`
  font-size: ${rem(14)};
  font-style: italic;
  font-weight: ${(p) => p.theme.fonts.weights.light};
  line-height: ${rem(16)};
  text-align: left;
  color: ${(p) => p.theme.colors.gray600};
`;

export const SeparationLine = styled.div`
  border-top: ${rem(1)} solid ${(p) => p.theme.colors.grayL10};
  margin: ${rem(16)} 0;
`;

export const Search = styled('input')`
  width: 100%;
  height: 100%;
  border: none;
`;

interface SubmitButtonProps {
  active: boolean;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  display: block;
  color: ${(p) => p.theme.colors.black};
  cursor: pointer;

  padding: ${rem(8)};
  border: none;
  border-radius: ${rem(4)};
  background-color: ${(p) => p.theme.colors.grayL30};
  ${(p) =>
    p.active &&
    css`
      background-color: ${(p) => p.theme.colors.primary};
    `}

  svg {
    width: ${rem(18)};
    height: ${rem(18)};
    vertical-align: bottom;
    transform: rotate(-90deg);
  }
`;

export const ButtonIcon = styled('button')`
  display: block;
  color: ${(p) => p.theme.colors.black};
  cursor: pointer;

  padding: ${rem(8)};
  border: none;
  background-color: ${(p) => p.theme.colors.white};

  svg {
    width: ${rem(18)};
    height: ${rem(18)};
    vertical-align: bottom;
  }
`;
