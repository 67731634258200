import styled, { gapSize, rem } from 'theme';

export const Checkbox = styled('div')`
  display: flex;

  label {
    margin: 0;
    top: 0;
    display: flex;
    cursor: pointer;

    input {
      display: inline-block;
      margin-right: ${() => gapSize(2)};
      margin-top: ${rem(2)};
    }

    span {
      display: inline-block;
    }
  }
`;
