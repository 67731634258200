import { NavLink } from 'react-router-dom';
import styled, { gapSize, rem, spacingBase } from 'theme';

export const PageWrapper = styled('div')`
  width: 100%;
  max-width: calc(${rem(500)} + ${() => spacingBase});
  margin: 0 auto;
  padding: 0 ${() => spacingBase};
  text-align: center;
`;

export const Title = styled('div')`
  text-align: center;
  margin: ${() => gapSize(5)} auto 0;

  h1 {
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const Content = styled('div')`
  margin-top: ${() => gapSize(2)};
  margin-bottom: ${() => gapSize(3)};
`;

export const Link = styled(NavLink)`
  text-align: center;
  text-decoration: underline;

  svg {
    margin-right: ${() => gapSize(0.5)};
  }
`;
