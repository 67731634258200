import React from 'react';

export const ShoppingCartIcon = () => (
  <svg role="none" width="32" height="32" viewBox="0 0 32 32">
    <defs>
      <path
        id="sci-a"
        d="M7.9 4C7 2.3 6.5 1.2 6 .7 5.7.2 4.9 0 3.5 0h-2C1.2 0 .9.1.6.4A1.4 1.4 0 000 1.5c0 .5.2.8.5 1.1.3.3.6.5 1 .5h3.1l5.3 11.6c-1.3.5-2.1 1.1-2.6 2-.5.8-.6 1.6-.5 2.4a3.7 3.7 0 003.7 3h16.8c.9 0 1.4-.5 1.4-1.4 0-1-.5-1.4-1.4-1.4H11c-.9 0-1.3-.3-1.3-1 0-.6.4-1 1.2-1l17.2-2c.4 0 .8-.2 1-.5.4-.3.5-.7.5-1.1v-8c0-.5-.1-1-.4-1.2-.3-.4-.8-.5-1.4-.5h-20zm16.9 20.2c-.8 0-1.4.2-2 .7a2.6 2.6 0 00-.7 2c0 .7.3 1.3.8 1.8a2.6 2.6 0 001.9.8c.7 0 1.4-.3 1.9-.8s.7-1.1.7-1.9-.2-1.3-.7-1.9c-.5-.5-1.2-.7-2-.7zm-18 0c-.8 0-1.4.2-1.9.7a2.6 2.6 0 00-.8 2c0 .7.3 1.3.8 1.8a2.5 2.5 0 001.9.8c.7 0 1.4-.3 1.9-.8s.7-1.1.7-1.9-.2-1.3-.7-1.9c-.5-.5-1.2-.7-2-.7zm20.5-15h-4.9l-.6-3h5.5v3zm-14.7 5.4l-1.8-3.8h4.8l1.2 3.4-4.2.4zM8.8 6.3h5.5l1 3h-5.1l-1.4-3zm11.5 0l.7 3h-4.3l-.9-3h4.5zm-2 7.8L17 10.8h4l1 3-3.9.3zm9-1l-3.9.4-.6-2.7h4.5v2.3z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <mask id="sci-b" fill="#fff">
        <use href="#sci-a" />
      </mask>
      <use fill="#BCC1C5" href="#sci-a" />
      <g fill="currentColor" mask="url(#sci-b)">
        <path d="M-1-1h32v32H-1z" />
      </g>
    </g>
  </svg>
);
