import { Label as LabelComponent } from 'components/Form/components/Label';
import styled, { css, gapSize, rem, screenTabletLandscapeAndBigger } from 'theme';
import borderRadius from 'theme/global/border-radius';
import boxShadow from 'theme/global/box-shadow';

export const Label = styled(LabelComponent)`
  ${screenTabletLandscapeAndBigger(css`
    align-self: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: ${() => gapSize(4)};
  `)}
`;

export const Textarea = styled.textarea`
  box-shadow: ${boxShadow.inset()};
  border-radius: ${borderRadius.input};
  position: relative;
  padding: ${rem(10)} ${rem(20)} ${rem(10)};
  width: 100%;
  border: ${rem(1)} solid;
  border-color: ${(p) => p.theme.colors.gray600};

  &:disabled {
    box-shadow: ${(p) => boxShadow.inset(p.theme.colors.grayL10)};
  }
`;
