export interface Payment {
  id: string;
  type: PaymentType;
  date: string;
  method?: PaymentMethod;
  submethod?: PaymentSubmethod;
  status?: PaymentStatus;
  amount: number;
  originalAmount?: number;
  directDebitStatus?: PaymentDirectDebitStatus;
  directDebitRejectionReason?: string;
  invoiceReferences?: InvoiceReference[];
  refundReason?: string;
}

export interface InvoiceReference {
  id: string;
  period?: string;
}

export enum PaymentType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export enum PaymentMethod {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CREDITCARD = 'CREDIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum PaymentSubmethod {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMERICANEXPRESS = 'AMERICAN_EXPRESS',
  DIRECTDEBIT = 'DIRECT_DEBIT',
}

export enum PaymentStatus {
  PAID = 'PAID',
  REVERSED = 'REVERSED',
  PARTIAL = 'PARTIAL',
}

export enum PaymentDirectDebitStatus {
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  REISSUE = 'REISSUE',
}

export enum PaymentResponseDetailedPaymentStatusCodeEnum {
  IDEALSUCCESS = 'IDEAL_SUCCESS',
  IDEALCANCELLED = 'IDEAL_CANCELLED',
  IDEALEXPIRED = 'IDEAL_EXPIRED',
  IDEALFAILURE = 'IDEAL_FAILURE',
  IDEALOPEN = 'IDEAL_OPEN',
  CREDITCARDSUCCESS = 'CREDIT_CARD_SUCCESS',
  CREDITCARDVESTADENIED = 'CREDIT_CARD_VESTA_DENIED',
  CREDITCARDBANKDENIED = 'CREDIT_CARD_BANK_DENIED',
  FULFILMENTPERMANENTFAILURE = 'FULFILMENT_PERMANENT_FAILURE',
  FULFILMENTTEMPORARYFAILURE = 'FULFILMENT_TEMPORARY_FAILURE',
}

export enum PaymentResponsePaymentStatusCodeEnum {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}
