import styled from 'styled-components';
import { rem } from 'theme';

export const Title = styled.span`
  display: flex;
  align-items: center;
`;

export const Content = styled.p`
  margin-top: 0;
`;

export const ListTitle = styled.p`
  margin-bottom: 0;
`;

export const List = styled.ul`
  margin-top: 0;
  padding-left: ${rem(20)};
`;
