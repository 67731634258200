import { LocaleEnum } from './language.types';
import { PayMeansCurrencyEnum } from './paymeans.types';

export enum InvoiceSummaryStatusEnum {
  OPEN = 'OPEN',
  NOTPAID = 'NOT_PAID',
  PAYMENTPENDING = 'PAYMENT_PENDING',
  PAID = 'PAID',
}

export enum InvoicePaymentStatusEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum InvoicePaymentDetailStatusEnum {
  IDEAL_SUCCESS = 'IDEAL_SUCCESS',
  IDEAL_FAILURE = 'IDEAL_FAILURE',
  IDEAL_CANCELLED = 'IDEAL_CANCELLED',
  IDEAL_EXPIRED = 'IDEAL_EXPIRED',
  IDEAL_OPEN = 'IDEAL_OPEN',
  CREDIT_CARD_SUCCESS = 'CREDIT_CARD_SUCCESS',
  CREDIT_CARD_VESTA_DENIED = 'CREDIT_CARD_VESTA_DENIED',
  CREDIT_CARD_BANK_DENIED = 'CREDIT_CARD_BANK_DENIED',
  FULFILMENT_PERMANENT_FAILURE = 'FULFILMENT_PERMANENT_FAILURE',
  FULFILMENT_TEMPORARY_FAILURE = 'FULFILMENT_TEMPORARY_FAILURE',
}

export enum InvoiceSummaryTypeEnum {
  REGULAR = 'REGULAR',
  CHANGEOFSERVICE = 'CHANGE_OF_SERVICE',
  FIRST = 'FIRST',
  FINAL = 'FINAL',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  ADDITIONAL = 'ADDITIONAL',
  SPECIALTY = 'SPECIALTY',
}

export enum InvoicePaymentMethodEnum {
  INGENICO = 'INGENICO',
  IDEAL = 'IDEAL',
  CREDIT_CARD = 'CREDIT_CARD',
}

interface InvoiceSummaryDirectDebit {
  attemptsLeft: number;
  rejectionReason?: string;
}

export interface TokenPaymentResponse {
  token: string;
}

export interface HashPaymentResponse {
  acceptance: string;
  amount: string;
  error: string;
  orderId: string;
  paymentMethod: string;
  shasign: string;
  status: string;
  paymentId: string;
}

export interface InvoiceSummary {
  /** Invoice identification as communicated to customer, referenceable for customer to VZ. */
  id: string;
  status: InvoiceSummaryStatusEnum;
  type: InvoiceSummaryTypeEnum;
  /** On which calendar period the invoice applies to (yyyy-mm). */
  period: string;
  /** Invoice amount in cents, including VAT. */
  totalAmount?: number;
  /** Date on which the invoice was paid. */
  paidDate?: string;
  /** Date on which the invoice is supposed to be fully paid. */
  dueDate?: string;
  /** The amount still open to be paid. */
  dueAmount?: number;
  /** Can the outstanding amount be payed online. */
  allowPayment: boolean;
  /** Is a PDF specification available for download. */
  pdfAvailable: boolean;
  directDebit: InvoiceSummaryDirectDebit | null;
  paymentReference: string;
}

export interface InvoicePaymentMethod {
  type: InvoicePaymentMethodEnum;
  enabled: boolean;
}

export interface InvoicePayment {
  link: string;
  invoice?: InvoiceSummary;
  hash?: string;
  currency?: PayMeansCurrencyEnum;
  locale?: LocaleEnum;
  merchant?: string;
  acceptUrl?: string;
  declineUrl?: string;
  cancelUrl?: string;
  exceptionUrl?: string;
  backUrl?: string;
  homeUrl?: string;
}

export interface InvoicePaymentStatus {
  invoiceId: string;
  amount: number;
  paymentStatusCode: InvoicePaymentStatusEnum;
  detailedPaymentStatusCode: InvoicePaymentDetailStatusEnum;
}

export interface PaymentLink {
  link: string;
}
