import React, { useEffect, useState } from 'react';

import * as S from './expander.styles';

interface ExpanderProps {
  className?: string; // for styled components only
  expanded: boolean;
  children: React.ReactNode;
}

export const Expander = ({ className, expanded, children }: ExpanderProps) => {
  const [height, setHeight] = useState(expanded ? 'auto' : '0px');
  const element = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!expanded) {
      setHeight('0px');
    } else {
      setHeight('auto');
    }
  }, [expanded, children]);

  return (
    <S.Expander
      className={className}
      style={{
        height,
        visibility: expanded ? 'visible' : 'hidden',
        overflow: expanded ? 'visible' : 'hidden',
      }}
      data-testid="expander"
      aria-hidden={!expanded}
      ref={element}>
      {children}
    </S.Expander>
  );
};
