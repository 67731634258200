import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { ChevronIcon } from 'assets/icons';
import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { Row } from 'components/Grid';
import React, { ReactNode } from 'react';
import { createDataTestId, globalContentUtil } from 'utils';
import { BrowserEnum, deviceUtils } from 'utils/device';

import * as S from './quick-links.styles';

interface LinkObject {
  url: string;
  text: string;
  isExternal?: boolean;
  icon?: ReactNode;
}

interface IQuickLinksProps {
  dataTestIdPrefix?: string;
  links: {
    1: LinkObject;
    2?: LinkObject;
    3?: LinkObject;
    4?: LinkObject;
  };
}

const QuickLink = ({ link, dataTestIdPrefix }: { link: LinkObject; dataTestIdPrefix: string }) => {
  const dataTestId = createDataTestId(link?.text, `${dataTestIdPrefix}-`);

  if (!link) return null;

  if (link.isExternal) {
    return (
      <S.QuickLink buttonVariant={ButtonVariant.LINK} isLeftAligned href={link.url} testid={dataTestId}>
        {link.icon} {link.text} <ChevronIcon />
      </S.QuickLink>
    );
  }

  return (
    <S.QuickLink buttonVariant={ButtonVariant.LINK} isLeftAligned to={link.url} testid={dataTestId}>
      {link.icon || ''} {link.text} <ChevronIcon />
    </S.QuickLink>
  );
};

export const QuickLinks = ({ links, dataTestIdPrefix = 'footer-links' }: IQuickLinksProps) => {
  if (deviceUtils.isBrowser(BrowserEnum.app)) return null;

  return (
    <S.QuickLinks data-testid={dataTestIdPrefix}>
      <Wrapper>
        <Row noMargin noPadding>
          <S.Col>
            <h4>{globalContentUtil.translateToString('global.quickLinksHeader')}</h4>
          </S.Col>
        </Row>
        <Row noMargin noPadding>
          <S.Col m={4} l={3}>
            <QuickLink link={links['1']} dataTestIdPrefix={dataTestIdPrefix} />
          </S.Col>
          <S.Col m={4} l={3}>
            {links['2'] && <QuickLink link={links['2']} dataTestIdPrefix={dataTestIdPrefix} />}
          </S.Col>
          <S.Col m={4} l={3}>
            {links['3'] && <QuickLink link={links['3']} dataTestIdPrefix={dataTestIdPrefix} />}
          </S.Col>
          <S.Col m={4} l={3}>
            {links['4'] && <QuickLink link={links['4']} dataTestIdPrefix={dataTestIdPrefix} />}
          </S.Col>
        </Row>
      </Wrapper>
    </S.QuickLinks>
  );
};
