import { ApiResponse, query } from './api';
import { putTelephoneNumberQuery } from './telephoney.query';
import { ChangePhoneNumberSuccess } from './telephoney.types';

export interface TelephonyApiInterface {
  changePhoneNumber(billingCustomerId: string, assignedProductId: string): ApiResponse<ChangePhoneNumberSuccess>;
}

export const TelephonyApi: TelephonyApiInterface = {
  changePhoneNumber(billingCustomerId: string, assignedProductId: string) {
    return query({
      query: putTelephoneNumberQuery,
      variables: { billingCustomerId, assignedProductId },
      name: 'putTelephoneNumber',
    }).then(({ putTelephoneNumber }) => {
      return {
        data: putTelephoneNumber,
      };
    });
  },
};
