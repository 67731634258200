import { Wrapper as WrapperComponent } from 'components/BaseLayout/components/Wrapper';
import styled, { css, rem, tablet } from 'theme';

import background from './background/pageheader.jpg';

export const PageHeader = styled.div<{ hasTabs: boolean }>`
  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) => p.theme.colors.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

  padding-bottom: ${(p) => (p.hasTabs ? rem(10) : 0)};

  background-image: url(${background});
`;

export const PageTitle = styled.div`
  color: ${(p) => p.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${rem(60)};

  ${tablet(css`
    min-height: ${rem(104)};
  `)}
`;

export const Title = styled.h1`
  color: inherit;
  font-weight: ${(p) => p.theme.fonts.weights.bold};
  line-height: normal;
  margin: 0;
`;

export const BreadCrumbs = styled.div`
  font-size: ${rem(18)};
  font-family: ${(p) => p.theme.fonts.stacks.secondary};
  display: none;

  ${tablet(css`
    display: block;
  `)}

  a,
  a:hover {
    color: inherit;
  }
`;

export const Wrapper = styled(WrapperComponent)`
  margin: 0;
  align-self: center;
`;
