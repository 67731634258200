import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import { Attachment } from 'api/support-case.types';
import { FileIcon } from 'assets/icons/FileIcon';
import { ConstructionBarrier } from 'assets/img/ConstructionBarrier';
import { Modal } from 'components/Modal';
import { ModalIcon } from 'components/Modal/modal.styles';
import { DEFAULT_OIDC_SCOPES } from 'config/app.config';
import { appSettings } from 'config/app.settings';
import { BillingCustomerContext } from 'context/billing-customer.context';
import React, { useContext, useMemo, useState } from 'react';
import { ContentUtil } from 'utils/content';

import { content } from './download-attachment.content';
import * as S from './download-attachment.styles';

const contentUtil = new ContentUtil({ content, componentName: 'DownloadAttachment' });

interface DownloadAttachmentProps {
  attachment: Attachment;
  reference?: string;
}

const DownloadAttachment = ({ reference, attachment }: DownloadAttachmentProps) => {
  const { activeBcId } = useContext(BillingCustomerContext);
  const [hasError, setHasError] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  useMemo(() => {
    const token = OidcService.getStoredToken({ scopes: DEFAULT_OIDC_SCOPES });
    if (token) {
      const authHeader = OidcService.getAuthHeader(token);

      if (authHeader) {
        const [, access_token] = authHeader.split(' ');

        setDownloadUrl(
          `${appSettings.ENV_MBSS_REST_BASE_URL}/support-case/attachment/${activeBcId}/${reference}/${attachment.id}/${attachment.fileName}?access_token=${access_token}`
        );
      }
    }
  }, [activeBcId, attachment]);

  return (
    <>
      <S.Attachment data-testid="support-case-attachment" href={downloadUrl}>
        <FileIcon /> {attachment.fileName || attachment.title}
      </S.Attachment>
      {hasError && (
        <Modal onClose={() => setHasError(false)}>
          <section>
            <ModalIcon>
              <ConstructionBarrier />
            </ModalIcon>
            <h4>{contentUtil.translate('error.header')}</h4>
            <p>{contentUtil.translateHtml('error.content')}</p>
          </section>
        </Modal>
      )}
    </>
  );
};

export default DownloadAttachment;
