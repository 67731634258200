import React, { ChangeEvent } from 'react';

import * as S from './radio.styles';

interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  classname?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  sublabel?: string;
  sublabelIsLink?: boolean;
  id?: string;
}

export const RadioButton = ({
  label,
  sublabel,
  className,
  onChange,
  id,
  sublabelIsLink,
  ...input
}: RadioButtonProps) => {
  return (
    <S.Label className="radio-button" data-testid={id}>
      <S.RadioSvg
        checked={input.checked!}
        disabled={!!input.disabled}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <circle className="outer" strokeWidth="1" cx="9" cy="9" r="8" />
          <circle className="inner" cx="9" cy="9" r="5" />
        </g>
      </S.RadioSvg>
      <input {...input} className={className} onChange={onChange} type="radio" />
      {sublabelIsLink ? (
        <S.SubLabelLink>
          <div>{label}</div>
          {sublabel && <S.SubLabel>{sublabel}</S.SubLabel>}
        </S.SubLabelLink>
      ) : (
        <div style={{ flex: 1 }}>
          <div>{label}</div>
          {sublabel && <S.SubLabel>{sublabel}</S.SubLabel>}
        </div>
      )}
    </S.Label>
  );
};
