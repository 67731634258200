// @ts-expect-error TS(2305): Module '"api"' has no exported member 'SupportCase... Remove this comment to see the full error message
import { SupportCaseNewComment } from 'api';
import { ArrowIcon } from 'assets/icons';
import { Wrapper } from 'components/BaseLayout/components/Wrapper';
import { ChildPage } from 'components/ChildPage';
import { Form, TextArea } from 'components/Form';
import { Col, Row } from 'components/Grid';
import { InlineAlert } from 'components/InlineAlert';
import { PageHeader } from 'components/PageHeader';
import { BillingCustomerContext } from 'context/billing-customer.context';
import SupportCaseContext from 'context/support-case/support-case.context';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentUtil, globalContentUtil } from 'utils';
import { Helmet } from 'react-helmet';

import content from './support-case-detail.content';
import * as Styles from './support-case-details.styles';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseDetailNewComment' });

const SupportCaseDetailNewComment = () => {
  const { reference } = useParams();
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const { addSupportCaseComment } = useContext(SupportCaseContext);
  const { activeBcId } = useContext(BillingCustomerContext);
  const navigate = useNavigate();

  const handleSubmit = async (formValues: SupportCaseNewComment) => {
    const { message } = formValues;

    setFetching(true);
    const response = await addSupportCaseComment(activeBcId, reference, message);
    const { success } = response;

    if (!success) {
      setError(true);
      setFetching(false);
      return;
    }

    navigate(`/service/case/${reference}`);
  };

  return (
    <ChildPage>
      <Helmet>
        <title>{contentUtil.translateToString('pageTitle')}</title>
      </Helmet>
      <PageHeader
        title={contentUtil.translateToString('pageTitle')}
        parents={[
          { to: '/service/case/overzicht', label: contentUtil.translateToString('parentPage.service') }, // @todo: Change to /service once the Service page is done
          { to: '/service/case/overzicht', label: contentUtil.translateToString('parentPage.overview') },
        ]}
      />
      <Wrapper>
        <Row>
          <Col>
            <Styles.BackLink to={`/service/case/${reference}`} data-testid="backlink">
              <ArrowIcon /> {contentUtil.translateToString('backToCaseDetail')}
            </Styles.BackLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{contentUtil.translateToString('yourComment')}</h4>
            <Form
              id="support-case-add-comment"
              testid="support-case-add-comment-textfield"
              grid={false}
              hideCancelButton
              buttonText={contentUtil.translate('addToCase')}
              isLoading={fetching}
              onSubmit={handleSubmit}
              render={(_, validators) => (
                <>
                  <TextArea
                    name="message"
                    id="message"
                    validate={[validators.required, validators.maxLength]}
                    isRequired
                  />
                  {error && (
                    <InlineAlert type="error" testid="support-case-new-comment-error">
                      {globalContentUtil.translate('error.general.error_text')}
                    </InlineAlert>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    </ChildPage>
  );
};

export default SupportCaseDetailNewComment;
