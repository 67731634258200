export enum ROLES {
  // A prepaid user
  PREPAID = 'prepaid',

  // A postpaid user
  POSTPAID = 'postpaid',

  // A business user
  BUSINESS = 'business',

  // A consumer user
  CONSUMER = 'consumer',

  // A prospect user, which has no BC's
  PROSPECT = 'prospect',

  // An user eligible for NAW bonus
  ELEGIBLE_NAW_BONUS = 'elegible_for_naw_bonus',

  // A ceased BC
  CEASED_BC = 'ceased_bc',

  // A BC with one or more ceased products
  CEASED_PRODUCTS = 'ceased_products',

  // A BC with a delayed payment
  DELAYED_PAYMENT = 'delayed_payment',

  COLLECTION_BARRED = 'collection_barred',
  COLLECTION_CEASED = 'collection_ceased',

  // Basic user roles
  // User always has ONE of these.
  ADMIN = 'admin',
  USER = 'user',
  USER_AND_BILL = 'user_and_bill',
  BILL = 'bill',
}

export type PermissionCombination = (currentUserRoles: ROLES[]) => boolean;
export type Permissions = ROLES[] | PermissionCombination;

export type PermissionSettings =
  | Permissions[]
  | { [key: string]: Permissions[]; consumer: Permissions[]; business: Permissions[] };
