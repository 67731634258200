import { PaymentInfoIcon } from 'assets/icons';
import { QuickLinks } from 'components/QuickLinks';
import React from 'react';
import { ContentUtil } from 'utils';

import { content } from './payment-arrangement.content';

const contentUtil = new ContentUtil({ content, componentName: 'PaymentArrangementFooter' });

export const PaymentArrangementFooter = () => (
  <QuickLinks
    dataTestIdPrefix="payment-arrangement-footer-links"
    links={{
      1: {
        url: contentUtil.translateToString('footer.url'),
        text: contentUtil.translateToString('footer.description'),
        isExternal: true,
        icon: <PaymentInfoIcon width="24" height="24" />,
      },
    }}
  />
);
