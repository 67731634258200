import { AxiosPromise } from 'axios';

import { ApiResponse, query } from './api';
import {
  changeToDigitalInvoiceTypeMutation,
  getPayMeansQuery,
  isInvoiceTypeChangedQuery,
  putPayMeansQuery,
} from './paymeans.query';
import { PayMeans, PayMeansPaymentMethodEnum, UpdatePayMeans } from './paymeans.types';

interface PayMeansApiInterface {
  getPayMeans(billingCustomerId: string): ApiResponse<PayMeans>;
  updatePayMeans(billingCustomerId: string, updatePayMeans: UpdatePayMeans): ApiResponse<PayMeans>;

  isInvoiceTypeChanged(billingCustomerId: string): Promise<boolean>;
  changeToDigitalInvoiceType(billingCustomerId: string): AxiosPromise<{ success: boolean }>;
}

const PayMeansApi: PayMeansApiInterface = {
  getPayMeans(billingCustomerId) {
    return query({
      query: getPayMeansQuery,
      variables: { billingCustomerId },
      name: 'getPayMeans',
    }).then(({ getPayMeans }) => ({ data: getPayMeans }));
  },
  updatePayMeans(billingCustomerId, { accountHolder, accountNumber }) {
    return query({
      query: putPayMeansQuery,
      variables: {
        billingCustomerId,
        accountHolder,
        accountNumber,
        paymentMethod: PayMeansPaymentMethodEnum.DIRECTDEBIT,
      },
      name: 'putPayMeans',
    }).then(({ putPayMeans }) => ({ data: putPayMeans }));
  },
  changeToDigitalInvoiceType(billingCustomerId) {
    return query({
      query: changeToDigitalInvoiceTypeMutation,
      variables: { billingCustomerId },
      name: 'changeToDigitalInvoiceType',
    }).then(({ changeToDigitalInvoiceType }) => ({ data: changeToDigitalInvoiceType }) as any);
  },

  isInvoiceTypeChanged(billingCustomerId) {
    return query({
      query: isInvoiceTypeChangedQuery,
      variables: {
        billingCustomerId,
      },
      name: 'isInvoiceTypeChanged',
    }).then((response) => response.isInvoiceTypeChanged.changed);
  },
};

export { PayMeansApi };
