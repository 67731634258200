import { ApiResponse, query } from './api';
import {
  addSupportCaseCommentQuery,
  closeSupportCaseQuery,
  findSupportCaseQuery,
  getSupportCaseListQuery,
  hasSupportCaseQuery,
} from './support-case.query';
import SupportCase, { HasSupportCases, SupportCaseSuccess } from './support-case.types';

interface SupportCaseApiInterface {
  get(billingCustomerId: string): ApiResponse<SupportCase[]>;
  hasCases(billingCustomerId: string): ApiResponse<HasSupportCases>;
  find(billingCustomerId: string, reference: string): ApiResponse<SupportCase>;
  addComment(billingCustomerId: string, reference: string, message: string): ApiResponse<SupportCaseSuccess>;
  close(billingCustomerId: string, reference: string): ApiResponse<SupportCaseSuccess>;
}

const SupportCaseApi: SupportCaseApiInterface = {
  async get(billingCustomerId: string): Promise<{ data: SupportCase[] }> {
    const response = await query({
      query: getSupportCaseListQuery,
      variables: { billingCustomerId },
      name: 'getSupportCaseList',
    });

    return response.getSupportCaseList;
  },

  async hasCases(billingCustomerId: string): Promise<{ data: HasSupportCases }> {
    const response = await query({
      query: hasSupportCaseQuery,
      variables: { billingCustomerId },
      name: 'hasSupportCase',
    });

    return response.hasSupportCase;
  },

  async find(billingCustomerId: string, reference: string): Promise<{ data: SupportCase }> {
    const response = await query({
      query: findSupportCaseQuery,
      variables: {
        billingCustomerId,
        reference,
      },
      name: 'findSupportCase',
    });

    return response.findSupportCase;
  },

  async addComment(
    billingCustomerId: string,
    reference: string,
    message: string
  ): Promise<{ data: SupportCaseSuccess }> {
    const response = await query({
      query: addSupportCaseCommentQuery,
      variables: {
        billingCustomerId,
        reference,
        message,
      },
      name: 'addSupportCaseComment',
    });

    return response.addSupportCaseComment;
  },

  async close(billingCustomerId: string, reference: string): Promise<{ data: SupportCaseSuccess }> {
    const response = await query({
      query: closeSupportCaseQuery,
      variables: {
        billingCustomerId,
        reference,
      },
      name: 'closeSupportCase',
    });

    return response.closeSupportCase;
  },
};

export default SupportCaseApi;
