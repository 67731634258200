export enum AccessStateCollectionStateEnum {
  BARRED = 'BARRED',
  CEASED = 'CEASED',
  NONE = 'NONE',
}

export interface AccessState {
  /**
   *
   * @type {boolean}
   * @memberof AccessState
   */
  ceased: boolean;
  /**
   *
   * @type {string}
   * @memberof AccessState
   */
  collectionState: AccessStateCollectionStateEnum;
}
