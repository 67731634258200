import React, { SVGProps } from 'react';

export const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 16 16">
    <title>Download</title>
    <path d="M1,13a2,2,0,0,0,2,2H15a2.06,2.06,0,0,0-2-2H1Z" />
    <path d="M13.36,4.31,9,8.67V3A2.06,2.06,0,0,0,7,1V8.67L2.64,4.31a2,2,0,0,0,0,2.83L8,12.5l5.36-5.36A2,2,0,0,0,13.36,4.31Z" />
  </svg>
);
