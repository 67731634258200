import styled, { rem } from 'theme';

type LoaderProps = {
  inline: boolean;
  noPadding: boolean;
};

export const SpinnerContainer = styled('div')<LoaderProps>`
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  padding: ${(props) => (props.noPadding ? rem(0) : rem(20))};
  height: ${(props) => (props.inline ? 'auto' : '100%')};
  box-sizing: border-box; /* is defined globally but spinner can show before global styles */

  img {
    align-self: center;
    justify-self: center;
    width: ${rem(50)};
    height: ${rem(50)};
  }
`;
