import { Location } from 'react-router-dom';

export const appendParamToUrl = (url: string, params: string): string => {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  const individualParams = params.split('&');

  individualParams.forEach((param) => {
    if (param.includes('=')) {
      const [key, value] = param.split('=');
      searchParams.append(key, value);
    } else {
      searchParams.append(param, '');
    }
  });

  const searchString = Array.from(searchParams.entries())
    .map(([key, value]) => (value ? `${key}=${value}` : key))
    .join('&');

  urlObj.search = searchString;

  const finalUrl = urlObj.toString();
  return url.endsWith('/') || url.includes('/?') ? finalUrl : finalUrl.replace(/\/\?/, '?');
};

export const parseQueryString = (query: string) => {
  return query
    .split('&')
    .filter((value) => value)
    .reduce(
      (accParams, keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        return {
          ...accParams,
          [key]: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
        };
      },
      {} as Partial<{ [key: string]: string }>
    );
};

export const getQueryStringParams = (query: string) => {
  return parseQueryString(/^[?#]/.test(query) ? query.slice(1) : query);
};

/**
 * Get a query string parameter by its name.
 * Defaults to `window.location` if no `Location` object is passed
 */
export const getQueryStringParamByName = (name: string, location?: Location) => {
  const params = getQueryStringParams(location ? location.search : window.location.search);
  return params[name];
};
