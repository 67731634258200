import React, { SVGProps } from 'react';

export const SearchClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="black"
      d="M16.5575 3.03391H16.5564C15.6789 2.15528 14.2546 2.15528 13.376 3.03391L8.99977 7.40903L4.62465 3.03391C3.74602 2.15528 2.32177 2.15528 1.44315 3.03391L7.40902 8.99978L1.44202 14.9657L1.44315 14.9668C2.32177 15.8454 3.74602 15.8454 4.62465 14.9668L8.99977 10.5905L13.376 14.9668C14.2546 15.8454 15.6789 15.8454 16.5564 14.9668L16.5575 14.9657L10.5905 8.99978L16.5575 3.03391Z"
    />
  </svg>
);
