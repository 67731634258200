import { rem } from 'theme/mixins/calculate';

const boxShadow = {
  button: `0 ${rem(2)} ${rem(2)}  0 rgba(0, 0, 0, 0.5)`,
  buttonLight: `0 ${rem(3)} ${rem(3)} 0 rgba(188, 193, 197, 0.35)`,
  section: `0 0 ${rem(15)} 0 #d3d6d9`, // Color is not in theme
  modal: `0 0 ${rem(30)} ${rem(1)} rgba(0, 0, 0, 0.4)`,
  notification: `0 ${rem(2)} ${rem(2)} rgba(0, 0, 0, 0.2)`,
  inset: (color = 'white') => `0 0 0 1000px ${color} inset`,
};

export default boxShadow;
