/**
 * Small mixin that only returns numeric value from
 * a string.
 * Eg: 10px -> 10
 */
export const stripUnit = (value: string): number => {
  const numberValue = /[0-9]+\.?[0-9]*/.exec(value);
  return numberValue ? Number(numberValue[0]) : 0;
};

/**
 * Small mixin that only returns unit from a string.
 * Eg: 10px -> px
 */
export const getUnit = (value: string): string => {
  const unitValue = /[a-z]+|%/i.exec(value);
  return unitValue ? unitValue[0] : '';
};

/**
 * Multiplies value, accepts values with a unit
 * eg: multiply(2, '10px'); -> '20px'
 */
export const multiply = (multiple: number, value: string): string => {
  const numberValue = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${numberValue * multiple}${unit}`;
};

/**
 * Dives value, accepts values with a unit
 * eg: divide(2, '10px')' -> '5px';]
 */
export const divide = (denominator: number, value: string): string => {
  const numberValue = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${numberValue / denominator}${unit}`;
};

/**
 * Convert pixels to rem
 * eg: 16px -> 1rem
 * @param px
 */
export const rem = (px: number): string => {
  const base = 16;
  return `${(1 / base) * px}rem`;
};
