import { NavLink } from 'react-router-dom';
import styled, { css, gapSize, rem, screenMobilePlusAndSmaller, spacingBase } from 'theme';

export const PageWrapper = styled('div')`
  width: 100%;
  max-width: calc(${rem(550)} + ${() => spacingBase});
  margin: 0 auto;
  padding: 0 ${() => spacingBase};
  text-align: center;
`;

export const Title = styled('div')`
  text-align: center;
  width: fit-content;
  margin: ${() => gapSize(5)} auto ${() => gapSize(3)};
  display: flex;
  align-items: flex-end;

  h1 {
    color: ${(p) => p.theme.colors.success};
    padding-left: ${() => spacingBase};
    margin: 0;
  }

  ${screenMobilePlusAndSmaller(css`
    flex-direction: column;
    align-items: center;

    h1 {
      padding-left: 0;
      line-height: 1.56;
    }
  `)}

  path {
    fill: ${(p) => p.theme.colors.success};
  }

  svg {
    width: ${rem(40)};
    height: ${rem(48)};
  }
`;

export const Link = styled(NavLink)`
  text-align: center;
  text-decoration: underline;

  svg {
    margin-right: ${() => gapSize(0.5)};
  }
`;
