import SupportCase from 'api/support-case.types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentUtil } from 'utils';
import { DateFormat, dateUtils } from 'utils/date';

import formatSupportCaseStatusUtil from '../../utils/formatSupportCaseStatus';
import content from './support-case-overview.content';
import * as Styles from './support-case-overview.styles';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseOverview' });

interface ISupportCaseTableArguments {
  isLoading: boolean;
  supportCases: Array<SupportCase>;
}

const SupportCaseTable = (args: ISupportCaseTableArguments) => {
  const navigate = useNavigate();

  const columns = [
    {
      key: 'reference',
      heading: contentUtil.translateToString('table.headings.reference'),
    },
    {
      key: 'title',
      heading: contentUtil.translateToString('table.headings.title'),
    },
    {
      key: 'date',
      heading: contentUtil.translateToString('table.headings.date'),
    },
    {
      key: 'status',
      heading: contentUtil.translateToString('table.headings.status'),
    },
    {
      key: 'viewDetails',
      heading: contentUtil.translateToString('table.headings.details'),
    },
  ];

  const rows = args.supportCases.map((supportCase) => {
    return {
      reference: supportCase.reference,
      title: supportCase.title,
      date: dateUtils.format(supportCase.date, DateFormat.DATE),
      status: formatSupportCaseStatusUtil(supportCase.status),
      viewDetails: `${contentUtil.translateToString('table.viewDetails')} >`,
    };
  });

  const navigateToDetailPage = (row: SupportCase) => {
    const { reference } = row;

    if (reference) {
      navigate(`/service/case/${reference}`);
    }
  };

  return (
    <Styles.Table
      showHeader
      isLoading={args.isLoading}
      columns={columns}
      rows={rows}
      noDataMessage={contentUtil.translateToString('table.noContent')}
      onRowClick={navigateToDetailPage}
    />
  );
};

export default SupportCaseTable;
