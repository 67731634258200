import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    error: {
      header: 'Er ging iets mis',
      content: 'We kunnen je bijlage nu niet laten zien. Probeer je het later nog eens?',
    },
  },
  en: {},
};
