import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    copyright: 'Alle rechten voorbehouden',
    disclaimer: 'Disclaimer',
    privacy: 'Privacy',
    cookies: 'Cookies',
    terms: 'Alle voorwaarden',
  },
  en: {},
};
