import React from 'react';

export const TelevisionIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg role="none" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 30 26" {...props}>
    <defs>
      <path
        id="tv-icon"
        d="M28.598 2H3.227c-.625 0-1.153.22-1.582.66C1.215 3.098 1 3.61 1 4.196v17.666c0 .606.215 1.128.645 1.568.43.44.957.659 1.582.659h25.37c.626 0 1.153-.22 1.583-.66.43-.439.644-.961.644-1.567V4.197c0-.586-.215-1.098-.644-1.538-.43-.44-.957-.659-1.582-.659zm-.41 18.604H3.637V5.486h24.55v15.118zm-15.206 4.834c-.312.996-.644 1.723-.996 2.182-.351.46-.732.689-1.142.689h-1.23v.79c0 .255.082.401.248.44l.25.059h11.6l.235-.059c.157-.039.235-.185.235-.44v-.79H20.98c-.41 0-.79-.23-1.142-.689-.352-.459-.684-1.186-.996-2.183h-5.86z"
      />
    </defs>
    <g fillRule="evenodd" transform="translate(-1 -2)">
      <mask id="television-icon" fill="#fff">
        <use href="#tv-icon" />
      </mask>
      <use href="#tv-icon" />
      <g mask="url(#television-icon)">
        <path d="M0 0h32v32H0z" />
      </g>
    </g>
  </svg>
);
