import { Attachment } from 'api/support-case.types';
import { Col, Row } from 'components/Grid';
import React from 'react';
import { ContentUtil } from 'utils';
import { DateFormat, dateUtils } from 'utils/date';

import formatSupportCaseStatusUtil from '../../../../utils/formatSupportCaseStatus';
import DownloadAttachment from '../DownloadAttachment';
import content from './support-case-details-block.content';
import * as S from './support-case-details-block.styles';

const contentUtil = new ContentUtil({ content, componentName: 'SupportCaseDetailsBlock' });

interface ISupportCaseDetailsBlockArguments {
  reference?: string;
  title?: string;
  description?: string;
  contactName?: string;
  date?: string;
  status?: string;
  attachments?: Attachment[];
}

const SupportCaseDetailsBlock = ({
  reference,
  title,
  description,
  contactName,
  date,
  status,
  attachments,
}: ISupportCaseDetailsBlockArguments) => {
  return (
    <S.SupportCaseDetailsBlock data-testid="support-case-details-block">
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
      </Row>
      <Row>
        <Col m={8}>
          {contactName && (
            <>
              <div>{contentUtil.translateToString('name')}</div>
              <S.Data>{contactName}</S.Data>
            </>
          )}
          {description && (
            <>
              <div>{contentUtil.translateToString('description')}</div>
              <S.Data>{description}</S.Data>
            </>
          )}
        </Col>
        <Col m={4}>
          <S.ExtraData>
            {date && (
              <>
                <div>{contentUtil.translateToString('date')}</div>
                <S.Data>{dateUtils.format(date, DateFormat.DATE)}</S.Data>
              </>
            )}
            <div>{contentUtil.translateToString('reference')}</div>
            <S.Data>{reference}</S.Data>
            {status && (
              <>
                <div>{contentUtil.translateToString('status')}</div>
                {/* @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
                <S.Data>{formatSupportCaseStatusUtil(status)}</S.Data>
              </>
            )}
            {attachments && (
              <>
                <div>{contentUtil.translateToString('attachments')}</div>
                {attachments.map((attachment) => (
                  <DownloadAttachment key={attachment.id} attachment={attachment} reference={reference} />
                ))}
              </>
            )}
          </S.ExtraData>
        </Col>
      </Row>
    </S.SupportCaseDetailsBlock>
  );
};

export default SupportCaseDetailsBlock;
