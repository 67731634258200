import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    information: '',
    context: {
      // this is for the context selection
      change_product: 'Pakket wijzigen',
      change_product_tv: 'Tv-abonnement wijzigen',
      change_tv_packages: 'Zenderpakketten wijzigen',
      change_receivers: 'Extra digitale ontvanger aanvragen',
      change_product_internet: 'Internetsnelheid wijzigen',
      change_internet_equipment: 'Wifi-apparatuur bestellen',
      change_installation_address: 'Verhuizing doorgeven',
      change_safe_online: 'Safe Online abonnement aanpassen',
      change_extra_services: 'Volop Bellen wijzigen',
      order_day_pass: 'Dagpas bestellen',
      other: 'Andere reden',
    },
    CARE: {
      details: 'Je gegevens',
      contactText: '',
    },
    SALES: {
      details: 'We nemen snel contact op',
      contactText: 'Geef een e-mailadres en telefoonnummer door waarop we je het beste kunnen bereiken.',
    },
    form: {
      email: 'E-mail',
      phone: 'Telefoonnummer',
      saveContactPreferences: 'Gebruik deze gegevens voor alle toekomstige communicatie.',
      whenToReach: `Ik ben bereikbaar op werkdagen `,
      time: {
        MORNING: 'tussen 8.00 en 12.00 uur',
        AFTERNOON: 'tussen 12.00 en 18.00 uur',
        EVENING: 'tussen 18.00 en 20.00 uur',
      },
      context: 'Onderwerp',
      description: 'Omschrijving',
      error: {
        contactDetails: 'Vul je jouw e-mailadres en telefoonnummer in?',
        timeSlot: 'Geef aan wanneer we het beste contact kunnen opnemen.',
      },
      submitted: `Bedankt voor je bericht. We nemen snel contact met je op.`,
      cancel: 'Toch niet',
      back: 'Terug',
      submit: 'Nu versturen',
    },
  },
  en: {},
};
