export interface IOutage {
  id: string;
  type: ConversationalMessageType;
  creationDate: string;
  expectedSolveDate?: string;
  impactedProducts: ConversationalMessageProductTypesEnum[];
  telephoneNumbers?: string[];
  subscribed: boolean;
}

export enum ConversationalMessageProductTypesEnum {
  INTERNET = 'INTERNET',
  DIGITALPHONE = 'DIGITAL_PHONE',
  DIGITALTV = 'DIGITAL_TV',
  CATV = 'CATV',
  OTHER = 'OTHER',
}

export enum ConversationalMessageType {
  PORTALOUTAGE = 'PORTAL_OUTAGE',
  OUTAGE = 'OUTAGE',
  PLANNEDMAINTENANCE = 'PLANNED_MAINTENANCE',
}
