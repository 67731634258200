import { gql } from 'graphql-request';

export const getCallBarsQuery = gql`
  query getCallBars($billingCustomerId: String!, $assignedProductId: String!) {
    getCallBars(billingCustomerId: $billingCustomerId, assignedProductId: $assignedProductId) {
      callBars
      allowChange
      reason
    }
  }
`;

export const postCallBarsQuery = gql`
  mutation postCallBars(
    $billingCustomerId: String!
    $assignedProductId: String!
    $callBars: String
    $existingCallBars: String
  ) {
    postCallBars(
      billingCustomerId: $billingCustomerId
      assignedProductId: $assignedProductId
      callBars: $callBars
      existingCallBars: $existingCallBars
    ) {
      success
    }
  }
`;
