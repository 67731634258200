import { PayMeans, PayMeansApi, UpdatePayMeans } from 'api';
import { IFormError } from 'models';
import React, { useContext, useEffect, useState } from 'react';

import { BillingCustomerContext } from './billing-customer.context';

export interface IContext {
  payMeans: PayMeans | null;
  isLoading: boolean;
  hasError: boolean;
  errors: IFormError[];
  updatePayMeans: (directDebit: UpdatePayMeans) => Promise<boolean>;
  changeToDigitalInvoiceType: () => Promise<boolean>;
  isInvoiceTypeChanged: boolean;
}

const intialState: IContext = {
  payMeans: null,
  isLoading: true,
  hasError: false,
  errors: [],
  updatePayMeans: Promise.resolve,
  // @ts-expect-error TS(2322): Type '{ (): Promise<void>; <T>(value: T | PromiseL... Remove this comment to see the full error message
  changeToDigitalInvoiceType: Promise.resolve,
  isInvoiceTypeChanged: false,
};

const PayMeansContext = React.createContext(intialState);

const PayMeansProvider = ({ children }: { children: React.ReactNode }) => {
  const bcContext = useContext(BillingCustomerContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState<IFormError[]>([]);
  const [payMeans, setPayMeans] = useState<PayMeans | null>(null);
  const [isInvoiceTypeChanged, setIsInvoiceTypeChanged] = useState<boolean>(false);

  useEffect(() => {
    if (bcContext.completed) {
      getPayMeans();
      getIsInvoiceTypeChanged();
    }
  }, [bcContext.completed]);

  const updatePayMeans = async (directDebit: UpdatePayMeans) => {
    setHasError(false);
    const { activeBcId } = bcContext;

    try {
      const { data: payMeans } = await PayMeansApi.updatePayMeans(activeBcId, directDebit);
      setPayMeans(payMeans);
      setHasError(false);
    } catch (e) {
      if (e?.error?.data?.errors) {
        e.error.data.errors = e.error.data.errors.map((error: IFormError) => {
          // Custom error containing list of countries, check only start to map to translation
          if (error.code.startsWith('Unsupported IBAN country')) {
            return {
              code: 'iban_unsupported_country',
              field: 'accountNumber',
            };
          }

          return error;
        });
      }

      throw e;
    }

    return true;
  };

  const getPayMeans = async () => {
    const { activeBcId } = bcContext;
    setHasError(false);

    try {
      const { data: payMeans } = await PayMeansApi.getPayMeans(activeBcId);
      setPayMeans(payMeans);
      setIsLoading(false);
      setHasError(false);
      setErrors([]);
    } catch (e) {
      if (e.status === 404 || (e.error && e.error.status === 404)) {
        // It should probably not be a true error when it's 404, since we won't be able to "fetch later"
        // This will display a: no pay means found message
        setIsLoading(false);
        setHasError(false);
        setPayMeans(null);
        setErrors([]);
      } else {
        setIsLoading(false);
        setHasError(true);
      }
    }
  };

  const changeToDigitalInvoiceType = async (): Promise<boolean> => {
    const { activeBcId } = bcContext;
    const result = await PayMeansApi.changeToDigitalInvoiceType(activeBcId);

    if (result.data.success) setIsInvoiceTypeChanged(true);

    return result.data.success;
  };

  const getIsInvoiceTypeChanged = async (): Promise<void> => {
    const { activeBcId } = bcContext;

    const response = await PayMeansApi.isInvoiceTypeChanged(activeBcId);
    setIsInvoiceTypeChanged(response as any);
  };

  return (
    <PayMeansContext.Provider
      value={{
        isLoading,
        hasError,
        errors,
        payMeans,
        updatePayMeans,
        isInvoiceTypeChanged,
        changeToDigitalInvoiceType,
      }}>
      {children}
    </PayMeansContext.Provider>
  );
};

export { PayMeansContext, PayMeansProvider };
