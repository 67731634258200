export enum ProductType {
  GROUPLEVELPRODUCT = 'GROUP_LEVEL_PRODUCT',
  INTERNET = 'INTERNET',
  TELEPHONY = 'TELEPHONY',
  TV = 'TV',
  EQUIPMENT = 'EQUIPMENT',
  SERVICE = 'SERVICE',
}
export interface GroupLevelProduct {
  id: string;
  name: string;
  type: ProductType;
  contractStartDate?: string | null;
  contractPeriod?: string | null;
  /** Date on which the contract period ends and the subscription can be canceled monthly */
  contractEndDate?: string | null;
}

export enum BenefitBrand {
  VODAFONE = 'VODAFONE',
  HOLLANDSNIEUWE = 'HOLLANDSNIEUWE',
}

export enum TelevisionPackageSource {
  BENEFIT = 'BENEFIT',
  BOUGHT = 'BOUGHT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum TvPackage {
  GOOTT = 'GO_OTT',
  TVSTANDARD = 'TV_STANDARD',
  FILM1ENZIGGOSPORTTOTAAL = 'FILM1_EN_ZIGGO_SPORT_TOTAAL',
  TVEXTRA = 'TV_EXTRA',
  ZIGGOGO = 'ZIGGO_GO',
  FOXSPORTSEREDIVISIEFILM1ENZIGGOSPORTTOTAAL = 'FOX_SPORTS_EREDIVISIE_FILM1_EN_ZIGGO_SPORT_TOTAAL',
  FOXSPORTSINTERNATIONAL = 'FOX_SPORTS_INTERNATIONAL',
  FOXSPORTSCOMPLEET = 'FOX_SPORTS_COMPLEET',
  ZIGGOSPORTTOTAALENFOXSPORTSEREDIVISIE = 'ZIGGO_SPORT_TOTAAL_EN_FOX_SPORTS_EREDIVISIE',
  FOXSPORTSEREDIVISIE = 'FOX_SPORTS_EREDIVISIE',
  ONEMAANDGRATISEROTIEKEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_EROTIEK_EINDIGT_AUTOMATISCH',
  EXTRATVPAKKETNAARKEUZEZIGGOSPORT_TOTAAL = 'EXTRA_TV_PAKKET_NAAR_KEUZE_ZIGGO_SPORT_TOTAAL',
  EXTRATVPAKKETNAARKEUZEKIDS = 'EXTRA_TV_PAKKET_NAAR_KEUZE_KIDS',
  EXTRATVPAKKETNAARKEUZEMOVIESSERIESL = 'EXTRA_TV_PAKKET_NAAR_KEUZE_MOVIES_SERIES_L',
  ONEMAANDGRATISTURKSEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_TURKS_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISGAYLIFESTYLEEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_GAY_LIFESTYLE_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISHINDIEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_HINDI_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISMOVIESSERIESXLEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_MOVIES_SERIES_XL_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISMOVIESSERIESXLEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_MOVIES_SERIES_XL_EINDIGT_AUTOMATISCH',
  SIXMAANDENGRATISMOVIESSERIESXLEINDIGTAUTOMATISCH = '6_MAANDEN_GRATIS_MOVIES_SERIES_XL_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISZIGGOSPORTTOTAALEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_ZIGGO_SPORT_TOTAAL_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISZIGGOSPORTTOTAALEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_ZIGGO_SPORT_TOTAAL_EINDIGT_AUTOMATISCH',
  SIXMAANDENGRATISZIGGOSPORTTOTAALEINDIGTAUTOMATISCH = '6_MAANDEN_GRATIS_ZIGGO_SPORT_TOTAAL_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISMOVIESSERIESEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_MOVIES_SERIES_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISMOVIESSERIESEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_MOVIES_SERIES_EINDIGT_AUTOMATISCH',
  SIXMAANDENGRATISMOVIESSERIESEINDIGTAUTOMATISCH = '6_MAANDEN_GRATIS_MOVIES_SERIES_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISFOXSPORTSEREDIVISIEEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_FOX_SPORTS_EREDIVISIE_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISFOXSPORTSEREDIVISIEEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_FOX_SPORTS_EREDIVISIE_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISFOXSPORTSINTERNATIONALEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_FOX_SPORTS_INTERNATIONAL_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISFOXSPORTSINTERNATIONALEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_FOX_SPORTS_INTERNATIONAL_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISZIGGOSPORTTOTAALENFOXSPORTSEREDIVISIEEINDIGTAUTOMATISCH = '1_MAAND_GRATIS_ZIGGO_SPORT_TOTAAL_EN_FOX_SPORTS_EREDIVISIE_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISZIGGOSPORTTOTAALENFOXSPORTSEREDIVISIEEINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_ZIGGO_SPORT_TOTAAL_EN_FOX_SPORTS_EREDIVISIE_EINDIGT_AUTOMATISCH',
  ONEMAANDGRATISFILM1EINDIGTAUTOMATISCH = '1_MAAND_GRATIS_FILM1_EINDIGT_AUTOMATISCH',
  THREEMAANDENGRATISFILM1EINDIGTAUTOMATISCH = '3_MAANDEN_GRATIS_FILM1_EINDIGT_AUTOMATISCH',
  ZIGGOSPORTTOTAALBENEFIT = 'ZIGGO_SPORT_TOTAAL_BENEFIT',
  KIDSBENEFIT = 'KIDS_BENEFIT',
  MOVIESSERIESLBENEFIT = 'MOVIES_SERIES_L_BENEFIT',
  TWELVEMAANDENGRATISZIGGOSPORTTOTAALEINDIGTAUTOMATISCH = '12_MAANDEN_GRATIS_ZIGGO_SPORT_TOTAAL_EINDIGT_AUTOMATISCH',
  TWELVEMAANDENGRATISMOVIESSERIESXLEINDIGTAUTOMATISCH = '12_MAANDEN_GRATIS_MOVIES_SERIES_XL_EINDIGT_AUTOMATISCH',
  AOTVTVZENDERSPLUS = 'AO_TV_TV_ZENDERS_PLUS',
  AOTV12MAANDENGRATISESPNCOMPLEETEINDIGTAUTOMATISCH = 'AO_TV_12_MAANDEN_GRATIS_ESPN_COMPLEET_EINDIGT_AUTOMATISCH',

  ARABISCH = 'ARABISCH',
  KABELTV = 'KABEL_TV',
  TVSTANDAARD = 'TV_STANDAARD',
  ZIGGOSPORTTOTAAL = 'ZIGGO_SPORT_TOTAAL',
  ZIGGOSPORTTOTAALFOXSPORTEREDIVISIE = 'ZIGGO_SPORT_TOTAAL_FOX_SPORT_EREDIVISIE',
  FOXSPORTEREDIVISIE = 'FOX_SPORT_EREDIVISIE',
  FOXSPORTINTERNATIONAL = 'FOX_SPORT_INTERNATIONAL',
  FOXSPORTCOMPLETE = 'FOX_SPORT_COMPLETE',
  FOXSPORTEREDIVISIEFILM1ZIGGOSPORTTOTAAL = 'FOX_SPORT_EREDIVISIE_FILM1_ZIGGO_SPORT_TOTAAL',
  EROTIEK = 'EROTIEK',
  KIDS = 'KIDS',
  MOVIESSERIES = 'MOVIES_SERIES',
  MOVIESSERIESL = 'MOVIES_SERIES_L', // SkyShowtime
  MOVIESSERIESXL = 'MOVIES_SERIES_XL', // SkyShowtime & TV Zenders Plus
  FILM1 = 'FILM1',
  FILM1ZIGGOSPORTTOTAAL = 'FILM1_ZIGGO_SPORT_TOTAAL',
  TURKS = 'TURKS',
  HINDI = 'HINDI',
  GAYLIFESTYLE = 'GAY_LIFESTYLE',
  TVZENDERSPLUS = 'TV_ZENDERS_PLUS',
  VIAPLAY = 'VIAPLAY',
  NETFLIXZIGGOSPORTTOTAAL = 'NETFLIX_STANDAARD_ZIGGO_SPORT_TOTAAL',
  NETFLIXPREMIUMZIGGOSPORTTOTAAL = 'NETFLIX_PREMIUM_ZIGGO_SPORT_TOTAAL',
  DISNEYPLUSESPNCOMPLEET = 'DISNEY_PLUS_ESPN_COMPLEET',
  UNKNOWN = 'UNKNOWN',
}

export interface OTTSubscription {
  partnerID: string;
  accountID: string;
  status: string;
  OTTstatus: string;
  tier?: string;
  price?: string;
  priceExclVAT?: string;
  chargeMethod?: number;
  purchaseDate?: string;
  endDate?: string;
  renewalDate?: string;
  channel?: string;
  OTTactivationDate?: string;
  pricepointIdentifier?: string;
  regularPrice?: string;
  pricepointDescription?: string;
  subscriptionID?: string;
}

export interface TelevisionPackage {
  benefitBrand?: BenefitBrand;
  source: TelevisionPackageSource;
  type: string | null;
  title: string | null;
  name: string | null;
  subscription?: OTTSubscription | null;
  isK2vOnly?: boolean | null;
  isBundle?: boolean | null;
}

export enum TvTechnologyType {
  IRDETO = 'IRDETO',
  D4A = 'D4A',
  OBO = 'OBO',
  NOTAPPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum TelevisionReceiverDeviceModelEnum {
  STB = 'STB',
  CIP = 'CIP',
  DO = 'DO',
  NEXT = 'NEXT',
  NEXTMINI = 'NEXT_MINI',
  SMARTCARD = 'SMARTCARD',
  XL = 'XL',
  UNKNOWN = 'UNKNOWN',
}

export enum PurchaseCategory {
  RENTAL = 'RENTAL',
  LEASETOLENT = 'LEASE_TO_LENT',
  BUYTOOWN = 'BUY_TO_OWN',
  UNKNOWN = 'UNKNOWN',
}

export interface TelevisionReceiver {
  cpeType: TvTechnologyType;
  id: string;
  name: string;
  serialNumber: string | null;
  smartcardNumber: string | null;
  /** boolean that indicates that the tv receiver can be managed in the cloud. */
  connectCloudCapable: boolean;
  /** Only available for D4A receivers. Will also be empty for D4A receivers when there was error retrieving the code. */
  activationCode: string | null;
  /** The model of the device:   * `STB` - Television Hardware > STANDAARD_MEDIABOX_CI of type settopbox   * `CIP` - Television Hardware > STANDAARD_MEDIABOX_CI of type CIP+   * `DO` - Television Hardware > DIGITALE_ONTVANGER   * `NEXT` - Television Hardware > MEDIABOX_NEXT   * `SMARTCARD` - Television Hardware > SMARTCARD (BuyToOwn)   * `XL` - Shared Hardware > MEDIABOX_XL   * `UNKNOWN` - Fallback if we fail to identify */
  deviceModel: TelevisionReceiverDeviceModelEnum;
  purchaseCategory: PurchaseCategory;
  visualId: string | null;
}

export interface TelevisionOffering {
  id: string;
  name: string;
}

export interface TelevisionProduct {
  id: string;
  name: string;
  type: ProductType;
  packages: Array<TelevisionPackage>;
  receivers: Array<TelevisionReceiver>;
  offering?: TelevisionOffering;
}

export enum TelephonyProductExtraServicesEnum {
  BELLEN = 'VOLOP_BELLEN',
  BELLENINTERNATIONAL = 'VOLOP_BELLEN_INTERNATIONAL',
  BELLENINTERNATIONALFREE = 'VOLOP_BELLEN_INTERNATIONAL_FREE',
  BELLENBUSINESS = 'VOLOP_BELLEN_BUSINESS',
  BELLENINTERNATIONALBUSINESS = 'VOLOP_BELLEN_INTERNATIONAL_BUSINESS',
  BELLENINTERNATIONALEXTRALINE = 'VOLOP_BELLEN_INTERNATIONAL_EXTRA_LINE',
}

export interface ExtraService {
  type: TelephonyProductExtraServicesEnum;
  name: string;
}

export interface TelephonyProduct {
  id: string;
  name: string;
  type: ProductType;
  telephoneNumber: string;
  extraServices?: ExtraService;
}

export interface InternetModem {
  brand: string;
  model: string;
  serialNumber: string;
  /** boolean that indicates that the modem can be managed in the cloud. */
  connectCloudCapable: boolean;
  visualId: string;
  /* color used to choose WifiBooster NBA type */
  colour: string;
  logicalEquipmentId: string;
}

export enum WifiDevicesTypeEnum {
  CABLE = 'WIFIBOOSTER_INTERNETKABEL',
  CABLE_CONNECT = 'WIFIBOOSTER_INTERNETKABEL_CONNECT',
  SOCKET = 'WIFIBOOSTER_STOPCONTACT_BASISSET',
  KIT = 'WIFIBOOSTER_STOPCONTACT_UITBREIDING',
  ACCESSPOINT = 'INTERNETPUNT_STOPCONTACT_BASISSET',
  ACCESSPOINTKIT = 'INTERNETPUNT_STOPCONTACT_UITBREIDING',
  PODS = 'WIFI_PODS',
  PODS_WIFI6 = 'WIFI_POD_WIFI6',
  TPLINK = 'TP_LINK',
  TP_LINK_X60 = 'TP_LINK_X60',
  UNKNOWN = 'UNKNOWN',
}

export enum ModemTypeEnum {
  SMART_WIFI_MODEM = 'SMART_WIFI_MODEM',
  GIGABIT_MODEM = 'GIGABIT_MODEM',
  WIFI_MODEM = 'WIFI_MODEM',
  MODEM = 'MODEM',
}

export interface WifiDevices {
  name: string | null;
  type: WifiDevicesTypeEnum;
  serialNumber: string;
  /** boolean that indicates that the device can be managed in the cloud. */
  connectCloudCapable: boolean;
  purchaseCategory: PurchaseCategory;
  visualId: string | null;
}

export enum ServiceProductServiceTypeEnum {
  EMAIL = 'EMAIL',
  SAFEONLINEFREE = 'SAFE_ONLINE_FREE',
  SAFEONLINEPAID = 'SAFE_ONLINE_PAID',
  SAFEONLINEBENEFIT = 'SAFE_ONLINE_BENEFIT',
}

export interface ServiceProduct {
  id: string;
  name: string;
  type: ProductType;
  serviceType: ServiceProductServiceTypeEnum;
  licenseAmount: number | null;
}

export interface InternetProduct {
  id: string;
  name: string;
  type: ProductType;
  mediabox: TelevisionReceiver | null;
  modem: InternetModem | null;
  wifiDevices: Array<WifiDevices>;
  internetSecurity: ServiceProduct | null;
  downloadSpeed: string | null;
  uploadSpeed: string | null;
}

export enum TvBenefitType {
  KIDS = 'KIDS',
  MOVIESSERIESL = 'MOVIES_SERIES_L',
  ZIGGOSPORTTOTAAL = 'ZIGGO_SPORT_TOTAAL',
}

export interface BenefitInfo {
  beneficialBrand: BenefitBrand;
  selectedTVBenefit?: TvBenefitType;
  /** The selected (if applicable) safe online license. */
  selectedSafeOnlineBenefit?: string;
}

export interface Products {
  groupLevelProduct?: GroupLevelProduct;
  televisionProducts: Array<TelevisionProduct>;
  telephonyProducts: Array<TelephonyProduct>;
  internetProducts: Array<InternetProduct>;
  benefitInfo?: BenefitInfo | null;
  hasDigitalTV?: boolean;
}
