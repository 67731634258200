import { BaseLayoutContext } from 'components/BaseLayout/BaseLayout';
import { Loader } from 'components/Loader';
import { appSettings } from 'config/app.settings';
import React, { useContext, useEffect } from 'react';
import { getQueryStringParamByName } from 'utils/url';

export const PaymentRedirect = () => {
  const baseLayoutContext = useContext(BaseLayoutContext);
  baseLayoutContext.setShowHeaderAndFooter(false);

  useEffect(() => {
    const token = getQueryStringParamByName('token');
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    window.top.location.href = `${appSettings.ENV_PUBLIC_URL}betaling?token=${token}`;
  }, []);

  return <Loader />;
};
