import React from 'react';

export const CheckIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg role="none" width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M11.924 23.438L0 12.305l4.453-4.248 6.71 6.24L25.048 0 30 4.248z"
      fill="currentColor"
      transform="translate(1 4)"
    />
  </svg>
);
