import { IContent } from 'models/content.model';

export const content: IContent = {
  nl: {
    length: '8 tekens',
    lowerCase: '1 kleine letter',
    upperCase: '1 hoofdletter',
    number: '1 cijfer',
    specialCharacter: '1 speciaal teken, bv. @#!*+',
    policy: 'Gebruik minimaal:',
  },
  en: {},
};
